import { Avatar, AvatarProps } from '@mui/material';
import { memo } from 'react';
import { useAsync } from 'react-use';
import mediaService from '../../services/media';
import { getOptimizeImage } from '../../utils/imageUtils';
import { isLocalImage } from '../../utils/productUtils';

async function getThumbnailImage(image: string | PendingUploadImage) {
  if (typeof image === 'string') {
    return getOptimizeImage(image, { w: 128, h: 128, fit: 'crop' });
  }
  if (isLocalImage(image)) {
    return mediaService.getLocalThumbnail(image.name);
  }

  return getOptimizeImage(image, { w: 96, h: 96, fit: 'crop' });
}

type ProductImageProps = {
  image?: string | PendingUploadImage;
} & AvatarProps;

const ProductImage = ({ image, ...props }: ProductImageProps) => {
  const thumbnail = useAsync(
    async () => image && (await getThumbnailImage(image)),
    [image]
  );

  return (
    <Avatar
      src={thumbnail.value}
      variant="square"
      alt="product image"
      {...props}
    />
  );
};

export default memo(ProductImage);
