import { memo } from 'react';
// import Download from '../../components/Icons/Download';
import Iconify from '../../minimal/Iconify';
import { Box, Button, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useAppDispatch from '../../hooks/useAppDispatch';
import { pageCashbooksActions } from './slice';

const ButtonStyle = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  boxShadow: 'none',
  '&:not(:last-of-type)': {
    marginRight: theme.spacing(2),
  },
}));

const CashbooksHeaderBreadcrumbs = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      {/* <ButtonStyle
        variant="outlined"
        startIcon={<Download fill={theme.palette.primary.main} />}
        onClick={() => setOpenCommingSoon(true)}
      >
        Tải báo cáo
      </ButtonStyle> */}
      <ButtonStyle
        variant="contained"
        startIcon={
          <Iconify
            icon="eva:arrow-upward-fill"
            color={theme.palette.common.white}
          />
        }
        sx={{
          backgroundColor: theme.palette.error.dark,
          '&.MuiButton-root:hover': {
            backgroundColor: theme.palette.error.darker,
          },
        }}
        onClick={() => {
          dispatch(pageCashbooksActions.setDialogType('out'));
          dispatch(pageCashbooksActions.setCategoryType('out'));
        }}
      >
        Tạo khoản chi
      </ButtonStyle>
      <ButtonStyle
        variant="contained"
        startIcon={
          <Iconify
            icon="eva:arrow-downward-fill"
            color={theme.palette.common.white}
          />
        }
        color="primary"
        onClick={() => {
          dispatch(pageCashbooksActions.setDialogType('in'));
          dispatch(pageCashbooksActions.setCategoryType('in'));
        }}
      >
        Tạo khoản thu
      </ButtonStyle>
    </Box>
  );
};
export default memo(CashbooksHeaderBreadcrumbs);
