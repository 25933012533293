import { Formik } from 'formik';
import MainLayout from '../../layouts/MainLayout';
import Header from './Header';
import { useEffect, memo } from 'react';
import OrderDetailTable from './OrderDetailTable';
import { Box, Grid, Typography } from '@mui/material';
import OrderTracking from './OrderTracking';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import { useParams } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  pageOrderDetailActions,
  selectPageOrderDetail,
  selectLocalOrderItem,
} from './slice';
import useAppSelector from '../../hooks/useAppSelector';
import Loader from '../Loader';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { getTotalPrice } from './utils';
import CustomerInfo from './CustomerInfo';
import HeaderBreadcrumbs from '../../minimal/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../config/routeConfig';
import OrderPaymentDialog from './OrderPaymentDialog';
import { ORDER_STATUS } from '../../utils/constants';
import contactService from '../../services/contact';
import orderService from '../../services/order';
import { useTranslation } from 'react-i18next';
import { AlertCustom } from './OrderDetailComponent';
import { pageOrdersActions } from '../Orders/slice';
import { selectAppAuth } from '../../store/auth';

const OrderDetail = () => {
  const { t } = useTranslation();
  const {
    main: {
      orderDetail,
      otherDiscountUnit,
      isEdit,
      orderPaymentDialog,
      contactDetail,
    },
  } = useAppSelector(selectPageOrderDetail);
  const dispatch = useAppDispatch();
  const { number } = useParams<{ number?: string }>();
  const getCreateOrderInput = useAppSelector(selectLocalOrderItem);
  const { business } = useAppSelector(selectAppAuth);

  useEffect(() => {
    dispatch(pageOrdersActions.getConfigInvoice(business?.phone_number || ''));
    dispatch(pageOrdersActions.getPaymentsInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (number) {
      dispatch(pageOrderDetailActions.fetchOrderDetail(number));
    }
    return () => {
      dispatch(pageOrderDetailActions.reset());
    };
  }, [dispatch, number]);

  useEffect(() => {
    orderDetail.id &&
      dispatch(pageOrderDetailActions.fetchOrderTracking(orderDetail.id));
  }, [dispatch, orderDetail]);

  if (!orderDetail.id) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  return (
    <Formik<CreateOrderInput>
      initialValues={getCreateOrderInput.createOrderInputCus}
      enableReinitialize
      // validationSchema={orderSchema}
      onSubmit={async (values) => {
        try {
          if (otherDiscountUnit === 'percent') {
            const otherDiscount =
              (values.other_discount / 100) *
              (getTotalPrice(
                values.list_order_item?.map((value) => ({
                  quantity: value.quantity,
                  price: value.price,
                }))
              ) -
                (values.promotion_discount || 0));

            dispatch(pageOrderDetailActions.setOtherDiscountUnit('cash'));
            values.other_discount = otherDiscount;
          }

          if (orderDetail.buyer_info.address !== values.buyer_info.address) {
            const nextContact: updateContact = {
              ...values.buyer_info,
              business_id: contactDetail?.business_id || '',
            };
            await contactService.updateContact({
              id: contactDetail?.id || '',
              contact: nextContact,
            });
          }

          const res = await orderService.updateOrderDetail({
            id: orderDetail.id,
            body: values,
          });
          if (res?.status) {
            dispatch(pageOrderDetailActions.setSubmitResponse(res));
            dispatch(pageOrderDetailActions.setIsEdit(true));
          } else {
            number && dispatch(pageOrderDetailActions.fetchOrderDetail(number));
            dispatch(
              toastActions.create({
                severity: 'success',
                message: 'Lưu chỉnh sửa đơn hàng thành công',
                dismissAfter: 3000,
              })
            );
          }
        } catch (ex) {
          dispatch(
            toastActions.create({
              severity: 'error',
              message: getErrorMessage(ex),
              dismissAfter: 3000,
            })
          );
        }
      }}
    >
      <MainLayout>
        <HeaderBreadcrumbs
          // Mã đơn hàng: {number}
          action={
            <Header
              state={orderDetail.state}
              orderDetailId={orderDetail.id}
              orderId={orderDetail.id}
            />
          }
          links={[
            { name: 'Quản lý đơn hàng', href: PATH_DASHBOARD.order.list },
            {
              name: orderDetail.order_number,
              href: PATH_DASHBOARD.order.detail,
            },
          ]}
        >
          <Box display={'flex'} alignItems={'center'}>
            <Typography variant="h4" display={'inline-block'}>
              {isEdit ? 'Chỉnh sửa đơn hàng' : 'Chi tiết đơn hàng'}
            </Typography>
            {
              <AlertCustom orderState={orderDetail.state} icon={false}>
                {t('order_state', { context: orderDetail.state })}
              </AlertCustom>
            }
          </Box>
        </HeaderBreadcrumbs>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Thông tin khách hàng
        </Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={6}>
            <CustomerInfo isEdit={isEdit} />
          </Grid>
          <Grid item xs={6}>
            <OrderTracking />
          </Grid>
        </Grid>

        <OrderDetailTable state={orderDetail.state} />
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <LeftPanel />
          </Grid>
          <Grid item xs={6}>
            <RightPanel />
          </Grid>
        </Grid>
        {orderPaymentDialog && <OrderPaymentDialog />}
      </MainLayout>
    </Formik>
  );
};

export default memo(OrderDetail);
