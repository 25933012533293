import { Box, Typography, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { numberFormat } from '../../utils/constants';
import useResponsive from '../../hooks/useResponsive';

type CommonSummaryProps = {
  title: string;
  value: number;
  icon: React.ReactNode;
  onClick?: () => void;
};

const CardStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 0, 0, 3),
}));

const CommonSummary = ({
  title,
  value = 0,
  icon,
  onClick,
}: CommonSummaryProps) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  return (
    <CardStyle
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'unset',
        '&:hover': { color: onClick ? theme.palette.primary.main : 'unset' },
      }}
    >
      <Box overflow="hidden" width={isDesktop ? '80%' : '60%'}>
        <Typography variant="subtitle2" fontWeight={500} sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography variant={isDesktop ? 'h3' : 'h5'} fontWeight={600} noWrap>
          {numberFormat.format(value)}
        </Typography>
      </Box>
      {icon}
    </CardStyle>
  );
};

export default CommonSummary;
