import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import EmptyProduct from '../../components/Icons/EmptyProduct';

const EmptyState = () => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={10}
      height="28vh"
    >
      <EmptyProduct />
      <Typography color={theme.palette.grey[500]} sx={{ my: 3 }}>
        Cửa hàng chưa có sản phẩm nào!
      </Typography>
    </Box>
  );
};

export default memo(EmptyState);
