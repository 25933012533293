import {
  Box,
  Typography,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel,
  Button,
  Popover,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useState, useMemo, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import { getFinalPrice } from '../../utils/productUtils';
import InventoryBar from '../../components/InventoryBar';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageProductEdit } from './slice';
import TextFieldNumber from '../../components/TextFieldNumber';
import TextFieldDecimal from '../../components/TextFieldDecimal';

type BoxStyleProps = {
  display?: string;
  mb?: string;
};

const BoxStyle = styled(Box)<BoxStyleProps>(
  ({ display = 'flex', mb, theme }) => ({
    marginBottom: mb || theme.spacing(2),
    display,
    width: '100%',
  })
);

const ToggleButtonStyle = styled(ToggleButton)(({ theme }) => ({
  paddingTop: theme.spacing(0.8),
  paddingBottom: theme.spacing(0.8),
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[500],
}));

const PopoverStyle = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const PopoverContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  maxWidth: '300px',
  padding: theme.spacing(1.5),
}));

type ProductInventoryProps = {
  openInventory: boolean;
  setOpenInventory?: (value: boolean) => void;
};

const ProductInventory = ({
  openInventory,
  setOpenInventory,
}: ProductInventoryProps) => {
  const formik = useFormikContext<PendingProduct>();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [warningInventory, setWarningInventory] = useState(false);
  const [agreeConfirm, setAgreeConfirm] = useState(false);
  const { isConfirmed } = useAppSelector(selectPageProductEdit);
  const sku = formik.values.skus[0];

  const validWarningInventory = useMemo(() => {
    return (
      (sku &&
        sku.po_details &&
        sku.po_details.warning_value <= sku.po_details.quantity) ||
      false
    );
  }, [sku]);

  const displayPercent = useMemo(() => {
    if (formik.values.skus.length > 0 && sku) {
      const selectedSku = sku;
      const percent =
        ((getFinalPrice(selectedSku) - selectedSku.historical_cost) /
          selectedSku.historical_cost) *
        100;
      const percentValue = Math.abs(percent) > 500 ? 500 : Math.abs(percent);
      return selectedSku.historical_cost > 0
        ? percentValue?.toFixed(percentValue < 10 ? 1 : 0)
        : '0';
    }
  }, [formik.values.skus.length, sku]);

  useEffect(() => {
    if (((sku && sku.po_details && sku.po_details.warning_value) || 0) > 0) {
      setWarningInventory(true);
    }
  }, [formik.values.skus, sku]);

  const handleChangeOpenAvanced = (event: ExpectedAny) => {
    if (isConfirmed) {
      formik.setFieldValue('is_advance_stock', true);
      return setOpenInventory?.(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleChangeWarning = () => {
    setWarningInventory((state) => {
      if (!state) {
        formik.setFieldValue(
          'skus[0].po_details.warning_value',
          ((sku?.po_details?.quantity || 0) * 10) / 100
        );
      } else {
        formik.setFieldValue('skus[0].po_details.warning_value', 0);
      }
      return !warningInventory;
    });
  };

  const handleConfirmInventory = () => setAgreeConfirm(!agreeConfirm);

  const handleClose = () => setAnchorEl(null);

  return (
    <Box>
      <Typography sx={{ mb: 2 }}>Tồn kho</Typography>
      {openInventory && sku && sku.po_details ? (
        <Box>
          <BoxStyle>
            <TextFieldDecimal
              id="skus[0].po_details.quantity"
              fullWidth
              label="Tồn kho"
              placeholder="Nhập tồn kho"
              value={sku.po_details.quantity || 0}
              onSetField={(value) => {
                if (warningInventory) {
                  formik.setFieldValue(
                    'skus[0].po_details.warning_value',
                    (value * 10) / 100
                  );
                }
                formik.setFieldValue('skus[0].po_details.quantity', value);
              }}
              sx={{ mr: 1.5 }}
            />
            <TextFieldNumber
              id="skus[0].po_details.blocked_quantity"
              fullWidth
              label="Lưu kho không bán"
              placeholder="Nhập lượng sản phẩm lưu kho không bán"
              error={formik.errors?.skus?.[0].po_details?.blocked_quantity}
              helperText={formik.errors?.skus?.[0].po_details?.blocked_quantity}
              value={sku.po_details.blocked_quantity || 0}
              onSetField={(value) =>
                formik.setFieldValue(
                  'skus[0].po_details.blocked_quantity',
                  value
                )
              }
            />
          </BoxStyle>
          <BoxStyle
            mb={!validWarningInventory ? '0px' : theme.spacing(2)}
            display="flex"
            justifyContent="space-between"
          >
            <Typography>
              Lãi lỗ trên giá vốn:{' '}
              <Typography component="span" color={theme.palette.primary.main}>
                {displayPercent}%
              </Typography>
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <FormControlLabel
                control={<Switch color="primary" checked={warningInventory} />}
                onChange={handleChangeWarning}
                label="Cảnh báo tồn kho"
                labelPlacement="start"
                sx={{ ml: 2 }}
              />
              {warningInventory && (
                <TextFieldDecimal
                  id="skus[0].po_details.warning_value"
                  placeholder="00"
                  value={sku.po_details.warning_value || 0}
                  error={!validWarningInventory}
                  sx={{ width: '120px', ml: 2 }}
                  onSetField={(value) =>
                    formik.setFieldValue(
                      'skus[0].po_details.warning_value',
                      value
                    )
                  }
                />
              )}
            </Box>
          </BoxStyle>
          {warningInventory && !validWarningInventory && (
            <Box textAlign="right" mb={2}>
              <Typography variant="caption" color={theme.palette.error.main}>
                Cảnh báo tồn kho không được lớn hơn số lượng tồn kho
              </Typography>
            </Box>
          )}
          <Box>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography>Tổng sản phẩm</Typography>
              <Typography>{sku.po_details?.quantity || 0}</Typography>
            </Box>
            <InventoryBar data={sku.po_details} />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between">
          <ToggleButtonGroup
            color="primary"
            value={formik.values.is_active}
            exclusive
            onChange={(_e, value) => formik.setFieldValue('is_active', value)}
          >
            <ToggleButtonStyle value={true}>Còn hàng</ToggleButtonStyle>
            <ToggleButtonStyle value={false}>Hết hàng</ToggleButtonStyle>
          </ToggleButtonGroup>
          <FormControlLabel
            control={<Switch color="primary" checked={openInventory} />}
            onChange={handleChangeOpenAvanced}
            label="Bật tồn kho nâng cao"
            labelPlacement="start"
          />
        </Box>
      )}

      <PopoverStyle
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <PopoverContent>
          <Typography variant="body2" fontWeight={600}>
            Bật tồn kho nâng cao
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[500]}>
            Không thể quay về quản lý cơ bản cho sản phẩm này sau khi bật tồn
            kho. Bạn có muốn tiếp tục?
          </Typography>
          <Box textAlign="center" mb={1.5}>
            <FormControlLabel
              control={<Checkbox onChange={handleConfirmInventory} />}
              label="Không hiển thị lại nhắc nhở này"
            />
          </Box>
          <Button
            variant="outlined"
            fullWidth
            sx={{ textTransform: 'unset' }}
            onClick={() => {
              formik.setFieldValue('is_advance_stock', true);
              setOpenInventory?.(true);
              setAnchorEl(null);
              if (agreeConfirm) {
                localStorage.setItem('confirm-advance-inventory', 'true');
              }
            }}
          >
            Xác nhận bật tồn kho
          </Button>
        </PopoverContent>
      </PopoverStyle>
    </Box>
  );
};

export default memo(ProductInventory);
