import { TableRow, TableCell, Box, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { numberFormat, ORDER_STATUS } from '../../utils/constants';

const OrderStateWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '6px',
  padding: theme.spacing(1),
  width: 'fit-content',
}));

const OrderState = ({ order }: { order: Order }) => {
  const theme = useTheme();

  switch (order.state) {
    case ORDER_STATUS.WAITING_CONFIRM:
      return (
        <TableCell align="left">
          <OrderStateWrapper
            bgcolor={theme.palette.warning.lighter}
            color={theme.palette.warning.dark}
          >
            Chờ xác nhận
          </OrderStateWrapper>
        </TableCell>
      );
    case ORDER_STATUS.DELIVERING:
      return (
        <TableCell align="left">
          <OrderStateWrapper
            bgcolor={theme.palette.success.lighter}
            color={theme.palette.success.dark}
          >
            Đang giao
          </OrderStateWrapper>
        </TableCell>
      );
    case ORDER_STATUS.CANCEL:
      return (
        <TableCell align="left">
          <OrderStateWrapper
            bgcolor={theme.palette.error.lighter}
            color={theme.palette.error.dark}
          >
            Đã huỷ
          </OrderStateWrapper>
        </TableCell>
      );
    default:
      return (
        <TableCell align="left">
          <OrderStateWrapper
            bgcolor={theme.palette.primary.lighter}
            color={theme.palette.primary.dark}
          >
            Đã giao
          </OrderStateWrapper>
        </TableCell>
      );
  }
};

const PaymentState = ({ order }: { order: Order }) => {
  return (
    <TableCell>
      {order.amount_paid === order.grand_total ? (
        <Typography variant="body2" fontWeight={500}>
          Đã trả
        </Typography>
      ) : order.amount_paid > 0 && order.amount_paid < order.grand_total ? (
        <Typography variant="body2" fontWeight={500}>
          Đã trả một phần
        </Typography>
      ) : (
        <Typography variant="body2" fontWeight={500}>
          Chưa trả
        </Typography>
      )}
    </TableCell>
  );
};

const TopOrdersRow = ({ order, index }: { order: Order; index: number }) => {
  const navigate = useNavigate();

  return (
    <TableRow
      hover
      sx={{
        backgroundColor: index % 2 != 0 ? '#919eab14' : 'white',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/orders/${order.order_number}`)}
    >
      <TableCell>{order.order_number}</TableCell>
      <TableCell align="right">
        {numberFormat.format(order.grand_total)}
      </TableCell>
      <OrderState order={order} />
      <PaymentState order={order} />
      <TableCell>
        {moment(order.created_at).format('DD/MM/YYYY HH:mm')}
      </TableCell>
    </TableRow>
  );
};

export default memo(TopOrdersRow);
