import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import LogoText from '../../components/Icons/LogoText';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3),
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  marginTop: `${theme.spacing(5)}`,
  marginLeft: `${theme.spacing(5)}`,
}));

type BannerProps = {
  text: string;
  primaryText?: string;
  imageUrl: string;
  imageWidth?: string;
  height?: string;
};

const Banner = ({
  text,
  primaryText,
  imageUrl,
  imageWidth,
  height,
}: BannerProps) => {
  return (
    <Root sx={{ height }}>
      <LogoWrapper>
        <LogoText />
      </LogoWrapper>
      <Typography fontSize="32px">
        {text}{' '}
        {primaryText && (
          <Typography
            fontSize="32px"
            component="span"
            color={(theme) => theme.palette.primary.main}
          >
            {primaryText}
          </Typography>
        )}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Box component="img" src={imageUrl} width={imageWidth} />
      </Box>
    </Root>
  );
};

export default Banner;
