import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  styled,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { numberFormat } from '../../utils/constants';
import { getOptimizeImage } from '../../utils/imageUtils';
import ProductImage from '../ProductImage';

const AvatarStyle = styled(ProductImage)(({ theme }) => ({
  width: '48px',
  height: '48px',
  marginRight: theme.spacing(2),
  borderRadius: '12px',
}));

const ItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 5),
}));

type ConfirmationProps = {
  open: boolean;
  onClose(): void;
  submitResponse: list_order_item[] | null;
};

const ProductRemovedDialog = ({
  open,
  onClose,
  submitResponse,
}: ConfirmationProps) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle color={theme.palette.grey[800]} sx={{ textAlign: 'center' }}>
        <Typography variant="h4">Xác nhận đơn hàng</Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: theme.spacing(2), textAlign: 'center' }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Một số sản phẩm trong giỏ hàng đã hết hàng hoặc ngừng kinh doanh. Cửa
          hàng sẽ tiến hành bỏ các sản phẩm này khỏi đơn hàng của bạn. Vui lòng
          cập nhật lại sản phẩm trong giỏ hàng
        </Typography>
        {submitResponse?.map((item) => (
          <ItemWrapper key={item.sku_id}>
            <Box display="flex" alignItems="center">
              <AvatarStyle
                image={getOptimizeImage(
                  `${item.media?.[0] || '/assets/product-image.png'}`,
                  {
                    w: 128,
                    h: 128,
                    fit: 'crop',
                  }
                )}
                variant="square"
                alt={item.sku_name}
              />
              <Box textAlign="left">
                <Typography
                  variant="subtitle2"
                  color={theme.palette.grey[800]}
                  noWrap
                >
                  {item.product_name}
                </Typography>
                {item.sku_name && (
                  <Typography variant="body2" noWrap>
                    {item.sku_name}
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              {numberFormat.format(item.price || 0)}đ
            </Typography>
          </ItemWrapper>
        ))}
      </DialogContent>
      <DialogActions>
        {/* <Button variant="outlined" color="inherit" onClick={onClose}>
          Quay lại
        </Button> */}
        <Button variant="contained" onClick={() => onClose()}>
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductRemovedDialog;
