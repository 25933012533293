const CameraPlus = ({
  className,
  fill = '#00AB55',
}: {
  className?: string;
  fill?: string;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.5 6.66732C2.5 7.12565 2.875 7.50065 3.33333 7.50065C3.79167 7.50065 4.16667 7.12565 4.16667 6.66732V5.00065H5.83333C6.29167 5.00065 6.66667 4.62565 6.66667 4.16732C6.66667 3.70898 6.29167 3.33398 5.83333 3.33398H4.16667V1.66732C4.16667 1.20898 3.79167 0.833984 3.33333 0.833984C2.875 0.833984 2.5 1.20898 2.5 1.66732V3.33398H0.833333C0.375 3.33398 0 3.70898 0 4.16732C0 4.62565 0.375 5.00065 0.833333 5.00065H2.5V6.66732Z"
      fill={fill}
    />
    <ellipse cx="10.8333" cy="11.666" rx="2.5" ry="2.5" fill={fill} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8583 5.00065H17.5C18.4167 5.00065 19.1667 5.75065 19.1667 6.66732V16.6673C19.1667 17.584 18.4167 18.334 17.5 18.334H4.16667C3.25 18.334 2.5 17.584 2.5 16.6673V8.10065C2.75 8.24232 3.025 8.33398 3.33333 8.33398C4.25 8.33398 5 7.58398 5 6.66732V5.83398H5.83333C6.75 5.83398 7.5 5.08398 7.5 4.16732C7.5 3.85898 7.40833 3.58398 7.26667 3.33398H12.6C13.0667 3.33398 13.5167 3.53398 13.825 3.87565L14.8583 5.00065ZM6.66667 11.6673C6.66667 13.9673 8.53333 15.834 10.8333 15.834C13.1333 15.834 15 13.9673 15 11.6673C15 9.36732 13.1333 7.50065 10.8333 7.50065C8.53333 7.50065 6.66667 9.36732 6.66667 11.6673Z"
      fill={fill}
    />
  </svg>
);

export default CameraPlus;
