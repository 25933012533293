import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import cashbookService from '../../services/cashbook';
import contactService from '../../services/contact';
import dashboardService from '../../services/dashboard';
import orderService from '../../services/order';
import { AppDispatch } from '../../store';
import { RootState } from '../../store/rootReducer';
import { delay } from '../../utils/promiseUtils';
import { DateRangeType } from './utils';

type SortType = 'revenue' | 'quantity';

type LatestOrder = {
  orders: Array<Order>;
  meta: ResponseMeta;
};

type TransactionContacts = {
  data: Array<DebtReminderContact>;
  meta: ResponseMeta;
};

type DashboardState = {
  shop: ShopOverview | null;
  topProducts: Array<ProductOverview> | null;
  inventory: InventoryOverview | null;
  visitedCustomers: VisitedCustomers | null;
  banners: Array<Banner> | null;
  cashbookAnalyticsIn: CashbookAnalytics | null;
  cashbookAnalyticsOut: CashbookAnalytics | null;
  cashbookAmount: CashbookTotalAmount | null;
  transactionContacts: TransactionContacts | null;
  latestOrders: LatestOrder | null;
  openDateOption: boolean;
  openDatePicker: boolean;
  dateRange: DateRangeType;
  order: SortType;
};

const initialState: DashboardState = {
  shop: null,
  topProducts: null,
  inventory: null,
  visitedCustomers: null,
  banners: null,
  cashbookAnalyticsIn: null,
  cashbookAnalyticsOut: null,
  cashbookAmount: null,
  transactionContacts: null,
  latestOrders: null,
  openDateOption: false,
  openDatePicker: false,
  dateRange: 'today',
  order: 'revenue',
};

const refresh = createAsyncThunk<
  [
    ShopOverview,
    ShopOverview,
    Array<ProductOverview>,
    InventoryOverview,
    // VisitedCustomers,
    // Array<Banner>,
    CashbookAnalytics,
    CashbookAnalytics,
    CashbookTotalAmount,
    TransactionContacts,
    LatestOrder
  ],
  void,
  { state: RootState }
>('dashboard/refresh', async (_, { getState }) => {
  const state = selectPageDashboard(getState());

  return await Promise.all([
    dashboardService.getShopOverview({
      start_time: moment().startOf('D').toISOString(),
    }),
    dashboardService.getShopOverview({
      start_time: '2020-04-05T17:00:00.000Z',
    }),
    dashboardService.getTopProducts(state.order, [
      moment().startOf('d').subtract(6, 'days'),
      moment().endOf('d'),
    ]),
    dashboardService.getInventoryOverview(),
    // dashboardService.getVisitedCustomers(),
    // dashboardService.getBanners(),
    dashboardService.getTransactionAnalytics({ transaction_type: 'in' }),
    dashboardService.getTransactionAnalytics({ transaction_type: 'out' }),
    cashbookService.getTransactionTotalAmount({
      start_time: moment().startOf('d').toISOString(),
      end_time: moment().endOf('d').toISOString(),
    }),
    contactService.getTransactionContacts({
      transaction_type: 'all',
      page_size: 2,
    }),
    orderService.getOrders({ page: 0, pageSize: 10, sort: 'created_at desc' }),
  ]);
});

const SYNC_IDLE = 30000;

const start = createAsyncThunk<void, AbortSignal, { dispatch: AppDispatch }>(
  'page_dashboard/start',
  async (signal, { dispatch }) => {
    while (!signal.aborted) {
      await dispatch(refresh()).unwrap();
      await dispatch(getVisitedCustomers());
      await delay(SYNC_IDLE);
    }
  }
);

const getVisitedCustomers = createAsyncThunk(
  'page_dashboard/get_visited_customers',
  async () => {
    const visitedCustomers = await dashboardService.getVisitedCustomers();

    if (!visitedCustomers) {
      throw new Error('NO_VISITED_CUSTOMERS');
    }

    return visitedCustomers;
  }
);

const getDeptAmount = createAsyncThunk(
  'page_dashboard/get_dept_amount',
  async () => {
    const deptAmount = await cashbookService.getTransactionTotalAmount({
      start_time: moment().startOf('d').toISOString(),
      end_time: moment().endOf('d').toISOString(),
    });

    if (!deptAmount) {
      throw new Error('NO_DEPT_AMOUNT');
    }

    return deptAmount;
  }
);

const dashboardSlice = createSlice({
  name: 'page_dashboard',
  initialState,
  reducers: {
    setDateRange: (state, action: PayloadAction<DateRangeType>) => {
      state.dateRange = action.payload;
    },
    setOpenDatePicker: (state, action: PayloadAction<boolean>) => {
      state.openDatePicker = action.payload;
    },
    setOpenDateOption: (state, action: PayloadAction<boolean>) => {
      state.openDateOption = action.payload;
    },
    setOrder: (state, action: PayloadAction<SortType>) => {
      state.order = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refresh.fulfilled, (state, action) => {
      const allOverview = {
        order_delivering_total: action.payload[1].order_delivering_total,
        order_waiting_confirm_total:
          action.payload[1].order_waiting_confirm_total,
      };
      state.shop = { ...action.payload[0], ...allOverview };
      state.topProducts = action.payload[2];
      state.inventory = action.payload[3];
      // state.visitedCustomers = action.payload[4];
      // state.banners = action.payload[4];
      state.cashbookAnalyticsIn = action.payload[4];
      state.cashbookAnalyticsOut = action.payload[5];
      state.cashbookAmount = action.payload[6];
      state.transactionContacts = action.payload[7];
      state.latestOrders = action.payload[8] || [];
    });
    builder.addCase(getVisitedCustomers.fulfilled, (state, action) => {
      if (action.payload) {
        state.visitedCustomers = action.payload;
      }
    });
    builder.addCase(getDeptAmount.fulfilled, (state, action) => {
      if (action.payload) {
        state.cashbookAmount = action.payload;
      }
    });
  },
});

export const pageDashboardActions = {
  start,
  refresh,
  getDeptAmount,
  ...dashboardSlice.actions,
};
export const selectPageDashboard = (state: RootState) => state.page_dashboard;
export default dashboardSlice;
