import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

const DialogTitleStyle = styled(DialogTitle)(() => ({
  fontWeight: 600,
}));

type PromotionDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ShippingAddressDialog = ({ open, setOpen }: PromotionDialogProps) => {
  const theme = useTheme();
  const formik = useFormikContext<PendingOrderForm>();
  const [value, setValue] = useState(formik.values.buyer_info.address);

  useEffect(
    () => setValue(formik.values.buyer_info.address),
    [formik.values.buyer_info.address]
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
      <DialogTitleStyle>Thêm địa chỉ giao hàng</DialogTitleStyle>
      <DialogContent
        sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        <Box mt={3}>
          <TextField
            label="Địa chỉ cụ thể"
            placeholder="Số nhà, tên đường/ khu phố, phường/ xã, ..."
            multiline
            autoFocus
            rows={4}
            fullWidth
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => setOpen(false)}
        >
          Quay lại
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            formik.setFieldValue('buyer_info.address', value);
            setOpen(false);
          }}
        >
          Hoàn thành
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShippingAddressDialog;
