import qs from 'qs';
import { API_URI } from '../config/env';
import { fetchData, post } from '../utils/fetch';
import authService from './auth';

async function getPromotions(lastUpdate: Date) {
  const business_id = await authService.getBusinessId();
  return fetchData<Array<JSONRecord<Promotion>>>(
    `${API_URI}/finan-promotion/api/v1/promotions/get-list?${qs.stringify({
      business_id,
      page: 0,
      page_size: 1000,
      sort: '',
      name: '',
      update_after: lastUpdate.toISOString(),
      has_record_deleted: true,
    })}`,
    {
      authorization: true,
    }
  );
}

async function processPromotion(data: ParamsProcessPromotion) {
  const business_id = await authService.getBusinessId();

  return await post<ProcessPromotion>(
    `${API_URI}/finan-promotion/api/v1/promotions/process`,
    {
      ...data,
      business_id,
    },
    {
      authorization: true,
    }
  );
}

const promotionService = { getPromotions, processPromotion };

export default promotionService;
