import { CLOUDFRONT_URI } from '../config/env';

type SelfServiceOptions = ({ w: number } | { w: number; h: number }) & {
  fit: 'crop';
};

export function getOptimizeImage<T = ExpectedAny>(
  url: string,
  op: SelfServiceOptions & T
) {
  if (!url) {
    return url;
  }

  const size = 'h' in op ? `${op.w}x${op.h}` : `w${op.w}`;

  return url.replace(CLOUDFRONT_URI, `${CLOUDFRONT_URI}/v2/${size}`);
}
