import { Box, Typography } from '@mui/material';
import CommingSoon from '../../components/Icons/CommingSoon';
import MainLayout from '../../layouts/MainLayout';
import { useTheme } from '@mui/material/styles';

const Inventory = () => {
  const theme = useTheme();
  return (
    <MainLayout>
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box textAlign="center">
          <CommingSoon />
          <Typography
            variant="h4"
            color={theme.palette.grey[600]}
            sx={{ mt: 3 }}
          >
            Chúng tôi đang phát triển tính năng này thật hoàn chỉnh. Hãy đón chờ
            nhé!
          </Typography>
        </Box>
      </Box>
    </MainLayout>
  );
};

export default Inventory;
