import {
  Box,
  Typography,
  Card,
  CardHeader,
  Stack,
  LinearProgress,
} from '@mui/material';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageDashboard } from './slice';
import { numberFormat } from '../../utils/constants';
import useResponsive from '../../hooks/useResponsive';
import { useMemo } from 'react';

const Inventory = () => {
  const isDesktop = useResponsive('up', 'lg');
  const { inventory } = useAppSelector(selectPageDashboard);
  const totalProductQuantity = useMemo(() => {
    if (inventory) {
      return (
        inventory.total_can_sell_quantity +
        inventory.total_out_of_stock_quantity
      );
    }
    return 0;
  }, [inventory]);

  return (
    <Card>
      <CardHeader
        title="Kho hàng"
        sx={{ '& .MuiTypography-root': { fontWeight: 500 } }}
      />
      <Stack spacing={3} sx={{ px: 3, my: 3 }}>
        <LinearProgress
          variant="determinate"
          value={inventory?.total_quantity ? 100 : 0}
          color="warning"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          value={
            (inventory &&
              (inventory?.total_can_sell_quantity / totalProductQuantity) *
                100) ||
            0
          }
          color="success"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
        <LinearProgress
          variant="determinate"
          value={
            (inventory &&
              (inventory?.total_out_of_stock_quantity / totalProductQuantity) *
                100) ||
            0
          }
          color="error"
          sx={{ height: 8, bgcolor: 'grey.50016' }}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 3, pb: 3, pt: 0.5 }}
      >
        <Stack alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                bgcolor: 'warning.main',
              }}
            />
            <Typography
              variant="subtitle2"
              fontSize={isDesktop ? '13px' : '12px'}
              sx={{ color: 'text.secondary' }}
            >
              Tổng kho
            </Typography>
          </Stack>

          <Typography variant="h6">
            {numberFormat.format(inventory?.total_quantity || 0)}
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',

                bgcolor: 'success.main',
              }}
            />
            <Typography
              variant="subtitle2"
              fontSize={isDesktop ? '13px' : '12px'}
              sx={{ color: 'text.secondary' }}
            >
              Còn hàng
            </Typography>
          </Stack>

          <Typography variant="h6">
            {numberFormat.format(inventory?.total_can_sell_quantity || 0)}
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',

                bgcolor: 'error.main',
              }}
            />
            <Typography
              variant="subtitle2"
              fontSize={isDesktop ? '13px' : '12px'}
              sx={{ color: 'text.secondary' }}
            >
              Hết hàng
            </Typography>
          </Stack>

          <Typography variant="h6">
            {numberFormat.format(inventory?.total_out_of_stock_quantity || 0)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default Inventory;
