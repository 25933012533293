import { TableRow, TableCell, Avatar, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
// import Chat from '../../components/Icons/Chat';
import { numberFormat } from '../../utils/constants';
import { getOptimizeImage } from '../../utils/imageUtils';
import { formatPhone } from '../Dashboard/utils';
import { OverflowText } from './ContactsComponent';

const ProductsRow = ({
  contact,
  index,
}: {
  contact: Contact;
  index: number;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const renderColorMoney = (contact: Contact) => {
    if (!contact.debt_amount) return theme.palette.grey[800];
    if (contact.option === 'out') return theme.palette.error.main;
    return theme.palette.primary.main;
  };

  return (
    <TableRow
      key={contact.id}
      hover
      sx={{
        cursor: 'pointer',
      }}
      onClick={() =>
        navigate(`/contacts/${contact.id}?business_id=${contact.business_id}`)
      }
    >
      <TableCell align="left" width={10}>
        {index + 1}
      </TableCell>
      <TableCell align="left" width={78}>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Avatar
            alt={contact.name}
            src={getOptimizeImage(contact.avatar || contact.social_avatar, {
              w: 128,
              h: 128,
              fit: 'crop',
            })}
            sx={{
              borderRadius: 10,
              width: 46,
              height: 46,
              marginLeft: 2,
            }}
            variant="rounded"
          />
        </Box>
      </TableCell>
      <TableCell align="left">
        <OverflowText variant="subtitle2" fontWeight={500} noWrap>
          {contact.name}
        </OverflowText>
      </TableCell>
      <TableCell align="left">{formatPhone(contact.phone_number)}</TableCell>
      <TableCell align="right">{contact?.state || '-'}</TableCell>
      <TableCell align="right" sx={{ color: renderColorMoney(contact) }}>
        {contact.debt_amount
          ? numberFormat.format(contact.debt_amount).replace('-', '')
          : '-'}
      </TableCell>
      {/* <TableCell align="right">
        <Box
          display="flex"
          alignItems="center"
          sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
          onClick={() => {
            // e.stopPropagation();
            // navigate('/chat');
          }}
        >
          <Chat fill={theme.palette.primary.main} />
          <Typography fontSize={13} fontWeight={700} sx={{ ml: 1 }}>
            Chat
          </Typography>
        </Box>
      </TableCell> */}
    </TableRow>
  );
};

export default memo(ProductsRow);
