import { createSelector } from '@reduxjs/toolkit';
import { selectCategory } from './category';

export const ALL_CATEGORY = { id: '', name: 'Tất cả', count_sku: 0 };

export const selectCategoriesAndAll = createSelector(
  selectCategory,
  (category) => {
    const categories = Object.values(category.byId);
    return [
      {
        ...ALL_CATEGORY,
        count_sku: categories.reduce((acc, cat) => acc + cat.count_sku, 0),
      } as Category,
      ...categories,
    ];
  }
);

export const selectCategories = createSelector(selectCategory, (category) => {
  return Object.values(category.byId);
});
