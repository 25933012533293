import { Box, styled, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

type ButtonStyleProps = {
  left?: number;
  right?: number;
};

const Root = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '114px',
  height: '32px',
  position: 'relative',
}));

const ButtonStyle = styled('button')<ButtonStyleProps>(
  ({ left = 0, right = 0, theme }) => ({
    position: 'absolute',
    top: 1,
    left: left || 'unset',
    right: right || 'unset',
    width: 30,
    height: 30,
    padding: 0,
    fontSize: 20,
    zIndex: 1,
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '&:active': {
      backgroundColor: theme.palette.grey[300],
    },
  })
);

const TextFieldStyle = styled(TextField)(() => ({
  '& input': {
    padding: '4px 6px',
    textAlign: 'center',
    width: 'calc(100% - 64px)',
    margin: '0 auto',
  },
}));

type QuantityControlProps = {
  name: string;
  value: number;
  canPickQuantity: number;
  isAdvanceStock: boolean;
  onChange(e: { target: { name: string; value: ExpectedAny } }): void;
  onRemove(): void;
};

const QuantityControl = ({
  name,
  value,
  canPickQuantity,
  isAdvanceStock,
  onChange,
  onRemove,
}: QuantityControlProps) => {
  const [qtty, setQtty] = useState(String(value || 0));
  useEffect(() => {
    setQtty(String(value || 0));
  }, [value]);
  return (
    <Root>
      <ButtonStyle
        left={1}
        onClick={(e) => {
          e.stopPropagation();
          if (value - 1 <= 0) {
            return onRemove();
          }
          const countSpecialCharacter = String(value).split('.').length - 1;
          setQtty(
            countSpecialCharacter > 0
              ? String((+value - 1).toFixed(2))
              : String(+value - 1)
          );
          onChange({
            target: {
              name,
              value:
                countSpecialCharacter > 0
                  ? (+value - 1).toFixed(2)
                  : +value - 1,
            },
          });
        }}
      >
        -
      </ButtonStyle>
      <TextFieldStyle
        fullWidth
        name={name}
        value={qtty}
        InputProps={{ inputMode: 'decimal' }}
        onBlur={() => {
          // check last character has . or , then set previous quantity
          if (
            qtty &&
            (String(qtty)
              .charAt(String(qtty).length - 1)
              .lastIndexOf('.') > -1 ||
              String(qtty)
                .charAt(String(qtty).length - 1)
                .lastIndexOf(',') > -1)
          ) {
            setQtty(String(value));
            onChange({ target: { name, value } });
          } else if (+value === 0) {
            onRemove();
          }
        }}
        onChange={(e) => {
          const { value } = e.target;
          // allow input numbers, dot, comma
          const regex = /^[0-9]+([,.][0-9]+)?$/;

          const replaceValue = value.replace(',', '.');
          const countSpecialCharacter = replaceValue.split('.').length - 1;
          if (countSpecialCharacter > 1) return;
          const convertNumber = +value.replace(',', '.');
          const isLastDot =
            replaceValue.charAt(replaceValue.length - 1).lastIndexOf('.') > -1;
          // sometimes values has many decimal part, so has to fixed 2 decimal part
          // otherwise allow input max 2 decimal part, ex: 100,11
          const newValue =
            value.length <= 7
              ? replaceValue.substring(0, replaceValue.indexOf('.') + 3)
              : convertNumber.toFixed(2);

          if (isLastDot) {
            return setQtty(newValue);
            // return onChange({ target: { name, value: +newValue } });
          }
          const finalValue = replaceValue.includes('.') ? newValue : value;
          if (
            finalValue.length <= 7 &&
            (regex.test(finalValue) || finalValue === '')
          ) {
            let nextValue = +finalValue;
            if (+finalValue > canPickQuantity && isAdvanceStock) {
              // correction
              nextValue = canPickQuantity;
              setQtty(String(nextValue));
              onChange({ target: { name, value: nextValue } });
            } else {
              setQtty(String(finalValue));
              onChange({ target: { name, value: +finalValue } });
            }
          }
        }}
      />
      <ButtonStyle
        right={1}
        onClick={(e) => {
          e.stopPropagation();
          if (
            isAdvanceStock &&
            (canPickQuantity < 1 || +value + 1 >= canPickQuantity)
          ) {
            setQtty(String(canPickQuantity));
            return onChange({ target: { name, value: canPickQuantity } });
          }
          if (
            !isAdvanceStock ||
            (isAdvanceStock && value + 1 <= canPickQuantity)
          ) {
            const countSpecialCharacter = String(value).split('.').length - 1;
            setQtty(
              countSpecialCharacter > 0
                ? String((+value + 1).toFixed(2))
                : String(+value + 1)
            );
            onChange({
              target: {
                name,
                value:
                  countSpecialCharacter > 0
                    ? (+value + 1).toFixed(2)
                    : +value + 1,
              },
            });
          }
        }}
      >
        +
      </ButtonStyle>
    </Root>
  );
};

export default QuantityControl;
