import React from 'react';
import useAppSelector from '../hooks/useAppSelector';
import MainLayout from '../layouts/MainLayout';
import { selectApp } from '../store/app';

function noOffline<T>(Component: React.FC<T>) {
  const NoOffline = (props: T) => {
    const { offline } = useAppSelector(selectApp);
    if (offline) {
      return (
        <MainLayout>
          <h1>The functions are not available in Offline mode</h1>
        </MainLayout>
      );
    }

    return <Component {...props} />;
  };

  return NoOffline;
}

export default noOffline;
