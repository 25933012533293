import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { SYNC_IDLE } from '../../config/env';
import { delay } from '../promiseUtils';
import { OfflineConfig } from './configOffline';

const SYNC_SINCE = new Date('2020-01-01').valueOf();

function lastUpdate(record: ISyncRecord) {
  return Math.max(record.deleted_at || SYNC_SINCE, record.updated_at);
}

function older(acc: number, record: ISyncRecord) {
  return Math.max(acc, lastUpdate(record));
}

function isChanged(record: ISyncRecord, new_updated_at: number) {
  if (lastUpdate(record) !== new_updated_at) {
    console.log(record, new_updated_at, lastUpdate(record));
  }
  return lastUpdate(record) !== new_updated_at;
}

export type OfflineState<T> = {
  fuseVersion: number;
  ids: Array<string>;
  byId: Record<string, T>;
};

function configOfflineCacheSlice<
  Name extends string,
  Data extends ISyncRecord,
  SearchData
>(offlineConfig: OfflineConfig<Name, Data, SearchData>) {
  async function refreshCache(api: {
    dispatch: ExpectedAny;
    getState(): ExpectedAny;
  }) {
    const { byId } = api.getState()[offlineConfig.name] as OfflineState<Data>;
    const lastUpdate = Object.values(byId).reduce(
      older,
      offlineConfig.syncSince || SYNC_SINCE
    );

    const changed = await offlineConfig.fetchChange(new Date(lastUpdate));

    const filteredChanged = changed.filter((next) => {
      const record = byId[next.id];
      return (
        !record ||
        isChanged(
          record,
          Math.max(
            new Date(next.updated_at).valueOf(),
            new Date(next.deleted_at || SYNC_SINCE).valueOf()
          )
        )
      );
    });

    if (filteredChanged.length > 0) {
      api.dispatch({ type: `${offlineConfig.name}/patch`, payload: changed });
    }
  }

  const synchronize = createAsyncThunk<void, AbortSignal, ExpectedAny>(
    `${offlineConfig.name}/sync`,
    async (signal, api) => {
      while (!signal.aborted) {
        await refreshCache(api);

        if (offlineConfig.loop === false) {
          return;
        }

        await delay(offlineConfig.idle || SYNC_IDLE.DEFAULT);
      }
    }
  );

  const refresh = createAsyncThunk<void, void, ExpectedAny>(
    `${offlineConfig.name}/refresh`,
    async (_, api) => {
      await refreshCache(api);
    }
  );

  const initialState = {
    fuseVersion: 0,
    ids: [],
    byId: {},
    pendingIds: [],
    pendingById: {},
  } as OfflineState<Data>;

  const slice = createSlice({
    name: offlineConfig.name,
    initialState,
    reducers: {
      pump: (state) => {
        state.fuseVersion++;
      },
      patch: (state, action: PayloadAction<Array<Data>>) => {
        const { byId } = state;
        for (const nextRecord of action.payload) {
          const id = nextRecord.id;
          byId[id] = {
            ...nextRecord,
            updated_at: new Date(nextRecord.updated_at).valueOf(),
            deleted_at:
              nextRecord.deleted_at &&
              new Date(nextRecord.deleted_at).valueOf(),
          } as unknown as ExpectedAny;
        }
        state.ids = Object.keys(byId).filter((id) => !byId[id]?.deleted_at);
      },
    },
    extraReducers: (builder) => {
      builder.addCase(PURGE, () => initialState);
    },
  });

  return {
    ...slice,
    actions: {
      ...slice.actions,
      synchronize,
      refresh,
    },
  };
}

export default configOfflineCacheSlice;
