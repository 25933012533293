import { IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import { createInitialOrder, posActions, selectAppPos } from '../../store/pos';
import { productOffline } from '../../config/offline/product';
import Header from './Header';
import SearchInput from '../SearchInput';
import { Tab, Tabs } from './Tabs';
import { useCallback, useEffect, useState } from 'react';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import { useThrottle } from 'react-use';
import { selectAppProduct } from '../../store/product';
import { pageProductsActions } from '../../screens/Products/slice';
import { scrollTo } from '../../utils/utils';
import { v4 } from 'uuid';
import { pagePosActions } from '../../screens/POS/slice';
import { createOrder } from '../../screens/POS/utils';
import Confirmation from '../Confirmation';
import { useNavigate } from 'react-router-dom';

export type PosHeaderProps = {
  formValues: PendingOrderForm;
  selectedOrder: PendingOrder | null;
  onSelectedOrderChange(order: PendingOrder): void;
  onSelectedItem(
    item: Sku & {
      product_id: string;
      product_name: string;
      product_images: Array<string>;
    }
  ): void;
  onResetData(): void;
};

const PosHeader = ({
  formValues,
  selectedOrder,
  onSelectedOrderChange,
  onSelectedItem,
  onResetData,
}: PosHeaderProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [term, setTerm] = useState<string>('');
  const [selectedDeleteOrder, setSelectedDeleteOrder] =
    useState<PendingOrder | null>(null);
  const throttledTerm = useThrottle(term, 200);
  const { pendingOrders: orders } = useAppSelector(selectAppPos);
  const { fuseVersion } = useAppSelector(selectAppProduct);

  useEffect(() => {
    const query = buildOfflineFuseQuery(
      throttledTerm,
      {
        category: null,
      },
      productOffline
    );
    dispatch(pageProductsActions.search(query));
  }, [dispatch, throttledTerm, fuseVersion]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTerm(e.target.value),
    []
  );

  return (
    <Header>
      <Iconify
        icon="eva:arrow-ios-back-outline"
        width="28px"
        height="28px"
        color={theme.palette.common.white}
        sx={{ ml: theme.spacing(1.5), cursor: 'pointer' }}
        onClick={() => navigate(-1)}
      />
      <SearchInput
        searchText={term}
        formValues={formValues}
        setSearchText={setTerm}
        onChangeSearch={handleSearchChange}
        onSelectedItem={onSelectedItem}
      />
      <Tabs>
        {orders.map((order, index) => (
          <Tab
            key={order.id}
            id={order.id}
            active={selectedOrder?.id === order.id}
            onClick={() => {
              setTimeout(() => {
                onSelectedOrderChange(order);
                const el = document.getElementById(order.id);
                if (el) scrollTo(el);
              }, 0);
            }}
          >
            <Typography noWrap>Đơn {index + 1}</Typography>
            <Iconify
              icon="eva:close-circle-fill"
              width="20px !important"
              height="20px !important"
              sx={{
                color: 'text.disabled',
                ml: `${theme.spacing(1)} !important`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (
                  order.items.length > 0 ||
                  (formValues?.selectedOrderId === order.id &&
                    formValues.list_order_item.length > 0)
                ) {
                  return setSelectedDeleteOrder(order);
                }
                if (orders.length > 1) {
                  dispatch(posActions.deleteOrder(order.id));
                }
              }}
            />
          </Tab>
        ))}
      </Tabs>
      <IconButton
        sx={{ color: 'background.paper', mr: 2 }}
        disableTouchRipple
        onClick={async () => {
          dispatch(
            posActions.save({
              ...formValues,
              id: formValues.selectedOrderId || '',
              items: formValues.list_order_item,
              is_wholesale_price: formValues.is_wholesale_price || false,
              selected_promotion: formValues.selected_promotion || null,
            })
          );
          const id = v4();
          const newOrder = createInitialOrder();
          newOrder.id = id;
          dispatch(posActions.newOrder(newOrder));
          dispatch(pagePosActions.setSelectedOrderId(id));
        }}
      >
        <Iconify icon="akar-icons:plus" />
      </IconButton>
      {selectedDeleteOrder && (
        <Confirmation
          open={Boolean(selectedDeleteOrder)}
          title="Xoá đơn hàng"
          description="Đơn hàng này sẽ được xoá sau khi bấm xác nhận. Bạn có chắc muốn xoá đơn hàng này?"
          onClose={() => setSelectedDeleteOrder(null)}
          onConfirm={() => {
            setSelectedDeleteOrder(null);
            if (orders.length > 1) {
              return dispatch(posActions.deleteOrder(selectedDeleteOrder.id));
            }
            const defaultOrder = createOrder();
            onResetData();
            dispatch(
              posActions.save({
                ...defaultOrder,
                id: formValues.selectedOrderId || '',
                items: [],
                is_wholesale_price: false,
                selected_promotion: null,
              })
            );
          }}
        />
      )}
    </Header>
  );
};

export default PosHeader;
