const Home = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="10" y="14" width="4" height="7" fill="#637381" />
    <path
      d="M20.4201 10.1797L12.7101 2.2997C12.5223 2.11039 12.2668 2.00391 12.0001 2.00391C11.7335 2.00391 11.4779 2.11039 11.2901 2.2997L3.58012 10.1897C3.20327 10.5696 2.99437 11.0846 3.00012 11.6197V19.9997C2.99851 21.0627 3.82872 21.9412 4.89012 21.9997H8.00012V12.9997C8.00012 12.4474 8.44783 11.9997 9.00012 11.9997H15.0001C15.5524 11.9997 16.0001 12.4474 16.0001 12.9997V21.9997H19.1101C20.1715 21.9412 21.0017 21.0627 21.0001 19.9997V11.6197C21.0009 11.0826 20.7929 10.5663 20.4201 10.1797Z"
      fill="#919EAB"
    />
  </svg>
);

export default Home;
