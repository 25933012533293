import {
  Typography,
  Box,
  // Select,
  // MenuItem,
  // styled,
  Grid
} from '@mui/material';
import { memo } from 'react';
import Paybook from './Paybook';
import OrderTracking from './OrderTracking';

// const SelectStyle = styled(Select)(() => ({
//   '& fieldset': {
//     border: 'none',
//   },
//   '& .MuiSelect-select': {
//     padding: 0,
//   },
// }));

const Content = () => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m={2}
      >
        <Typography variant="h6" fontWeight={600}>
          Lịch sử chi tiết
        </Typography>
        {/* <SelectStyle value={'all'}>
          <MenuItem value="all">Tất cả</MenuItem>
          <MenuItem value="today">Hôm nay</MenuItem>
          <MenuItem value="yesterday">Hôm qua</MenuItem>
          <MenuItem value="this_week">Tuần này</MenuItem>
          <MenuItem value="this_month">Tháng này</MenuItem>
          <MenuItem value="this_year">Năm này</MenuItem>
          <MenuItem value="this_year">Thời gian khác</MenuItem>
        </SelectStyle> */}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paybook />
        </Grid>
        <Grid item xs={12} md={6}>
          <OrderTracking />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(Content);
