import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  ToggleButtonGroup,
  ToggleButton,
  ClickAwayListener,
  Avatar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import Location from '../../components/Icons/Location';
import SearchContact from '../../components/SearchContact';
import TextFieldNumber from '../../components/TextFieldNumber';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import { numberFormat, ORDER_STATUS } from '../../utils/constants';
import { getFieldProps } from '../../utils/formik';
import {
  getDeliveryMethod,
  getGrandTotalPrice,
  getTotalPrice,
} from '../../utils/orderUtils';
import AddContactDialog from '../Contacts/AddContactDIalog';
import CommingSoonDialog from '../../components/CommingSoonDialog';
import PromotionDialog from './PromotionDIalog';
import ShippingAddressDialog from './ShippingAddressDIalog';
import { pagePosActions, selectPagePos } from './slice';
import useResponsive from '../../hooks/useResponsive';
import TextFieldOtherDiscount from '../../components/TextFieldOtherDiscount';
import { selectAppAuth } from '../../store/auth';

type CommonProps = {
  isDesktop: boolean;
};

const OrderPaper = styled(Paper)<CommonProps>(({ isDesktop, theme }) => ({
  minWidth: isDesktop ? 432 : 350,
  maxWidth: isDesktop ? 432 : 350,
  padding: theme.spacing(isDesktop ? 2 : 1.5, 2, 2, 2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

type FormControlLabelStyleProps = {
  mr?: string;
};

const FormControlLabelStyle = styled(
  FormControlLabel
)<FormControlLabelStyleProps>(({ mr = '0px' }) => ({
  '&:not(:last-of-type)': {
    marginRight: mr,
  },
  '&:last-of-type': {
    marginRight: 0,
  },
  marginLeft: 0,
}));

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1.5),
}));

const Dot = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 4,
  height: 4,
  borderRadius: '50%',
  backgroundColor: theme.palette.grey[500],
  margin: theme.spacing(0, 1),
}));

const TextFieldStyle = styled(TextFieldNumber)(() => ({
  width: 140,
  '& input': {
    textAlign: 'right',
  },
}));

const TextFieldDisountStyle = styled(TextFieldOtherDiscount)(() => ({
  width: 140,
  '& input': {
    textAlign: 'right',
  },
}));

const ToggleButtonStyle = styled(ToggleButton)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[500],
  fontSize: 13,
}));

const ShippingAddressText = styled(Typography)(({ theme }) => ({
  maxWidth: 288,
  textDecoration: 'underline',
  marginRight: theme.spacing(1),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  wordBreak: 'break-word',
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: '32px',
  height: '32px',
  marginRight: theme.spacing(2),
}));

type ButtonStyleProps = {
  height?: number;
};

const ButtonStyle = styled(Button)<ButtonStyleProps>(({ height = 48 }) => ({
  height,
  boxShadow: 'none',
  textTransform: 'inherit',
}));

const RightPanel = ({ loading }: { loading: boolean }) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg') || false;
  const dispatch = useAppDispatch();
  const {
    main: {
      payments,
      otherDiscountUnit,
      selectedPromotion: selectedLocalPromotion,
    },
  } = useAppSelector(selectPagePos);
  const { business } = useAppSelector(selectAppAuth);
  const otherDiscountRef = useRef<HTMLInputElement>();
  const [searchContact, setSearchContact] = useState('');
  const [openPromotion, setOpenPromotion] = useState(false);
  const [openAddContact, setOpenAddContact] = useState(false);
  const [openShippingAddress, setOpenShippingAddress] = useState(false);
  const [openOtherDiscount, setOpenOtherDiscountUnit] = useState(false);
  const [openCommingSoon, setOpenCommingSoon] = useState(false);
  const formik = useFormikContext<PendingOrderForm>();
  const totalPrice = getTotalPrice(formik.values.list_order_item);
  const isSellerDelivery = getDeliveryMethod(formik.values.delivery_method);
  const selectedPromotion = formik.values.selected_promotion;
  const validPromotion =
    (selectedPromotion && selectedPromotion.min_order_price <= totalPrice) ||
    false;
  const grandTotal = useMemo(
    () =>
      getGrandTotalPrice({
        ...formik.values,
        otherDiscountUnit,
        validPromotion,
      }),
    [formik.values, otherDiscountUnit, validPromotion]
  );
  const changeMoney = grandTotal - formik.values.debit.buyer_pay;

  useEffect(() => {
    if (selectedLocalPromotion) {
      const discount =
        selectedLocalPromotion.type === 'percent'
          ? (totalPrice * selectedLocalPromotion.value) / 100
          : selectedLocalPromotion.value;
      const finalDiscount =
        selectedLocalPromotion.max_price_discount !== 0 &&
        discount > selectedLocalPromotion.max_price_discount
          ? selectedLocalPromotion.max_price_discount
          : discount;
      if (validPromotion) {
        formik.setFieldValue(
          'selected_promotion',
          {
            discount: Math.round(finalDiscount),
            min_order_price: selectedLocalPromotion.min_order_price,
          } || null
        );
        formik.setFieldValue('promotion_discount', finalDiscount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, selectedLocalPromotion]);

  useEffect(() => {
    if (
      formik.values.delivery_method === 'seller_delivery' &&
      (formik.values.delivery_fee === 0 ||
        formik.values.delivery_fee === business?.delivery_fee)
    ) {
      if (
        business?.min_price_free_ship &&
        totalPrice >= business?.min_price_free_ship
      ) {
        return formik.setFieldValue('delivery_fee', 0);
      }
      if (formik.values.delivery_fee !== business?.delivery_fee) {
        formik.setFieldValue('delivery_fee', business?.delivery_fee || 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice, formik.values.delivery_method]);

  useEffect(() => {
    if (formik.values.delivery_method === 'buyer_pick_up') {
      formik.setFieldValue('debit.buyer_pay', grandTotal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotal, formik.values.delivery_method]);

  const handleSelectedContact = (
    contact: Pick<
      Contact,
      'name' | 'phone_number' | 'address' | 'avatar' | 'debt_amount' | 'option'
    >
  ) => {
    // formik.setFieldValue('buyer_info', contact);
    formik.setFieldValue('buyer_info', {
      name: contact.name,
      phone_number: contact.phone_number,
      address: contact.address,
      avatar: contact.avatar,
      option: contact.option,
      debt_amount: contact.debt_amount,
    });
  };

  const handleChangePayment = (payment: Payment) => {
    formik.setFieldValue('payment_method', payment.name);
    formik.setFieldValue('payment_source_id', payment.id);
    formik.setFieldValue('payment_source_name', payment.name);
  };

  useEffect(() => {
    if (
      formik.values.other_discount >
      totalPrice - ((validPromotion && selectedPromotion?.discount) || 0)
    ) {
      formik.setFieldValue(
        'other_discount',
        totalPrice - ((validPromotion && selectedPromotion?.discount) || 0)
      );
    }
  }, [formik, selectedPromotion?.discount, totalPrice, validPromotion]);

  return (
    <OrderPaper square isDesktop={isDesktop || false}>
      <Box>
        <Box display="flex" alignItems="center" mb={0.5}>
          <Box style={{ flexGrow: 1 }}>
            <FormControlLabelStyle
              labelPlacement="start"
              control={<Switch checked={isSellerDelivery} />}
              label="Giao hàng sau"
              onChange={async (e) => {
                const target = e.target as HTMLInputElement;
                if (target.checked) {
                  await formik.setFieldValue(
                    'delivery_method',
                    'seller_delivery'
                  );
                  formik.setFieldValue('debit.buyer_pay', 0);
                  formik.setFieldValue('state', ORDER_STATUS.DELIVERING);
                } else {
                  await formik.setFieldValue(
                    'delivery_method',
                    'buyer_pick_up'
                  );
                  formik.setFieldValue('delivery_fee', 0);
                  formik.setFieldValue('state', ORDER_STATUS.COMPLETE);
                }
              }}
            />
          </Box>
          <Box>
            <FormControlLabelStyle
              labelPlacement="start"
              control={<Switch checked={formik.values.is_wholesale_price} />}
              label="Giá sỉ"
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                let newItemsPrice = formik.values.list_order_item;
                if (target.checked) {
                  newItemsPrice = newItemsPrice.map((item) => {
                    if (
                      item.wholesale_price &&
                      item.price ===
                        (item.product_selling_price ||
                          item.product_normal_price)
                    ) {
                      item.price = item.wholesale_price;
                    }
                    return item;
                  });
                } else {
                  newItemsPrice = newItemsPrice.map((item) => {
                    if (item.price === item.wholesale_price) {
                      item.price =
                        item.product_selling_price || item.product_normal_price;
                    }
                    return item;
                  });
                }
                formik.setFieldValue('list_order_item', newItemsPrice);
                formik.setFieldValue('is_wholesale_price', target.checked);
              }}
            />
          </Box>
        </Box>
        <Box
          paddingBottom={1.5}
          borderBottom={`1px solid ${theme.palette.divider}`}
        >
          <Box display="flex" alignItems="stretch">
            {formik.values.buyer_info.name ? (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" alignItems="center">
                    <AvatarStyle
                      src={formik.values.buyer_info.avatar}
                      alt={formik.values.buyer_info.name}
                    />
                    <Box>
                      <Typography
                        variant="body2"
                        color={theme.palette.grey[800]}
                        noWrap
                      >
                        {formik.values.buyer_info.name}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        color={theme.palette.grey[600]}
                      >
                        <Typography variant="caption" noWrap>
                          {formik.values.buyer_info.phone_number}
                        </Typography>
                        {typeof formik.values.buyer_info.debt_amount ===
                          'number' &&
                          formik.values.buyer_info.debt_amount > 0 && (
                            <>
                              <Dot />
                              <Typography variant="caption" noWrap>
                                Tôi phải{' '}
                                {formik.values.buyer_info.option === 'in'
                                  ? 'thu: '
                                  : 'trả: '}{' '}
                                {numberFormat.format(
                                  formik.values.buyer_info.debt_amount
                                )}
                                đ
                              </Typography>
                            </>
                          )}
                      </Box>
                    </Box>
                  </Box>
                  <Button
                    color="error"
                    onClick={() => {
                      formik.setFieldValue('buyer_info', {
                        phone_number: '',
                        name: '',
                        address: '',
                        avatar: '',
                        debt_amount: 0,
                        option: '',
                      });
                    }}
                  >
                    Xoá
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <SearchContact
                  onSelectedContact={handleSelectedContact}
                  onOpenAddContact={(value) => {
                    setOpenAddContact(true);
                    setSearchContact(value);
                  }}
                />
                <Button
                  variant="contained"
                  style={{
                    minWidth: 40,
                    maxWidth: 40,
                    marginLeft: 12,
                    padding: '8px 0',
                  }}
                  onClick={() => setOpenAddContact(true)}
                >
                  <Iconify
                    icon="akar-icons:plus"
                    sx={{ width: 18, height: 18 }}
                  />
                </Button>
              </>
            )}
          </Box>
          {isSellerDelivery && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={1.5}
              >
                <Typography variant="body2" color={theme.palette.grey[600]}>
                  Địa chỉ
                </Typography>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  textAlign="right"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setOpenShippingAddress(true)}
                >
                  {formik.values.buyer_info.address ? (
                    <>
                      <ShippingAddressText
                        variant="body2"
                        onClick={() => setOpenShippingAddress(true)}
                      >
                        {formik.values.buyer_info.address}
                      </ShippingAddressText>
                      <Iconify
                        icon="eva:trash-2-fill"
                        color={theme.palette.grey[600]}
                        width={20}
                        height={20}
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setFieldValue('buyer_info.address', '');
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Location />
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color={theme.palette.primary.main}
                        sx={{ ml: 1 }}
                      >
                        Thêm địa chỉ
                      </Typography>
                    </>
                  )}
                </Box>
              </Box>
              {formik.errors?.buyer_info?.address && (
                <Box textAlign="right">
                  <Typography
                    variant="caption"
                    color={theme.palette.error.main}
                  >
                    {formik.errors?.buyer_info?.address}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
        <Box mt={1.5}>
          <Row>
            <Typography variant="body2" color={theme.palette.grey[600]}>
              Tổng {formik.values.list_order_item.length} sản phẩm
            </Typography>
            <Typography variant="body2">
              {numberFormat.format(totalPrice)}đ
            </Typography>
          </Row>
          {selectedPromotion && selectedPromotion.discount > 0 && (
            <>
              <Row mb={!validPromotion ? '0px !important' : 2}>
                <Typography variant="body2" color={theme.palette.grey[600]}>
                  Khuyến mãi
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ opacity: !validPromotion ? 0.4 : 1 }}
                >
                  {numberFormat.format(selectedPromotion.discount)}đ
                </Typography>
              </Row>
              {!validPromotion && (
                <Box textAlign="right" mb={2}>
                  <Typography
                    variant="caption"
                    color={theme.palette.error.main}
                  >
                    Chưa đủ điều kiện sử dụng
                  </Typography>
                </Box>
              )}
            </>
          )}
          <Row>
            <Typography variant="body2" color={theme.palette.grey[600]}>
              Chiết khấu
            </Typography>
            <ClickAwayListener
              onClickAway={() => setOpenOtherDiscountUnit(false)}
            >
              <Box display="flex" alignItems="center">
                <TextFieldDisountStyle
                  variant="standard"
                  inputRef={otherDiscountRef}
                  endAdornment={
                    <>
                      {openOtherDiscount && otherDiscountUnit === 'percent'
                        ? ''
                        : 'đ'}
                    </>
                  }
                  onClick={() => setOpenOtherDiscountUnit(true)}
                  value={
                    !openOtherDiscount && otherDiscountUnit === 'percent'
                      ? Math.round(
                          (formik.values.other_discount / 100) *
                            (totalPrice -
                              ((validPromotion &&
                                selectedPromotion?.discount) ||
                                0))
                        )
                      : formik.values.other_discount
                  }
                  onChange={(e) => {
                    if (!e.target.value) {
                      return formik.setFieldValue('other_discount', 0);
                    }
                    const valueNumber = e.target.value.replace(/[^0-9 ]/g, '');
                    if (otherDiscountUnit === 'percent' && +valueNumber > 100) {
                      return;
                    }
                    if (
                      +valueNumber >
                      totalPrice -
                        ((validPromotion && selectedPromotion?.discount) || 0)
                    ) {
                      return formik.setFieldValue(
                        'other_discount',
                        totalPrice -
                          ((validPromotion && selectedPromotion?.discount) || 0)
                      );
                    }
                    formik.setFieldValue('other_discount', +valueNumber);
                  }}
                />

                {openOtherDiscount && (
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    value={otherDiscountUnit}
                    sx={{ ml: 1 }}
                    onChange={(event, newValue) => {
                      if (!newValue) return;
                      dispatch(pagePosActions.setOtherDiscountUnit(newValue));

                      if (newValue === 'percent') {
                        formik.setFieldValue(
                          'other_discount',
                          Math.round(
                            (formik.values.other_discount * 100) /
                              (totalPrice -
                                ((validPromotion &&
                                  selectedPromotion?.discount) ||
                                  0))
                          )
                        );
                      } else {
                        formik.setFieldValue(
                          'other_discount',
                          Math.round(
                            (formik.values.other_discount / 100) *
                              (totalPrice -
                                ((validPromotion &&
                                  selectedPromotion?.discount) ||
                                  0))
                          )
                        );
                      }
                      otherDiscountRef && otherDiscountRef?.current?.focus();
                    }}
                  >
                    <ToggleButtonStyle value="cash">VNĐ</ToggleButtonStyle>
                    <ToggleButtonStyle value="percent">%</ToggleButtonStyle>
                  </ToggleButtonGroup>
                )}
              </Box>
            </ClickAwayListener>
          </Row>
          {isSellerDelivery && (
            <Row>
              <Typography variant="body2" color={theme.palette.grey[600]}>
                Phí vận chuyển
              </Typography>
              <TextFieldStyle
                id="delivery_fee"
                variant="standard"
                endAdornment={<>đ</>}
                textAlign="right"
                value={formik.values.delivery_fee}
                onSetField={(value) =>
                  formik.setFieldValue('delivery_fee', value || 0)
                }
              />
            </Row>
          )}
          <Row>
            <Typography variant="subtitle1">Tổng cộng</Typography>
            <Typography variant="subtitle1" color={theme.palette.error.main}>
              {numberFormat.format(grandTotal)}đ
            </Typography>
          </Row>
          <Row mb={`${theme.spacing(1)} !important`}>
            <Typography variant="subtitle1">Tiền khách đưa</Typography>
            <TextFieldStyle
              id="debit.buyer_pay"
              variant="standard"
              endAdornment={<>đ</>}
              value={formik.values.debit.buyer_pay}
              textAlign="right"
              onSetField={(value) =>
                formik.setFieldValue('debit.buyer_pay', value || 0)
              }
            />
          </Row>
        </Box>
        <RadioGroup row sx={{ mb: 0.5 }}>
          {payments.map((payment) => (
            <FormControlLabelStyle
              key={payment.id}
              control={<Radio />}
              label={payment.name}
              checked={payment.id === formik.values.payment_source_id}
              onChange={() => handleChangePayment(payment)}
              sx={{
                '& .MuiTypography-root': {
                  fontSize: isDesktop ? 'inherit' : '12px',
                },
              }}
              mr={isDesktop ? theme.spacing(3) : theme.spacing(1)}
            />
          ))}
        </RadioGroup>
        {formik.values.delivery_method === 'buyer_pick_up' && (
          <Row>
            {formik.values.debit.buyer_pay < grandTotal ? (
              <FormControlLabelStyle
                labelPlacement="start"
                control={<Switch defaultChecked />}
                label="Ghi nợ"
                onChange={(e) => {
                  const target = e.target as HTMLInputElement;
                  formik.setFieldValue('debit.is_debit', target.checked);
                }}
              />
            ) : (
              <Typography variant="subtitle1">Tiền thừa</Typography>
            )}

            <Typography variant="subtitle1">
              {numberFormat.format(changeMoney).replace('-', '')}đ
            </Typography>
          </Row>
        )}
      </Box>
      <Box>
        <TextField
          placeholder="Ghi chú đơn hàng"
          fullWidth
          {...getFieldProps(formik, 'note')}
        />
        <Box display="flex" my={1.5}>
          <ButtonStyle
            variant={formik.values.promotion_code ? 'outlined' : 'contained'}
            color={formik.values.promotion_code ? 'primary' : 'inherit'}
            fullWidth
            // sx={{ mr: 1.5, whiteSpace: 'nowrap' }}
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => setOpenPromotion(true)}
          >
            {formik.values.promotion_code || 'Thêm khuyến mãi'}
          </ButtonStyle>
          {/* <ButtonStyle
            variant="contained"
            color="inherit"
            fullWidth
            onClick={() => setOpenCommingSoon(true)}
          >
            In hoá đơn
          </ButtonStyle> */}
        </Box>
        <ButtonStyle
          variant="contained"
          fullWidth
          height={isDesktop ? 86 : 80}
          disabled={loading}
          onClick={() => formik.handleSubmit()}
          sx={{ fontSize: isDesktop ? 24 : 20 }}
        >
          {isSellerDelivery ? 'Đặt đơn và giao sau' : 'Thanh toán'}
        </ButtonStyle>
      </Box>
      <ShippingAddressDialog
        open={openShippingAddress}
        setOpen={setOpenShippingAddress}
      />
      <AddContactDialog
        open={openAddContact}
        defaultValue={searchContact}
        setOpen={setOpenAddContact}
        onSuccess={handleSelectedContact}
      />
      <PromotionDialog open={openPromotion} setOpen={setOpenPromotion} />
      <CommingSoonDialog
        open={openCommingSoon}
        onClose={() => setOpenCommingSoon(false)}
      />
    </OrderPaper>
  );
};

export default RightPanel;
