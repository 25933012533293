import noAccents from '../encoding';
import { SearchFieldsConfig } from './configOffline';

function buildOfflineFuseQuery(
  term: string,
  filter: Record<string, ExpectedAny>,
  offlineConfig: SearchFieldsConfig
) {
  const { searchFields } = offlineConfig;
  let conditions: Array<ExpectedAny> = [];

  if (term) {
    conditions = [
      ...conditions,
      { $or: searchFields.map((field) => ({ [field]: noAccents(term) })) },
    ];
  }

  for (const name in filter) {
    const value = filter[name];
    if (value !== null && value !== undefined) {
      conditions = [...conditions, { [name]: value }];
    }
  }

  if (conditions.length === 0) {
    return null;
  }

  return { $and: conditions };
}

export default buildOfflineFuseQuery;
