import { Route, Routes } from 'react-router-dom';
import POS from '../screens/POS';
import Login from '../screens/Login';
import Dashboard from '../screens/Dashboard';
import Register from '../screens/Register';
import Products from '../screens/Products';
import Inventory from '../screens/Inventory';
import Orders from '../screens/Orders';
import ProductEdit from '../screens/ProductEdit';
import ContactDetail from '../screens/ContactDetail';
import Cashbook from '../screens/Cashbook';
import Chat from '../screens/Chat';
import Contacts from '../screens/Contacts';
import OrderDetail from '../screens/OrderDetail';
import useAppSelector from '../hooks/useAppSelector';
import { selectAppAuth } from '../store/auth';
import LoginVerifyOTP from '../screens/Login/VerifyOTP';
import Maintenance from '../screens/Maintenance';

export const AuthorizedRoute = () => {
  const { isProUser } = useAppSelector(selectAppAuth);
  return (
    <Routes>
      {isProUser && (
        <>
          <Route path="orders" element={<Orders />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="contacts/:id" element={<ContactDetail />} />
          <Route path="orders/:number" element={<OrderDetail />} />
          <Route path="cashbook" element={<Cashbook />} />
          <Route path="chat" element={<Chat />} />
          <Route path="pos/*" element={<POS />} />
        </>
      )}
      <Route path="products" element={<Products />} />
      <Route path="products/create" element={<ProductEdit />} />
      <Route path="products/:id" element={<ProductEdit />} />
      <Route path="products/inventory" element={<Inventory />} />
      <Route path="maintenance" element={<Maintenance />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  );
};

export const UnauthorizedRoute = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="verify-otp" element={<LoginVerifyOTP />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
};
