import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { memo } from 'react';
import { useFormikContext } from 'formik';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageProductEdit } from './slice';
import TextFieldNumber from '../../components/TextFieldNumber';

const BoxStyle = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const SkuPrice = () => {
  const { selectedSku } = useAppSelector(selectPageProductEdit);
  const formik = useFormikContext<PendingProduct>();
  return (
    <BoxStyle>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Giá sản phẩm
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <TextFieldNumber
            id={`skus[${selectedSku}].normal_price`}
            fullWidth
            label="Giá bán"
            placeholder="Nhập giá bán"
            error={
              formik.touched.skus?.[selectedSku]?.normal_price &&
              formik.errors?.skus?.[selectedSku]?.normal_price
            }
            helperText={
              formik.touched.skus?.[selectedSku]?.normal_price &&
              formik.errors?.skus?.[selectedSku]?.normal_price
            }
            value={
              formik.values.skus[selectedSku]
                ? formik.values.skus[selectedSku]?.normal_price
                : 0
            }
            onSetField={(value) =>
              formik.setFieldValue(`skus[${selectedSku}].normal_price`, value)
            }
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextFieldNumber
            id={`skus[${selectedSku}].historical_cost`}
            fullWidth
            label="Giá vốn"
            placeholder="Nhập giá vốn"
            value={formik.values.skus?.[selectedSku]?.historical_cost || 0}
            onSetField={(value) => {
              formik.setFieldValue(
                `skus[${selectedSku}].historical_cost`,
                value
              );
              if (formik.values.skus?.[selectedSku]?.sku_type === 'stock') {
                formik.setFieldValue(
                  `skus[${selectedSku}].po_details.pricing`,
                  value
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextFieldNumber
            id={`skus[${selectedSku}].wholesale_price`}
            fullWidth
            label="Giá sỉ"
            placeholder="Nhập giá sỉ"
            value={
              formik.values.skus[selectedSku]
                ? formik.values.skus[selectedSku]?.wholesale_price
                : 0
            }
            onSetField={(value) =>
              formik.setFieldValue(
                `skus[${selectedSku}].wholesale_price`,
                value
              )
            }
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextFieldNumber
            id={`skus[${selectedSku}].selling_price`}
            fullWidth
            label="Giá khuyến mãi"
            placeholder="Nhập giá KM"
            error={formik.errors?.skus?.[selectedSku]?.selling_price}
            helperText={formik.errors?.skus?.[selectedSku]?.selling_price}
            value={
              formik.values.skus[selectedSku]
                ? formik.values.skus[selectedSku]?.selling_price
                : 0
            }
            onSetField={(value) =>
              formik.setFieldValue(`skus[${selectedSku}].selling_price`, value)
            }
          />
        </Grid>
      </Grid>
    </BoxStyle>
  );
};

export default memo(SkuPrice);
