import { API_URI } from '../config/env';
import {
  ACCESS_TOKEN_KEY,
  BUSINESS_KEY,
  REFRESH_TOKEN_KEY,
  USER_KEY,
} from '../utils/constants';
import { encrypt } from '../utils/crypto';
import { fetchData, post } from '../utils/fetch';

async function generateQRCode({ device_id }: { device_id: string }) {
  return await post<QRCodeChallenge>(`${API_URI}/finan-user/api/v1/auth/qr`, {
    device_id,
  });
}

async function getLoginInfo({ code }: { code: string }) {
  return await post<QRCodeInfo>(`${API_URI}/finan-user/api/v1/auth/qr/info`, {
    code,
  });
}

async function confirmQRCode(data: ConfirmQRCodeForm) {
  return await post<ConfirmQRCodeResult>(
    `${API_URI}/finan-user/api/v1/auth/qr/confirmation`,
    {
      ...data,
    }
  );
}

async function generateOTP({
  phone_number,
  device_id,
}: {
  phone_number: string;
  device_id: string;
}) {
  return await post<OTPChallenge>(
    `${API_URI}/finan-user/api/v1/auth/otp/generate`,
    {
      phone_number,
      platform: 'buyer_web',
      device_id: device_id,
      secret_key: generateSecretKey(phone_number),
    }
  );
}

async function verifyOTP({
  phone_number,
  otp,
  otp_record,
  device_id,
}: {
  phone_number: string;
  otp: string;
  otp_record: string;
  device_id: string;
}) {
  return await post<VerifyOTPResult>(
    `${API_URI}/finan-user/api/v1/auth/otp/confirm`,
    {
      phone_number,
      otp,
      device_id,
      platform: 'buyer_web',
      remember_me: true,
      secret_key: generateSecretKey(otp_record),
    }
  );
}

async function switchBusiness(business_id: string) {
  return await post<SwitchBusiness>(
    `${API_URI}/finan-business/api/v1/user-has-business/switch`,
    { business_id: business_id },
    { authorization: true }
  );
}

async function getBusinessById(id: string) {
  return await fetchData<Business>(
    `${API_URI}/finan-business/api/v1/business/get-one/${id}`,
    {
      authorization: true,
    }
  );
}

async function renewToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  if (!refreshToken) {
    throw new Error('NO_REFRESH_TOKEN');
  }

  const { token } = await fetchData<{ token: string }>(
    `${API_URI}/finan-user/api/v1/auth/renew-token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    }
  );

  return token;
}

async function getAccessToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (!refreshToken) {
    return false;
  }

  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!accessToken) {
    return false;
  }

  return accessToken;
}

async function getBusinessId() {
  return localStorage.getItem(BUSINESS_KEY);
}

async function getUserId() {
  return localStorage.getItem(USER_KEY);
}

function generateSecretKey(data: string) {
  const timeStamp = Math.floor(Date.now() / 1000);
  return encrypt(`${data}|${timeStamp}`);
}

const authService = {
  generateQRCode,
  getLoginInfo,
  confirmQRCode,
  generateOTP,
  verifyOTP,
  getAccessToken,
  renewToken,
  getBusinessId,
  getUserId,
  getBusinessById,
  switchBusiness,
};
export default authService;
