import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import _orderBy from 'lodash/orderBy';
import { productOffline } from '../../config/offline/product';
import { selectAppProduct } from '../../store/product';
import { RootState } from '../../store/rootReducer';
import createSearchSlice from '../../utils/createSearchSlice';
import getOfflineDataByIds from '../../utils/offline/getOfflineDataByIds';
import searchOfflineIds from '../../utils/offline/searchOfflineIds';
import { selectLocalSearchResult } from '../Products/slice';
import { selectAppPos } from '../../store/pos';
import { selectAppContact } from '../../store/contact';
import { selectLocalSearchResultContacts } from '../Contacts/slice';
import { promotionOffline } from '../../config/offline/promotion';
import { selectAppPromotion } from '../../store/promotion';
import promotionService from '../../services/promotion';
import paymentService from '../../services/payment';
import { categoryOffline } from '../../config/offline/category';
import { selectCategory } from '../../store/category';
import { ALL_CATEGORY } from '../../store/selectors';

export const selectPagePos = (state: RootState) => state.page_pos;
export const selectLocalProducts = createSelector(
  selectAppProduct,
  selectPagePos,
  (
    cacheProducts,
    { products: { rows: ids, page, pageSize, order, orderBy } }
  ) => {
    const products = getOfflineDataByIds(ids, cacheProducts);
    const skus = products
      .map((product) =>
        product.list_sku.map((sku) => ({
          ...sku,
          product_id: product.id,
          product_name: product.name,
          sold_quantity: product.sold_quantity,
          product_images: product.images,
          sort_price: sku.selling_price || sku.normal_price,
        }))
      )
      .reduce((a, b) => a.concat(b), []);

    const sortedProducts = _orderBy(
      skus,
      [orderBy || 'created_at'],
      [order || 'desc']
    );

    return {
      skus: sortedProducts
        // pagination
        .slice(page * pageSize, page * pageSize + pageSize),
      totalItems: skus.length,
    };
  }
);

export const selectLocalSearchProducts = createSelector(
  selectAppProduct,
  selectLocalSearchResult,
  (cacheProduct, { rows: ids }) => {
    const products = getOfflineDataByIds(ids, cacheProduct);
    const skus = products
      .map((product) =>
        product.list_sku.map((sku) => ({
          ...sku,
          product_id: product.id,
          product_name: product.name,
          sold_quantity: product.sold_quantity,
          product_images: product.images,
        }))
      )
      .reduce((a, b) => a.concat(b), []);
    return skus;
  }
);

const selectLocalSearchResultCategories = createSelector(
  selectPagePos,
  ({ searchCategories }) => searchCategories
);

export const selectLocalSearchCategories = createSelector(
  selectCategory,
  selectLocalSearchResultCategories,
  (cacheCategories, { rows: ids }) => {
    const categories = getOfflineDataByIds(ids, cacheCategories);
    return [
      {
        ...ALL_CATEGORY,
        count_sku: categories.reduce((acc, cat) => acc + cat.count_sku, 0),
      } as Category,
      ...categories,
    ];
  }
);

export const selectLocalSearchContacts = createSelector(
  selectAppContact,
  selectLocalSearchResultContacts,
  (cacheContact, { rows: ids }) => {
    const contacts = getOfflineDataByIds(ids, cacheContact);

    return contacts;
  }
);

export const selectLocalSearchPromotions = createSelector(
  selectAppPromotion,
  selectPagePos,
  (cachePromotion, { promotions: { rows: ids } }) => {
    const promotions = getOfflineDataByIds(ids, cachePromotion);

    return promotions;
  }
);

export const selectLocalSelectedOrder = createSelector(
  selectAppPos,
  selectPagePos,
  ({ pendingOrders }, { main: { selectedOrderId } }) => {
    const selectedOrder = pendingOrders.find((o) => o.id === selectedOrderId);
    return selectedOrder || pendingOrders[0] || null;
  }
);

const processPromotion = createAsyncThunk<
  SelectedPromotion,
  ParamsProcessPromotion,
  { state: RootState }
>('page_pos/process_promotion', async ({ promotion_code, grand_total }) => {
  const promotion = await promotionService.processPromotion({
    promotion_code,
    grand_total,
  });

  if (!promotion) {
    throw new Error('NO_PROMOTION');
  }

  return {
    min_order_price: promotion.min_order_price,
    discount: promotion.value_discount,
  };
});

const getPayments = createAsyncThunk('page_pos/payments', async () => {
  const payments = await paymentService.getPayments({ type: 'default' });

  if (!payments) {
    throw new Error('NO_PRODUCT');
  }

  return payments;
});

const name = 'page_pos' as const;

type SelectedPromotion = { min_order_price: number; discount: number } | null;

type PagePOSSliceState = {
  stateProcessPromotion: 'loading' | 'error' | 'saved' | 'notFound';
  statePayments: 'loading' | 'error' | 'saved' | 'notFound';
  selectedCategoryIds: Array<string>;
  selectedOrderBy: string;
  selectedOrderId: string;
  selectedPromotion: Promotion | null;
  highlightedSkus: Array<string>;
  payments: Array<Payment>;
  otherDiscountUnit: 'cash' | 'percent';
  submitResponse: CreateOrderResponse | null;
  expandedProducts: boolean;
  openDropdownProduct: boolean;
};

const initialState: PagePOSSliceState = {
  stateProcessPromotion: 'loading',
  statePayments: 'loading',
  selectedCategoryIds: [''],
  selectedOrderBy: 'created_at',
  selectedOrderId: '',
  selectedPromotion: null,
  highlightedSkus: [],
  payments: [],
  otherDiscountUnit: 'cash',
  submitResponse: null,
  expandedProducts: true,
  openDropdownProduct: false,
};

const mainSlice = createSlice({
  name,
  initialState,
  reducers: {
    highlightSkus: (state, action: PayloadAction<Array<string>>) => {
      state.highlightedSkus = action.payload;
    },
    setSelectedCategoryIds: (state, action: PayloadAction<Array<string>>) => {
      state.selectedCategoryIds = action.payload;
    },
    setSelectedOrderBy: (state, action: PayloadAction<string>) => {
      state.selectedOrderBy = action.payload;
    },
    setSelectedOrderId: (state, action: PayloadAction<string>) => {
      state.selectedOrderId = action.payload;
    },
    setSelectedPromotion: (state, action: PayloadAction<Promotion | null>) => {
      state.selectedPromotion = action.payload;
    },
    setOtherDiscountUnit: (
      state,
      action: PayloadAction<'cash' | 'percent'>
    ) => {
      state.otherDiscountUnit = action.payload;
    },
    setSubmitResponse: (
      state,
      action: PayloadAction<CreateOrderResponse | null>
    ) => {
      state.submitResponse = action.payload;
    },
    toggleProducts: (state) => {
      state.expandedProducts = !state.expandedProducts;
    },
    toggleDropdownProduct: (state, action: PayloadAction<boolean>) => {
      state.openDropdownProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(processPromotion.fulfilled, (state, action) => {
      //   if (action.payload) {
      //     state.selectedPromotion = action.payload;
      //   }
      // })
      // .addCase(processPromotion.rejected, (state) => {
      //   state.stateProcessPromotion = 'notFound';
      // })
      .addCase(getPayments.fulfilled, (state, action) => {
        if (action.payload) {
          state.payments = action.payload;
        }
      })
      .addCase(getPayments.rejected, (state) => {
        state.statePayments = 'notFound';
      });
  },
});

const productsSlice = createSearchSlice('page_pos/products', {
  handleSearch: async (query: FuseQuery, api) => {
    const ids = await searchOfflineIds(
      api.getState<ExpectedAny>(),
      query,
      productOffline
    );
    return [ids, ids.length];
  },
});

export const searchCategoriesSlice = createSearchSlice('page_pos/categories', {
  handleSearch: async (query: FuseQuery, api) => {
    const ids = await searchOfflineIds(
      api.getState<ExpectedAny>(),
      query,
      categoryOffline
    );

    return [ids, ids.length];
  },
});

export const searchPromotionsSlice = createSearchSlice('page_pos/promotions', {
  handleSearch: async (query: FuseQuery, api) => {
    const ids = await searchOfflineIds(
      api.getState<ExpectedAny>(),
      query,
      promotionOffline
    );
    return [ids, ids.length];
  },
});

export const pagePosActions = {
  ...mainSlice.actions,
  categories: searchCategoriesSlice.actions,
  products: productsSlice.actions,
  promotions: searchPromotionsSlice.actions,
  processPromotion,
  getPayments,
};

const pagePosSlice = {
  name,
  reducer: combineReducers({
    main: mainSlice.reducer,
    products: productsSlice.reducer,
    promotions: searchPromotionsSlice.reducer,
    searchCategories: searchCategoriesSlice.reducer,
  }),
} as const;

export default pagePosSlice;
