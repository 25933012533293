export const TABLE_HEAD: Array<HeadCellConfig> = [
  { id: 'action', label: '' },
  { id: 'id', label: 'STT' },
  { id: 'sku_code', label: 'Mã SKU' },
  { id: 'name', label: 'Sản phẩm' },
  { id: 'normal_price', label: 'Giá bán', align: 'right' },
  { id: 'quantity', label: 'Số lượng', align: 'center' },
  { id: 'total', label: 'Tổng tiền', align: 'right' },
];

export const TABLE_HEAD_PROMOTION = [
  { id: 'name', label: 'Tên khuyến mãi' },
  { id: 'code', label: 'Mã giảm' },
  { id: 'condition', label: 'Điều kiện' },
  { id: 'total', label: 'Tổng giảm', align: 'right' },
  { id: 'warning', label: '' },
];
