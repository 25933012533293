import { Table, TableBody, TableContainer } from '@mui/material';
import { memo } from 'react';
import { TableHeaderSort } from '../../components/TableHeader';
import createSearchOrderHook from '../../hooks/createSearchOrderHook';
import useAppSelector from '../../hooks/useAppSelector';
import ProductRow from './ProductRow';
import { TABLE_HEAD } from './settings';
import {
  pageProductsActions,
  selectLocalSearchResult,
  selectLocalSearchRows,
} from './slice';

const useSearchOrder = createSearchOrderHook({
  selectResult: selectLocalSearchResult,
  actions: pageProductsActions,
});

const ProductsHeader = () => {
  return <TableHeaderSort {...useSearchOrder()} headLabel={TABLE_HEAD} />;
};

const ProductsTable = memo(() => {
  const products = useAppSelector(selectLocalSearchRows);
  return (
    <TableContainer>
      <Table stickyHeader>
        <ProductsHeader />
        <TableBody>
          {products.map((product, index) => (
            <ProductRow key={product.id} index={index} product={product} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

ProductsTable.displayName = 'ProductsTable';

export default ProductsTable;
