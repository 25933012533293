import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { RootState } from './rootReducer';
import notificationService from '../services/notification';

type NotificationState = {
  state: 'ready';
  statistic: NotificationStatistic | null;
  notifications: Record<string, AppNotification>;
};

const initialState: NotificationState = {
  state: 'ready',
  notifications: {},
  statistic: null,
};

export const selectAppNotification = (state: RootState) => state.notification;

const synchronize = createAsyncThunk('notification/getCount', (_signal: AbortSignal) => {
  return notificationService.getNotificationCount();
});

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(synchronize.fulfilled, (state, action) => {
      state.statistic = action.payload;
    });
  },
});

export const notificationActions = {
  synchronize,
  ...notificationSlice.actions,
};
export default notificationSlice;
