const Product = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.5 2H4.5C3.83696 2 3.20107 2.27054 2.73223 2.75209C2.26339 3.23365 2 3.88678 2 4.56781L2 7.84749C2 8.39977 2.44772 8.84749 3 8.84749H21C21.5523 8.84749 22 8.39978 22 7.84749V4.56781C22 3.88678 21.7366 3.23365 21.2678 2.75209C20.7989 2.27054 20.163 2 19.5 2Z"
      fill="#919EAB"
    />
    <path
      d="M4.83301 10.0169C3.72844 10.0169 2.83301 10.9123 2.83301 12.0169V20C2.83301 21.1046 3.72844 22 4.83301 22H19.1663C20.2709 22 21.1663 21.1046 21.1663 20V12.0169C21.1663 10.9123 20.2709 10.0169 19.1663 10.0169H4.83301ZM15.333 13.4406C15.333 13.9134 14.9498 14.2966 14.4771 14.2966H9.52228C9.04956 14.2966 8.66634 13.9134 8.66634 13.4406C8.66634 12.9679 9.04956 12.5847 9.52228 12.5847H14.4771C14.9498 12.5847 15.333 12.9679 15.333 13.4406Z"
      fill="#637381"
    />
  </svg>
);

export default Product;
