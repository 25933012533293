import { Box, TextField, styled, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../hooks/useAppSelector';
import cashbookService from '../../services/cashbook';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { pageCashbooksActions, selectPageCashbooks } from './slice';

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: '8px',
  padding: theme.spacing(1, 2, 3),
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}));

type AddCashbookCategoryProps = {
  setOpenCreateCategory: (value: boolean) => void;
};

const AddCashbookCategory = ({
  setOpenCreateCategory,
}: AddCashbookCategoryProps) => {
  const dispatch = useDispatch();
  const {
    main: { dialogType, categories },
  } = useAppSelector(selectPageCashbooks);
  const [dataCreateCategory, setDataCreateCategory] = useState({ name: '' });

  const handleCreateCategory = async () => {
    try {
      const response = await cashbookService.createCashbookCategory({
        name: dataCreateCategory.name,
        type: dialogType,
      });
      if (response) {
        dispatch(pageCashbooksActions.setCategories([response, ...categories]));
        setOpenCreateCategory(false);
        dispatch(
          toastActions.create({
            severity: 'success',
            message: getErrorMessage('Tạo mới danh mục thành công'),
            dismissAfter: 3000,
          })
        );
      }
    } catch (ex) {
      dispatch(
        toastActions.create({
          severity: 'error',
          message: getErrorMessage(ex),
          dismissAfter: 3000,
        })
      );
    }
  };

  return (
    <Root>
      <Header>
        <Typography variant="body2">Tạo mới danh mục</Typography>
        <Box display="flex">
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            onClick={() => setOpenCreateCategory(false)}
          >
            Quay lại
          </Button>
          <Button color="primary" onClick={handleCreateCategory}>
            Tạo mới
          </Button>
        </Box>
      </Header>
      <TextField
        label="Tên danh mục"
        fullWidth
        autoFocus
        onChange={(e) => setDataCreateCategory({ name: e.target.value })}
      />
    </Root>
  );
};

export default AddCashbookCategory;
