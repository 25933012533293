import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AppSlice = {
  sidebar: {
    open: boolean;
  };
  offline: boolean;
};

const initialState: AppSlice = {
  sidebar: {
    open: true,
  },
  offline: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean | undefined>) => {
      state.sidebar.open =
        typeof action.payload !== 'undefined'
          ? action.payload
          : !state.sidebar.open;
    },
    toggleOffline: (state, action: PayloadAction<boolean>) => {
      state.offline = action.payload;
    },
  },
});

export const appActions = {
  ...appSlice.actions,
};
export const selectApp = (state: {[appSlice.name]: AppSlice}) => state.app;
export default appSlice;
