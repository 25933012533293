import { SxProps, TextField, Theme } from '@mui/material';
import { Ref } from 'react';
import { changeToNumberFormat } from '../../utils/utils';

type TextFieldNumberProps = {
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  value: number;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  inputRef?: Ref<unknown> | undefined;
  endAdornment?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  readOnly?: boolean;
};

export default function TextFieldOtherDiscount({
  label,
  placeholder,
  fullWidth,
  value = 0,
  variant = 'outlined',
  inputRef,
  endAdornment,
  sx,
  onChange,
  onClick,
  readOnly = false,
  ...props
}: TextFieldNumberProps) {
  return (
    <TextField
      fullWidth={fullWidth}
      label={label}
      placeholder={placeholder}
      variant={variant}
      inputRef={inputRef}
      InputProps={{
        endAdornment,
        readOnly,
      }}
      value={changeToNumberFormat(value.toString())}
      onKeyUp={(e) => {
        if (isNaN(+e.key)) return;
      }}
      onChange={onChange}
      onClick={onClick}
      sx={sx}
      {...props}
    />
  );
}
