import { Box, Button, Stepper, StepLabel, Step, styled } from '@mui/material';
import QRCodeIcon from '../../components/Icons/QRCode';

const StepperStyle = styled(Stepper)(({ theme }) => ({
  '& .MuiStepIcon-root': { color: theme.palette.primary.main },
  '& .MuiStepLabel-label': {
    display: 'flex',
    alignItems: 'center',
    color: `${theme.palette.grey[600]} !important`,
    fontWeight: 400,
  },
}));

const ButtonStyle = styled(Button)(() => ({
  fontWeight: 400,
}));

const steps = [
  {
    label: <>Đăng nhập ứng dụng Sổ Bán Hàng trên điện thoại</>,
  },
  {
    label: (
      <>
        Tại ứng dụng chọn biểu tượng{' '}
        <Box mx={1} height={24}>
          <QRCodeIcon />
        </Box>{' '}
        ở thanh tiêu đề.
      </>
    ),
  },
  {
    label: <>Di chuyển máy ảnh đến mã QR trên màn hình máy tính và quét.</>,
  },
];

type TutorialQRCodeProps = {
  tutorial: boolean;
  setTutorial: (value: boolean) => void;
};

const TutorialQRCode = ({ tutorial, setTutorial }: TutorialQRCodeProps) => {
  return !tutorial ? (
    <ButtonStyle color="info" onClick={() => setTutorial(true)} sx={{ mt: 1 }}>
      Hướng dẫn quét mã QR
    </ButtonStyle>
  ) : (
    <Box mt={3}>
      <StepperStyle orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </StepperStyle>
      <ButtonStyle
        color="info"
        sx={{ mt: 1 }}
        onClick={() => setTutorial(false)}
      >
        Thu gọn
      </ButtonStyle>
    </Box>
  );
};

export default TutorialQRCode;
