import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const HotkeyIcon = styled('div')(() => ({
  border: '1px solid #999',
  color: '#999',
  borderRadius: 4,
  whiteSpace: 'nowrap',
  height: 18,
  fontSize: 12,
  padding: '0 5px',
  lineHeight: '16px',
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': { border: '1px solid #666', color: '#666' },
}));

type HotkeyInfoProps = {
  title: string;
  children: React.ReactNode;
};

const HotkeyInfo = ({ title, children }: HotkeyInfoProps) => {
  return (
    <Tooltip title={title}>
      <HotkeyIcon>{children}</HotkeyIcon>
    </Tooltip>
  );
};

export default HotkeyInfo;
