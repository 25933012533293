import qs from 'qs';
import moment, { Moment } from 'moment';
import { API_URI } from '../config/env';
import { fetchData, post } from '../utils/fetch';
import authService from './auth';

async function getShopOverview({ start_time }: { start_time?: string }) {
  const business_id = await authService.getBusinessId();
  return await fetchData<ShopOverview>(
    `${API_URI}/finan-order/api/v1/pro-seller/get-overview?${qs.stringify({
      business_id,
      start_time,
      end_time: moment().endOf('D').toISOString(),
    })}`,
    { authorization: true }
  );
}

async function getTopProducts(
  order: 'revenue' | 'quantity',
  [start_time, end_time]: [Moment, Moment]
) {
  const business_id = await authService.getBusinessId();
  return await fetchData<Array<ProductOverview>>(
    `${API_URI}/finan-order/api/v2/pro-seller/get-list-top-sales?${qs.stringify(
      {
        business_id,
        start_time: start_time.toISOString(),
        end_time: end_time.toISOString(),
        sort: order,
        page: 1,
        page_size: 10,
      }
    )}`,
    { authorization: true }
  );
}

async function getInventoryOverview() {
  const business_id = await authService.getBusinessId();
  return await fetchData<InventoryOverview>(
    `${API_URI}/ms-warehouse-management/api/v1/stock/get-overview?${qs.stringify(
      {
        business_id,
      }
    )}`,
    { authorization: true }
  );
}

async function getVisitedCustomers() {
  const business_id = await authService.getBusinessId();
  return await fetchData<VisitedCustomers>(
    `${API_URI}/finan-order/api/v1/get-daily-visit-analytics?${qs.stringify({
      business_id,
    })}`,
    { authorization: true }
  );
}

async function getBanners() {
  return await fetchData<Array<Banner>>(
    `${API_URI}/ms-meta-data/api/banner?${qs.stringify({
      date_check: true,
    })}`,
    { authorization: true }
  );
}

async function getTransactionAnalytics({
  transaction_type,
}: {
  transaction_type: string;
}) {
  const business_id = await authService.getBusinessId();

  return await post<CashbookAnalytics>(
    `${API_URI}/finan-transaction/api/v2/business-transaction/get-cash-book-analytics`,
    {
      business_id,
      option: 'this_month',
      transaction_type,
      start_time: moment().startOf('M').toISOString(),
      end_time: moment().endOf('M').toISOString(),
    },
    { authorization: true }
  );
}

const dashboardService = {
  getShopOverview,
  getTopProducts,
  getInventoryOverview,
  getVisitedCustomers,
  getBanners,
  getTransactionAnalytics,
};

export default dashboardService;
