import {
  Stepper,
  StepConnector,
  Step,
  StepLabel,
  styled,
  Typography,
  Box,
} from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { memo } from 'react';
import DeliveringOrder from '../../components/Icons/DeliveringOrder';
import Receipt from '../../components/Icons/Receipt';
import Iconify from '../../minimal/Iconify';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageOrderDetail } from './slice';
import { useTheme } from '@mui/material/styles';
import { ORDER_STATUS } from '../../utils/constants';

const StepperStyle = styled(Stepper)(() => ({
  width: '100%',
  margin: '0 auto',
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[500_24],
  zIndex: 1,
  color: '#fff',
  width: 48,
  height: 48,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    '& path': {
      fill: theme.palette.common.white,
    },
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    '& path': {
      fill: theme.palette.common.white,
    },
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    width: '90%',
    margin: '0 auto',
    border: 0,
    backgroundColor: theme.palette.divider,
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Receipt />,
    2: (
      <DeliveringOrder
        width={24}
        height={24}
        opacity={1}
        fillOpacity={1}
        fill="#919EAB"
      />
    ),
    3: (
      <Iconify
        icon="eva:done-all-fill"
        width={24}
        height={24}
        color="#919EAB"
      />
    ),
  };

  return (
    <ColorlibStepIconRoot ownerState={{ active, completed }}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  { label: 'Chờ xác nhận', time: '' },
  { label: 'Đang giao', time: '' },
  { label: 'Đã giao', time: '' },
];

const OrderTracking = () => {
  const { t } = useTranslation();
  const stepsHandle: { label: string; time: string }[] = [];
  const {
    main: { orderTracking, isEdit, orderDetail },
  } = useAppSelector(selectPageOrderDetail);
  const theme = useTheme();

  const pos: number[] = [];
  steps.map((value, index) => {
    let isValue = false;
    orderTracking.map((orderTrack: { state: string; updated_at: string }) => {
      if (value.label == t('order_state', { context: orderTrack?.state })) {
        if (
          !stepsHandle.find(
            (value) =>
              value.label == t('order_state', { context: orderTrack?.state })
          )
        ) {
          pos.push(index);
          isValue = true;
          stepsHandle.push({
            label: value.label,
            time: moment(orderTrack?.updated_at).format('DD/MM/YYYY - HH:mm'),
          });
        }
      }
    });
    if (!isValue) {
      stepsHandle.push({ label: value.label, time: '' });
    }
  });

  return (
    <>
      {!isEdit && (
        <Box display="flex" alignItems="center" mb={2} ml={3}>
          <DeliveringOrder
            width={18}
            height={18}
            opacity={1}
            fillOpacity={1}
            fill={theme.palette.grey[600]}
          />
          <Typography
            variant="body2"
            sx={{ ml: 1 }}
            color={theme.palette.grey[600]}
          >
            {t('delivery_state', { context: orderDetail.delivery_method })}
          </Typography>
        </Box>
      )}
      {!isEdit && orderDetail.state != ORDER_STATUS.CANCEL && (
        <StepperStyle
          alternativeLabel
          activeStep={Math.max(...pos)}
          connector={<ColorlibConnector />}
        >
          {stepsHandle.map((step) => (
            <Step key={step?.label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {step?.label}
                <Typography
                  variant="body2"
                  color={(theme) => theme.palette.grey[600]}
                >
                  {step?.time}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </StepperStyle>
      )}
    </>
  );
};

export default memo(OrderTracking);
