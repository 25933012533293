import {
  Box,
  Typography,
  TextField,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  Radio,
  styled,
  DialogActions,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useThrottle } from 'react-use';
import { TableHeader } from '../../components/TableHeader';
import { promotionOffline } from '../../config/offline/promotion';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import { selectAppPromotion } from '../../store/promotion';
import { toastActions } from '../../store/toast';
import { numberFormat } from '../../utils/constants';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import {
  formatDescriptionPromotion,
  getTotalPrice,
} from '../../utils/orderUtils';
import { TABLE_HEAD_PROMOTION } from './settings';
import {
  pagePosActions,
  selectLocalSearchPromotions,
  selectPagePos,
} from './slice';

type TableRowProps = {
  index: number;
  valid: boolean;
};

const TableRowStyle = styled(TableRow)<TableRowProps>(
  ({ index, valid, theme }) => ({
    backgroundColor: index % 2 != 0 ? '#919eab14' : 'unset',
    '& .MuiTableCell-root:first-of-type': { paddingLeft: theme.spacing(2) },
    '& .MuiTableCell-root:last-of-type': { paddingRight: theme.spacing(0.5) },
    '& .MuiTableCell-root': {
      color: valid ? theme.palette.grey[800] : theme.palette.grey[500_56],
    },
  })
);

const OverflowText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
}));

const DialogTitleStyle = styled(DialogTitle)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 600,
}));

type PromotionDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const PromotionDialog = ({ open, setOpen }: PromotionDialogProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(
    null
  );
  const [term, setTerm] = useState<string>('');
  const throttledTerm = useThrottle(term, 200);
  const { fuseVersion } = useAppSelector(selectAppPromotion);
  const promotions = useAppSelector(selectLocalSearchPromotions);
  const {
    main: { stateProcessPromotion },
  } = useAppSelector(selectPagePos);
  const formik = useFormikContext<PendingOrderForm>();
  const totalPrice = getTotalPrice(formik.values.list_order_item);
  const disabled = promotions.length === 0 || !selectedPromotion;

  useEffect(() => {
    if (stateProcessPromotion === 'notFound') {
      dispatch(
        toastActions.create({
          severity: 'error',
          message: 'Có lỗi xảy ra, vui lòng thử lại sau',
          dismissAfter: 3000,
        })
      );
    }
  }, [dispatch, stateProcessPromotion]);

  useEffect(() => {
    const query = buildOfflineFuseQuery(
      throttledTerm,
      {
        category: null,
      },
      promotionOffline
    );
    dispatch(pagePosActions.promotions.search(query));
  }, [dispatch, throttledTerm, fuseVersion]);

  const handleUpdate = (promotion: Promotion | null) => {
    if (promotion) {
      const discount =
        promotion.type === 'percent'
          ? (totalPrice * promotion.value) / 100
          : promotion.value;
      formik.setFieldValue('selected_promotion', {
        discount,
        min_order_price: promotion.min_order_price,
      });
      formik.setFieldValue('promotion_code', promotion?.promotion_code);
      return;
    }
    formik.setFieldValue('promotion_code', null);
    formik.setFieldValue('selected_promotion', null);
    dispatch(pagePosActions.setSelectedPromotion(null));
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setTerm('');
        setOpen(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <DialogTitleStyle>Áp dụng khuyến mãi</DialogTitleStyle>
        <Box display="flex" alignItems="center" mr={3}>
          <Iconify
            icon="eva:alert-triangle-fill"
            width={16}
            height={16}
            color={theme.palette.grey[500]}
          />
          <Typography
            variant="caption"
            color={theme.palette.grey[500]}
            sx={{ ml: 1 }}
          >
            Khuyến mãi chưa đủ điều kiện sử dụng
          </Typography>
        </Box>
      </Box>
      <DialogContent
        sx={{
          px: 0,
          borderBottom: `1px solid ${theme.palette.divider}`,
          pt: 0,
        }}
      >
        <TextField
          placeholder="Tìm tên hoặc mã giảm"
          autoFocus
          onChange={(e) => setTerm(e.target.value)}
          sx={{ width: '50%', mx: 3, my: 3 }}
        />
        {promotions.length === 0 && (
          <Box textAlign="center">
            <Typography
              variant="subtitle1"
              fontWeight={500}
              color={theme.palette.grey[600]}
            >
              {term ? 'Không tìm thấy kết quả phù hợp' : ''}
            </Typography>
          </Box>
        )}
        {promotions.length > 0 && (
          <Table stickyHeader>
            <TableHeader headLabel={TABLE_HEAD_PROMOTION} />
            <TableBody>
              {promotions
                .sort(
                  (a, b) =>
                    +(a.min_order_price > totalPrice) -
                    +(b.min_order_price > totalPrice)
                )
                .map((promotion, index) => {
                  const valid =
                    promotion.min_order_price <= totalPrice &&
                    (promotion.max_size === 0 ||
                      promotion.current_size < promotion.max_size);
                  return (
                    <TableRowStyle
                      key={promotion.id}
                      index={index}
                      valid={valid}
                      hover={valid}
                    >
                      <TableCell
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          if (valid) {
                            if (
                              selectedPromotion &&
                              promotion.id === selectedPromotion.id
                            ) {
                              setSelectedPromotion(null);
                              handleUpdate(null);
                            } else {
                              setSelectedPromotion(promotion);
                            }
                          }
                        }}
                      >
                        <Radio
                          checked={selectedPromotion?.id === promotion.id}
                        />
                        <OverflowText variant="body2" fontWeight={500} noWrap>
                          {promotion.name}
                        </OverflowText>
                      </TableCell>
                      <TableCell align="left">
                        {promotion.promotion_code}
                      </TableCell>
                      <TableCell align="left">
                        {formatDescriptionPromotion(promotion)}
                      </TableCell>
                      <TableCell align="right">
                        {promotion.type === 'percent'
                          ? `${promotion.value}%`
                          : `${numberFormat.format(promotion.value)}đ`}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ display: 'flex', maxWidth: 30, p: 0 }}
                      >
                        {!valid && (
                          <Iconify
                            icon="eva:alert-triangle-fill"
                            width={16}
                            height={16}
                          />
                        )}
                      </TableCell>
                    </TableRowStyle>
                  );
                })}
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            setTerm('');
            setOpen(false);
          }}
        >
          Huỷ bỏ
        </Button>
        <Button
          variant="contained"
          disabled={disabled}
          onClick={() => {
            handleUpdate(selectedPromotion);
            if (selectedPromotion) {
              dispatch(pagePosActions.setSelectedPromotion(selectedPromotion));
              // dispatch(
              //   pagePosActions.processPromotion({
              //     promotion_code: selectedPromotion.promotion_code,
              //     grand_total: totalPrice,
              //   })
              // );
            }
            setTerm('');
            setOpen(false);
          }}
        >
          Áp dụng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromotionDialog;
