const NotFound404 = () => (
  <svg
    width="328"
    height="242"
    viewBox="0 0 328 242"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333374 132.52C0.333374 160.159 10.5253 185.344 27.2968 204.378C46.5375 226.221 74.4384 239.954 105.539 240C119.131 240.02 132.127 237.409 144.065 232.642C150.238 230.177 157.146 230.46 163.125 233.381C170.137 236.803 177.99 238.721 186.281 238.721C189.707 238.721 193.061 238.391 196.306 237.766C205.567 235.985 213.995 231.772 220.917 225.805C225.267 222.054 230.838 220.168 236.531 220.175H236.628C255.442 220.175 272.918 214.353 287.428 204.378C300.399 195.471 310.992 183.247 318.05 168.894C324.203 156.387 327.667 142.259 327.667 127.305C327.667 76.0181 286.904 34.4356 236.628 34.4356C231.503 34.4356 226.481 34.8772 221.583 35.7012C208.611 14.28 185.383 0 158.884 0C147.697 0 137.091 2.54512 127.597 7.10748C118.568 11.4322 110.541 17.5775 103.974 25.0667C81.6766 25.4293 61.0592 32.86 44.1844 45.261C17.6217 64.7702 0.333374 96.5882 0.333374 132.52Z"
        fill="url(#paint0_linear_3782_415843)"
      />
    </g>
    <g filter="url(#filter0_d_3782_415843)">
      <ellipse
        opacity="0.35"
        cx="92.4055"
        cy="55.2208"
        rx="29.7489"
        ry="29.7489"
        fill="#FFDE7C"
      />
      <ellipse
        cx="92.4055"
        cy="55.2208"
        rx="15.6041"
        ry="15.6041"
        fill="url(#paint1_linear_3782_415843)"
      />
      <ellipse
        cx="157.928"
        cy="163.357"
        rx="41.7169"
        ry="41.7169"
        fill="#005249"
      />
      <circle cx="157.928" cy="163.357" r="26.2596" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.32 177.062C111.32 174.306 110.325 173.311 107.561 173.311H99.9793V128.904C99.9793 125.92 98.9847 125.146 96.2209 125.146H90.1065C88.0199 125.139 86.0536 126.122 84.8073 127.795L48.1205 173.311C47.2004 174.635 46.742 176.226 46.8161 177.836V181.815C46.8161 184.578 47.8107 185.573 50.5663 185.573H87.0166V200.044C87.0166 202.799 88.0113 203.794 90.7668 203.794H96.1802C98.9439 203.794 99.9385 202.799 99.9385 200.044V185.573H107.561C110.504 185.573 111.32 184.578 111.32 181.815V177.062ZM63.0398 173.311L87.0166 143.701V173.311H63.0398Z"
        fill="#00AB55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M269.081 177.062C269.081 174.306 268.086 173.311 265.322 173.311H257.708V128.904C257.708 125.92 256.713 125.146 253.95 125.146H247.876C245.789 125.139 243.823 126.122 242.577 127.795L205.89 173.311C204.964 174.633 204.5 176.224 204.569 177.836V181.815C204.569 184.578 205.564 185.573 208.319 185.573H244.778V200.044C244.778 202.799 245.772 203.794 248.528 203.794H253.941C256.705 203.794 257.7 202.799 257.7 200.044V185.573H265.322C268.306 185.573 269.081 184.578 269.081 181.815V177.062ZM220.801 173.311L244.778 143.701V173.311H220.801Z"
        fill="#00AB55"
      />
      <path
        opacity="0.58"
        d="M248.96 116.259C248.96 116.259 242.022 114.368 240.514 107.903C240.514 107.903 251.267 105.734 251.577 116.83L248.96 116.259Z"
        fill="#00AB55"
      />
      <path
        opacity="0.73"
        d="M249.816 115.575C249.816 115.575 244.965 107.903 249.229 100.737C249.229 100.737 257.382 105.93 253.778 115.591L249.816 115.575Z"
        fill="#00AB55"
      />
      <path
        d="M251.063 115.583C251.063 115.583 253.623 107.479 261.377 105.946C261.377 105.946 262.828 111.205 256.355 115.599L251.063 115.583Z"
        fill="#00AB55"
      />
      <path
        d="M246.033 115.395L247.436 125.023L256.297 125.064L257.602 115.444L246.033 115.395Z"
        fill="#005249"
      />
      <path
        d="M162.975 108.58C162.975 108.58 164.116 114.083 163.619 117.898C163.525 118.647 163.136 119.328 162.536 119.788C161.937 120.248 161.179 120.448 160.431 120.344C158.515 120.067 156.004 119.137 155.034 116.268L152.784 111.58C152.065 109.561 152.695 107.307 154.357 105.955C157.243 103.297 162.445 105.229 162.975 108.58Z"
        fill="#F4A28C"
      />
      <path
        d="M153.191 112.403L152.458 130.836L162.64 130.51L159.086 117.23L153.191 112.403Z"
        fill="#F4A28C"
      />
      <path
        d="M161.613 108.971C159.896 109.246 158.153 109.32 156.42 109.191C157.62 110.623 157.857 112.631 157.023 114.303C156.238 115.954 154.391 116.816 152.621 116.357L151.862 109.167C151.565 107.079 152.443 104.997 154.145 103.753C154.864 103.216 155.619 102.729 156.403 102.294C158.368 101.218 161.556 102.237 163.243 100.623C163.592 100.297 164.085 100.178 164.544 100.308C165.002 100.438 165.36 100.799 165.485 101.259C166.072 103.403 166.089 106.884 163.276 108.425C162.757 108.7 162.194 108.884 161.613 108.971Z"
        fill="#24285B"
      />
      <path
        d="M157.602 114.066C157.602 114.066 157.308 111.914 155.711 112.273C154.113 112.631 154.52 115.738 156.754 115.762L157.602 114.066Z"
        fill="#F4A28C"
      />
      <path
        d="M163.513 112.085L165.331 114.05C165.54 114.279 165.618 114.598 165.537 114.898C165.456 115.197 165.227 115.434 164.931 115.526L162.844 116.178L163.513 112.085Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.31"
        d="M159.991 120.107C158.695 119.938 157.476 119.394 156.485 118.542C156.485 118.542 157.023 121.876 161.099 124.746L159.991 120.107Z"
        fill="#CE8172"
      />
      <path
        d="M152.474 130.836L162.657 130.51C162.657 130.51 178.652 127.795 184.204 140.84C189.756 153.884 182.639 172.317 182.639 172.317C182.639 172.317 176.85 191.777 152.474 189.625C152.474 189.625 132.198 188.451 129.908 160.659C129.804 159.446 129.619 158.242 129.353 157.055C128.424 152.71 127.886 139.527 152.474 130.836Z"
        fill="url(#paint2_linear_3782_415843)"
      />
      <path
        opacity="0.08"
        d="M138.835 148.152C138.835 148.152 144.264 148.747 151.805 161.376C159.347 174.004 174.152 169.374 182.5 160.031L167.01 180.526L149.661 179.132L140.229 154.022L138.835 148.152Z"
        fill="black"
      />
      <rect
        x="195.311"
        y="119.322"
        width="3.00831"
        height="8.75592"
        transform="rotate(-7.60999 195.311 119.322)"
        fill="#FFD200"
      />
      <rect
        opacity="0.08"
        x="195.311"
        y="119.322"
        width="3.00831"
        height="8.75592"
        transform="rotate(-7.60999 195.311 119.322)"
        fill="black"
      />
      <rect
        x="195.387"
        y="125.693"
        width="4.62254"
        height="11.2424"
        transform="rotate(-7.61002 195.387 125.693)"
        fill="#24285B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.585 112.051C184.862 106.652 188.653 101.688 194.052 100.965C196.646 100.618 199.271 101.315 201.351 102.904C203.43 104.492 204.793 106.842 205.14 109.436C205.862 114.835 202.07 119.798 196.67 120.52C191.27 121.242 186.307 117.451 185.585 112.051ZM189.326 114.241C190.743 116.679 193.491 118.023 196.286 117.645H196.319C200.112 117.11 202.765 113.617 202.262 109.819C201.89 107.023 199.87 104.726 197.144 104.001C194.418 103.276 191.524 104.266 189.812 106.508C188.1 108.749 187.908 111.802 189.326 114.241Z"
        fill="#FFE16A"
      />
      <ellipse
        cx="195.414"
        cy="110.773"
        rx="7.00308"
        ry="7.00309"
        fill="#B78103"
      />
      <path
        d="M129.647 149.522C130.154 147.566 131.781 146.102 133.78 145.804C135.778 145.505 137.762 146.43 138.818 148.152C142.709 154.451 145.986 161.11 148.601 168.037C154.431 183.575 183.014 173.328 193.563 137.097L199.58 140.839C199.58 140.839 191.28 187.896 157.871 189.625C157.871 189.625 137.017 193.848 130.682 168.257C130.682 168.257 129.052 163.438 128.954 160.716L128.513 157.65C128.446 154.928 128.809 152.212 129.59 149.604L129.647 149.522Z"
        fill="#00AB55"
      />
      <path
        opacity="0.39"
        d="M129.647 149.522C130.154 147.566 131.781 146.102 133.78 145.804C135.778 145.505 137.762 146.43 138.818 148.152C142.709 154.451 145.986 161.11 148.601 168.037C154.431 183.575 183.014 173.328 193.563 137.097L199.58 140.839C199.58 140.839 191.28 187.896 157.871 189.625C157.871 189.625 137.017 193.848 130.682 168.257C130.682 168.257 129.052 163.438 128.954 160.716L128.513 157.65C128.446 154.928 128.809 152.212 129.59 149.604L129.647 149.522Z"
        fill="white"
      />
      <path
        d="M195.088 138.011C195.088 138.011 195.699 130.257 198.895 130.274C202.091 130.29 209.599 136.029 198.006 139.82L195.088 138.011Z"
        fill="#F4A28C"
      />
      <path
        opacity="0.24"
        d="M278.424 78.8146C278.424 75.7708 275.956 73.3034 272.913 73.3034C272.615 73.3032 272.318 73.3277 272.024 73.3768C270.735 70.9724 268.23 69.4695 265.502 69.4635H265.233C266.119 65.9024 264.707 62.1648 261.689 60.078C258.67 57.9912 254.675 57.9912 251.656 60.078C248.638 62.1648 247.226 65.9024 248.112 69.4635H247.843C245.13 69.3736 242.583 70.77 241.199 73.1062C239.816 75.4424 239.816 78.3469 241.199 80.6831C242.583 83.0192 245.13 84.4156 247.843 84.3257H273.63V84.2768C276.37 83.9134 278.419 81.5788 278.424 78.8146Z"
        fill="#00AB55"
      />
      <path
        opacity="0.24"
        d="M64.1812 133.364C64.1812 132.109 63.683 130.907 62.7962 130.02C61.9094 129.133 60.7067 128.635 59.4526 128.635C59.1987 128.64 58.9454 128.662 58.6944 128.7C57.5826 126.634 55.4241 125.346 53.0773 125.35H52.849C53.6174 122.293 52.4102 119.08 49.819 117.285C47.2278 115.491 43.7956 115.491 41.2043 117.285C38.6131 119.08 37.4059 122.293 38.1743 125.35H37.9461C35.6155 125.266 33.4253 126.462 32.2353 128.468C31.0453 130.473 31.0453 132.968 32.2353 134.974C33.4253 136.98 35.6155 138.175 37.9461 138.092H60.0641V138.051C62.4171 137.741 64.177 135.737 64.1812 133.364Z"
        fill="#00AB55"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3782_415843"
        x="19.3428"
        y="13.4719"
        width="295.081"
        height="227.602"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="12" dy="12" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3782_415843"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3782_415843"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3782_415843"
        x1="224.564"
        y1="282.688"
        x2="340.493"
        y2="15.4176"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#00AB55" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3782_415843"
        x1="61.1973"
        y1="55.2208"
        x2="92.4055"
        y2="86.429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE16A" />
        <stop offset="1" stopColor="#DDAA33" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3782_415843"
        x1="135.889"
        y1="146.243"
        x2="137.475"
        y2="191.108"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#11CA6D" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
    </defs>
  </svg>
);

export default NotFound404;
