const RightArrow = ({ fill = '#637381' }: { fill?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66687 12.6663C6.5111 12.6666 6.36014 12.6124 6.2402 12.513C6.10386 12.3999 6.0181 12.2373 6.00184 12.061C5.98558 11.8846 6.04016 11.709 6.15353 11.573L9.1402 7.99964L6.2602 4.41964C6.14835 4.28191 6.09602 4.10528 6.11479 3.92885C6.13356 3.75242 6.22188 3.59075 6.3602 3.47964C6.49964 3.35694 6.68395 3.29799 6.86871 3.31697C7.05348 3.33595 7.22195 3.43115 7.33353 3.57964L10.5535 7.57964C10.7557 7.82564 10.7557 8.1803 10.5535 8.4263L7.2202 12.4263C7.08456 12.5899 6.87902 12.6791 6.66687 12.6663Z"
      fill={fill}
    />
  </svg>
);

export default RightArrow;
