import {
  Box,
  ClickAwayListener,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { createSelector } from '@reduxjs/toolkit';
import { useFormikContext } from 'formik';
import { memo, useMemo, useState } from 'react';
import { assert } from 'ts-essentials';
import { Motion, spring } from 'react-motion';
import QuantityControl from '../../components/QuantityControl';
import TextFieldNumber from '../../components/TextFieldNumber';
import useAppSelector from '../../hooks/useAppSelector';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../../minimal/Iconify';
import { selectCacheProducts } from '../../store/product';
import { RootState } from '../../store/rootReducer';
import { numberFormat } from '../../utils/constants';
import { getFieldProps } from '../../utils/formik';
import { pagePosActions, selectPagePos } from './slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import { isAdvanceStock } from '../../utils/productUtils';

type OverflowTextProps = {
  size?: number;
};

const OverflowText = styled(Typography)<OverflowTextProps>(({ size = 12 }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 500,
  fontSize: size,
}));

const NoteTextField = styled(TextField)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  width: '86px',
  '& .MuiInput-root': {
    fontSize: '12px',
  },
}));

const RowHighlight = styled('div')(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  border: '3px solid #31ab55',
  pointerEvents: 'none',
}));

type OrderItemProps = {
  item: PendingOrderItem;
  index: number;
};

type SkuFilter = { product_id: string; sku_id: string };

const makeSelectSku = () =>
  createSelector(
    selectCacheProducts,
    (_: RootState, filter: SkuFilter) => filter,
    (byId, filter) => {
      const product = byId[filter.product_id];
      assert(!!product, 'Product can not be null');
      const sku = product?.list_sku.find((s) => s.id === filter.sku_id);
      assert(!!sku, 'SKU can not be null');
      return [product, { ...sku }] as const;
    }
  );

const OrderItem = ({ item, index }: OrderItemProps) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const formik = useFormikContext<PendingOrderForm>();
  const { list_order_item } = formik.values;
  const [selectedItem, setSelectedItem] = useState<PendingOrderItem | null>(
    null
  );
  const [selectedType, setSelectedType] = useState('');
  const selectSku = useMemo(() => makeSelectSku(), []);
  const dispatch = useAppDispatch();
  const [product, sku] = useAppSelector((state) => selectSku(state, item));
  const {
    main: { highlightedSkus },
  } = useAppSelector(selectPagePos);
  const isStock = isAdvanceStock(sku);

  const handleOpenInput = (item: PendingOrderItem, type: string) => {
    setSelectedItem(item);
    setSelectedType(type);
  };

  const handleFocus = (item: PendingOrderItem, type: string) => {
    const el = document.getElementById(
      `${item.sku_id}-${type}`
    ) as HTMLInputElement;
    if (el) el.select();
  };

  const handleRemove = () => {
    formik.setFieldValue('list_order_item', [
      ...list_order_item.slice(0, index),
      ...list_order_item.slice(index + 1, list_order_item.length),
    ]);
  };

  return (
    <TableRow
      id={`order-item-${sku.id}`}
      sx={{
        position: 'relative',
        backgroundColor: index % 2 != 0 ? '#919eab14' : 'white',
      }}
    >
      <TableCell
        align="left"
        width={50}
        sx={{
          padding: 1,
          '&.MuiTableCell-root:first-of-type': {
            paddingLeft: isDesktop ? 2 : 1,
          },
        }}
      >
        <Iconify
          icon="eva:trash-2-fill"
          width={20}
          height={20}
          color={theme.palette.grey[800]}
          onClick={handleRemove}
          sx={{ cursor: 'pointer' }}
        />
      </TableCell>
      {isDesktop && (
        <>
          <TableCell width={50} align="left" sx={{ padding: 1 }}>
            {list_order_item.length - index}
          </TableCell>
          <TableCell width={100} align="left" sx={{ padding: 1 }}>
            <Typography variant="caption" fontWeight={'normal'}>
              {sku.sku_code}
            </Typography>
          </TableCell>
        </>
      )}
      <TableCell width={isDesktop ? '300px' : '170px'} sx={{ padding: 1 }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width={isDesktop ? '300px' : '170px'}
        >
          <OverflowText variant="subtitle2" size={14} noWrap>
            {product.name}
          </OverflowText>
          <Box display="flex" alignItems="center">
            <OverflowText
              variant="subtitle2"
              color={theme.palette.grey[600]}
              noWrap
              maxWidth="70%"
              sx={{ mr: sku.name ? 1 : 0 }}
            >
              {sku.name}
            </OverflowText>
            {selectedItem &&
            selectedItem.sku_id === item.sku_id &&
            selectedType === 'note' ? (
              <ClickAwayListener onClickAway={() => setSelectedItem(null)}>
                <NoteTextField
                  autoFocus
                  variant="standard"
                  {...getFieldProps(formik, `list_order_item.${index}.note`)}
                  onFocus={() => handleFocus(item, 'note')}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') setSelectedItem(null);
                  }}
                />
              </ClickAwayListener>
            ) : (
              <OverflowText
                variant="subtitle2"
                color={theme.palette.grey[500]}
                noWrap
                sx={{ cursor: 'pointer' }}
                onClick={() => handleOpenInput(item, 'note')}
              >
                {item.note || 'Ghi chú'}
              </OverflowText>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right" width="170px" sx={{ padding: 1 }}>
        <Box display="flex" alignItems="center">
          {selectedItem &&
          selectedItem.sku_id === item.sku_id &&
          selectedType === 'price' ? (
            <ClickAwayListener onClickAway={() => setSelectedItem(null)}>
              <TextFieldNumber
                id={`list_order_item.${index}.price`}
                autoFocus
                variant="standard"
                textAlign="right"
                value={item.price}
                onSetField={(value) =>
                  formik.setFieldValue(`list_order_item.${index}.price`, value)
                }
                sx={{ width: '100%', '& input': { textAlign: 'right' } }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    setSelectedItem(null);
                  }
                }}
              />
            </ClickAwayListener>
          ) : (
            <Box
              width="100%"
              borderBottom={`1px solid ${theme.palette.divider}`}
              onClick={() => handleOpenInput(item, 'price')}
            >
              {numberFormat.format(item.price || 0)}
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell align="center" sx={{ padding: 1 }}>
        <Box display="flex" justifyContent="center">
          <QuantityControl
            {...getFieldProps(formik, `list_order_item.${index}.quantity`)}
            canPickQuantity={sku.can_pick_quantity}
            isAdvanceStock={isStock}
            onRemove={handleRemove}
          />
        </Box>
        {isStock && list_order_item[index]?.quantity === sku.can_pick_quantity && (
          <Typography
            variant="caption"
            color={theme.palette.error.main}
            sx={{ mt: 0.5 }}
          >
            Đã đạt số lượng tối đa
          </Typography>
        )}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          padding: 1,
          '&.MuiTableCell-root:last-of-type': {
            paddingRight: isDesktop ? 2 : 1,
          },
        }}
      >
        <span>
          {numberFormat.format(Math.round(item.quantity * item.price || 0))}
        </span>
      </TableCell>

      {highlightedSkus.includes(sku.id) && (
        <Motion
          style={{
            opacity: spring(0, { stiffness: 25 }),
          }}
          defaultStyle={{ opacity: 1 }}
          onRest={() => {
            dispatch(pagePosActions.highlightSkus([]));
          }}
        >
          {({ opacity }) => <RowHighlight style={{ opacity }} />}
        </Motion>
      )}
    </TableRow>
  );
};

export default memo(OrderItem);
