import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  TextField,
  Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { alpha, styled } from '@mui/system';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { assert } from 'ts-essentials';
import { v4 } from 'uuid';
import Confirmation from '../../components/Confirmation';
import CameraPlus from '../../components/Icons/CameraPlus';
import ProductImage from '../../components/ProductImage';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import mediaService from '../../services/media';
import { getFieldProps } from '../../utils/formik';
import { getCanPickQuantity, isAdvanceStock } from '../../utils/productUtils';
import SkuInventory from './SkuInventory';
import SkuPrice from './SkuPrice';
import { pageProductEditActions, selectPageProductEdit } from './slice';
import { createSku } from './utils';

type IconButtonProps = {
  bgColor?: string;
  width?: string;
  height?: string;
};

const BoxStyle = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  width: '100%',
  '& .MuiFormControl-root:first-child': {
    marginRight: theme.spacing(2),
  },
}));

const IconButtonStyle = styled(IconButton)<IconButtonProps>(
  ({ width = '40px', height = '40px', bgColor, theme }) => ({
    backgroundColor: bgColor,
    width,
    height,
    borderRadius: theme.shape.borderRadius,
  })
);

const AvatarStyle = styled(ProductImage)<{ pointer?: boolean }>(
  ({ pointer, theme }) => ({
    width: '56px',
    height: '56px',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    cursor: pointer ? 'pointer' : 'unset',
  })
);

const DividerStyle = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const VariantWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active, theme }) => ({
  padding: theme.spacing(1, 0, 1, 2),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  ...(active && {
    background: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  }),
}));

const VariantName = styled(Typography)(() => ({
  fontSize: '14px',
  textOverflow: 'ellipsis',
}));

const VariantInfo = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey[600],
}));

type ProductVariantProps = {
  openVariant: boolean;
  setOpenVariant: () => void;
};

const ProductVariant = ({
  openVariant,
  setOpenVariant,
}: ProductVariantProps) => {
  const theme = useTheme();
  const formik = useFormikContext<PendingProduct>();
  const { selectedSku: indexSku, confirming } = useAppSelector(
    selectPageProductEdit
  );
  const { skus } = formik.values;
  const selectedSku = skus?.[indexSku];
  const dispatch = useAppDispatch();

  const handleAdd = () => {
    formik.setFieldValue('skus', [
      ...skus,
      createSku({ skusLength: skus.length }),
    ]);
    dispatch(pageProductEditActions.selectSku(skus.length));
  };

  const handleRemove = () => {
    let updateSkus = [...skus.slice(indexSku + 1, skus.length)];
    if (indexSku < skus.length - 1) {
      updateSkus = updateSkus.map((sku, index) => {
        if (sku.name.includes('Phân loại ')) {
          return { ...sku, name: `Phân loại ${indexSku + index + 1}` };
        }
        return sku;
      });
    }
    const newSkus = [...skus.slice(0, indexSku), ...updateSkus];
    formik.setFieldValue('skus', newSkus);

    if (skus.length === 1) {
      formik.setValues({ ...formik.values, is_variant: false });
      formik.setFieldValue('skus', [createSku({})]);
    } else {
      dispatch(pageProductEditActions.selectSku(indexSku - 1));
    }
    dispatch(pageProductEditActions.cancelConfirm());
  };

  return (
    <Box>
      <Typography sx={{ mb: 2 }}>Phân loại</Typography>
      {!openVariant ? (
        <Button
          variant="contained"
          sx={{ textTransform: 'unset' }}
          onClick={setOpenVariant}
        >
          Bật phân loại
        </Button>
      ) : (
        <Grid container>
          <Grid
            item
            xs={5}
            sx={{
              paddingRight: 2,
              borderRight: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography>
                Tổng phân loại ({formik.values.skus.length})
              </Typography>
              <IconButtonStyle
                bgColor={theme.palette.primary.main}
                onClick={handleAdd}
              >
                <Iconify
                  icon={'eva:plus-fill'}
                  color={theme.palette.common.white}
                  width={16}
                  height={16}
                />
              </IconButtonStyle>
            </Box>
            <Box>
              {formik.values.skus.map((sku, index) => (
                <VariantWrapper
                  active={index === indexSku}
                  key={String(index)}
                  onClick={() =>
                    dispatch(pageProductEditActions.selectSku(index))
                  }
                >
                  <AvatarStyle
                    alt="variant"
                    variant="square"
                    image={sku.media[0] || '/assets/product-image.png'}
                  />
                  <Box width="100%" sx={{ overflow: 'hidden' }}>
                    <VariantName noWrap>
                      {sku.name || `Phân loại ${index + 1}`}
                    </VariantName>
                    {isAdvanceStock(sku) && sku.po_details ? (
                      <>
                        <VariantInfo sx={{ my: 0.5 }}>
                          Có thể bán:{' '}
                          {getCanPickQuantity({ ...sku.po_details })}
                        </VariantInfo>
                        <VariantInfo>
                          Tồn kho: {sku.po_details.quantity}
                        </VariantInfo>
                      </>
                    ) : (
                      <VariantInfo>
                        {sku.is_active ? 'Còn hàng' : 'Hết hàng'}
                      </VariantInfo>
                    )}
                  </Box>
                </VariantWrapper>
              ))}
            </Box>
          </Grid>
          <Grid
            item
            xs={7}
            sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 1 }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="h6" fontWeight={600}>
                Chi tiết phân loại
              </Typography>
              <Button
                variant="outlined"
                color="error"
                startIcon={<Iconify icon="eva:trash-2-fill" />}
                sx={{ textTransform: 'inherit' }}
                onClick={() =>
                  dispatch(pageProductEditActions.openConfirm('remove_sku'))
                }
              >
                Xoá phân loại
              </Button>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Thông tin chung
              </Typography>
              <BoxStyle>
                <TextField
                  fullWidth
                  label="Tên phân loại"
                  placeholder="Nhập tên phân loại"
                  {...getFieldProps(formik, `skus[${indexSku}].name`)}
                />
                <label htmlFor="icon-button-file">
                  <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    hidden
                    onChange={async (e) => {
                      if (e.target.files) {
                        const { files } = e.target;
                        for (let i = 0; i < files.length; i++) {
                          const file = files[i];
                          assert(!!file, 'File can not be null');
                          const dotExt = /\.[^.$]+$/.exec(file.name)?.[0];
                          if (dotExt) {
                            const name = v4() + dotExt;

                            await mediaService.saveLocal(
                              name,
                              await file.arrayBuffer()
                            );

                            formik.setFieldValue(`skus[${indexSku}].media`, [
                              {
                                type: 'local',
                                name,
                                mime_type: file.type,
                              },
                            ]);
                          }
                        }
                      }
                    }}
                  />
                  {selectedSku && selectedSku?.media.length > 0 ? (
                    <AvatarStyle
                      alt="variant"
                      variant="square"
                      image={selectedSku?.media[0]}
                      pointer
                    />
                  ) : (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      sx={{
                        backgroundColor: theme.palette.grey[200],
                        width: 56,
                        height: 56,
                        borderRadius: `${theme.shape.borderRadius}px !important`,
                      }}
                    >
                      <CameraPlus fill={theme.palette.grey[600]} />
                    </IconButton>
                  )}
                </label>
              </BoxStyle>
              <BoxStyle>
                <TextField
                  fullWidth
                  label="Mã vạch"
                  placeholder="Nhập mã vạch"
                  {...getFieldProps(formik, `skus[${indexSku}].barcode`)}
                />
                <TextField
                  fullWidth
                  label="Mã sản phẩm SKU"
                  placeholder="Nhập mã SKU sản phẩm"
                  {...getFieldProps(formik, `skus[${indexSku}].sku_code`)}
                />
              </BoxStyle>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Đơn vị"
                  placeholder="Ví dụ: 1 kilogram, 1 đôi, 1 chiếc,..."
                  {...getFieldProps(formik, `skus[${indexSku}].uom`)}
                />
              </Box>
            </Box>
            <DividerStyle />
            <SkuPrice />
            <DividerStyle />
            <SkuInventory />
          </Grid>
        </Grid>
      )}
      <Confirmation
        open={confirming === 'remove_sku'}
        title="Xoá phân loại sản phẩm"
        description="Phân loại sản phẩm này sẽ được xoá sau khi bấm xác nhận.
        Bạn có chắc muốn xoá?"
        onClose={() => dispatch(pageProductEditActions.cancelConfirm())}
        onConfirm={handleRemove}
      />
    </Box>
  );
};

export default memo(ProductVariant);
