import { useFormikContext } from 'formik';
import {
  Card,
  Typography,
  Box,
  styled,
  ClickAwayListener,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useEffect, useState, useRef } from 'react';
import { FlexCenterBetween } from './OrderDetailComponent';
import { numberFormat } from '../../utils/constants';
import { getTotalPrice } from './utils';
import useAppSelector from '../../hooks/useAppSelector';
import { pageOrderDetailActions, selectPageOrderDetail } from './slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import TextFieldOtherDiscount from '../../components/TextFieldOtherDiscount';
import TextFieldNumber from '../../components/TextFieldNumber';

// const TextFieldStyle = styled(TextField)(() => ({
//   width: 150,
//   '& .MuiInput-root': {
//     fontSize: 14,
//   },
//   '& input': {
//     textAlign: 'right',
//   },
// }));

const ToggleButtonStyle = styled(ToggleButton)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[500],
  fontSize: 13,
}));

type PaymentInfoProps = {
  orderDetail?: Order;
};

const PaymentInfo = ({ orderDetail }: PaymentInfoProps) => {
  const {
    main: { isEdit },
  } = useAppSelector(selectPageOrderDetail);
  const formik = useFormikContext<CreateOrderInput>();
  const theme = useTheme();
  const [openOtherDiscount, setOpenOtherDiscountUnit] = useState(false);
  const otherDiscountRef = useRef<HTMLInputElement>();
  const totalPrice = getTotalPrice(
    formik?.values?.list_order_item?.map((value) => ({
      quantity: value.quantity,
      price: value.price,
    }))
  );
  const dispatch = useAppDispatch();
  const {
    main: { otherDiscountUnit },
  } = useAppSelector(selectPageOrderDetail);

  useEffect(
    () => {
      formik.setFieldValue('ordered_grand_total', totalPrice);

      formik.setFieldValue('grand_total', generateFinalPrice());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      formik.values.list_order_item,
      formik.values.other_discount,
      formik.values.delivery_fee,
      formik.values.ordered_grand_total,

      otherDiscountUnit,
    ]
  );

  const generateFinalPrice = () => {
    if (otherDiscountUnit === 'percent') {
      const totalPricePer =
        totalPrice -
        (formik.values.other_discount / 100) *
          (totalPrice - formik.values.promotion_discount) -
        formik.values.promotion_discount +
        formik.values.delivery_fee;
      return totalPricePer < 0 ? 0 : totalPricePer;
    }
    const totalPriceCash =
      totalPrice -
      formik.values.other_discount -
      formik.values.promotion_discount +
      formik.values.delivery_fee;
    return totalPriceCash < 0 ? 0 : totalPriceCash;
  };

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Thông tin thanh toán
      </Typography>
      <FlexCenterBetween mb={2}>
        <Typography variant="body2" color={theme.palette.grey[600]}>
          Tổng{' '}
          {formik?.values?.list_order_item?.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.quantity,
            0
          )}{' '}
          sản phẩm
        </Typography>
        <Box textAlign="right">
          <Typography variant="body2">
            {numberFormat.format(formik.values.ordered_grand_total || 0)} đ
          </Typography>
        </Box>
      </FlexCenterBetween>
      <FlexCenterBetween mb={2}>
        <Typography variant="body2" color={theme.palette.grey[600]}>
          Chiết khấu
        </Typography>
        {isEdit ? (
          <ClickAwayListener
            onClickAway={() => setOpenOtherDiscountUnit(false)}
          >
            <Box display="flex" alignItems="center">
              <TextFieldOtherDiscount
                sx={{ '& input': { textAlign: 'right' } }}
                variant="standard"
                inputRef={otherDiscountRef}
                readOnly={isEdit ? false : true}
                endAdornment={
                  openOtherDiscount && otherDiscountUnit === 'percent' ? (
                    ''
                  ) : (
                    <>&nbsp;đ</>
                  )
                }
                onClick={() => isEdit && setOpenOtherDiscountUnit(true)}
                value={
                  !openOtherDiscount && otherDiscountUnit === 'percent'
                    ? Math.round(
                        (formik.values.other_discount / 100) *
                          (totalPrice - (formik.values.promotion_discount || 0))
                      ) < 0
                      ? 0
                      : Math.round(
                          (formik.values.other_discount / 100) *
                            (totalPrice -
                              (formik.values.promotion_discount || 0))
                        )
                    : formik.values.other_discount
                }
                onChange={(e) => {
                  const regex = /^[0-9]*$/;
                  if (!e.target.value)
                    formik.setFieldValue('other_discount', 0);
                  if (regex.test(e.target.value)) {
                    if (
                      otherDiscountUnit === 'percent' &&
                      +e.target.value > 100
                    ) {
                      return;
                    }

                    if (
                      +e.target.value >
                      totalPrice - (formik.values.promotion_discount || 0)
                    ) {
                      return formik.setFieldValue(
                        'other_discount',
                        totalPrice - (formik.values.promotion_discount || 0) >=
                          0
                          ? totalPrice - (formik.values.promotion_discount || 0)
                          : 0
                      );
                    }
                    formik.setFieldValue('other_discount', +e.target.value);
                  }
                }}
              />

              {openOtherDiscount && (
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  value={otherDiscountUnit}
                  sx={{ ml: 1 }}
                  onChange={(event, newValue) => {
                    if (!newValue) return;
                    dispatch(
                      pageOrderDetailActions.setOtherDiscountUnit(newValue)
                    );

                    if (newValue === 'percent') {
                      formik.setFieldValue(
                        'other_discount',
                        Math.round(
                          (formik.values.other_discount * 100) /
                            (totalPrice -
                              (formik.values.promotion_discount || 0))
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        'other_discount',
                        Math.round(
                          (formik.values.other_discount / 100) *
                            (totalPrice -
                              (formik.values.promotion_discount || 0))
                        )
                      );
                    }
                    otherDiscountRef && otherDiscountRef?.current?.focus();
                  }}
                >
                  <ToggleButtonStyle value="cash">VNĐ</ToggleButtonStyle>
                  <ToggleButtonStyle value="percent">%</ToggleButtonStyle>
                </ToggleButtonGroup>
              )}
            </Box>
          </ClickAwayListener>
        ) : (
          <Typography variant="body2">
            {numberFormat.format(formik.values.other_discount || 0)}&nbsp;đ
          </Typography>
        )}
      </FlexCenterBetween>
      <FlexCenterBetween mb={2}>
        <Typography variant="body2" color={theme.palette.grey[600]}>
          Khuyến mãi
        </Typography>
        <Box textAlign="right">
          <Typography variant="body2">
            {numberFormat.format(orderDetail?.promotion_discount || 0)}&nbsp;đ
          </Typography>
        </Box>
      </FlexCenterBetween>
      <FlexCenterBetween mb={2}>
        <Typography variant="body2" color={theme.palette.grey[600]}>
          Phí vận chuyển
        </Typography>
        {isEdit ? (
          <TextFieldNumber
            id="delivery_fee"
            variant="standard"
            value={formik.values.delivery_fee || 0}
            onSetField={(value) => formik.setFieldValue('delivery_fee', value)}
            endAdornment="&nbsp;đ"
            textAlign="right"
          />
        ) : (
          <Typography variant="body2">
            {numberFormat.format(formik.values.delivery_fee || 0)}&nbsp;đ
          </Typography>
        )}
      </FlexCenterBetween>
      <FlexCenterBetween mb={2}>
        <Typography variant="subtitle1">Tổng cộng</Typography>
        <Box textAlign="right">
          <Typography variant="subtitle1" color={theme.palette.error.main}>
            {numberFormat.format(formik.values.grand_total || 0)} đ
          </Typography>
        </Box>
      </FlexCenterBetween>
    </Card>
  );
};

export default memo(PaymentInfo);
