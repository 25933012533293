import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import OutOfStockDialog from '../../components/OutOfStockDialog';
import { HEADER } from '../../config/styles';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import useResponsive from '../../hooks/useResponsive';
import PosLayout from '../../layouts/PosLayout';
import { posActions } from '../../store/pos';
import { isAdvanceStock } from '../../utils/productUtils';
import OrderItems from './OrderItems';
import PosForm from './PosForm';
import Products from './Products';
import RightPanel from './RightPanel';
import {
  pagePosActions,
  selectLocalSelectedOrder,
  selectPagePos,
} from './slice';

const Layout = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  paddingTop: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  },
}));

const POSComponent = ({ loading }: { loading: boolean }) => {
  const dispatch = useAppDispatch();
  const isDesktop = useResponsive('up', 'lg');
  const selectedOrder = useAppSelector(selectLocalSelectedOrder);
  const {
    main: { payments, submitResponse },
  } = useAppSelector(selectPagePos);
  const formik = useFormikContext<PendingOrderForm>();
  const formValues = formik.values;

  useEffect(() => {
    dispatch(pagePosActions.getPayments());
  }, [dispatch]);

  useEffect(() => {
    if (payments[0] && !formik.values.payment_source_id) {
      formik.setFieldValue('payment_method', payments[0].name);
      formik.setFieldValue('payment_source_id', payments[0].id);
      formik.setFieldValue('payment_source_name', payments[0].name);
    }
  }, [formik, payments]);

  return (
    <PosLayout
      formValues={formValues}
      selectedOrder={selectedOrder}
      onResetData={() => formik.resetForm()}
      onSelectedOrderChange={(order) => {
        dispatch(pagePosActions.setSelectedOrderId(order.id));
        dispatch(
          posActions.save({
            ...formValues,
            id: formValues.selectedOrderId || '',
            items: formValues.list_order_item,
            is_wholesale_price: formValues.is_wholesale_price || false,
            selected_promotion: formValues.selected_promotion || null,
          })
        );
      }}
      onSelectedItem={(sku) => {
        const { list_order_item } = formik.values;
        const isStock = isAdvanceStock(sku);
        if ((isStock && sku.can_pick_quantity > 0) || !isStock) {
          const existedItemIndex = list_order_item.findIndex(
            (item) => item.sku_id === sku.id
          );
          if (existedItemIndex !== -1) {
            list_order_item.map((item) => {
              if (
                item.sku_id === sku.id &&
                item.quantity + 1 <= sku.can_pick_quantity
              ) {
                item.quantity = item.quantity + 1;
              }
            });
            formik.setFieldValue('list_order_item', list_order_item);
          } else {
            formik.setFieldValue('list_order_item', [
              {
                sku_id: sku.id,
                // client_id: v4(),
                sku_code: sku.sku_code,
                product_id: sku.product_id,
                product_name: sku.product_name,
                product_normal_price: sku.normal_price,
                product_selling_price: sku.selling_price,
                price: sku.selling_price || sku.normal_price,
                wholesale_price: sku.wholesale_price,
                product_images: [
                  sku.media?.[0] || sku.product_images?.[0] || '',
                ],
                quantity: 1,
                note: '',
              } as PendingOrderItem,
              ...formik.values.list_order_item,
            ]);
          }
          setTimeout(() => {
            dispatch(pagePosActions.highlightSkus([sku.id]));
            document &&
              document
                .getElementById(`order-item-${sku.id}`)
                ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 0);
        }
      }}
    >
      <Layout>
        <Box
          display="flex"
          flexDirection="column"
          style={{
            height: '100%',
            width: `calc(100% - ${isDesktop ? '432px' : '360px'})`,
          }}
        >
          <OrderItems />
          <Products />
        </Box>
        <RightPanel loading={loading} />
      </Layout>
      {submitResponse && submitResponse.status === 'sold_out' && (
        <OutOfStockDialog
          open={Boolean(submitResponse)}
          onClose={() => dispatch(pagePosActions.setSubmitResponse(null))}
          formik={formik}
          submitResponse={submitResponse}
        />
      )}
    </PosLayout>
  );
};

const POS = () => {
  const selectedOrder = useAppSelector(selectLocalSelectedOrder);
  const [loading, setLoading] = useState(false);

  return (
    <PosForm setLoading={setLoading}>
      <POSComponent key={selectedOrder?.id || ''} loading={loading} />
    </PosForm>
  );
};

export default POS;
