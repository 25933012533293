import * as Yup from 'yup';
import { ORDER_STATUS } from '../../utils/constants';

export const initialBuyerInfo = {
  phone_number: '',
  name: '',
  address: '',
  avatar: '',
  debt_amount: 0,
  option: '',
};

export const createOrder = (): PendingOrderForm => ({
  promotion_code: null,
  ordered_grand_total: 0,
  promotion_discount: 0,
  other_discount: 0,
  delivery_fee: 0,
  grand_total: 0,
  state: ORDER_STATUS.COMPLETE,
  payment_method: '',
  payment_source_id: '',
  payment_source_name: '',
  email: '',
  note: '',
  delivery_method: 'buyer_pick_up',
  images: [],
  debit: {
    buyer_pay: 0,
    description: '',
    is_debit: false,
  },
  buyer_info: initialBuyerInfo,
  create_method: 'seller',
  list_order_item: [],
  is_wholesale_price: false,
  selected_promotion: null,
  has_debt_amount: false,
});

export const POSSchema = Yup.object().shape({
  delivery_method: Yup.string(),
  buyer_info: Yup.object()
    .shape({})
    .when('delivery_method', {
      is: 'seller_delivery',
      then: (schema) =>
        schema.shape({
          address: Yup.string().required('Bạn chưa nhập địa chỉ'),
        }),
    }),
  debit: Yup.object().shape({
    buyer_pay: Yup.number(),
  }),
});
