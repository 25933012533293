import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  styled,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { numberFormat } from '../../utils/constants';
import ProductImage from '../ProductImage';

const AvatarStyle = styled(ProductImage)(({ theme }) => ({
  width: '48px',
  height: '48px',
  marginRight: theme.spacing(2),
  borderRadius: '12px',
}));

const ItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 5),
  cursor: 'pointer',
}));

type ConfirmationProps = {
  open: boolean;
  onClose(): void;
  formik: ExpectedAny;
  submitResponse: CreateOrderResponse | null;
};

const OutOfStockDialog = ({
  open,
  onClose,
  formik,
  submitResponse,
}: ConfirmationProps) => {
  const theme = useTheme();

  const handleRemoveItems = () => {
    const { list_order_item } = formik.values;
    if (submitResponse && submitResponse.items_info) {
      const newItems = list_order_item.filter((item: ExpectedAny) =>
        submitResponse?.items_info?.some(
          (resItem) => item.sku_id !== resItem.id
        )
      );
      formik.setFieldValue('list_order_item', newItems);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle color={theme.palette.grey[800]} sx={{ textAlign: 'center' }}>
        <Typography variant="h4">Sản phẩm hết hàng</Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: theme.spacing(2), textAlign: 'center' }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Bạn hãy cập nhật số lượng tồn kho cho những sản phẩm này nhé!
        </Typography>
        {submitResponse?.items_info?.map((item) => (
          <ItemWrapper
            key={item.id}
            onClick={() =>
              window.open(`/products/${item.product_id}`, '_blank')
            }
          >
            <Box display="flex" alignItems="center">
              <AvatarStyle
                image={item.media?.[0] || '/assets/product-image.png'}
                variant="square"
                alt={item.sku_name}
              />
              <Box textAlign="left">
                <Typography
                  variant="subtitle2"
                  color={theme.palette.grey[800]}
                  noWrap
                >
                  {item.product_name}
                </Typography>
                {item.sku_name && (
                  <Typography variant="body2" noWrap>
                    {item.sku_name}
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              {numberFormat.format(
                item.selling_price || item.normal_price || 0
              )}
              đ
            </Typography>
          </ItemWrapper>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Quay lại
        </Button>
        <Button variant="contained" onClick={handleRemoveItems}>
          Loại khỏi đơn hàng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OutOfStockDialog;
