import { List, Box, BoxProps } from '@mui/material';
import { NavListRoot } from './NavList';

interface NavSectionProps extends BoxProps {
  isCollapse?: boolean;
  navConfig: NavListProps[];
}

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}: NavSectionProps) {
  return (
    <Box {...other} width="100%">
      {navConfig.map((item) => (
        <List key={item.path || item.key} disablePadding>
          <NavListRoot key={item.title} item={item} isCollapse={isCollapse} />
        </List>
      ))}
    </Box>
  );
}
