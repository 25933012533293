import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  styled,
  Card,
  Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useEffect } from 'react';
import { TableHeader } from '../../components/TableHeader';
import { TABLE_HEAD } from './settings';
import Scrollbar from '../../minimal/Scrollbar';
import useAppSelector from '../../hooks/useAppSelector';
import moment from 'moment';
import { numberFormat } from '../../utils/constants';
import EmptyPaybookState from './EmptyPaybookState';
import { useParams, useLocation } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import { pageContactEditActions, selectPageContactEdit } from './slice';

type BorrowedWrapperProps = {
  status: boolean;
};

const BorrowedWrapper = styled(Box)<BorrowedWrapperProps>(
  ({ status, theme }) => ({
    textAlign: 'center',
    borderRadius: 6,
    padding: '2px 8px',
    width: 'fit-content',
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.primary.dark,
    ...(!status && {
      backgroundColor: theme.palette.warning.lighter,
      color: theme.palette.warning.dark,
    }),
  })
);

const TableCellStyle = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const Paybook = () => {
  const dispatch = useAppDispatch();
  const { contactTransactions, stateContactTransactions } = useAppSelector(
    selectPageContactEdit
  );
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const businessId = search.slice(13);

  useEffect(() => {
    if (id) {
      dispatch(
        pageContactEditActions.getContactTransaction({
          contact_id: id,
          business_id: businessId,
          page: 1,
          page_size: 100,
        })
      );
    }
  }, [businessId, dispatch, id, search]);
  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
        Sổ nợ
      </Typography>

      <TableContainer>
        <Scrollbar sx={{ height: 240 }}>
          <Table stickyHeader>
            <TableHeader headLabel={TABLE_HEAD} />
            <TableBody>
              {stateContactTransactions === 'loading' &&
                [1, 2, 3, 4].map((value) => (
                  <TableRow key={value}>
                    <TableCellStyle>
                      <Skeleton animation="wave" width={160} height={24} />
                    </TableCellStyle>
                    <TableCellStyle>
                      <Skeleton animation="wave" width={100} height={24} />
                    </TableCellStyle>
                    <TableCellStyle>
                      <Skeleton animation="wave" width={120} height={24} />
                    </TableCellStyle>
                  </TableRow>
                ))}
              {stateContactTransactions !== 'loading' &&
              contactTransactions.length == 0 ? (
                <TableRow>
                  <TableCellStyle colSpan={3}>
                    <EmptyPaybookState classCus={{ height: 150, mt: 2 }} />
                  </TableCellStyle>
                </TableRow>
              ) : (
                contactTransactions.map((item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{
                      backgroundColor: index % 2 != 0 ? '#919eab14' : 'white',
                    }}
                  >
                    <TableCellStyle>
                      {moment(item.start_time).format('DD/MM/YYYY HH:mm')}
                    </TableCellStyle>

                    <TableCellStyle
                      sx={{
                        color: theme.palette.error.main,
                        textAlign: 'right',
                      }}
                    >
                      {item.transaction_type === 'in' &&
                        numberFormat.format(item.amount || 0)}
                    </TableCellStyle>
                    <TableCellStyle
                      sx={{
                        color: theme.palette.grey[600],
                        textAlign: 'right',
                      }}
                    >
                      {item.transaction_type === 'out' &&
                        numberFormat.format(item.amount || 0)}
                      <Box display="flex" justifyContent="flex-end">
                        {item.transaction_type === 'out' && (
                          <BorrowedWrapper
                            status={item.is_pay_transaction}
                            sx={{ display: 'flex' }}
                          >
                            <Typography
                              variant="caption"
                              display="inline-block"
                              fontWeight="bold"
                            >
                              {item.is_pay_transaction
                                ? 'Đã xác nhận'
                                : 'Chưa xác nhận'}
                            </Typography>
                          </BorrowedWrapper>
                        )}
                      </Box>
                    </TableCellStyle>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </Card>
  );
};

export default memo(Paybook);
