import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../rootReducer';
import { categoryOffline } from '../../config/offline/category';
import configOfflineCacheSlice from '../../utils/offline/configOfflineCacheSlice';

export const selectCategory = (state: RootState) => state.cache_category;
export const selectAppCategoriesCache = createSelector(
  selectCategory,
  state => state.byId
);

const categorySlice = configOfflineCacheSlice(categoryOffline);

export const categoryActions = {
  ...categorySlice.actions,
};
export default categorySlice;
