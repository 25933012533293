import {
  TableContainer,
  Table,
  TableBody,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import { FlexBetween } from './OrderDetailComponent';
import { TableHeader } from '../../components/TableHeader';
import { TABLE_HEAD } from './settings';
import Iconify from '../../minimal/Iconify';
import AddProductPopover from './AddProductPopover';
import OrderItemRow from './OrderItemRow';
import { pageOrderDetailActions, selectLocalOrderItem } from './slice';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageOrderDetail } from './slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import OutOfStockDialog from '../../components/OutOfStockDialog';
import ProductRemovedDialog from '../../components/ProductRemovedDialog';
import { getTotalPrice } from './utils';
import { ORDER_STATUS } from '../../utils/constants';
import usePrinter from '../../hooks/usePrinter';
import { selectPageOrders } from '../Orders/slice';

type OrderDetailTableProps = {
  state: string;
};

const OrderDetailTable = ({ state }: OrderDetailTableProps) => {
  const dispatch = useAppDispatch();
  const {
    main: { isEdit, submitResponse, orderDetail },
  } = useAppSelector(selectPageOrderDetail);
  const {
    main: { configInvoice, paymentsInfo },
  } = useAppSelector(selectPageOrders);
  const formik = useFormikContext<CreateOrderInput>();
  const theme = useTheme();
  const [openAddProduct, setOpenAddProduct] = useState<HTMLElement | null>(
    null
  );
  const [isCheck, setIsCheck] = useState(false);
  const [orderItemRemove, setOrderItemRemove] = useState<string[]>([]);
  const [hadChoose, setHadChoose] = useState(0);
  const { list_order_item } = formik.values;
  const getCreateOrderInput = useAppSelector(selectLocalOrderItem);
  const [productRemoved, setProductRemoved] = useState<ExpectedAny[] | null>(
    null
  );

  const { handlePrint, showDataPrint } = usePrinter({
    ordersPrint: [orderDetail],
    configs: configInvoice,
    paymentsInfo,
  });

  const removeProductOrder = async () => {
    formik.setFieldValue('list_order_item', [
      ...formik.values.list_order_item.filter(
        (value) => !orderItemRemove.includes(value.sku_id)
      ),
    ]);
    setIsCheck(false);
    setOrderItemRemove([]);
  };

  const handleCheckbox = ({
    event,
    product,
  }: {
    event: React.ChangeEvent<HTMLInputElement>;
    product: list_order_item;
  }) => {
    const checkboxes = document.querySelectorAll(
      'input[type=checkbox]:checked'
    );
    setHadChoose(checkboxes.length);
    checkboxes.length > 0 ? setIsCheck(true) : setIsCheck(false);
    event.target.checked
      ? setOrderItemRemove((stateprev) => [...stateprev, product.sku_id])
      : setOrderItemRemove((stateprev) => [
          ...stateprev.filter((value) => value != product.sku_id),
        ]);
  };

  useEffect(() => {
    state !== ORDER_STATUS.CANCEL &&
      state !== ORDER_STATUS.COMPLETE &&
      !isEdit &&
      setProductRemoved(getCreateOrderInput.removeProductOrder);
  }, [getCreateOrderInput.removeProductOrder, isEdit, state]);

  return (
    <Box mt={3}>
      {state != ORDER_STATUS.CANCEL && (
        <FlexBetween
          width={'100%'}
          sx={{ color: theme.palette.primary.main, mb: 2, mr: 1 }}
        >
          <Box>
            {isEdit && (
              <Typography
                variant="h6"
                fontWeight={600}
                color={theme.palette.grey[800]}
                sx={{ ml: 1 }}
              >
                Đã chọn ({hadChoose})
              </Typography>
            )}
          </Box>
          {isEdit ? (
            <Box>
              <Button
                variant="outlined"
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{ mr: 2 }}
                onClick={(e) => {
                  setOpenAddProduct(e.currentTarget);
                }}
              >
                <Typography variant="subtitle2">Thêm sản phẩm</Typography>
              </Button>
              <Button
                variant="outlined"
                startIcon={<Iconify icon="eva:trash-2-fill" />}
                onClick={removeProductOrder}
                color="error"
                disabled={isCheck ? false : true}
              >
                <Typography variant="subtitle2">Xoá</Typography>
              </Button>
            </Box>
          ) : (
            <Box>
              {state && state != ORDER_STATUS.COMPLETE && (
                <Button
                  variant="outlined"
                  startIcon={<Iconify icon="eva:edit-fill" />}
                  onClick={() => {
                    dispatch(pageOrderDetailActions.setIsEdit(!isEdit));
                  }}
                  sx={{ border: 'none', mr: 2, '&:hover': { border: 'none' } }}
                >
                  <Typography variant="subtitle2">Sửa đơn</Typography>
                </Button>
              )}

              <Button
                variant="outlined"
                startIcon={<Iconify icon="eva:printer-fill" />}
                sx={{ border: 'none', '&:hover': { border: 'none' } }}
                onClick={handlePrint}
              >
                {showDataPrint()}
                <Typography variant="subtitle2">In đơn</Typography>
              </Button>
            </Box>
          )}
        </FlexBetween>
      )}

      <TableContainer sx={{ maxHeight: 370 }}>
        <Table stickyHeader>
          <TableHeader headLabel={TABLE_HEAD} />
          <TableBody>
            {list_order_item?.map((product, index) => (
              <OrderItemRow
                key={product.sku_id}
                product={product}
                index={index}
                handleCheckbox={handleCheckbox}
                isEdit={isEdit}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddProductPopover open={openAddProduct} setOpen={setOpenAddProduct} />
      {productRemoved && productRemoved.length > 0 && (
        <ProductRemovedDialog
          open={Boolean(productRemoved)}
          onClose={() => {
            dispatch(pageOrderDetailActions.setIsEdit(true));
            formik.setFieldValue(
              'ordered_grand_total',
              getTotalPrice(
                formik.values.list_order_item.map((value) => ({
                  quantity: value.quantity,
                  price: value.price,
                }))
              )
            );
            const newOrderItems = getCreateOrderInput.listOrderItem.filter(
              (item: ExpectedAny) => {
                const itemExist = productRemoved?.find(
                  (resItem) => item.sku_id === resItem.sku_id
                );
                if (itemExist) {
                  return;
                }
                return item;
              }
            );
            formik.setFieldValue('list_order_item', newOrderItems);
            setProductRemoved(null);
          }}
          submitResponse={productRemoved}
        />
      )}
      {submitResponse && submitResponse.status === 'sold_out' && (
        <OutOfStockDialog
          open={Boolean(submitResponse)}
          onClose={() => {
            dispatch(pageOrderDetailActions.setSubmitResponse(null));
          }}
          formik={formik}
          submitResponse={submitResponse}
        />
      )}
    </Box>
  );
};

export default memo(OrderDetailTable);
