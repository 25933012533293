import {
  Box,
  Typography,
  Card,
  Avatar,
  Tooltip,
  Button,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { numberFormat } from '../../utils/constants';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../../minimal/Iconify';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../config/routeConfig';
import {
  pageContactEditActions,
  selectPageContactEdit,
} from '../ContactDetail/slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import NewDeptDialog from '../ContactDetail/NewDeptDialog';
import useAppSelector from '../../hooks/useAppSelector';
import { pageDashboardActions, selectPageDashboard } from './slice';
import EmptyOrder from '../../components/Icons/EmptyOrder';
import { getOptimizeImage } from '../../utils/imageUtils';

const BoxSummary = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
  width: '100%',
  textAlign: 'center',
}));

const ContactWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1.5)} 0px`,
}));

const ContactInfo = styled(Box)(({ theme }) => ({
  width: '65%',
  display: 'flex',
  marginRight: theme.spacing(1.5),
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  marginRight: theme.spacing(2),
  borderRadius: '12px',
}));

const OverflowText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const DebtReminder = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cashbookAmount, transactionContacts } =
    useAppSelector(selectPageDashboard);
  const { openNewDept } = useAppSelector(selectPageContactEdit);
  const isEmptyState =
    !transactionContacts ||
    !transactionContacts?.data ||
    transactionContacts?.data?.length === 0;

  return (
    <Card
      sx={{
        p: 3,
        height: isDesktop ? 482 : 542,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box mb={3}>
          <Typography>Lịch nhắc nợ hôm nay</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <BoxSummary mr={2}>
              <Typography variant="body2" sx={{ mb: theme.spacing(0.5) }}>
                Tôi phải trả
              </Typography>
              <Typography
                variant="h6"
                color={theme.palette.primary.dark}
                noWrap
              >
                {numberFormat.format(cashbookAmount?.total_amount_out || 0)}
              </Typography>
            </BoxSummary>
          </Grid>
          <Grid item xs={12} lg={6}>
            <BoxSummary>
              <Typography variant="body2" sx={{ mb: theme.spacing(0.5) }}>
                Tôi phải thu
              </Typography>
              <Typography variant="h6" color={theme.palette.error.dark} noWrap>
                {numberFormat.format(cashbookAmount?.total_amount_in || 0)}
              </Typography>
            </BoxSummary>
          </Grid>
        </Grid>
        {isEmptyState ? (
          <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
            <EmptyOrder height={isDesktop ? '150' : '130'} />
            <Typography
              variant="subtitle1"
              color={theme.palette.grey[500]}
              sx={{ mt: 2 }}
            >
              Chưa có giao dịch nào!
            </Typography>
          </Box>
        ) : (
          <Box mb={0.5}>
            {(transactionContacts.data || []).map((contact) => (
              <ContactWrapper key={contact.contact_id}>
                <ContactInfo>
                  <AvatarStyle
                    alt={contact.name}
                    src={getOptimizeImage(
                      `${contact.avatar || contact.social_avatar}`,
                      {
                        w: 128,
                        h: 128,
                        fit: 'crop',
                      }
                    )}
                  />
                  <Box py="4px" width={isDesktop ? '90%' : '70%'}>
                    <Tooltip title={contact.name} placement="top-start">
                      <OverflowText noWrap variant="body2" fontWeight={600}>
                        {contact.name}
                      </OverflowText>
                    </Tooltip>
                    <Tooltip
                      title={contact.phone_number.replace('+84', '0')}
                      placement="top-start"
                    >
                      <OverflowText
                        variant="body2"
                        color={theme.palette.grey[600]}
                      >
                        {contact.phone_number.replace('+84', '0')}
                      </OverflowText>
                    </Tooltip>
                  </Box>
                </ContactInfo>
                <Box
                  py="4px"
                  width={isDesktop ? '25%' : '48%'}
                  overflow="hidden"
                  textAlign="right"
                >
                  <Tooltip
                    title={numberFormat
                      .format(contact.debt_amount)
                      .replace('-', '')}
                    placement="top-start"
                  >
                    <OverflowText
                      variant="body2"
                      fontWeight={600}
                      color={
                        contact.transaction_type === 'in'
                          ? theme.palette.error.main
                          : theme.palette.primary.main
                      }
                      noWrap
                    >
                      {numberFormat
                        .format(contact.debt_amount)
                        .replace('-', '')}
                    </OverflowText>
                  </Tooltip>
                </Box>
              </ContactWrapper>
            ))}
            {transactionContacts.data.length === 2 && (
              <Box
                py={2}
                textAlign="right"
                borderTop={`1px solid ${theme.palette.divider}`}
              >
                <Button
                  color="inherit"
                  endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                  onClick={() => navigate(PATH_DASHBOARD.contact.list)}
                  sx={{ mr: 3 }}
                >
                  Xem thêm khách nợ
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button
          variant="contained"
          color="error"
          startIcon={<Iconify icon="eva:minus-circle-fill" />}
          fullWidth
          sx={{
            mr: 1.5,
            backgroundColor: theme.palette.error.dark,
            py: 1.5,
            whiteSpace: 'nowrap',
          }}
          onClick={() =>
            dispatch(pageContactEditActions.setOpenNewDept('borrowed'))
          }
        >
          Tôi cho nợ
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="eva:plus-circle-fill" />}
          fullWidth
          sx={{ whiteSpace: 'nowrap' }}
          onClick={() =>
            dispatch(pageContactEditActions.setOpenNewDept('lent'))
          }
        >
          Tôi mượn nợ
        </Button>
      </Box>
      {Boolean(openNewDept) && (
        <NewDeptDialog
          hasSearch
          onSuccess={() => dispatch(pageDashboardActions.getDeptAmount())}
        />
      )}
    </Card>
  );
};

export default DebtReminder;
