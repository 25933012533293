import { createAsyncThunk } from '@reduxjs/toolkit';
import productService from '../../services/product';
import { isLocalImage } from '../../utils/productUtils';
import { toastActions } from '../toast';
import { selectApp } from '../app';
import { RootState } from '../rootReducer';

export const commitProduct = createAsyncThunk<
  void,
  { id?: string; product: PendingProduct },
  { state: RootState }
>('product/commit', async ({ id, product }, { dispatch, getState }) => {
  const { offline } = selectApp(getState());
  if (offline) {
    // save offline
  } else {
    // upload local images
    const nextProduct = {
      ...product,
      images: await Promise.all(
        product.images.map(async (image) => {
          if (isLocalImage(image)) {
            try {
              return await productService.uploadProductImage(image);
            } catch (ex) {
              dispatch(
                toastActions.create({
                  severity: 'error',
                  message:
                    'Không thể tải ảnh sản phẩm lên, vui lòng kiểm tra lại kết nối.',
                  dismissAfter: 3000,
                })
              );
              return image;
            }
          }

          return image;
        })
      ),
      skus: await Promise.all(
        product.skus.map(async (sku) => {
          if (sku.media[0] && isLocalImage(sku.media[0])) {
            try {
              const media = await productService.uploadProductImage(
                sku.media[0]
              );
              return { ...sku, media: [media] };
            } catch (ex) {
              dispatch(
                toastActions.create({
                  severity: 'error',
                  message:
                    'Không thể tải ảnh sản phẩm lên, vui lòng kiểm tra lại kết nối.',
                  dismissAfter: 3000,
                })
              );
              return sku;
            }
          }
          return sku;
        })
      ),
    };

    const allImagesReady = nextProduct.images.every(
      (image) => !isLocalImage(image)
    );

    const allImagesSkuReady = nextProduct.skus.every((sku) => {
      if (sku.media[0]) {
        if (!isLocalImage(sku.media[0])) return true;
        return false;
      }
      return true;
    });

    if (allImagesReady && allImagesSkuReady) {
      // save product
      if (id) {
        await productService.updateProduct(id, nextProduct);
      } else {
        const newProduct = await productService.createProduct(nextProduct);
        if (nextProduct.category.length > 0) {
          await productService.addProductToCategory({
            product_ids: [newProduct.id],
            category_ids: nextProduct.category,
          });
        }
      }
    } else {
      throw new Error('Sản phẩm chưa sẵn sàng để tải lên.');
    }
  }
});
