const Transaction = ({
  fill = '#FFA48D',
  opacity = '0.2',
}: {
  fill?: string;
  opacity?: string;
}) => (
  <svg
    width="116"
    height="112"
    viewBox="0 0 116 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={opacity}>
      <path
        d="M83.3062 51.8438C85.7861 51.8438 87.7936 49.865 87.7936 47.4206L89.0308 26.0532C89.0308 22.9382 86.4665 20.4162 83.3062 20.4162C80.1459 20.4162 77.5873 22.9382 77.5873 26.0532L78.8188 47.4206C78.8188 49.865 80.8263 51.8438 83.3062 51.8438Z"
        fill={fill}
      />
      <path
        d="M77.1807 94.8572C77.1807 94.8572 78.4685 96.1432 79.267 96.703C80.4254 97.5677 81.8537 98 83.2764 98C84.8735 98 86.358 97.5122 87.567 96.5921C87.7863 96.376 88.7198 95.5612 89.4902 94.8018C94.3263 90.3621 102.238 78.7721 104.656 72.7028C105.039 71.7827 105.865 69.4547 105.916 68.2076C105.916 67.0214 105.646 65.8796 105.095 64.7988C104.325 63.4463 103.116 62.36 101.687 61.7669C100.698 61.3844 97.734 60.7914 97.6778 60.7914C94.4331 60.1927 89.1584 59.8713 83.3326 59.8713C77.7824 59.8713 72.727 60.1927 69.4317 60.6805C69.3755 60.7359 65.6978 61.329 64.4326 61.9831C62.127 63.1748 60.6931 65.5027 60.6931 67.9914L60.6931 68.2076C60.7493 69.8316 62.1832 73.246 62.2339 73.246C64.6519 78.9883 72.1816 90.3066 77.1807 94.8572Z"
        fill={fill}
      />
      <path
        d="M32.4258 60.1577C29.9459 60.1577 27.9384 62.1365 27.9384 64.581L26.7069 85.9488C26.7069 89.0639 29.2655 91.5859 32.4258 91.5859C35.5861 91.5859 38.1504 89.0639 38.1504 85.9488L36.9133 64.581C36.9133 62.1365 34.9057 60.1577 32.4258 60.1577Z"
        fill={fill}
      />
      <path
        d="M10.6381 47.2052C11.4085 48.5576 12.6175 49.6385 14.0458 50.2371C15.0355 50.6196 18.0047 51.2127 18.0553 51.2127C21.3 51.8113 26.5747 52.1328 32.4004 52.1328C37.9507 52.1328 43.0061 51.8113 46.3014 51.3236C46.3576 51.2681 50.0353 50.6695 51.3005 50.021C53.6117 48.8292 55.04 46.5012 55.04 44.0069L55.04 43.7908C54.9838 42.1667 53.5555 38.7578 53.4992 38.7578C51.0812 33.0154 43.5572 21.6912 38.5524 17.1461C38.5524 17.1461 37.2646 15.8601 36.4661 15.3058C35.3133 14.4356 33.885 14.0032 32.4511 14.0032C30.8596 14.0032 29.3807 14.491 28.1661 15.4111C27.9467 15.6273 27.0133 16.4421 26.2429 17.196C21.4124 21.6414 13.4947 33.2315 11.0823 39.2955C10.6943 40.2156 9.86769 42.5492 9.81708 43.7908C9.81708 44.9825 10.087 46.1243 10.6381 47.2052Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default Transaction;
