import { TablePagination } from '@mui/material';
import createPaginationHook from '../../hooks/createPaginationHook';
import { pageOrdersActions, selectLocalSearchResult } from './slice';

const usePagination = createPaginationHook({
  selectResult: selectLocalSearchResult,
  actions: pageOrdersActions,
});

const OrdersPagination = () => {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 20]}
      component="div"
      labelRowsPerPage="Số đơn hàng trên trang"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
      {...usePagination()}
    />
  );
};

export default OrdersPagination;
