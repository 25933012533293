import { useNavigate } from 'react-router-dom';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Typography,
  MenuItem,
  // Stack,
  // ListItemIcon,
  // styled
} from '@mui/material';
import MenuPopover from '../../minimal/MenuPopover';
import useAppSelector from '../../hooks/useAppSelector';
import { authActions, selectAppAuth } from '../../store/auth';
import useAppDispatch from '../../hooks/useAppDispatch';
// import Iconify from '../../minimal/Iconify';
import { formatPhone } from '../../screens/Dashboard/utils';

// const MENU_OPTIONS = [
//   {
//     label: 'Cá nhân',
//     icon: 'eva:person-fill',
//     linkTo: '/user',
//   },
// ];

// const Icon = styled(Iconify)(({ theme }) => ({
//   width: 24,
//   height: 24,
//   '& path': {
//     fill: theme.palette.grey[500],
//   },
// }));

type AccountPopoverProps = {
  open: HTMLElement | null;
  setOpen: (value: HTMLElement | null) => void;
};

export default function AccountPopover({
  open = null,
  setOpen,
}: AccountPopoverProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user_info } = useAppSelector(selectAppAuth);

  const handleClose = () => setOpen(null);

  const handleLogout = async () => {
    try {
      await dispatch(authActions.logout()).unwrap();
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <MenuPopover
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleClose}
      sx={{
        p: 0,
        mt: 1.5,
        ml: 0.75,
        '& .MuiMenuItem-root': {
          typography: 'body2',
          borderRadius: 0.75,
        },
      }}
    >
      <Box sx={{ my: 1.5, px: 2.5 }}>
        <Typography variant="subtitle2" noWrap>
          {user_info?.full_name}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {formatPhone(user_info?.phone_number || '')}
        </Typography>
      </Box>

      <Divider sx={{ borderStyle: 'dashed' }} />

      {/* <Stack sx={{ p: 1 }}>
        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
          >
            <ListItemIcon sx={{ mr: 0 }}>
              <Icon icon={option.icon} />
            </ListItemIcon>
            {option.label}
          </MenuItem>
        ))}
      </Stack> */}

      <Divider sx={{ borderStyle: 'dashed' }} />

      <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
        <Button fullWidth variant="outlined" color="inherit">
          Đăng xuất
        </Button>
      </MenuItem>
    </MenuPopover>
  );
}
