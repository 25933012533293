import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import QRCode from 'qrcode';
import authService from '../services/auth';
import { authActions } from './auth';
import {
  ACCESS_TOKEN_KEY,
  BUSINESS_KEY,
  EXPIRE_PRO_TIME,
  // FIRST_TIME_REDIRECT,
  // MESSAGE_NOT_USER_PRO,
  REFRESH_TOKEN_KEY,
  USER_KEY,
} from '../utils/constants';
import { getErrorMessage } from '../utils/errorUtils';
import { v4 } from 'uuid';
import { RootState } from './rootReducer';
import { AppDispatch } from '.';
import { APP_VERSION } from '../config/env';
import { getDeviceName, getOperatingSystem } from '../utils/utils';

type VerifyOTPState = {
  state: 'loading' | 'ready' | 'error' | 'success';
  loginType: 'qrcode' | 'otp';
  qrCodeChallenge: QRCodeChallenge | null;
  otpChallenge: OTPChallenge | null;
  QRImage: string;
  userInfo: QRCodeInfo | null;
  userLocation: UserLocation | null;
  cooldown: number;
  cooldownTimer: null | NodeJS.Timer;
  error: string | null;
  errorOTP: string | null;
  device_id: string;
};

const initialState: VerifyOTPState = {
  state: 'loading',
  device_id: v4(),
  loginType: 'qrcode',
  qrCodeChallenge: null,
  otpChallenge: null,
  QRImage: '',
  userInfo: null,
  userLocation: null,
  cooldown: 0,
  cooldownTimer: null,
  error: null,
  errorOTP: null,
};

const COOLDOWN_SECONDS = 30;

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setCooldown: (state, action: PayloadAction<number>) => {
      state.cooldown = action.payload;
    },
    setGeolocation: (state, action: PayloadAction<UserLocation>) => {
      state.userLocation = action.payload;
    },
    setChallenge: (state, action: PayloadAction<QRCodeChallenge | null>) => {
      state.qrCodeChallenge = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<QRCodeInfo | null>) => {
      state.userInfo = action.payload;
    },
    setLoginType: (state, action: PayloadAction<'qrcode' | 'otp'>) => {
      state.loginType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetCooldown.fulfilled, (state, action) => {
        state.cooldownTimer = action.payload;
      })
      .addCase(renewOTPChallenge.fulfilled, (state, action) => {
        state.state = 'ready';
        state.otpChallenge = action.payload;
        state.errorOTP = null;
      })
      .addCase(generateQRCode.fulfilled, (state, action) => {
        state.state = 'ready';
        state.qrCodeChallenge = action.payload.qrCodeChallenge;
        state.QRImage = action.payload.image;
        state.error = null;
      })
      .addCase(authActions.logout.fulfilled, () => initialState)
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.state = 'ready';
        state.userInfo = action.payload;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(
          generateQRCode.rejected,
          getUserInfo.rejected,
          confirmQRCode.rejected
        ),
        (state, payload) => {
          const errorMessage = getErrorMessage(payload.error);
          state.state = 'error';
          state.error = errorMessage.includes('failed to request upstream')
            ? 'Yêu cầu không hợp lệ'
            : errorMessage;
        }
      )
      .addMatcher(
        isAnyOf(verifyOTP.rejected, renewOTPChallenge.rejected),
        (state, payload) => {
          const errorMessage = getErrorMessage(payload.error);
          state.state = 'error';
          state.errorOTP = errorMessage;
        }
      )
      .addMatcher(
        isAnyOf(
          generateQRCode.pending,
          getUserInfo.pending,
          confirmQRCode.pending,
          verifyOTP.pending,
          renewOTPChallenge.pending
        ),
        (state) => {
          state.state = 'loading';
        }
      );
  },
});

const generateQRCode = createAsyncThunk<
  { qrCodeChallenge: QRCodeChallenge; image: string },
  void,
  { state: RootState; dispatch: AppDispatch }
>('login/generateQRCode', async (_, { getState, dispatch }) => {
  const { device_id } = selectAppLogin(getState());
  const qrCodeChallenge = await authService.generateQRCode({
    device_id,
  });

  let image = '';
  if (qrCodeChallenge) {
    dispatch(getUserInfo(qrCodeChallenge.code));
    image = await QRCode.toDataURL(qrCodeChallenge.link);
  }

  return { qrCodeChallenge, image };
});

const getUserInfo = createAsyncThunk<
  QRCodeInfo,
  string,
  { state: RootState; dispatch: AppDispatch }
>('login/getUserInfo', async (code: string, { dispatch }) => {
  const userInfo = await authService.getLoginInfo({
    code: code,
  });

  if (userInfo) {
    dispatch(confirmQRCode());
  }

  return userInfo;
});

const confirmQRCode = createAsyncThunk<void, void, { state: RootState }>(
  'login/confirmQRCode',
  async (_, { getState, dispatch }) => {
    const { device_id, qrCodeChallenge, userLocation } = selectAppLogin(
      getState()
    );
    const result = await authService.confirmQRCode({
      app_version: APP_VERSION || '1.1.1',
      code: qrCodeChallenge?.code || '',
      device_id,
      device_name: getDeviceName(),
      operating_system: getOperatingSystem(),
      platform_key: 'pro_web',
      location: userLocation
        ? `${userLocation.latitude},${userLocation.longitude}`
        : '',
    });

    saveData(result);
    await dispatch(authActions.initialize()).unwrap();
  }
);

const verifyOTP = createAsyncThunk<
  void,
  Omit<Parameters<typeof authService.verifyOTP>[0], 'otp_record' | 'device_id'>,
  { state: RootState }
>('login/verifyOTP', async (args, api) => {
  const { otpChallenge, device_id } = selectAppLogin(api.getState());

  if (!otpChallenge) {
    throw new Error('NO_OTP_CHALLENGE');
  }

  const data = await authService.verifyOTP({
    ...args,
    device_id,
    otp_record: otpChallenge.otp_record,
  });
  if (data.business_info.list_business === null) {
    throw new Error('NOT_REGISTER');
  }
  saveData(data);
  await api.dispatch(authActions.initialize()).unwrap();
});

const renewOTPChallenge = createAsyncThunk<
  OTPChallenge,
  string,
  { state: RootState }
>('login/renewOTPChallenge', async (phone_number: string, { getState }) => {
  const { device_id } = selectAppLogin(getState());
  const otpChallenge = await authService.generateOTP({
    device_id,
    phone_number,
  });

  if (otpChallenge.status !== 'created') {
    throw new Error(otpChallenge.status);
  }

  return otpChallenge;
});

const resetCooldown = createAsyncThunk<
  NodeJS.Timer,
  AbortSignal | null,
  { dispatch: AppDispatch }
>('login/cooldown', async (signal, { dispatch }) => {
  if (signal) {
    signal.onabort = () => {
      dispatch(stopCooldown());
    };
  }

  let cooldown = COOLDOWN_SECONDS;
  dispatch(loginSlice.actions.setCooldown(cooldown));

  return setInterval(() => {
    dispatch(loginSlice.actions.setCooldown(--cooldown));
    if (cooldown <= 0) {
      dispatch(stopCooldown());
    }
  }, 1000);
});

const stopCooldown = createAsyncThunk<void, void, { state: RootState }>(
  'login/end',
  (_, { getState }) => {
    const timer = selectAppLogin(getState()).cooldownTimer;
    timer && clearInterval(timer);
  }
);

const saveData = (data: VerifyOTPResult | ConfirmQRCodeResult) => {
  if (data.status !== 'success') {
    throw new Error(data.status);
  }

  localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
  localStorage.setItem(ACCESS_TOKEN_KEY, data.token);
  localStorage.setItem(
    BUSINESS_KEY,
    data.business_info.current_business?.id || ''
  );
  localStorage.setItem(USER_KEY, data.user_info.id);
  if (data.history_registration_pro) {
    localStorage.setItem(
      EXPIRE_PRO_TIME,
      data.history_registration_pro.expire_time
    );
  }
};

export const loginActions = {
  ...loginSlice.actions,
  generateQRCode,
  verifyOTP,
  renewOTPChallenge,
  resetCooldown,
};
export const selectAppLogin = (state: RootState) => state.login;
export default loginSlice;
