type MessagesBreakDown = 'invalid_phone';

const labels: { [key in MessagesBreakDown]: string } = {
  invalid_phone: 'Invalid phone format',
};

const vn = {
  error: {
    locked: 'Tài khoản của bạn đã bị khóa',
    can_not_retry: 'Gửi lại mã xác thực quá nhanh, vui lòng thử lại sau',
    locked_due_to_retry: 'Số điện thoại đang tạm thời bị khóa',
    wrong_otp: 'Mã OTP không trùng khớp',
    otp_expired: 'Mã OTP hết hạn',
    NOT_REGISTER: 'Bạn chưa đăng ký tài khoản SoBanHang',
    [labels['invalid_phone']]: 'Số điện thoại không hợp lệ',
  },
  app: {
    order_state_waiting_confirm: 'Chờ xác nhận',
    order_state_delivering: 'Đang giao',
    order_state_cancel: 'Đã hủy',
    order_state_complete: 'Đã giao',
    delivery_state_buyer_pick_up: 'Bán hàng nhanh',
    delivery_state_seller_delivery: 'Giao hàng sau',
  },
};

export default vn;
