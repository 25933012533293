import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { PATH_DASHBOARD } from '../../config/routeConfig';
import useAppDispatch from '../../hooks/useAppDispatch';
import { loginActions } from '../../store/login';
import { toastActions } from '../../store/toast';
import { MESSAGE_NOT_USER_PRO } from '../../utils/constants';
import { getErrorMessage } from '../../utils/errorUtils';

const validationSchema = Yup.object().shape({
  otp: Yup.string()
    .required()
    .matches(/[0-9]{4,}/),
});

const useVerifyOTPForm = (phoneNumber: string) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          loginActions.verifyOTP({
            phone_number: phoneNumber,
            otp: values.otp,
          })
        ).unwrap();
      } catch (ex: ExpectedAny) {
        if (ex.message === 'NOT_REGISTER') {
          navigate('/register', { state: phoneNumber });
        }
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    },
  });
};

export default useVerifyOTPForm;
