import { useFormik } from 'formik';
import { v4 } from 'uuid';
import * as Yup from 'yup';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { selectAppAuth } from '../../store/auth';
import { contactActions } from '../../store/contact';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { validatePhone } from '../../utils/utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Tên liên hệ không được để trống'),
  phone_number: Yup.string().matches(/^\+?\d*$/, 'Số điện thoại không hợp lệ'),
  address: Yup.string(),
});

type useAddContactFormProps = {
  defaultValue?: string;
  setOpen: (value: boolean) => void;
  saveAnotherContact?: (value: Contact) => void;
  onSuccess?: (value: Contact) => void;
};

const useAddContactForm = ({
  defaultValue,
  setOpen,
  saveAnotherContact,
  onSuccess,
}: useAddContactFormProps) => {
  const dispatch = useAppDispatch();
  const { business } = useAppSelector(selectAppAuth);
  const isNumber = defaultValue && /^[0-9]*$/.test(defaultValue);

  return useFormik({
    enableReinitialize: true,
    initialValues: {
      name: !isNumber ? defaultValue : '',
      phone_number: isNumber ? defaultValue : '',
      address: '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const nextValues = {
          ...values,
          business_id: business?.id,
          client_id: v4(),
        };

        const valid = values.phone_number
          ? validatePhone(values.phone_number)
          : true;
        if (!valid) {
          return dispatch(
            toastActions.create({
              severity: 'error',
              message: 'Số điện thoại không hợp lệ',
              dismissAfter: 3000,
            })
          );
        }

        const contact = await dispatch(
          contactActions.commitContact({ contact: nextValues })
        ).unwrap();
        saveAnotherContact && saveAnotherContact(contact);
        await dispatch(contactActions.refresh()).unwrap();
        setOpen(false);
        onSuccess?.(contact);
        resetForm();
        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Thêm vào danh bạ thành công',
            dismissAfter: 3000,
          })
        );
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    },
  });
};

export default useAddContactForm;
