import {
  Box,
  Typography,
  InputAdornment,
  Popover,
  Checkbox,
  TextField,
  MenuItem,
  Select,
  Avatar,
  Button,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useThrottle } from 'react-use';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../minimal/Iconify';
import Scrollbar from '../../minimal/Scrollbar';
import { FlexCenter, OverflowText } from './OrderDetailComponent';
import useAppSelector from '../../hooks/useAppSelector';
import { numberFormat } from '../../utils/constants';
import useAppDispatch from '../../hooks/useAppDispatch';
import { selectAppProduct } from '../../store/product';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import { productOffline } from '../../config/offline/product';
import { createOrderProducts } from './utils';
import { useFormikContext } from 'formik';
import {
  selectLocalSearchProducts,
  pageOrderDetailActions,
  selectPageOrderDetail,
} from './slice';

import { getOptimizeImage } from '../../utils/imageUtils';
import { pageProductsActions } from '../Products/slice';
import EmptyState from './EmptyState';

const PopoverStyle = styled(Popover)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const PopoverContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  minWidth: '684px',
  padding: theme.spacing(1.5, 2),
  position: 'relative',
}));

const SelectStyle = styled(Select)(() => ({
  '& fieldset': { border: 'none' },
  '& .MuiSelect-select': { padding: 0 },
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: '48px',
  height: '48px',
  margin: theme.spacing(0, 2),
  borderRadius: '12px',
}));

const ItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 0),
  '&:last-of-type': {
    marginBottom: theme.spacing(7),
  },
}));

type AddProductPopoverProps = {
  open: HTMLElement | null;
  setOpen: (value: HTMLElement | null) => void;
};

type ProductsOrder = 'created_at' | 'sold_quantity';

const availableOrders: Array<ProductsOrder> = ['created_at', 'sold_quantity'];

const labels: { [key in ProductsOrder]: string } = {
  created_at: 'Mới nhất',
  sold_quantity: 'Bán chạy',
};

const AddProductPopover = ({ open, setOpen }: AddProductPopoverProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [term, setTerm] = useState<string>('');
  const throttledTerm = useThrottle(term, 200);
  // const { main: { selectedCategoryId, selectedOrderBy } } = useAppSelector(selectPageOrderDetail);
  const { fuseVersion } = useAppSelector(selectAppProduct);

  const {
    main: { selectedOrderBy, productCheck },
  } = useAppSelector(selectPageOrderDetail);
  const formik = useFormikContext<CreateOrderInput>();
  const skus = useAppSelector(selectLocalSearchProducts);
  // const [skueSort, setSkueSort] = useState<string>('');

  const handleClose = () => {
    dispatch(pageOrderDetailActions.setProductCheck([]));
    setOpen(null);
  };

  useEffect(() => {
    const query = buildOfflineFuseQuery(
      throttledTerm,
      {
        category: null,
      },
      productOffline
    );
    dispatch(pageProductsActions.search(query));
  }, [dispatch, throttledTerm, fuseVersion]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTerm(e.target.value),
    []
  );

  const checkBoxProductPopoverOnChange = useCallback(
    ({
      event,
      product,
    }: {
      event: React.ChangeEvent<HTMLInputElement>;
      product: ExpectedAny;
    }) => {
      event.target.checked
        ? dispatch(
            pageOrderDetailActions.setProductCheck([...productCheck, product])
          )
        : dispatch(
            pageOrderDetailActions.setProductCheck([
              ...productCheck.filter((value) => value.id != product.id),
            ])
          );
    },
    [dispatch, productCheck]
  );

  const addOrderProducts = useCallback(() => {
    const productCus = createOrderProducts(productCheck);
    setOpen(null);
    const { list_order_item } = formik.values;
    const newListItem: ExpectedAny = [];

    if (list_order_item && list_order_item.length > 0) {
      productCus.map((addSku: ExpectedAny) => {
        let isExist = false;
        list_order_item.map((item) => {
          if (item.sku_id === addSku.sku_id) {
            isExist = true;
            return newListItem.push({
              ...item,
              quantity: item.quantity + 1,
            });
          }
        });
        if (!isExist) {
          newListItem.push({
            ...addSku,
            quantity: 1,
          });
        }
      });
      list_order_item.map(
        (item) =>
          !newListItem.find(
            (value: ExpectedAny) => item.sku_id == value.sku_id
          ) &&
          newListItem.push({
            ...item,
          })
      );
    } else {
      productCus.map((value: ExpectedAny) => newListItem.push(value));
    }
    formik.setFieldValue('list_order_item', newListItem);
    dispatch(pageOrderDetailActions.setProductCheck([]));
  }, [dispatch, formik, productCheck, setOpen]);

  return (
    <PopoverStyle
      open={Boolean(open)}
      anchorEl={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <PopoverContent>
        <Box display="flex" mb={1.5}>
          <TextField
            fullWidth
            placeholder="Tìm kiếm sản phẩm theo tên/ SKU/ Barcode"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" />
                </InputAdornment>
              ),
            }}
            value={term}
            sx={{ mr: 2 }}
            onChange={handleSearchChange}
          />
          <SelectStyle defaultValue={selectedOrderBy}>
            {availableOrders.map((value) => (
              <MenuItem
                key={value}
                onClick={() => {
                  dispatch(pageOrderDetailActions.setSelectedOrderBy(value));
                  dispatch(
                    pageOrderDetailActions.products.setOrder([value, 'desc'])
                  );
                }}
                value={value}
              >
                {labels[value]}
              </MenuItem>
            ))}
          </SelectStyle>
        </Box>
        <Scrollbar sx={{ height: '50vh' }}>
          {skus && skus.length > 0 ? (
            skus.map((product, index) => (
              <ItemWrapper
                key={index}
                sx={{ opacity: !product.disabled ? 1 : 0.5 }}
              >
                <FlexCenter>
                  <Checkbox
                    disabled={product.disabled}
                    onChange={(e) =>
                      checkBoxProductPopoverOnChange({
                        event: e,
                        product: product,
                      })
                    }
                    inputProps={{ 'aria-label': 'product_add' }}
                  />
                  <AvatarStyle
                    alt="name"
                    src={getOptimizeImage(
                      product.media?.[0] || '/assets/product-image.png',
                      { w: 128, h: 128, fit: 'crop' }
                    )}
                  />
                  <Box maxWidth="70%">
                    <OverflowText variant="subtitle2" noWrap>
                      {product.product_name}
                    </OverflowText>
                    <OverflowText
                      variant="caption"
                      color={theme.palette.grey[500]}
                      noWrap
                      sx={{ my: 0.5 }}
                    >
                      {product.name}
                    </OverflowText>
                    {!product.type?.match(/non_stock/) && (
                      <Box display="flex">
                        <Typography
                          variant="body2"
                          color={theme.palette.grey[500]}
                        >
                          Có thể bán
                        </Typography>
                        <Typography variant="body2" sx={{ ml: 0.5, mr: 1.5 }}>
                          {numberFormat.format(product.can_pick_quantity || 0)}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </FlexCenter>
                <Typography variant="subtitle2">
                  {numberFormat.format(
                    product.wholesale_price ||
                      product.selling_price ||
                      product.normal_price ||
                      0
                  )}
                  đ
                </Typography>
              </ItemWrapper>
            ))
          ) : (
            <EmptyState />
          )}
        </Scrollbar>
        <Box
          position="absolute"
          bottom={theme.spacing(2)}
          right={theme.spacing(2)}
        >
          <Button
            variant="contained"
            sx={{ width: 264, py: 1.5, textTransform: 'unset' }}
            onClick={addOrderProducts}
          >
            Xác nhận
          </Button>
        </Box>
      </PopoverContent>
    </PopoverStyle>
  );
};

export default memo(AddProductPopover);
