import { Box, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import useResponsive from '../../hooks/useResponsive';
import Iconify from '../../minimal/Iconify';
import ProductItem from './ProductItem';
import { pagePosActions, selectLocalProducts, selectPagePos } from './slice';

type ProductsWrapperProps = {
  columns: number;
};

const ProductsWrapper = styled(Box)<ProductsWrapperProps>(({ columns }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gridTemplateRows: `repeat(${columns}, 1fr)`,
  gridGap: '12px',
  flexGrow: 1,
  maxHeight: '290px',
  overflow: 'hidden',
  scrollSnapType: 'x mandatory',
  whiteSpace: 'nowrap',
}));

const ProductCarousel = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const carouselRef = useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();
  const {
    products: { page, pageSize },
  } = useAppSelector(selectPagePos);
  const { skus, totalItems } = useAppSelector(selectLocalProducts);

  const COLUMNS = Math.floor(
    (window.innerWidth - (isDesktop ? 600 : 470)) / 102
  );

  useEffect(() => {
    dispatch(pagePosActions.products.setPageSize(COLUMNS * 2));
    if (carouselRef.current) {
      carouselRef.current.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const canBack = page > 0;
  const canForward = page < Math.ceil(totalItems / pageSize) - 1;

  return (
    <Box display="flex" alignItems="center" mt={2}>
      <Box
        textAlign="center"
        minWidth={60}
        onClick={() => {
          if (canBack) {
            dispatch(pagePosActions.products.setPage(page - 1));
          }
        }}
      >
        {canBack && (
          <Iconify
            icon="eva:arrow-ios-back-fill"
            width={40}
            height={40}
            color={theme.palette.grey[500_80]}
            sx={{ cursor: 'pointer' }}
          />
        )}
      </Box>
      <ProductsWrapper ref={carouselRef} columns={COLUMNS}>
        {skus.map((sku) => (
          <ProductItem key={sku.product_id + sku.id} sku={sku} />
        ))}
      </ProductsWrapper>
      <Box
        textAlign="center"
        minWidth={60}
        onClick={() => {
          if (canForward) {
            dispatch(pagePosActions.products.setPage(page + 1));
          }
        }}
      >
        {canForward && (
          <Iconify
            icon="eva:arrow-ios-forward-fill"
            width={40}
            height={40}
            color={theme.palette.grey[500_80]}
            sx={{ cursor: 'pointer' }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductCarousel;
