const WaitingOrder = () => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.3333 32.0833C56.7225 32.0833 55.4167 33.3892 55.4167 35V52.5C55.4167 54.1108 54.1108 55.4167 52.5 55.4167H17.5C15.8892 55.4167 14.5833 54.1108 14.5833 52.5V17.5C14.5833 15.8892 15.8892 14.5833 17.5 14.5833H35C36.6108 14.5833 37.9167 13.2775 37.9167 11.6667C37.9167 10.0558 36.6108 8.75 35 8.75H17.5C12.6675 8.75 8.75 12.6675 8.75 17.5V52.5C8.75 57.3325 12.6675 61.25 17.5 61.25H52.5C57.3325 61.25 61.25 57.3325 61.25 52.5V35C61.25 33.3892 59.9442 32.0833 58.3333 32.0833Z"
      fill="#919EAB"
      fillOpacity="0.16"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.87 7.0182L62.8638 13.012C64.5394 14.6084 64.6079 17.2594 63.0169 18.9402L43.3292 38.6279C42.6162 39.335 41.6816 39.7753 40.6823 39.8748L31.5604 40.7061H31.3635C30.782 40.7094 30.2232 40.4812 29.8103 40.0717C29.3488 39.6119 29.1163 38.9704 29.176 38.3217L30.1166 29.1997C30.2161 28.2004 30.6564 27.2658 31.3635 26.5528L51.0512 6.86507C52.7483 5.43127 55.2506 5.49712 56.87 7.0182ZM49.5667 14.4558L55.4293 20.3184L59.8043 16.0527L53.8324 10.0808L49.5667 14.4558Z"
      fill="#919EAB"
      fillOpacity="0.16"
    />
  </svg>
);

export default WaitingOrder;
