import { Dispatch, Middleware } from 'redux';
import { assert } from 'ts-essentials';
import { OfflineConfig } from './configOffline';
import { OfflineState } from './configOfflineCacheSlice';
import { getSearchWorker } from './utils';

async function configOfflineFuse<
  Name extends string,
  Data extends ISyncRecord,
  SearchData,
  DispatchExt = unknown,
  S extends { [name in Name]: OfflineState<Data> } = {
    [name in Name]: OfflineState<Data>;
  },
  D extends Dispatch = Dispatch
>(config: OfflineConfig<Name, Data, SearchData>) {
  const fuse = await getSearchWorker(config.name, {
    keys: config.searchFields,
  });

  return ((store) => (next) => (action) => {
    const lastState = store.getState();
    const nextStore = next(action);
    const state = store.getState();
    const lastCache = lastState[config.name].byId;
    const nextCache = state[config.name].byId;
    if (lastCache !== nextCache) {
      let changed = false;
      for (const name in nextCache) {
        const nextItem = nextCache[name];
        assert(!!nextItem, 'nextItem can not be null');
        const lastItem = lastCache[name];
        const shouldDelete = !!nextItem.deleted_at;

        if (shouldDelete) {
          if (lastItem && !lastItem.deleted_at) {
            // remove
            fuse.delete(lastItem.id);
            changed = true;
            //console.log('FUSE delete', config.name, lastItem.id);
          }
        } else {
          const shouldReplace =
            !lastItem || lastItem.updated_at !== nextItem.updated_at;
          if (shouldReplace) {
            //
            fuse.replace(config.makeSearchData(nextItem));
            changed = true;
            //console.log('FUSE replace', config.name, nextItem);
          }
        }
      }

      if (changed) {
        store.dispatch({ type: `${config.name}/pump` });
      }
    }
    return nextStore;
  }) as Middleware<DispatchExt, S, D>;
}

export default configOfflineFuse;
