function nop() {
  //
}

function debug(...args: Array<ExpectedAny>) {
  return console.log(...args);
}

const logger =
  process.env.NODE_ENV === 'development' ? { debug } : { debug: nop };

export default logger;
