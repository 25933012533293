import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import type { RootState } from './rootReducer';

type PendingOrdersState = {
  pendingOrders: Array<PendingOrder>;
};

export const createInitialOrder = (): PendingOrder => ({
  id: '',
  items: [],
  promotion_code: null,
  promotion_discount: 0,
  other_discount: 0,
  delivery_fee: 0,
  payment_method: '',
  payment_source_id: '',
  payment_source_name: '',
  note: '',
  delivery_method: 'buyer_pick_up',
  images: [],
  debit: {
    buyer_pay: 0,
    description: '',
    is_debit: false,
  },
  buyer_info: {
    phone_number: '',
    name: '',
    address: '',
    avatar: '',
    debt_amount: 0,
    option: '',
  },
  is_wholesale_price: false,
  selected_promotion: null,
});

const initialState: PendingOrdersState = {
  pendingOrders: [createInitialOrder()],
};

const posSlice = createSlice({
  name: 'pos',
  initialState,
  reducers: {
    deleteOrder: (state, action: PayloadAction<string>) => {
      state.pendingOrders = state.pendingOrders.filter(
        (order) => order.id !== action.payload
      );
    },
    save: (state, action: PayloadAction<PendingOrder>) => {
      const order =
        state.pendingOrders.find((o) => o.id === action.payload.id) ||
        state.pendingOrders[0];
      if (order) {
        Object.assign(order, action.payload);
      }
    },
    newOrder: (state, action: PayloadAction<PendingOrder>) => {
      state.pendingOrders.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const posActions = { ...posSlice.actions };
export const selectAppPos = (state: RootState) => state.pos;

export default posSlice;
