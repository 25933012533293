const EmptyPaybookIcon = ({ className }: { className?: string }) => (
    <svg className={className} width="328" height="240" viewBox="0 0 328 240" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
            <g opacity="0.2">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.5 132.52C0.5 160.159 10.6815 185.344 27.436 204.378C46.657 226.221 74.5295 239.954 105.598 240C119.177 240.02 132.16 237.409 144.085 232.642C150.252 230.177 157.153 230.46 163.125 233.381C170.131 236.803 177.975 238.721 186.259 238.721C189.68 238.721 193.031 238.391 196.273 237.766C205.525 235.985 213.944 231.772 220.859 225.805C225.204 222.054 230.77 220.168 236.458 220.175H236.554C255.349 220.175 272.808 214.353 287.302 204.378C300.26 195.471 310.842 183.247 317.893 168.894C324.04 156.387 327.5 142.259 327.5 127.305C327.5 76.0181 286.779 34.4356 236.554 34.4356C231.434 34.4356 226.418 34.8772 221.524 35.7012C208.566 14.28 185.361 0 158.89 0C147.713 0 137.118 2.54512 127.634 7.10748C118.614 11.4322 110.595 17.5775 104.035 25.0667C81.7604 25.4293 61.1639 32.86 44.3064 45.261C17.7707 64.7702 0.5 96.5882 0.5 132.52Z" fill="url(#paint0_linear_3503_457921)" />
            </g>
            <path opacity="0.545118" fillRule="evenodd" clipRule="evenodd" d="M185.074 26.5563C186.736 28.2193 188.92 29.0504 191.105 29.0504C193.289 29.0504 195.473 28.2193 197.135 26.5563C200.461 23.231 200.461 17.8199 197.135 14.494C193.81 11.1681 188.398 11.1693 185.074 14.494C181.748 17.8193 181.748 23.2304 185.074 26.5563ZM189.232 18.6534C189.749 18.1369 190.427 17.8793 191.105 17.8793C191.782 17.8793 192.46 18.1375 192.976 18.6534C194.008 19.6857 194.008 21.3651 192.976 22.3969C191.943 23.4293 190.265 23.4287 189.232 22.3969C188.201 21.3646 188.201 19.6851 189.232 18.6534Z" fill="#C4CDD5" />
            <path opacity="0.545118" fillRule="evenodd" clipRule="evenodd" d="M118.021 225.7C119.683 227.362 121.868 228.194 124.052 228.194C126.236 228.194 128.421 227.363 130.083 225.7C133.409 222.375 133.409 216.963 130.083 213.638C126.758 210.312 121.345 210.312 118.021 213.638C114.695 216.963 114.695 222.374 118.021 225.7ZM122.179 217.797C122.696 217.28 123.374 217.023 124.052 217.023C124.729 217.023 125.408 217.281 125.923 217.797C126.955 218.829 126.955 220.509 125.923 221.54C124.891 222.573 123.212 222.573 122.179 221.54C121.148 220.508 121.148 218.829 122.179 217.797Z" fill="#C4CDD5" />
            <path d="M273.174 188.022C272.422 188.022 271.668 187.735 271.095 187.161L267.586 183.652L264.077 187.161C262.929 188.309 261.066 188.309 259.918 187.161C258.769 186.012 258.769 184.149 259.918 183.001L265.506 177.413C266.654 176.265 268.518 176.265 269.665 177.413L275.254 183.001C276.402 184.15 276.402 186.012 275.254 187.161C274.68 187.734 273.926 188.022 273.174 188.022Z" fill="#DFE3E8" />
            <path d="M65.7259 176.816C64.9735 176.816 64.22 176.529 63.6465 175.955L60.1377 172.446L56.6288 175.955C55.4812 177.103 53.6177 177.103 52.47 175.955C51.3212 174.806 51.3212 172.943 52.47 171.795L58.0583 166.207C59.2059 165.059 61.0694 165.059 62.2171 166.207L67.8053 171.795C68.9541 172.944 68.9541 174.806 67.8053 175.955C67.2312 176.528 66.4783 176.816 65.7259 176.816Z" fill="#D5DBE0" />
            <rect x="78.5" y="50" width="172" height="140" rx="12" fill="#DFE3E8" stroke="#919EAB" strokeOpacity="0.48" strokeWidth="1.5" />
            <mask id="mask0_3503_457921" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="77" y="49" width="175" height="142">
                <rect x="78.5" y="50" width="172" height="140" rx="12" fill="white" stroke="white" strokeWidth="1.5" />
            </mask>
            <g mask="url(#mask0_3503_457921)">
                <path opacity="0.32" fillRule="evenodd" clipRule="evenodd" d="M83.5391 50H245.461C248.283 50 250.5 51.7597 250.5 54V90H78.5V54C78.5 51.7597 80.7169 50 83.5391 50ZM99.5 70C99.5 72.7997 101.7 75 104.5 75C107.3 75 109.5 72.7997 109.5 70C109.5 67.1997 107.3 65 104.5 65C101.7 65 99.5 67.1997 99.5 70ZM124.5 75C121.7 75 119.5 72.7997 119.5 70C119.5 67.1997 121.7 65 124.5 65C127.3 65 129.5 67.1997 129.5 70C129.5 72.7997 127.3 75 124.5 75ZM139.5 70C139.5 72.7997 141.7 75 144.5 75C147.3 75 149.5 72.7997 149.5 70C149.5 67.1997 147.3 65 144.5 65C141.7 65 139.5 67.1997 139.5 70Z" fill="#919EAB" />
                <path d="M224.5 104H174.5C171.736 104 169.5 106.236 169.5 109V169C169.5 171.764 171.736 174 174.5 174H224.5C227.264 174 229.5 171.764 229.5 169V109C229.5 106.236 227.264 104 224.5 104Z" fill="#919EAB" fillOpacity="0.48" stroke="#919EAB" strokeWidth="1.2" strokeDasharray="2 2" />
                <path opacity="0.72" d="M104.5 114H124.5C127.264 114 129.5 111.764 129.5 109C129.5 106.236 127.264 104 124.5 104H104.5C101.736 104 99.5 106.236 99.5 109C99.5 111.764 101.736 114 104.5 114Z" stroke="#919EAB" strokeWidth="1.2" strokeDasharray="2 2" />
                <path opacity="0.72" d="M154.5 124H104.5C101.736 124 99.5 126.236 99.5 129C99.5 131.764 101.736 134 104.5 134H154.5C157.264 134 159.5 131.764 159.5 129C159.5 126.236 157.264 124 154.5 124Z" stroke="#919EAB" strokeWidth="1.2" strokeDasharray="2 2" />
                <path opacity="0.72" d="M154.5 144H104.5C101.736 144 99.5 146.236 99.5 149C99.5 151.764 101.736 154 104.5 154H154.5C157.264 154 159.5 151.764 159.5 149C159.5 146.236 157.264 144 154.5 144Z" stroke="#919EAB" strokeWidth="1.2" strokeDasharray="2 2" />
                <path opacity="0.72" d="M154.5 164H104.5C101.736 164 99.5 166.236 99.5 169C99.5 171.764 101.736 174 104.5 174H154.5C157.264 174 159.5 171.764 159.5 169C159.5 166.236 157.264 164 154.5 164Z" stroke="#919EAB" strokeWidth="1.2" strokeDasharray="2 2" />
            </g>
        </g>
        <defs>
            <linearGradient id="paint0_linear_3503_457921" x1="224.502" y1="282.688" x2="340.512" y2="15.5039" gradientUnits="userSpaceOnUse">
                <stop stopColor="#919EAB" />
                <stop offset="1" stopColor="#919EAB" stopOpacity="0.01" />
            </linearGradient>
        </defs>
    </svg>



);

export default EmptyPaybookIcon;