// import Chat from '../components/Icons/Chat';
import Contact from '../components/Icons/Contact';
import Home from '../components/Icons/Home';
import Order from '../components/Icons/Order';
import Product from '../components/Icons/Product';
import Shop from '../components/Icons/Shop';
import Transaction from '../components/Icons/Transaction';

const sidebarConfig = [
  { title: 'Tổng quan', path: '/', icon: <Home />, exact: true },
  {
    title: 'Bán hàng',
    path: '/pos',
    icon: <Shop />,
    isPro: true,
  },
  {
    title: 'Đơn hàng',
    path: '/orders',
    icon: <Order />,
    isPro: true,
  },
  {
    title: 'Sản phẩm',
    path: '/products',
    icon: <Product />,
  },
  // {
  //   key: 'group-1',
  //   title: 'Hàng hoá',
  //   path: '/products',
  //   icon: <Product />,
  //   children: [
  //     { title: 'Sản phẩm', path: '/products', exact: true },
  //     // { title: 'Xuất nhập hàng', path: '/products/inventory' },
  //   ],
  // },
  // {
  //   title: 'Chat',
  //   path: '/chat',
  //   icon: <Chat />,
  // },
  {
    title: 'Khách hàng',
    path: '/contacts',
    icon: <Contact />,
    isPro: true,
  },
  {
    title: 'Thu chi',
    path: '/cashbook',
    icon: <Transaction opacity="1" fill="#637381" />,
    isPro: true,
  },
];

export default sidebarConfig;
