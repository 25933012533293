import { styled } from '@mui/system';
import { Box, DialogTitle, Typography, Select } from '@mui/material';

export const OverflowText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const SelectStyle = styled(Select)(() => ({
  fontSize: 14,
  fontWeight: 700,
  '& fieldset': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    padding: 0,
  },
}));

export const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  width: '100%',
}));

export const DialogTitleStyle = styled(DialogTitle)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 600,
}));
