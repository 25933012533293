import { API_URI } from '../config/env';
import { fetchData, fetchAll, put, post } from '../utils/fetch';
import authService from './auth';
import qs from 'querystring';

async function getOrders({
  page,
  pageSize,
  state,
  search,
  start_time,
  end_time,
  sort = 'created_at desc',
}: OrdersParams) {
  const business_id = await authService.getBusinessId();
  const { data, meta } = await fetchAll<{
    data: Array<Order>;
    meta: ResponseMeta;
  }>(
    `${API_URI}/finan-order/api/v2/get-list-order?${qs.stringify({
      business_id: business_id,
      state: state || undefined,
      search: search || undefined,
      page: page + 1,
      size: pageSize,
      date_from: start_time || '2020-04-05T17:00:00.000Z',
      date_to: end_time || '2080-04-05T17:23:59.000Z',
      sort,
    })}`,
    { authorization: true }
  );

  return { orders: data, meta };
}

async function createOrder(body: PendingOrderForm) {
  const business_id = await authService.getBusinessId();
  return await post<Order>(
    `${API_URI}/finan-order/api/v4/seller/create-order`,
    {
      ...body,
      business_id,
    },
    {
      authorization: true,
    }
  );
}

async function updateOrder(id: string, body: UpdateOrderInput): ExpectedAny {
  return await put<void>(
    `${API_URI}/finan-order/api/v4/update-order/${id}`,
    body,
    {
      authorization: true,
    }
  );
}

async function updateOrderDetail({
  id,
  body,
}: {
  id: string;
  body: CreateOrderInput;
}): ExpectedAny {
  const res = await put<void>(
    `${API_URI}/finan-order/api/v2/seller/update-detail-order/${id}`,
    body,
    {
      authorization: true,
    }
  );
  return res;
}

async function getOrder(orderNumber: string) {
  const order = await fetchData<JSONRecord<Order>>(
    `${API_URI}/finan-order/api/v1/get-one-order/${orderNumber}`,
    {
      authorization: true,
    }
  );
  return order;
}

async function getOrderTracking(order_id: string) {
  const orderTracking = await fetchData<Array<JSONRecord<OrderTracking>>>(
    `${API_URI}/finan-order/api/v1/get-order-tracking?${qs.stringify({
      order_id: order_id,
    })}`,
    {
      authorization: true,
    }
  );
  return orderTracking;
}

async function getPaymentMethod(business_id: string) {
  const paymentMethod = await fetchData<Array<JSONRecord<PaymentMethod>>>(
    `${API_URI}/finan-transaction/api/v1/payment-source/get-list?${qs.stringify(
      {
        business_id: business_id,
        type: 'default',
      }
    )}`,
    {
      authorization: true,
    }
  );
  return paymentMethod;
}

async function createCustomPaid(customPaid: CreateCustomPaid) {
  const custompaid = await post<JSONRecord<CreateCustomPaid>>(
    `${API_URI}/finan-order/api/v1/payment-order-history/create`,
    { ...customPaid },
    {
      authorization: true,
    }
  );
  return custompaid;
}

async function getCustomPaid(business_id: string, order_id: string) {
  const custompaid = await fetchData<Array<JSONRecord<PaymentOrderHistory>>>(
    `${API_URI}/finan-order/api/v1/payment-order-history/get-list?${qs.stringify(
      {
        business_id: business_id,
        order_id: order_id,
      }
    )}`,
    {
      authorization: true,
    }
  );
  return custompaid;
}

async function getOrderAnalysis(id: string) {
  const orderAnalysis = await fetchData<Array<JSONRecord<PaymentOrderHistory>>>(
    `${API_URI}/finan-business/api/v1/business/get-one/${id}`,
    {
      authorization: true,
    }
  );
  return orderAnalysis;
}

async function getConfigInvoice(phone: string) {
  const configInvoice = await post<ConfigInvoice>(
    `${API_URI}/finan-business/api/v1/invoice/get-one-or-create`,
    { phone_number: phone },

    {
      authorization: true,
    }
  );
  return configInvoice;
}

const orderService = {
  getOrders,
  updateOrder,
  getOrder,
  getOrderTracking,
  createCustomPaid,
  getPaymentMethod,
  getCustomPaid,
  updateOrderDetail,
  createOrder,
  getOrderAnalysis,
  getConfigInvoice,
};
export default orderService;
