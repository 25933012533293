import contactService from '../../services/contact';
import noAccents from '../../utils/encoding';
import configOffline from '../../utils/offline/configOffline';

export const contactOffline = configOffline({
  name: 'cache_contact',
  fetchChange: (lastUpdate) => contactService.getNewContacts(lastUpdate),
  makeSearchData: (item: Contact) => ({
    id: item.id,
    name: noAccents(item.name),
    phone_number: item.phone_number.replace('+84', '0'),
    option: item.option,
  }),
  searchFields: ['name', 'phone_number', 'option'],
});
