import { Table, TableBody, TableContainer } from '@mui/material';
import { memo } from 'react';
import { TableHeaderSort } from '../../components/TableHeader';
import createSearchOrderHook from '../../hooks/createSearchOrderHook';
import useAppSelector from '../../hooks/useAppSelector';
import ContactsRow from './ContactsRow';
import {
  pageContactsActions,
  selectLocalSearchResultContacts,
  selectLocalSearchRows,
} from './slice';

type ContactsTableProps = {
  tableHead: Array<HeadCellConfig>;
};

const useSearchOrder = createSearchOrderHook({
  selectResult: selectLocalSearchResultContacts,
  actions: pageContactsActions,
});

const ContactsTable = ({ tableHead }: ContactsTableProps) => {
  const contacts = useAppSelector(selectLocalSearchRows);

  const ContactsTableHeader = () => {
    return <TableHeaderSort {...useSearchOrder()} headLabel={tableHead} />;
  };

  return (
    <TableContainer >
      <Table stickyHeader>
        <ContactsTableHeader />
        <TableBody>
          {contacts.map((contact, index) => (
            <ContactsRow key={contact.id} index={index} contact={contact} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(ContactsTable);
