import { Link as RouterLink } from 'react-router-dom';
import { BoxProps } from '@mui/material';
import LogoText from '../components/Icons/LogoText';
import LogoNonText from '../components/Icons/Logo';

interface Props extends BoxProps {
  disabledLink?: boolean;
  logoFull?: boolean;
}

export default function Logo({
  disabledLink = false,
  logoFull = false,
}: Props) {
  if (disabledLink) {
    return <LogoText />;
  }

  return (
    <RouterLink to="/">{logoFull ? <LogoText /> : <LogoNonText />}</RouterLink>
  );
}
