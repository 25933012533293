import { DateRange } from '@mui/lab/DateRangePicker';
import {
  createSelector,
  createSlice,
  PayloadAction,
  combineReducers,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import { PURGE } from 'redux-persist';
import cashbookService from '../../services/cashbook';
import paymentService from '../../services/payment';
import type { RootState } from '../../store/rootReducer';
import createSearchSlice from '../../utils/createSearchSlice';

export const selectPageCashbooks = (state: RootState) => state.page_cashbooks;

export const selectLocalSearchResult = createSelector(
  selectPageCashbooks,
  ({ search }) => search
);

type Payment = {
  balance: number;
  business_id: string;
  created_at: string;
  creator_id: string;
  id: string;
  is_default: boolean;
  name: string;
  priority: number;
  status: boolean;
  updated_at: string;
  updater_id: string;
};

type CashbookPagesState = {
  selectedStatusId: string;
  transactionType: string;
  dateRange: DateRange<Date>;
  dialogType: string;
  amount: CashbookTotalAmount;
  stateAmount: 'loading' | 'error' | 'saved' | 'notFound';
  categories: Array<CashbookCategory>;
  stateCategories: 'loading' | 'error' | 'saved' | 'notFound';
  categoryType: string;
  statePayments: 'loading' | 'error' | 'saved' | 'notFound';
  payments: Array<Payment>;
};

const initialState: CashbookPagesState = {
  selectedStatusId: 'all',
  transactionType: '',
  dateRange: [null, null],
  dialogType: '',
  amount: { total_amount_in: 0, total_amount_out: 0 },
  stateAmount: 'loading',
  categories: [],
  stateCategories: 'loading',
  categoryType: '',
  statePayments: 'loading',
  payments: [],
};

const name = 'page_cashbooks' as const;

const mainSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDialogType: (state, action: PayloadAction<string>) => {
      state.dialogType = action.payload;
    },
    selectStatus: (state, action: PayloadAction<string>) => {
      state.selectedStatusId = action.payload;
    },
    selectTransactionType: (state, action: PayloadAction<string>) => {
      state.transactionType = action.payload;
    },
    setDateRange: (state, action: PayloadAction<DateRange<Date>>) => {
      state.dateRange = action.payload;
    },
    setCategoryType: (state, action: PayloadAction<string>) => {
      state.categoryType = action.payload;
    },
    setCategories: (state, action: PayloadAction<Array<CashbookCategory>>) => {
      state.categories = action.payload;
    },
    setPayments: (state, action: PayloadAction<Array<Payment>>) => {
      state.payments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(getTotalAmount.fulfilled, (state, action) => {
        if (action.payload) {
          state.amount = action.payload;
        }
      })
      .addCase(getTotalAmount.rejected, (state) => {
        state.stateAmount = 'notFound';
      });
    builder
      .addCase(getCashbookCategories.fulfilled, (state, action) => {
        if (action.payload) {
          state.categories = action.payload;
        }
      })
      .addCase(getCashbookCategories.rejected, (state) => {
        state.stateCategories = 'notFound';
      });
    builder
      .addCase(getPayments.fulfilled, (state, action) => {
        if (action.payload) {
          state.payments = action.payload;
        }
      })
      .addCase(getPayments.rejected, (state) => {
        state.statePayments = 'notFound';
      });
  },
});

const getTotalAmount = createAsyncThunk(
  'page_cashbook/total',
  async (arg: { start_time: string; end_time: string }) => {
    const total = await cashbookService.getCashbookTotalAmount({ ...arg });

    if (!total) {
      throw new Error('NO_TOTAL_AMOUNT');
    }

    return total;
  }
);

const getCashbookCategories = createAsyncThunk(
  'page_cashbook/categories',
  async ({ type }: { type: string }) => {
    const categories = await cashbookService.getCashbookCategories({ type });

    if (!categories) {
      throw new Error('NO_CATEGORIES');
    }

    return categories;
  }
);

const getPayments = createAsyncThunk('page_pos/payments', async () => {
  const payments = await paymentService.getPayments({ type: '' });

  if (!payments) {
    throw new Error('NO_PAYMENTS');
  }

  return payments;
});

const searchSlice = createSearchSlice(name, {
  handleSearch: async (arg: CashbooksParams) => {
    const { cashbooks, meta } = await cashbookService.getCashbooks({ ...arg });
    return [cashbooks, meta.total_rows];
  },
});

export const pageCashbooksActions = {
  ...mainSlice.actions,
  ...searchSlice.actions,
  getTotalAmount,
  getCashbookCategories,
  getPayments,
};

const pageCashbooksSlice = {
  name,
  reducer: combineReducers({
    main: mainSlice.reducer,
    search: searchSlice.reducer,
  }),
};

export default pageCashbooksSlice;
