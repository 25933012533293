import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import productService from '../../services/product';
import { selectApp } from '../../store/app';
import { selectCacheProducts } from '../../store/product';
import { RootState } from '../../store/rootReducer';
import { createProduct, toPendingProduct } from './utils';

type ConfirmType = 'back' | 'delete' | 'remove_sku';

type PageProductEditSliceState = {
  state: 'loading' | 'error' | 'saved' | 'notFound';
  loading: boolean;
  editing: PendingProduct | null;
  selectedSku: number;
  isConfirmed: boolean;
} & ConfirmState<ConfirmType>;

const initialState: PageProductEditSliceState = {
  state: 'loading',
  loading: false,
  confirming: false,
  editing: null,
  selectedSku: 0,
  isConfirmed: localStorage.getItem('confirm-advance-inventory') === 'true',
};

const openProduct = createAsyncThunk<
  PendingProduct,
  string,
  { state: RootState }
>('page_productEdit/open', async (id, { getState }) => {
  const online = !selectApp(getState()).offline;
  const product = online
    ? await productService.getProduct(id)
    : selectCacheProducts(getState())[id];

  if (!product) {
    throw new Error('NO_PRODUCT');
  }

  return toPendingProduct(product);
});

const pageProductEditSlice = createSlice({
  name: 'page_productEdit',
  initialState,
  reducers: {
    reset: () => initialState,
    selectSku: (state, action: PayloadAction<number>) => {
      state.selectedSku = action.payload;
    },
    cancelConfirm: (state) => {
      state.confirming = false;
    },
    openConfirm: (state, action: PayloadAction<ConfirmType>) => {
      state.confirming = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    createProduct: {
      prepare: () => ({
        payload: createProduct(),
      }),
      reducer: (state, action: PayloadAction<PendingProduct>) => {
        state.editing = action.payload;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openProduct.fulfilled, (state, action) => {
        if (action.payload) {
          state.editing = action.payload;
        }
      })
      .addCase(openProduct.rejected, (state) => {
        state.state = 'notFound';
      });
  },
});

export const pageProductEditActions = {
  openProduct,
  ...pageProductEditSlice.actions,
};
export const selectPageProductEdit = (root: RootState) => root.page_productEdit;
export default pageProductEditSlice;
