export const TABLE_HEAD: HeadCellConfig[] = [
  { id: 'index', label: 'STT' },
  { id: 'type', label: 'Phân loại' },
  { id: 'date', label: 'Thời gian giao dịch' },
  { id: 'payment', label: 'Hình thức thanh toán' },
  { id: 'in', label: 'Tổng chi', align: 'right' },
  { id: 'out', label: 'Tổng thu', align: 'right' },
  { id: 'description', label: 'Mô tả' },
];

export const initialCashbook = (): PendingCashbook => ({
  amount: 0,
  business_id: '',
  currency: 'VND',
  day: new Date().toString(),
  description: '',
  payment_information: null,
  transaction_type: '',
  status: 'paid',
  images: [],
});
