import {
  createSelector,
  createSlice,
  PayloadAction,
  combineReducers,
} from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import { PURGE } from 'redux-persist';
import { productOffline } from '../../config/offline/product';
import { selectAppProduct } from '../../store/product';
import { RootState } from '../../store/rootReducer';
import { selectCategoriesAndAll } from '../../store/selectors';
import createSearchSlice from '../../utils/createSearchSlice';
import getOfflineDataByIds from '../../utils/offline/getOfflineDataByIds';
import searchOfflineIds from '../../utils/offline/searchOfflineIds';
import { TABLE_HEAD } from './settings';

export const selectPageProducts = (state: RootState) => state.page_products;
export const selectLocalSearchResult = createSelector(
  selectAppProduct,
  selectPageProducts,
  (cacheProduct, { search }) => {
    const products = getOfflineDataByIds(search.rows, cacheProduct);
    const skus = products
      .map((product) => product.list_sku.map((sku) => ({ ...sku })))
      .reduce((a, b) => a.concat(b), []);
    return { ...search, totalRows: skus.length };
  }
);

export const selectLocalSearchProducts = createSelector(
  selectAppProduct,
  selectLocalSearchResult,
  (cacheProduct, { rows: ids }) => {
    const products = getOfflineDataByIds(ids, cacheProduct);

    return products;
  }
);

export const selectLocalSearchRows = createSelector(
  selectAppProduct,
  selectLocalSearchResult,
  (cacheProduct, { rows: ids, orderBy, order, page, pageSize }) => {
    const products = getOfflineDataByIds(ids, cacheProduct);
    const skus = products
      .map((product) =>
        product.list_sku.map((sku) => ({
          ...sku,
          name:
            product.product_type === 'non_variant' ? 'Phân loại 1' : sku.name,
          product_id: product.id,
          product_name: product.name,
          sold_quantity: product.sold_quantity,
          product_images: product.images,
          type: `${sku.sku_type}_${product.product_type}`,
          is_active: sku.is_active,
          historical_cost: Math.round(sku.historical_cost),
        }))
      )
      .reduce((a, b) => a.concat(b), []);

    const orderFnOrString =
      TABLE_HEAD.find((o) => o.id === orderBy)?.orderBy || orderBy;
    const sortedProducts = _orderBy(
      skus,
      [orderFnOrString || 'updated_at'],
      [order || 'desc']
    );

    return (
      sortedProducts
        // pagination
        .slice(page * pageSize, page * pageSize + pageSize) as Array<SkuMixed>
    );
  }
);

export const selectLocalSelectedCategory = createSelector(
  selectCategoriesAndAll,
  selectPageProducts,
  (categories, { main: { selectedCategoryId } }) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      categories.find((cat) => cat.id === selectedCategoryId) || categories[0]!
    );
  }
);

export const selectLocalCategories = createSelector(
  selectCategoriesAndAll,
  selectAppProduct,
  (categories, { byId }) => {
    return categories.map((category) => ({
      ...category,
      count_sku: Object.values(byId)
        .filter(
          (product) =>
            !category.id || product.category?.some((c) => c.id === category.id)
        )
        .reduce((sum, product) => sum + product.list_sku.length, 0),
    }));
  }
);

type ProductPagesState = {
  selectedCategoryId: string | null;
};

const initialState: ProductPagesState = {
  selectedCategoryId: null,
};

const name = 'page_products' as const;

const mainSlice = createSlice({
  name,
  initialState,
  reducers: {
    selectCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const searchSlice = createSearchSlice(name, {
  handleSearch: async (query: FuseQuery, api) => {
    const ids = await searchOfflineIds(
      api.getState<ExpectedAny>(),
      query,
      productOffline
    );
    return [ids, ids.length];
  },
});

const pageProductsSlice = {
  name,
  reducer: combineReducers({
    main: mainSlice.reducer,
    search: searchSlice.reducer,
  }),
  actions: {
    ...mainSlice.actions,
    ...searchSlice.actions,
  },
} as const;

export const pageProductsActions = pageProductsSlice.actions;

export default pageProductsSlice;
