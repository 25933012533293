import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { PURGE } from 'redux-persist';
import type { RootState } from './rootReducer';

type Toast = {
  id: string;
  timeoutId: NodeJS.Timer | null;
  severity: 'error' | 'success' | 'info';
  buttonText?: string;
  link?: string;
  message: string;
};

const create = createAsyncThunk<
  Toast,
  Partial<Pick<Toast, 'severity'>> &
    Pick<Toast, 'message' | 'buttonText' | 'link'> & { dismissAfter?: number }
>(
  'toast/create',
  ({ dismissAfter, severity, message, buttonText, link }, api) => {
    const id = nanoid();
    const timeoutId = dismissAfter
      ? setTimeout(() => {
          api.dispatch(toastSlice.actions.dismiss(id));
        }, dismissAfter)
      : null;

    return {
      id,
      timeoutId: timeoutId,
      severity: severity || 'info',
      buttonText: buttonText,
      link: link,
      message: message,
    } as Toast;
  }
);

const initialState = {
  toasts: [] as Array<Toast>,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    dismiss: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
    },
  },
  extraReducers: (build) => {
    build
      .addCase(PURGE, () => initialState)
      .addCase(create.fulfilled, (state, action) => {
        state.toasts.push(action.payload);
      });
  },
});

export const selectAppToast = (state: RootState) => state.toast;
export const toastActions = { create, ...toastSlice.actions };
export default toastSlice;
