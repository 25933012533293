import { Box, Typography } from '@mui/material';
import DesktopMobile from '../../components/Icons/DesktopMobile';
import Logo from '../../minimal/Logo';

const NotSupportDevice = () => {
  return (
    <Box textAlign="center" mx={3}>
      <Box position="absolute" top={24} left={16}>
        <Logo logoFull />
      </Box>
      <DesktopMobile />
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Thông báo
      </Typography>
      <Typography variant="body2" color={(theme) => theme.palette.grey[600]}>
        Sổ Bán Hàng phiên bản website chỉ hỗ trợ trên máy tính hoặc máy tính
        bảng.
      </Typography>
    </Box>
  );
};

export default NotSupportDevice;
