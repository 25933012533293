import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { List, Collapse } from '@mui/material';
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '../../../minimal/nav-section';
import useAppSelector from '../../../hooks/useAppSelector';
import { selectAppAuth } from '../../../store/auth';

// ----------------------------------------------------------------------

type NavListRootProps = {
  item: NavListProps;
  isCollapse: boolean;
};

export function NavListRoot({ item, isCollapse }: NavListRootProps) {
  const { pathname } = useLocation();
  const { isProUser } = useAppSelector(selectAppAuth);

  const active = getActive(item.path, pathname, item.exact);

  const [open, setOpen] = useState(active);

  const hasChildren = item.children;

  if (!isProUser && item.isPro) return null;

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={item}
          isCollapse={isCollapse}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {(item.children || []).map((child) => (
              <NavListSub
                key={child.title}
                list={child}
                isCollapse={isCollapse}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemRoot item={item} active={active} isCollapse={isCollapse} />;
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  list: NavListProps;
  isCollapse: boolean;
};

function NavListSub({ list, isCollapse }: NavListSubProps) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname, list.exact);

  if (isCollapse) return null;

  return <NavItemSub item={list} active={active} />;
}
