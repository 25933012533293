import { Typography, Box, styled, Card, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useState, useEffect } from 'react';
import Scrollbar from '../../minimal/Scrollbar';
import EmptyPaybookState from './EmptyPaybookState';
import NoteDialog from './NoteDialog';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageContactEdit, pageContactEditActions } from './slice';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';

const Step = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const Dot = styled(Box)(({ theme }) => ({
  width: 12,
  height: 12,
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: '50%',
  backgroundColor: 'transparent',
}));

type DividerProps = {
  height?: number;
};

const Divider = styled(Box)<DividerProps>(({ height = 38, theme }) => ({
  width: 1,
  height,
  backgroundColor: theme.palette.divider,
  margin: '0 auto',
}));

const NoteWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  cursor: 'pointer',
  width: '100%',
}));

const NoteTracking = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [openNote, setOpenNote] = useState(false);
  const { notes, stateNotes } = useAppSelector(selectPageContactEdit);
  const { id } = useParams<{ id?: string }>();
  useEffect(() => {
    if (id) {
      dispatch(pageContactEditActions.fetchNotes({ contact_id: id, page: 1, page_size: 100 }));
    }
  }, [dispatch, id]);
  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <Typography variant="h6" fontWeight={600} sx={{ mb: 2 }}>
        Ghi chú
      </Typography>
      <Scrollbar sx={{ maxHeight: 350 }}>
        {stateNotes === 'loading' &&
          [1, 2].map(value =>

            <Step key={value} width="100%">
              <Box>
                <Skeleton animation="wave" width={60} height={12} />
                <Skeleton animation="wave" width={60} height={12} />
              </Box>
              <Box mx={2}>
                <Divider />
                <Dot />
                <Divider height={30} />
              </Box>
              <NoteWrapper >
                <Skeleton animation="wave" width='100%' height={24} />
              </NoteWrapper>
            </Step>
          )
        }
        {stateNotes !== 'loading' && notes.length == 0 ?
          <EmptyPaybookState classCus={{ height: 150, mt: 5 }} textCus='Bạn chưa có ghi chú nào đối với khách hàng này!' /> :
          notes.map((step) => (
            <Step key={step.id} width="100%">
              <Box width={60}>
                <Typography variant="body2">{moment(step.day).format('DD/MM')}</Typography>
                <Typography variant="body2" color={theme.palette.grey[600]}>
                  {moment(step.day).format('HH:mm')}
                </Typography>
              </Box>
              <Box mx={2}>
                <Divider />
                <Dot />
                <Divider height={30} />
              </Box>
              <NoteWrapper onClick={() => {
                dispatch(pageContactEditActions.updateNote(step));
                dispatch(pageContactEditActions.createNote(false));
                setOpenNote(true);
              }}>
                <Typography variant="body2">{step.note}</Typography>
              </NoteWrapper>
            </Step>
          ))}
      </Scrollbar>

      <NoteDialog
        open={openNote}
        setOpen={setOpenNote}
        onSubmit={() => setOpenNote(false)}
      />
    </Card>
  );
};

export default memo(NoteTracking);
