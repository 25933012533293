import { styled } from '@mui/system';
import { TableCell, Typography, Box, Alert } from '@mui/material';
import ProductImage from '../../components/ProductImage';
import { ORDER_STATUS } from '../../utils/constants';

export const ProductWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
}));

export const IndexStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: 24,
  height: 24,
  fontSize: theme.spacing(1),
  padding: theme.spacing(0.5),
  backgroundColor: theme.palette.grey[500_16],
  '& .MuiTypography-root': {
    margin: theme.spacing(1),
  },
}));

export const ProductInfo = styled(Box)(() => ({
  width: '76%',
  display: 'flex',
  alignItems: 'center',
}));

export const AvatarStyle = styled(ProductImage)(({ theme }) => ({
  width: 48,
  height: 48,
  margin: `0 ${theme.spacing(2)}`,
  borderRadius: '12px',
  border: '2px solid #FFFFFF',
}));

export const AvatarNoImageStyle = styled(Box)(({ theme }) => ({
  width: 48,
  height: 48,
  margin: `0 ${theme.spacing(2)}`,
  borderRadius: '12px',
  position: 'absolute',
}));

export const OverflowText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const ButtonOrderLink = styled(Typography)(() => ({
  display: 'flex',
}));

export const TableCellProduct = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  display: 'flex',
  alignItems: 'left',
  position: 'relative',
  borderRight: `1px solid ${theme.palette.divider}`,
  justifyContent: 'center',
  '&:first-of-type': {
    paddingLeft: theme.spacing(2),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
}));

export const NameOrder = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  backgroundColor: '#919eab14',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  alignItem: 'center',
  justifyContent: 'space-between',
  margin: 8,
  marginBottom: 0,
  marginTop: 0,
}));

export const NameCodeOrder = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  borderLeft: `1px solid ${theme.palette.divider}`,
  paddingLeft: theme.spacing(1.2),
  marginLeft: theme.spacing(1),
}));

export const TotalCellOrder = styled(TableCell)(() => ({
  verticalAlign: 'top',
  padding: 8,
}));

export const AlertCustom = styled(Alert)<{ orderState: string }>(
  ({ theme, orderState }) => ({
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    padding: `0 ${theme.spacing(1)}`,
    fontSize: 12,
    fontWeight: 700,
    color:
      orderState === ORDER_STATUS.CANCEL
        ? theme.palette.grey[600]
        : orderState === ORDER_STATUS.WAITING_CONFIRM
        ? theme.palette.error.dark
        : orderState === ORDER_STATUS.DELIVERING
        ? theme.palette.warning.dark
        : orderState === ORDER_STATUS.COMPLETE
        ? theme.palette.success.dark
        : '',
    backgroundColor:
      orderState === ORDER_STATUS.CANCEL
        ? theme.palette.grey[500_16]
        : orderState === ORDER_STATUS.WAITING_CONFIRM
        ? 'rgba(255, 72, 66, 0.16)'
        : orderState === ORDER_STATUS.DELIVERING
        ? 'rgba(255, 193, 7, 0.16)'
        : orderState === ORDER_STATUS.COMPLETE
        ? 'rgba(84, 214, 44, 0.16)'
        : '',
    '& .MuiAlert-message': {
      padding: '4px 0px',
    },
  })
);
