import { styled } from '@mui/system';
import { Typography, Box, ToggleButton, Alert } from '@mui/material';
import { ORDER_STATUS } from '../../utils/constants';

type ActiveProps = {
  orderState: string;
};

export const OverflowText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const FlexCenter = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const FlexBetween = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const FlexCenterBetween = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ToggleButtonStyle = styled(ToggleButton)(({ theme }) => ({
  paddingTop: theme.spacing(0.8),
  paddingBottom: theme.spacing(0.8),
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[500],
}));

export const AlertCustom = styled(Alert)<ActiveProps>(
  ({ theme, orderState }) => ({
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    padding: `0 ${theme.spacing(1)}`,
    fontSize: 12,
    fontWeight: 700,
    color:
      orderState === ORDER_STATUS.CANCEL
        ? theme.palette.grey[600]
        : orderState === ORDER_STATUS.WAITING_CONFIRM
        ? theme.palette.error.dark
        : orderState === ORDER_STATUS.DELIVERING
        ? theme.palette.warning.dark
        : orderState === ORDER_STATUS.COMPLETE
        ? theme.palette.success.dark
        : '',
    backgroundColor:
      orderState === ORDER_STATUS.CANCEL
        ? theme.palette.grey[500_16]
        : orderState === ORDER_STATUS.WAITING_CONFIRM
        ? 'rgba(255, 72, 66, 0.16)'
        : orderState === ORDER_STATUS.DELIVERING
        ? 'rgba(255, 193, 7, 0.16)'
        : orderState === ORDER_STATUS.COMPLETE
        ? 'rgba(84, 214, 44, 0.16)'
        : '',
    '& .MuiAlert-message': {
      padding: '4px 0px',
    },
  })
);
