import {
  Box,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
  Typography,
  Divider,
  Stack,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useCallback, useState } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import {
  pageProductsActions,
  selectLocalCategories,
  selectLocalSearchResult,
  selectLocalSelectedCategory,
} from './slice';
import Label from '../../minimal/Label';

type HeaderProps = {
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Header = ({ onChangeSearch }: HeaderProps) => {
  const theme = useTheme();
  const [openMoreCategory, setOpenMoreCategory] = useState(false);
  const categories = useAppSelector(selectLocalCategories);
  const selectedCategory = useAppSelector(selectLocalSelectedCategory);
  const { totalRows } = useAppSelector(selectLocalSearchResult);
  const dispatch = useAppDispatch();

  const handleChangeTab = useCallback(
    (_e: Unused, value: string) => {
      dispatch(pageProductsActions.selectCategory(value));
      dispatch(pageProductsActions.setPage(0));
    },
    [dispatch]
  );

  return (
    <>
      <Stack
        sx={{ px: 2, bgcolor: 'background.neutral' }}
        width="100%"
        position="relative"
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={selectedCategory.id}
          onChange={handleChangeTab}
          sx={{ width: '98%' }}
        >
          {categories.map((category) => (
            <Tab
              key={category.id}
              value={category.id}
              label={
                <Stack spacing={1} direction="row" alignItems="center">
                  <div>{category.name}</div>{' '}
                  <Label color="success">{category.count_sku}</Label>
                </Stack>
              }
              sx={{
                px: 2,
                textTransform: 'none',
                '&:not(:last-of-type)': { mr: 1 },
              }}
            />
          ))}
        </Tabs>
        <Stack
          position="absolute"
          right={theme.spacing(1)}
          top={theme.spacing(0.5)}
        >
          <IconButton
            sx={{ height: '40px' }}
            onClick={() => setOpenMoreCategory(true)}
          >
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </Stack>
      </Stack>

      <Divider />

      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ py: 2.5, px: 3 }}
      >
        <Grid container>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                placeholder="Tìm kiếm Tên sản phẩm/ Mã SKU"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon={'eva:search-fill'}
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{ mr: 2 }}
                onChange={onChangeSearch}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>

      <Dialog
        open={openMoreCategory}
        onClose={() => setOpenMoreCategory(false)}
        fullWidth
      >
        <DialogTitle>Danh mục ({categories.length})</DialogTitle>
        <DialogContent sx={{ mt: 2, pt: 4, px: 2 }}>
          {categories.map((category) => (
            <Box
              key={category.id}
              display="flex"
              alignItems="center"
              mt={0.5}
              sx={{
                cursor: 'pointer',
                backgroundColor:
                  selectedCategory.id === category.id
                    ? theme.palette.grey[200]
                    : 'unset',
              }}
              onClick={() => {
                dispatch(pageProductsActions.selectCategory(category.id));
                setOpenMoreCategory(false);
              }}
            >
              <Avatar
                alt={category.name}
                src={'/assets/product-image.png'}
                sx={{
                  width: 80,
                  height: 80,
                  mr: 2,
                }}
                variant="square"
              />
              <Box width="100%">
                <Typography variant="subtitle2" fontWeight={500} noWrap>
                  {category.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontSize={12}
                  fontWeight={500}
                  color={(theme) => theme.palette.grey[600]}
                  noWrap
                >
                  {category.id === '' ? totalRows : category.count_sku} sản phẩm
                </Typography>
              </Box>
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default memo(Header);
