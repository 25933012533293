import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import contactService from '../../services/contact';
import { contactActions } from '../../store/contact';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { validatePhone } from '../../utils/utils';
import { formatPhone } from '../Dashboard/utils';
import { pageContactEditActions, selectPageContactEdit } from './slice';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Tên liên hệ không được để trống'),
  phone_number: Yup.string()
    .matches(/^\+?\d*$/, 'Số điện thoại không hợp lệ')
    .required('Số điện thoại không được để trống'),
  address: Yup.string(),
});

type useUpdateContactFormProps = {
  setOpen?: (value: boolean) => void;
  id: string;
  businessId: string;
};

const useUpdateContactForm = ({
  setOpen,
  id,
  businessId,
}: useUpdateContactFormProps) => {
  const dispatch = useAppDispatch();
  const { editing: contactOld } = useAppSelector(selectPageContactEdit);

  return useFormik({
    enableReinitialize: true,
    initialValues: {
      name: contactOld?.name || '',
      phone_number: formatPhone(contactOld?.phone_number || '') || '',
      address: contactOld?.address || '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const nextContact: updateContact = {
          ...values,
          business_id: businessId,
        };
        const valid = validatePhone(values.phone_number);
        if (!valid) {
          return dispatch(
            toastActions.create({
              severity: 'error',
              message: 'Số điện thoại không hợp lệ',
              dismissAfter: 3000,
            })
          );
        }

        const res: ExpectedAny = await contactService.updateContact({
          id,
          contact: nextContact,
        });
        setOpen && setOpen(false);
        await dispatch(contactActions.refresh()).unwrap();
        resetForm();
        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Cập nhật thành công',
            dismissAfter: 3000,
          })
        );
        dispatch(pageContactEditActions.updateContact(res));
        dispatch(
          pageContactEditActions.openContact({
            id: id,
            business_id: businessId,
          })
        );
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    },
  });
};

export default useUpdateContactForm;
