import { Box, TextField, styled, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import TextFieldNumber from '../../components/TextFieldNumber';
import useAppSelector from '../../hooks/useAppSelector';
import paymentService from '../../services/payment';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { pageCashbooksActions, selectPageCashbooks } from './slice';

const Root = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: '8px',
  padding: theme.spacing(1, 2, 3),
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}));

type AddPaymentSourceProps = {
  setOpenCreatePayment: (value: boolean) => void;
};

const AddPaymentSource = ({ setOpenCreatePayment }: AddPaymentSourceProps) => {
  const dispatch = useDispatch();
  const {
    main: { payments },
  } = useAppSelector(selectPageCashbooks);
  const [data, setData] = useState({ name: '', balance: '' });

  const handleCreateCategory = async () => {
    try {
      if (isNaN(+data.balance)) {
        return dispatch(
          toastActions.create({
            severity: 'error',
            message: 'Số dư ban đầu chỉ được nhập số',
            dismissAfter: 3000,
          })
        );
      }
      const response = await paymentService.createPayment({
        ...data,
        balance: +data.balance,
      });
      if (response) {
        dispatch(pageCashbooksActions.setPayments([response, ...payments]));
        setOpenCreatePayment(false);
        dispatch(
          toastActions.create({
            severity: 'success',
            message: getErrorMessage('Tạo mới nguồn tiền thành công'),
            dismissAfter: 3000,
          })
        );
      }
    } catch (ex) {
      dispatch(
        toastActions.create({
          severity: 'error',
          message: getErrorMessage(ex),
          dismissAfter: 3000,
        })
      );
    }
  };

  return (
    <Root>
      <Header>
        <Typography variant="body2">Tạo mới nguồn tiền</Typography>
        <Box display="flex">
          <Button
            color="inherit"
            sx={{ mr: 1 }}
            onClick={() => setOpenCreatePayment(false)}
          >
            Quay lại
          </Button>
          <Button
            color="primary"
            disabled={!data.name || !data.balance}
            onClick={handleCreateCategory}
          >
            Tạo mới
          </Button>
        </Box>
      </Header>
      <TextField
        label="Tên nguồn tiền"
        fullWidth
        autoFocus
        onChange={(e) => setData({ ...data, name: e.target.value })}
        sx={{ mb: 2 }}
      />
      <TextFieldNumber
        id="balance"
        label="Số dư ban đầu"
        placeholder="Nhập số dư ban đầu"
        fullWidth
        value={+data.balance}
        onSetField={(value) => setData({ ...data, balance: String(value) })}
      />
    </Root>
  );
};

export default AddPaymentSource;
