import { FieldHelperProps, FieldInputProps, FieldMetaProps } from 'formik';

type BaseFormik = {
  getFieldProps: (nameOrOptions: ExpectedAny) => FieldInputProps<ExpectedAny>;
  getFieldMeta: (name: string) => FieldMetaProps<ExpectedAny>;
  getFieldHelpers: (name: string) => FieldHelperProps<ExpectedAny>;
};

export function getFieldProps(formik: BaseFormik, fieldName: string) {
  const meta = formik.getFieldMeta(fieldName);
  return {
    ...formik.getFieldProps(fieldName),
    error: meta.touched && !!meta.error,
    helperText: meta.touched && meta.error,
  };
}
