export type DateRangeType =
  | 'today'
  | 'yesterday'
  | 'this_week'
  | 'this_year'
  | [string, string];

export function isOther(value: DateRangeType): value is [string, string] {
  return typeof value !== 'string';
}

export function formatPhone(value: string ){
  return value.replace('+84', '0');
}