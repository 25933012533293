import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import contactService from '../../services/contact';
import { selectApp } from '../../store/app';
import { selectCacheContacts } from '../../store/contact';
import { RootState } from '../../store/rootReducer';
import { toPendingContact } from '../Contacts/utils';
// import { createProduct, toPendingProduct } from './utils';
import { PURGE } from 'redux-persist';

type ConfirmType = 'back' | 'delete' | 'remove_contact';

type PageContactEditSliceState = {
  state: 'loading' | 'error' | 'saved' | 'notFound';
  editing: PendingContact | null;
  isConfirmed: boolean;
  notes: Note[];
  stateNotes:'loading' | 'error' | 'saved' | 'notFound',
  contactTransactions: ContactTransactions[],
  stateContactTransactions: 'loading' | 'error' | 'saved' | 'notFound',
  ordersContact: orderContact[],
  stateOrdersContact: 'loading' | 'error' | 'saved' | 'notFound',
  noteUpdate: PendingNoteUpdate,
  openNewDept: string,
  createNote: boolean,
} & ConfirmState<ConfirmType>;

const initialState: PageContactEditSliceState = {
  state: 'loading',
  confirming: false,
  editing: null,
  isConfirmed: localStorage.getItem('confirm-advance-inventory') === 'true',
  notes: [],
  stateNotes:'loading',
  contactTransactions: [],
  stateContactTransactions: 'loading',
  ordersContact: [],
  stateOrdersContact: 'loading',
  noteUpdate: <PendingNoteUpdate>{},
  openNewDept: '',
  createNote: false,
};


const openContact = createAsyncThunk<
  PendingContact,
  DetailContactParam,
  { state: RootState }
>('page_ContactEdit/open', async (params, { getState }) => {
  const online = !selectApp(getState()).offline;
  const contact = online
    ? await contactService.getContact({id: params.id, businessId: params.business_id})
    : selectCacheContacts(getState())[params.id];

  if (!contact) {
    throw new Error('NO_CONTACT');
  }

  return toPendingContact(contact);
});

const fetchNotes = createAsyncThunk('page_ContactEdit/getNotes', async ({contact_id, page, page_size}: {contact_id: string, page: number, page_size: number} ) => {
  const notes = await contactService.getNotes({contact_id: contact_id, page, page_size});

  if (!notes) {
    throw new Error('NO_PRODUCT');
  }

  return notes;
});

const getContactTransaction = createAsyncThunk('page_ContactEdit/getContactTransaction', async ({business_id, contact_id, page, page_size} :{ business_id: string, contact_id: string, page: number, page_size: number}) => {
  const notes = await contactService.getContactTransaction({business_id: business_id, contact_id: contact_id, page: page, page_size: page_size});

  if (!notes) {
    throw new Error('NO_PRODUCT');
  }

  return notes;
});

const getContactOrders = createAsyncThunk('page_ContactEdit/getContactOrders', async ({business_id, contact_id, page, page_size} :{ business_id: string, contact_id: string, page: number, page_size: number}) => {
  const notes = await contactService.getContactOrders({ business_id, contact_id, page, page_size});

  if (!notes) {
    throw new Error('NO_PRODUCT');
  }

  return notes;
});

const pageContactEditSlice = createSlice({
  name: 'page_ContactEdit',
  initialState,
  reducers: {
    reset: () => initialState,
    cancelConfirm: (state) => {
      state.confirming = false;
    },
    openConfirm: (state, action: PayloadAction<ConfirmType>) => {
      state.confirming = action.payload;
    },
    updateContact: (state, action: PayloadAction<PendingContact>) => {
      state.editing = action.payload;
    },

    updateNote: (state, action: PayloadAction<PendingNoteUpdate>) => {
      state.noteUpdate = action.payload;
    },
 
    setOpenNewDept: (state, action: PayloadAction<string>) => {
      state.openNewDept = action.payload;
    },

    createNote: (state, action: PayloadAction<boolean>) => {
      state.createNote = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openContact.fulfilled, (state, action) => {
      if (action.payload) {
        state.editing = action.payload;
      }
    })
    .addCase(openContact.rejected, (state) => {
      state.state = 'notFound';
    });

    builder.addCase(PURGE, () => initialState)
    .addCase(fetchNotes.fulfilled, (state, action) => {
      if (action.payload) {
        state.notes = action.payload ;
        state.stateNotes = 'saved';
      }
    })
    .addCase(fetchNotes.rejected, (state) => {
      state.stateNotes = 'notFound';
    });

    builder.addCase(getContactTransaction.fulfilled, (state, action) => {
      if (action.payload) {
        state.contactTransactions = action.payload ;
        state.stateContactTransactions = 'saved';
      }
    })
    .addCase(getContactTransaction.rejected, (state) => {
      state.stateContactTransactions = 'notFound';
    });
   
    builder.addCase(getContactOrders.fulfilled, (state, action) => {
      if (action.payload) {
        state.ordersContact = action.payload ;
        state.stateOrdersContact = 'saved';

      }
    })
    .addCase(getContactOrders.rejected, (state) => {
      state.stateOrdersContact = 'notFound';
    });
  },
});

export const pageContactEditActions = {
  openContact,
  fetchNotes,
  getContactTransaction,
  getContactOrders,
  ...pageContactEditSlice.actions,
};
export const selectPageContactEdit = (root: RootState) => root.page_ContactEdit;
export default pageContactEditSlice;
