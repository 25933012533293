import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ProVersion from '../Icons/ProVersion';

type ConfirmationProps = {
  open: boolean;
  onClose(
    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>
  ): void;
};

const ProUserDialog = ({ open, onClose }: ConfirmationProps) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color={theme.palette.grey[800]} sx={{ textAlign: 'center' }}>
        <Typography variant="h3">
          Chúc mừng bạn đã khám phá tính năng Phiên bản Pro !{' '}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: theme.spacing(2), textAlign: 'center' }}>
        <Typography
          variant="body1"
          color={theme.palette.grey[600]}
          sx={{ mb: 5 }}
        >
          Hãy nâng cấp phiên bản Pro miễn phí trên ứng dụng di động để trải
          nghiệm nhé!
        </Typography>
        <ProVersion />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Quay lại
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProUserDialog;
