import qs from 'qs';
import { API_URI } from '../config/env';
import { fetchData, post } from '../utils/fetch';
import authService from './auth';

type GetPaymentsProps = { type?: string };

async function getPayments({ type }: GetPaymentsProps) {
  const business_id = await authService.getBusinessId();
  const params = {
    business_id,
    page: 0,
    page_size: 100,
    sort: 'created_at asc',
  };

  if (type) {
    Object.assign(params, { type });
  }
  return fetchData<Array<Payment>>(
    `${API_URI}/finan-transaction/api/v1/payment-source/get-list?${qs.stringify(
      params
    )}`,
    {
      authorization: true,
    }
  );
}

async function createPayment(payment: PendingPayment) {
  const business_id = await authService.getBusinessId();
  return await post<JSONRecord<Payment>>(
    `${API_URI}/finan-transaction/api/v1/payment-source/create`,
    { ...payment, business_id },
    { authorization: true }
  );
}

async function getPaymentsInfo() {
  const business_id = await authService.getBusinessId();
  const params = {
    business_id,
    sort: 'payment_method',
  };

  return fetchData<Array<PaymentInfo>>(
    `${API_URI}/ms-payment-management/api/payment-info/get-list?${qs.stringify(
      params
    )}`,
    {
      authorization: true,
    }
  );
}

const paymentService = { getPayments, createPayment, getPaymentsInfo };

export default paymentService;
