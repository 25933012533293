import { DateRangePicker } from '@mui/lab';
import {
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Divider,
  Stack,
  Grid,
  Box,
  // Button,
} from '@mui/material';
import { memo } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import {
  pageOrdersActions,
  selectPageOrders,
  selectedLocalSelectedStatus,
  selectLocalSearchResult,
} from './slice';
import Label from '../../minimal/Label';
import { getDateRangePicker } from '../../utils/utils';
// import { useTheme } from '@mui/material/styles';
// import OrderFilterSidebar from './OrderFilterSidebar';

type HeaderProps = {
  search: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Header = ({ search, onChangeSearch }: HeaderProps) => {
  // const theme = useTheme();
  const dispatch = useAppDispatch();
  const { pageSize } = useAppSelector(selectLocalSearchResult);
  const selectedStatus = useAppSelector(selectedLocalSelectedStatus);
  const {
    main: { dateRange, statusType },
  } = useAppSelector(selectPageOrders);

  const fetchOrders = (value: ExpectedAny) => {
    const params = {
      page: 0,
      pageSize,
      state: value.id,
      search,
    };

    dateRange.forEach((time, index) => {
      if (time !== null) {
        if (index === 0) {
          Object.assign(params, { start_time: time.toISOString() });
        } else {
          Object.assign(params, { end_time: time.toISOString() });
        }
      }
    });
    dispatch(pageOrdersActions.setEmptyArraySelected());
    dispatch(pageOrdersActions.search(params));
  };

  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={selectedStatus}
        onChange={(_e, value) => {
          dispatch(pageOrdersActions.selectStatus(value.id));
          dispatch(pageOrdersActions.setPage(0));
          fetchOrders(value);
        }}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        {statusType.map((category) => (
          <Tab
            key={category.id}
            value={category}
            label={
              <Stack spacing={1} direction="row" alignItems="center">
                <div>{category.name}</div>
                {category.color !== '' && (
                  <Label color={category.color}>
                    {category.count ? category.count : 0}
                  </Label>
                )}
              </Stack>
            }
            sx={{
              px: 2,
              textTransform: 'none',
              '&:not(:last-of-type)': { mr: 1 },
            }}
          />
        ))}
      </Tabs>
      <Divider />
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ py: 2.5, px: 3 }}
      >
        <Grid container>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon={'eva:search-fill'}
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder="Tìm kiếm Mã đơn/ Tên/ Số điện thoại"
                sx={{ mr: 2 }}
                onChange={onChangeSearch}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <DateRangePicker
              startText="Từ ngày"
              endText="Đến ngày"
              value={dateRange}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                const result = getDateRangePicker(newValue);
                if (result === false) return;

                dispatch(pageOrdersActions.setDateRange(result));
                dispatch(pageOrdersActions.setPage(0));
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    sx={{ mr: 1 }}
                    InputProps={{
                      endAdornment: (
                        <Iconify
                          icon={'healthicons:i-schedule-school-date-time'}
                          sx={{ color: 'text.disabled', width: 40, height: 40 }}
                        />
                      ),
                    }}
                  />
                  <TextField
                    {...endProps}
                    InputProps={{
                      endAdornment: (
                        <Iconify
                          icon={'healthicons:i-schedule-school-date-time'}
                          sx={{ color: 'text.disabled', width: 40, height: 40 }}
                        />
                      ),
                    }}
                  />
                </>
              )}
            />
          </Grid>
          {/* <Grid
            item
            xs={2}
            ml={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: theme.palette.grey[500],
                fontWeight: 400,
                borderColor: theme.palette.grey[300],
              }}
              endIcon={
                <Iconify
                  icon={'cil:filter'}
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                />
              }
              onClick={() => dispatch(pageOrdersActions.setOpenDrawer())}
            >
              Bộ lọc
            </Button>
            <OrderFilterSidebar />
          </Grid> */}
        </Grid>
      </Stack>
    </>
  );
};

export default memo(Header);
