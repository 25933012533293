import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  styled,
  Typography,
  ClickAwayListener,
  Button,
} from '@mui/material';
import { Motion, spring } from 'react-motion';
import { useTheme } from '@mui/material/styles';
import HotkeyInfo from '../HotkeyInfo';
import Iconify from '../../minimal/Iconify';
import useAppSelector from '../../hooks/useAppSelector';
import {
  pagePosActions,
  selectLocalSearchProducts,
  selectPagePos,
} from '../../screens/POS/slice';
import { useEffect, useRef } from 'react';
import { numberFormat } from '../../utils/constants';
import Scrollbar from '../../minimal/Scrollbar';
import { useNavigate } from 'react-router-dom';
import { canPickItem } from '../../utils/orderUtils';
import ProductImage from '../ProductImage';
import useResponsive from '../../hooks/useResponsive';
import { useDispatch } from 'react-redux';
import EmptyProduct from '../Icons/EmptyProduct';
import { isAdvanceStock } from '../../utils/productUtils';

type SearchInputStyleProps = {
  isDesktop: boolean;
};

const SearchInputStyle = styled('div')<SearchInputStyleProps>(
  ({ isDesktop, theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(0, 1.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.customShadows.z8,
    minWidth: isDesktop ? 400 : 300,
    '&:focus-within': {
      outline: `2px solid ${theme.palette.secondary.dark}`,
    },
  })
);

const CustomInput = styled(Input)(({ theme }) => ({
  '.MuiInput-input': {
    padding: theme.spacing(0, 0, 0, 0),
  },
}));

const DropdownWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 52,
  left: 0,
  width: 556,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.grey[600],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: 350,
  boxShadow: theme.customShadows.z24,
}));

const AvatarStyle = styled(ProductImage)(({ theme }) => ({
  width: '48px',
  height: '48px',
  marginRight: theme.spacing(2),
  borderRadius: '12px',
}));

const RowHighlight = styled('div')(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  border: '3px solid #31ab55',
  pointerEvents: 'none',
}));

type ItemWrapperProps = {
  canPick: boolean;
};

const ItemWrapper = styled(Box)<ItemWrapperProps>(({ canPick, theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 5),
  cursor: 'pointer',
  opacity: canPick ? 1 : 0.5,
  position: 'relative',
}));

type SearchInputProps = {
  searchText: string;
  formValues: PendingOrderForm;
  setSearchText: (value: string) => void;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectedItem: (
    item: Sku & {
      product_id: string;
      product_name: string;
      product_images: Array<string>;
    }
  ) => void;
};

const SearchInput = ({
  searchText,
  formValues,
  setSearchText,
  onChangeSearch,
  onSelectedItem,
}: SearchInputProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const {
    main: { openDropdownProduct, highlightedSkus },
  } = useAppSelector(selectPagePos);
  const skus = useAppSelector(selectLocalSearchProducts);

  useEffect(() => {
    if (document.activeElement === searchRef.current) {
      dispatch(pagePosActions.toggleDropdownProduct(true));
    }
  }, [dispatch, skus.length]);

  useEffect(() => {
    if (searchText && /^[0-9]*$/.test(searchText) && skus.length === 1) {
      const sku = skus?.[0];
      if (sku) {
        const isStock = isAdvanceStock(sku);
        const canPick = canPickItem(sku, isStock);
        const existedSku = formValues.list_order_item.find(
          (item) => item.sku_id === sku.id
        );
        if (canPick && !existedSku) {
          onSelectedItem(sku);
          setSearchText('');
        }
      }
    }
  }, [
    formValues.list_order_item,
    onSelectedItem,
    searchText,
    setSearchText,
    skus,
  ]);

  return (
    <SearchInputStyle isDesktop={isDesktop || false}>
      <CustomInput
        fullWidth
        disableUnderline
        placeholder="Tìm kiếm sản phẩm"
        size="small"
        autoComplete="off"
        value={searchText}
        inputRef={searchRef}
        inputProps={{ id: 'search' }}
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="akar-icons:search" sx={{ width: 18, height: 18 }} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <HotkeyInfo title={'Bấm "/" để tìm kiếm'}>⌘-/</HotkeyInfo>
            <Box sx={{ ml: 0.5 }}>
              <IconButton>
                <Iconify icon="tabler:scan" sx={{ width: 18, height: 18 }} />
              </IconButton>
            </Box>
          </InputAdornment>
        }
        onChange={onChangeSearch}
        onFocus={() => dispatch(pagePosActions.toggleDropdownProduct(true))}
      />
      {openDropdownProduct && (
        <ClickAwayListener
          onClickAway={() => {
            if (document.activeElement === searchRef.current) return;
            dispatch(pagePosActions.toggleDropdownProduct(false));
          }}
        >
          <DropdownWrapper>
            {skus.length === 0 && (
              <Box textAlign="center" py={2}>
                <EmptyProduct />

                <Typography
                  variant="h5"
                  color={theme.palette.grey[500]}
                  sx={{ mb: 2 }}
                >
                  Cửa hàng chưa có sản phẩm nào!
                </Typography>
                <Button
                  variant="outlined"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => navigate('/products/create')}
                >
                  Tạo sản phẩm
                </Button>
              </Box>
            )}
            {skus.length > 0 && (
              <Scrollbar sx={{ maxHeight: 350, width: '100%' }}>
                {skus.map((sku) => {
                  const isStock = isAdvanceStock(sku);
                  const canPick = canPickItem(sku, isStock);
                  return (
                    <ItemWrapper
                      key={sku.id}
                      id={sku.id}
                      canPick={canPick}
                      onClick={() => canPick && onSelectedItem(sku)}
                    >
                      <Box display="flex" alignItems="center">
                        <AvatarStyle
                          image={sku.media[0] || '/assets/product-image.png'}
                          variant="square"
                          alt={sku.name}
                        />
                        <Box>
                          <Typography
                            variant="subtitle2"
                            color={theme.palette.grey[800]}
                            noWrap
                          >
                            {sku.product_name}
                          </Typography>
                          <Typography variant="body2" noWrap>
                            {sku.sku_code}
                          </Typography>
                          <Typography variant="body2" noWrap>
                            {sku.name}
                          </Typography>
                          {isStock && (
                            <Box display="flex">
                              <Typography variant="body2">
                                Có thể bán
                              </Typography>
                              <Typography
                                variant="body2"
                                color={theme.palette.grey[800]}
                                sx={{ ml: 0.5, mr: 1.5 }}
                              >
                                {sku.can_pick_quantity}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Typography
                        variant="subtitle2"
                        color={theme.palette.primary.main}
                      >
                        {numberFormat.format(
                          sku.selling_price || sku.normal_price || 0
                        )}
                        đ
                      </Typography>
                      {highlightedSkus.includes(sku.id) && (
                        <Motion
                          style={{
                            opacity: spring(0, { stiffness: 25 }),
                          }}
                          defaultStyle={{ opacity: 1 }}
                          onRest={() => {
                            dispatch(pagePosActions.highlightSkus([]));
                          }}
                        >
                          {({ opacity }) => (
                            <RowHighlight style={{ opacity }} />
                          )}
                        </Motion>
                      )}
                    </ItemWrapper>
                  );
                })}
              </Scrollbar>
            )}
          </DropdownWrapper>
        </ClickAwayListener>
      )}
    </SearchInputStyle>
  );
};

export default SearchInput;
