import { TablePagination } from '@mui/material';
import { memo } from 'react';
import createPaginationHook from '../../hooks/createPaginationHook';
import { pageCashbooksActions, selectLocalSearchResult } from './slice';

const usePagination = createPaginationHook({
  selectResult: selectLocalSearchResult,
  actions: pageCashbooksActions,
});

const CashbookPagination = () => {
  return (
    <TablePagination
      sx={{ mt: 1 }}
      rowsPerPageOptions={[5, 10, 20]}
      component="div"
      labelRowsPerPage="Số giao dịch trên trang"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
      {...usePagination()}
    />
  );
};

export default memo(CashbookPagination);
