import { useFormikContext } from 'formik';
import { Box, Button, styled } from '@mui/material';
import { memo, useState } from 'react';
import { FlexBetween } from './OrderDetailComponent';
import Confirmation from '../../components/Confirmation';
import orderService from '../../services/order';
import { toastActions } from '../../store/toast';
import useAppDispatch from '../../hooks/useAppDispatch';
import { getErrorMessage } from '../../utils/errorUtils';
import { pageOrderDetailActions, selectLocalOrderItem } from './slice';
import { selectPageOrderDetail } from './slice';
import useAppSelector from '../../hooks/useAppSelector';
import { useParams } from 'react-router-dom';
import { ORDER_STATUS } from '../../utils/constants';

const ButtonStyle = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  boxShadow: 'none',
  textTransform: 'inherit',
}));

type HeaderProps = {
  state: string;
  orderDetailId: string;
  orderId: string;
};

const Header = ({ state, orderDetailId, orderId }: HeaderProps) => {
  const {
    main: { isEdit, orderDetail, paymentMethod },
  } = useAppSelector(selectPageOrderDetail);
  const { number } = useParams<{ number?: string }>();
  const formik = useFormikContext();
  const [openCancel, setOpenCancel] = useState(false);
  const dispatch = useAppDispatch();
  const getCreateOrderInput = useAppSelector(selectLocalOrderItem);

  const handleCancel = async (id: string) => {
    if (id) {
      try {
        await orderService.updateOrder(id, {
          state: ORDER_STATUS.CANCEL,
          payment_method: paymentMethod[0]?.name || '',
          payment_source_id: paymentMethod[0]?.id || '',
          payment_source_name: paymentMethod[0]?.name || '',
          debit: {
            buyer_pay: orderDetail.amount_paid || 0,
            description: '',
            is_debit: true,
          },
        });

        number && dispatch(pageOrderDetailActions.fetchOrderDetail(number));
        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Hủy đơn hàng thành công',
            dismissAfter: 3000,
          })
        );
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    }
  };

  const handleDelivering = async (id: string, state: string) => {
    if (id) {
      try {
        const res = await orderService.updateOrder(id, {
          state: state,
          payment_method: paymentMethod[0]?.name || '',
          payment_source_id: paymentMethod[0]?.id || '',
          payment_source_name: paymentMethod[0]?.name || '',
          debit: {
            buyer_pay: orderDetail.amount_paid || 0,
            description: '',
            is_debit: true,
          },
        });
        if (res.status && res.status === 'sold_out') {
          dispatch(
            toastActions.create({
              severity: 'error',
              message: 'Có sản phẩm hết hàng trong đơn hàng của Bạn',
              dismissAfter: 3000,
            })
          );
          return;
        }

        number && dispatch(pageOrderDetailActions.fetchOrderDetail(number));
        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Đã cập nhật thành công',
            dismissAfter: 3000,
          })
        );
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    }
  };

  return (
    <>
      <FlexBetween mr={1}>
        {state != ORDER_STATUS.CANCEL && (
          <Box display="flex">
            {isEdit ? (
              <>
                <ButtonStyle
                  variant="outlined"
                  color="error"
                  sx={{ mr: 2, border: 'none', '&:hover': { border: 'none' } }}
                  onClick={() => {
                    formik.resetForm();
                    formik.setFieldValue(
                      'list_order_item',
                      getCreateOrderInput.listOrderItem
                    );
                    dispatch(pageOrderDetailActions.setIsEdit(false));
                    dispatch(pageOrderDetailActions.setProductCheck([]));
                  }}
                >
                  Huỷ chỉnh sửa
                </ButtonStyle>
                <ButtonStyle
                  variant="contained"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Cập nhật
                </ButtonStyle>
              </>
            ) : (
              <>
                {state != ORDER_STATUS.COMPLETE ? (
                  <>
                    <ButtonStyle
                      variant="outlined"
                      color="error"
                      sx={{ mr: 2 }}
                      onClick={() => {
                        setOpenCancel(true);
                      }}
                    >
                      Hủy đơn
                    </ButtonStyle>
                    {state == ORDER_STATUS.WAITING_CONFIRM ? (
                      <ButtonStyle
                        variant="contained"
                        onClick={() =>
                          handleDelivering(orderId, ORDER_STATUS.DELIVERING)
                        }
                      >
                        Xác nhận đơn hàng
                      </ButtonStyle>
                    ) : state == ORDER_STATUS.DELIVERING ? (
                      <ButtonStyle
                        variant="contained"
                        onClick={() =>
                          dispatch(
                            pageOrderDetailActions.setOrderPaymentDialog(
                              orderDetail
                            )
                          )
                        }
                      >
                        Đã giao
                      </ButtonStyle>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <ButtonStyle
                    variant="outlined"
                    color="error"
                    sx={{ mr: 2 }}
                    onClick={() => {
                      setOpenCancel(true);
                    }}
                  >
                    Huỷ đơn
                  </ButtonStyle>
                )}
              </>
            )}
          </Box>
        )}
        <Confirmation
          open={openCancel}
          title="Huỷ đơn hàng"
          description="Sau khi huỷ đơn hàng sẽ ảnh hưởng đến số liệu thu chi.
        Bạn có chắc muốn huỷ đơn hàng này?"
          cancelLabel="Quay lại"
          confirmLabel="Huỷ đơn hàng"
          onClose={() => setOpenCancel(false)}
          onConfirm={() => {
            handleCancel(orderDetailId);
            setOpenCancel(false);
          }}
        />
      </FlexBetween>
    </>
  );
};

export default memo(Header);
