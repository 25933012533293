import { useFormikContext } from 'formik';
import {
  Card,
  Typography,
  Box,
  styled,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { memo, useState, useEffect } from 'react';
import Scrollbar from '../../minimal/Scrollbar';
import { FlexBetween, FlexCenterBetween } from './OrderDetailComponent';
import moment from 'moment';
import { numberFormat, ORDER_STATUS } from '../../utils/constants';
import useAppSelector from '../../hooks/useAppSelector';
import { pageOrderDetailActions, selectPageOrderDetail } from './slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import orderService from '../../services/order';
import { useParams } from 'react-router-dom';
import TextFieldNumber from '../../components/TextFieldNumber';

const Step = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

const Dot = styled(Box)(({ theme }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  margin: theme.spacing(0.5, 2, 1.5),
}));

const Divider = styled(Box)(({ theme }) => ({
  width: 1,
  height: 36,
  backgroundColor: theme.palette.divider,
  margin: `0 auto ${theme.spacing(1)}`,
}));

const DeptWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(255, 193, 7, 0.16)',
  padding: theme.spacing(0.5, 1),
  marginLeft: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const PayingButton = styled(Button)(({ theme }) => ({
  border: 'none',
  boxShadow: 'none',
  marginTop: theme.spacing(1),
  '&:hover': { border: 'none' },
  '&.Mui-disabled': {
    border: 'none',
  },
}));

const PayingWrapper = styled(Box)(({ theme }) => ({
  borderRadius: +theme.shape.borderRadius * 2,
  border: `1px solid ${theme.palette.primary.main}`,
  marginTop: theme.spacing(1.5),
  padding: theme.spacing(1.5, 2, 3, 2),
}));

type CustomerPaidProps = {
  orderDetail: Order;
};

const CustomerPaid = ({ orderDetail }: CustomerPaidProps) => {
  const theme = useTheme();
  const [openPaying, setOpenPaying] = useState(false);
  const [grandTotalCustom, setGrandTotalCustom] = useState(0);
  const [payMethodName, setPayMethodName] = useState<string | undefined>('');
  const [payMethodId, setPayMethodId] = useState<string | undefined>('');
  const { number } = useParams<{ number?: string }>();
  const {
    main: { paymentMethod },
  } = useAppSelector(selectPageOrderDetail);
  const formik = useFormikContext<CreateOrderInput>();
  const dispatch = useAppDispatch();

  const payMethodHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const payment = paymentMethod.filter(
      (value) => value.name == e.currentTarget.value
    );
    setPayMethodId(payment[0]?.id ?? '');
    setPayMethodName(e.currentTarget.value);
  };

  useEffect(() => {
    orderDetail.business_id &&
      dispatch(
        pageOrderDetailActions.getPaymentMethod(orderDetail.business_id)
      );
  }, [dispatch, orderDetail]);

  useEffect(() => {
    if (paymentMethod) {
      setPayMethodId(paymentMethod[0]?.id);
      setPayMethodName(paymentMethod[0]?.name);
    }
  }, [paymentMethod]);

  const createCustomPaid = async () => {
    const customerPaid: CreateCustomPaid = {
      order_id: orderDetail?.id,
      business_id: orderDetail?.business_id,
      amount: +grandTotalCustom,
      name: payMethodName,
      payment_source_id: payMethodId,
      payment_method: payMethodName,
    };
    try {
      await orderService.createCustomPaid(customerPaid);
      number && dispatch(pageOrderDetailActions.fetchOrderDetail(number));
      setOpenPaying(false);
      setGrandTotalCustom(0);
      dispatch(
        toastActions.create({
          severity: 'success',
          message: 'Xác nhận thanh toán thành công',
          dismissAfter: 3000,
        })
      );
    } catch (ex) {
      dispatch(
        toastActions.create({
          severity: 'error',
          message: getErrorMessage(ex),
          dismissAfter: 3000,
        })
      );
    }
  };

  const renderBgColor = (type: string) => {
    switch (type) {
      case 'Tiền mặt':
        return theme.palette.primary.lighter;
      case 'Ví điện tử':
        return theme.palette.primary.light;
      default:
        return theme.palette.primary.dark;
    }
  };
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FlexBetween mb={2}>
        <Typography variant="subtitle1">Khách đã thanh toán</Typography>
        <Typography variant="subtitle1">
          {numberFormat.format(orderDetail?.amount_paid || 0)}&nbsp;đ
        </Typography>
      </FlexBetween>
      <Scrollbar sx={{ maxHeight: 230 }}>
        {orderDetail?.payment_order_history?.map((step) => (
          <FlexBetween key={step.id}>
            <Step>
              <Box>
                <Typography variant="body2">
                  {moment(step.created_at).format('DD/MM')}
                </Typography>
                <Typography variant="body2" color={theme.palette.grey[600]}>
                  {moment(step.created_at).format('HH:mm')}
                </Typography>
              </Box>
              <Box>
                <Dot bgcolor={renderBgColor(step.payment_method)} />
                <Divider />
              </Box>
              <Typography variant="subtitle2">{step.name}</Typography>
            </Step>
            <Typography variant="body2" color={theme.palette.grey[600]}>
              {numberFormat.format(step.amount || 0)}&nbsp;đ
            </Typography>
          </FlexBetween>
        ))}
      </Scrollbar>
      <FlexBetween mt={1}>
        <DeptWrapper>
          <Typography variant="caption" color={theme.palette.warning.dark}>
            Khách còn nợ
          </Typography>
        </DeptWrapper>
        <Typography variant="body2" color={theme.palette.grey[600]}>
          {numberFormat.format(
            formik.values.grand_total - orderDetail?.amount_paid || 0
          )}
          &nbsp;đ
        </Typography>
      </FlexBetween>
      {orderDetail?.state !== ORDER_STATUS.CANCEL &&
        orderDetail?.state !== ORDER_STATUS.COMPLETE &&
        orderDetail.state !== ORDER_STATUS.WAITING_CONFIRM &&
        orderDetail?.grand_total - orderDetail?.amount_paid !== 0 && (
          <>
            <PayingButton
              variant="contained"
              disabled={openPaying}
              sx={{
                p: 2,
                mt: 3,
                width: '30%',
                align: 'right',
                ml: 'auto',
                display: 'block',
              }}
              onClick={() => {
                setOpenPaying(true);
                setTimeout(() => {
                  window.scrollTo({
                    left: 0,
                    top: document.body.scrollHeight,
                    behavior: 'smooth',
                  });
                }, 0);
              }}
            >
              <Typography variant="subtitle2">Thanh toán</Typography>
            </PayingButton>

            {openPaying && (
              <PayingWrapper>
                <Typography variant="subtitle1">Xác nhận thanh toán</Typography>
                <FlexBetween mt={3} mb={2}>
                  <Typography variant="body2">Tổng tính</Typography>
                  <Typography variant="body2">
                    {' '}
                    {numberFormat.format(
                      orderDetail?.grand_total - orderDetail?.amount_paid || 0
                    )}
                    &nbsp;đ
                  </Typography>
                </FlexBetween>
                <FlexCenterBetween mb={2}>
                  <Typography variant="body2">Khách đã trả</Typography>
                  <TextFieldNumber
                    id="grandTotalCustom"
                    sx={{ '& input': { py: 1 } }}
                    value={grandTotalCustom}
                    autoFocus
                    endAdornment="&nbsp;đ"
                    textAlign="right"
                    onSetField={(value) => {
                      if (
                        +value >
                        orderDetail?.grand_total - orderDetail?.amount_paid
                      ) {
                        return setGrandTotalCustom(
                          orderDetail?.grand_total - orderDetail?.amount_paid
                        );
                      }

                      setGrandTotalCustom(value);
                    }}
                  />
                </FlexCenterBetween>
                <Box mb={3}>
                  <Typography variant="body2">Trả bằng</Typography>
                  <RadioGroup
                    row
                    value={payMethodName}
                    onChange={payMethodHandle}
                    sx={{ justifyContent: 'space-between' }}
                  >
                    {paymentMethod.map(
                      (
                        value: { name: string },
                        index: React.Key | null | undefined
                      ) => (
                        <FormControlLabel
                          key={index}
                          value={value.name}
                          control={<Radio />}
                          label={value.name}
                        />
                      )
                    )}
                  </RadioGroup>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="inherit"
                    sx={{ mr: 1.5 }}
                    onClick={() => {
                      setGrandTotalCustom(0);
                      setOpenPaying(false);
                    }}
                  >
                    Huỷ
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      createCustomPaid();
                    }}
                  >
                    Xác nhận
                  </Button>
                </Box>
              </PayingWrapper>
            )}
          </>
        )}
    </Card>
  );
};

export default memo(CustomerPaid);
