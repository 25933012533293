import { Box, Typography, Card, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { assert } from 'ts-essentials';
import { v4 } from 'uuid';
import { useFormikContext } from 'formik';
import CameraPlus from '../../components/Icons/CameraPlus';
import Iconify from '../../minimal/Iconify';
import { HEADER } from '../../config/styles';
import { memo } from 'react';
import mediaService from '../../services/media';
import { isLocalImage } from '../../utils/productUtils';
import ProductImage from '../../components/ProductImage';
import useAppDispatch from '../../hooks/useAppDispatch';
import { toastActions } from '../../store/toast';

const ImageWrapper = styled(Box)(() => ({
  position: 'relative',
  maxWidth: '100%',
  maxHeight: '100%',
  width: 'auto',
  height: 'auto',
  '&::after': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  },
}));

const AvatarStyle = styled(ProductImage)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.shape.borderRadius,
  position: 'absolute',
}));

const CloseIcon = styled(Iconify)(({ theme }) => ({
  width: 24,
  height: 24,
  position: 'absolute',
  top: '6px',
  right: '6px',
  color: theme.palette.grey[300],
  cursor: 'pointer',
}));

const ProductImages = () => {
  const dispatch = useAppDispatch();
  const formik = useFormikContext<PendingProduct>();
  const { images } = formik.values;

  return (
    <Card sx={{ pb: 2, mt: 2 }}>
      <Box p={2} pb={0}>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Typography>Ảnh sản phẩm</Typography>
          <Typography>{images.length}/10</Typography>
        </Box>
        <label htmlFor="button-file">
          <input
            style={{ display: 'none' }}
            accept="image/*"
            id="button-file"
            multiple
            type="file"
            onChange={async (e) => {
              if (e.target.files) {
                const { files } = e.target;
                if (formik.values.images.length >= 10) {
                  return dispatch(
                    toastActions.create({
                      severity: 'error',
                      message: 'Tối đa 10 hình ảnh',
                      dismissAfter: 5000,
                    })
                  );
                }
                const localImages = [...formik.values.images];
                for (let i = 0; i < files.length; i++) {
                  if (i < 10) {
                    const file = files[i];
                    assert(!!file, 'File can not be null');
                    const dotExt = /\.[^.$]+$/.exec(file.name)?.[0];
                    if (dotExt) {
                      const name = v4() + dotExt;

                      await mediaService.saveLocal(
                        name,
                        await file.arrayBuffer()
                      );

                      localImages.push({
                        type: 'local',
                        name,
                        mime_type: file.type,
                      });
                    }
                  }
                }
                if (files.length > 10) {
                  dispatch(
                    toastActions.create({
                      severity: 'error',
                      message: 'Tối đa 10 hình ảnh',
                      dismissAfter: 5000,
                    })
                  );
                }
                formik.setFieldValue('images', localImages);
              }
            }}
          />
          <Button variant="outlined" component="span" fullWidth sx={{ py: 1 }}>
            <Box display="flex" alignItems="flex-start">
              <CameraPlus />
              <Typography fontWeight={700} fontSize={14} sx={{ ml: 1 }}>
                Thêm ảnh sản phẩm
              </Typography>
            </Box>
          </Button>
        </label>
      </Box>
      <Grid
        spacing={1}
        container
        sx={{
          mt: 1,
          px: 1,
          overflowY: 'scroll',
          maxHeight: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px - ${HEADER.LAYOUT_HEIGHT}px - 250px)`,
        }}
      >
        {images.map((image) => (
          <Grid key={isLocalImage(image) ? image.name : image} item xs={6}>
            <ImageWrapper>
              <AvatarStyle image={image} />
              <CloseIcon
                icon="eva:close-circle-fill"
                onClick={() => {
                  formik.setFieldValue(
                    'images',
                    images.filter((i) => image !== i)
                  );
                }}
              />
            </ImageWrapper>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default memo(ProductImages);
