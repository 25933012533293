import { Box, Typography } from '@mui/material';
import Maintenence from '../../components/Icons/Maintenence';

const Maintenance = () => (
  <Box
    height="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    bgcolor="white"
  >
    <Box textAlign="center">
      <Typography variant="h3">Sổ Bán Hàng Pro hiện đang bảo trì</Typography>
      <Typography
        variant="body1"
        color={(theme) => theme.palette.grey[600]}
        sx={{ mb: 10 }}
      >
        Vui lòng quay lại sau nhé. Xin cảm ơn!
      </Typography>
      <Maintenence />
    </Box>
  </Box>
);

export default Maintenance;
