import { Box } from '@mui/material';
import { styled } from '@mui/system';

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: theme.spacing(5, 4, 0),
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

type BannerProps = {
  imageUrl: string;
};

const ImageBanner = ({ imageUrl }: BannerProps) => {
  return <Root sx={{ backgroundImage: `url(${imageUrl})` }} />;
};

export default ImageBanner;
