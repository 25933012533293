export const SECRET_KEY = process.env.OTP_SECRET_KEY;
export const API_URI = process.env.API_URI;
export const APP_VERSION = process.env.APP_VERSION;
export const CLOUDFRONT_URI = process.env.CLOUDFRONT_URI || '';
export const DSN_SENTRY = process.env.DSN_SENTRY;
export const SYNC_IDLE =
  process.env.NODE_ENV === 'development'
    ? {
        DEFAULT: 30000,
      }
    : {
        DEFAULT: 30000,
      };

console.log(SYNC_IDLE);
