export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
export const FIRST_TIME_REDIRECT = 'FIRST_TIME_REDIRECT';
export const REFRESH_TOKEN_BEFORE = 10 * 60000;
export const BUSINESS_KEY = 'BUSINESS';
export const USER_KEY = 'USER';
export const EXPIRE_PRO_TIME = 'EXPIRE_PRO_TIME';
export const MESSAGE_NOT_USER_PRO =
  'Bạn chưa nâng cấp tài khoản SoBanHanng Pro';
export const PERMISSIONS = {
  DEFAULT: 'Chủ cửa hàng',
};
export const LINK_DOWNLOAD_ANDROID =
  'https://play.google.com/store/apps/details?id=me.finan.app';
export const LINK_DOWNLOAD_IOS =
  'https://apps.apple.com/vn/app/s%E1%BB%95-b%C3%A1n-h%C3%A0ng/id1560099589';
export const EMPTY_ARRAY = [];
export const numberFormat = new Intl.NumberFormat('vi-VN');
export const RETAILCUSTOMER = {
  name: 'Khách lẻ',
  phone_number: '+84999988888',
};
export const USAGE_RULES = 'https://sobanhang.com/dieu-khoan-dich-vu/';
export const POLICY = 'https://sobanhang.com/chinh-sach-bao-mat/';

export const ORDER_STATUS = {
  WAITING_CONFIRM: 'waiting_confirm',
  DELIVERING: 'delivering',
  COMPLETE: 'complete',
  CANCEL: 'cancel',
};
