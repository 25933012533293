import { useEffect } from 'react';
import useAppDispatch from '../../hooks/useAppDispatch';
import MainLayout from '../../layouts/MainLayout';
import { pageDashboardActions } from './slice';
import General from './General';
import noOffline from '../../hocs/noOffline';
// import Introduce from './Introduce';
import FinanAnalytics from './FinanAnalytics';
import TopOrders from './TopOrders';

const Dashboard = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(pageDashboardActions.start(abortController.signal));

    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  return (
    <MainLayout fullWidth>
      {/* <Introduce /> */}
      <General />
      <FinanAnalytics />
      <TopOrders />
    </MainLayout>
  );
};

export default noOffline(Dashboard);
