import { Box, TextField, Button } from '@mui/material';
import useLocationState from '../../hooks/useLocationState';
import { getFieldProps } from '../../utils/formik';
import { getDeviceName } from '../../utils/utils';
import useLoginForm from './useLoginForm';
import NotSupportDevice from './NotSupportDevice';

const LoginOTP = () => {
  const phoneNumber: string | null = useLocationState();
  const formik = useLoginForm(phoneNumber);
  const isMobile = getDeviceName() === 'mobile';

  if (isMobile) {
    return <NotSupportDevice />;
  }

  return (
    <Box sx={{ padding: 2, width: '480px' }}>
      <Box
        width="100%"
        component={'form'}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <TextField
          fullWidth
          autoFocus
          label="Nhập số điện thoại"
          placeholder="Nhập số điện thoại bạn đã đăng ký"
          {...getFieldProps(formik, 'phoneNumber')}
        />
        <Button
          fullWidth
          disabled={formik.isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
          sx={{ py: 2, mt: 2 }}
        >
          Đăng nhập
        </Button>
      </Box>
    </Box>
  );
};

export default LoginOTP;
