import { Box, Typography, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import { assert } from 'ts-essentials';
import { Motion, spring } from 'react-motion';
import ProductImage from '../../components/ProductImage';
import useAppDispatch from '../../hooks/useAppDispatch';
import { numberFormat } from '../../utils/constants';
import { canPickItem } from '../../utils/orderUtils';
import { pagePosActions, selectPagePos } from './slice';
import useAppSelector from '../../hooks/useAppSelector';
import { isAdvanceStock } from '../../utils/productUtils';

type RootProps = {
  canPick: boolean;
};

const Root = styled(Box)<RootProps>(({ canPick }) => ({
  width: '90px',
  scrollSnapAlign: 'center',
  cursor: 'pointer',
  opacity: canPick ? 1 : 0.5,
  position: 'relative',
}));

const AvatarStyle = styled(ProductImage)(({ theme }) => ({
  width: '100%',
  height: '90px',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
}));

const PriceWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  backgroundColor: theme.palette.common.white,
  borderTopRightRadius: +theme.shape.borderRadius / 2,
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  maxWidth: '120px',
}));

const OverflowText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const OutOfStockWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '5px 12px',
  backgroundColor: theme.palette.common.white,
  borderRadius: '50px',
  zIndex: 1,
}));

const RowHighlight = styled('div')(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  top: 0,
  right: 0,
  border: '3px solid #31ab55',
  pointerEvents: 'none',
}));

type ProductItemProps = {
  sku: Sku & {
    product_id: string;
    product_name: string;
    product_images: Array<string>;
  };
};

const ProductItem = ({ sku }: ProductItemProps) => {
  const theme = useTheme();
  const formik = useFormikContext<PendingOrderForm>();
  assert(!!sku, 'A product need to have at least one sku');
  const isStock = isAdvanceStock(sku);
  const canPick = canPickItem(sku, isStock);
  const dispatch = useAppDispatch();
  const {
    main: { highlightedSkus },
  } = useAppSelector(selectPagePos);

  return (
    <Root
      canPick={canPick}
      onClick={() => {
        const { list_order_item } = formik.values;
        let newOrderItem = [...list_order_item];
        if (canPick) {
          const existedItemIndex = list_order_item.findIndex(
            (item) => item.sku_id === sku.id
          );
          if (existedItemIndex !== -1) {
            newOrderItem = list_order_item.map((item) => {
              if (item.sku_id === sku.id) {
                if (item.quantity + 1 <= sku.can_pick_quantity || !isStock) {
                  const newItem = { ...item, quantity: item.quantity + 1 };
                  return newItem;
                }
              }
              return item;
            });
            formik.setFieldValue('list_order_item', newOrderItem);
          } else {
            formik.setFieldValue('list_order_item', [
              {
                sku_id: sku.id,
                // client_id: v4(),
                sku_code: sku.sku_code,
                product_id: sku.product_id,
                product_name: sku.product_name,
                product_normal_price: sku.normal_price,
                product_selling_price: sku.selling_price,
                price: sku.selling_price || sku.normal_price,
                wholesale_price: sku.wholesale_price,
                product_images: [
                  sku.media?.[0] || sku.product_images?.[0] || '',
                ],
                quantity: 1,
                note: '',
              } as PendingOrderItem,
              ...formik.values.list_order_item,
            ]);
          }
        }

        setTimeout(() => {
          dispatch(pagePosActions.highlightSkus([sku.id]));
          document &&
            document
              .getElementById(`order-item-${sku.id}`)
              ?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 0);
      }}
    >
      <Box id={sku.id} position="relative" mb={1}>
        {!canPick && (
          <OutOfStockWrapper>
            <Typography variant="body2" color={theme.palette.grey[800]}>
              Hết hàng
            </Typography>
          </OutOfStockWrapper>
        )}

        <AvatarStyle
          image={sku.media[0] || '/assets/product-image.png'}
          variant="square"
          alt={sku.name}
        />
        <PriceWrapper>
          <OverflowText fontSize={12} color={theme.palette.primary.main}>
            {numberFormat.format(sku.selling_price || sku.normal_price)}đ
          </OverflowText>
        </PriceWrapper>
        {highlightedSkus.includes(sku.id) && (
          <Motion
            style={{
              opacity: spring(0, { stiffness: 25 }),
            }}
            defaultStyle={{ opacity: 1 }}
            onRest={() => {
              dispatch(pagePosActions.highlightSkus([]));
            }}
          >
            {({ opacity }) => <RowHighlight style={{ opacity }} />}
          </Motion>
        )}
      </Box>
      <OverflowText variant="body2">{sku.product_name}</OverflowText>
      <OverflowText fontSize={12} color={theme.palette.grey[600]}>
        {sku.name}
      </OverflowText>
    </Root>
  );
};

export default ProductItem;
