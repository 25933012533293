import {
  Button,
  Dialog,
  DialogActions,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ClickAwayListener,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  Box,
} from '@mui/material';
import { DialogContentStyle, DialogTitleStyle } from './ContactDetailComponent';
import DeptForm from './DeptForm';
import { useParams } from 'react-router-dom';
import useTransactionForm from './useTransactionForm';
import useAppSelector from '../../hooks/useAppSelector';
import { useState, useEffect, memo, useCallback } from 'react';
import { useThrottle } from 'react-use';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import { contactOffline } from '../../config/offline/contact';
import { pageContactEditActions, selectPageContactEdit } from './slice';
import { selectAppContact } from '../../store/contact';
import AddContactDialog from '../Contacts/AddContactDIalog';
import {
  pageContactsActions,
  selectLocalSearchRowsNoPagination,
} from '../Contacts/slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import Contact from '../../components/Icons/Contact';
import Scrollbar from '../../minimal/Scrollbar';
import Iconify from '../../minimal/Iconify';
import { getOptimizeImage } from '../../utils/imageUtils';
import { formatPhone } from '../Dashboard/utils';

type ToggleButtonStyleProps = {
  isBorrowed?: boolean;
};

const ToggleButtonStyle = styled(ToggleButton)<ToggleButtonStyleProps>(
  ({ isBorrowed = false, theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[500],
    fontSize: 13,
    ...(isBorrowed && {
      '&.Mui-selected': {
        color: theme.palette.error.dark,
        backgroundColor: theme.palette.error.lighter,
      },
      '&.MuiButtonBase-root:hover': {
        backgroundColor: theme.palette.error.lighter,
      },
    }),
  })
);

type NewDeptDialogProps = { hasSearch?: boolean; onSuccess?: () => void };

const NewDeptDialog = ({
  hasSearch = false,
  onSuccess,
}: NewDeptDialogProps) => {
  const { id } = useParams<{ id?: string }>();
  const formik = useTransactionForm({ id: id || '', onSuccess });
  const { editing: contact, openNewDept } = useAppSelector(
    selectPageContactEdit
  );
  const dispatch = useAppDispatch();
  const [term, setTerm] = useState<string>('');
  const throttledTerm = useThrottle(term, 200);
  const [dropdownContact, setDropdownContact] = useState(false);
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [buyerInfo, setBuyerInfo] = useState('');
  const { fuseVersion } = useAppSelector(selectAppContact);
  const contacts = useAppSelector(selectLocalSearchRowsNoPagination);

  useEffect(() => {
    openNewDept &&
      formik.setFieldValue(
        'transaction_type',
        openNewDept === 'borrowed' ? 'in' : 'out'
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openNewDept]);

  useEffect(() => {
    if (hasSearch) {
      const query = buildOfflineFuseQuery(throttledTerm, {}, contactOffline);
      dispatch(pageContactsActions.search(query));
    }
  }, [dispatch, hasSearch, fuseVersion, throttledTerm]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setTerm(e.target.value),
    []
  );

  const contactItemHandle = (contact: Contact) => {
    formik.setFieldValue('buyer_info.phone_number', contact.phone_number);
    formik.setFieldValue('buyer_info.name', contact.name);
    formik.setFieldValue('buyer_info.address', contact.address);
    formik.setFieldValue('contact_id', contact.id);
    setBuyerInfo(`${contact.name} - ${formatPhone(contact.phone_number)}`);
  };

  const setOpenAddCustomerCustom = (value: Contact) => {
    formik.setFieldValue('buyer_info.phone_number', value.phone_number);
    formik.setFieldValue('buyer_info.name', value.name);
    formik.setFieldValue('buyer_info.address', value.address);
    formik.setFieldValue('contact_id', value.id);
    setBuyerInfo(`${value.name} - ${formatPhone(value.phone_number)}`);
  };

  return (
    <Dialog
      open={Boolean(openNewDept)}
      onClose={() => {
        formik.resetForm();
        dispatch(pageContactEditActions.setOpenNewDept(''));
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitleStyle>{contact?.name || 'Tạo nợ'}</DialogTitleStyle>
      <DialogContentStyle>
        {hasSearch && (
          <Box sx={{ pt: 1 }}>
            <ClickAwayListener onClickAway={() => setDropdownContact(false)}>
              <FormControl fullWidth variant="outlined" sx={{ mb: 2.5 }}>
                <InputLabel htmlFor="customer-name">
                  Tên/ Số điện thoại khách hàng
                </InputLabel>
                <OutlinedInput
                  id="customer-name"
                  fullWidth
                  autoComplete="off"
                  required
                  label="Tên/ Số điện thoại khách hàng"
                  onChange={(e) => {
                    handleSearchChange(e);
                    setBuyerInfo(e.target.value);
                  }}
                  onClick={() => setDropdownContact(!dropdownContact)}
                  value={buyerInfo}
                  // {...getFieldProps(formik, 'buyer_info.name')}

                  sx={{ cursor: 'pointer' }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Contact />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {dropdownContact && (
                  <Card
                    sx={{
                      maxHeight: '23vh',
                      overflowY: 'hidden',
                      position: 'absolute',
                      width: '100%',
                      top: '110%',
                      zIndex: '99',
                    }}
                  >
                    <Scrollbar sx={{ maxHeight: '23vh' }}>
                      <List
                        dense
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                      >
                        <ListItem
                          key="0"
                          disablePadding
                          sx={{ display: 'flex', alignItems: 'revert' }}
                          onClick={() => setOpenAddCustomer(true)}
                        >
                          <ListItemButton sx={{ padding: 2 }}>
                            <Button
                              startIcon={<Iconify icon="eva:plus-fill" />}
                            >
                              Thêm khách hàng
                            </Button>
                          </ListItemButton>
                        </ListItem>

                        {contacts.map((contact, index) => (
                          <ListItem
                            key={index + 1}
                            disablePadding
                            onClick={() => {
                              contactItemHandle(contact);
                              setDropdownContact(false);
                            }}
                          >
                            <ListItemButton>
                              <ListItemAvatar>
                                <Avatar
                                  alt={contact.name}
                                  src={getOptimizeImage(
                                    contact.avatar ||
                                      contact.social_avatar ||
                                      '/assets/default-avatar.png',
                                    { w: 128, h: 128, fit: 'crop' }
                                  )}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                id={contact.id}
                                primary={contact.name}
                              />
                              <ListItemText
                                sx={{ textAlign: 'right' }}
                                id={contact.id}
                                primary={formatPhone(contact.phone_number)}
                              />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Scrollbar>
                  </Card>
                )}
              </FormControl>
            </ClickAwayListener>
          </Box>
        )}
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={openNewDept}
          onChange={(event, newValue) => {
            if (newValue === null) return;
            formik.setFieldValue(
              'transaction_type',
              newValue === 'lent' ? 'out' : newValue === 'borrowed' ? 'in' : ''
            );
            dispatch(pageContactEditActions.setOpenNewDept(newValue));
          }}
          sx={{ mb: 4 }}
        >
          <ToggleButtonStyle value="lent">Tôi mượn nợ</ToggleButtonStyle>
          <ToggleButtonStyle isBorrowed value="borrowed">
            Tôi cho nợ
          </ToggleButtonStyle>
        </ToggleButtonGroup>
        <DeptForm formik={formik} />
      </DialogContentStyle>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            formik.resetForm();
            dispatch(pageContactEditActions.setOpenNewDept(''));
          }}
        >
          Huỷ
        </Button>
        <Button
          variant="contained"
          disabled={
            formik.isSubmitting ||
            formik.values.amount <= 0 ||
            formik.values.start_time == ''
          }
          onClick={() => {
            formik.submitForm();
          }}
        >
          Xác nhận
        </Button>
      </DialogActions>
      <AddContactDialog
        open={openAddCustomer}
        setOpen={setOpenAddCustomer}
        saveAnotherContact={setOpenAddCustomerCustom}
      />
    </Dialog>
  );
};

export default memo(NewDeptDialog);
