import { API_URI } from '../config/env';
import { fetchAll, fetchData, post, put } from '../utils/fetch';
import authService from './auth';
import qs from 'querystring';

async function getCashbooks({
  start_time,
  end_time,
  page,
  pageSize,
  sort = 'created_at desc',
  search,
  transaction_type,
  status,
}: CashbooksParams) {
  const business_id = await authService.getBusinessId();
  const { data, meta } = await fetchAll<{
    data: Array<Cashbook>;
    meta: ResponseMeta;
  }>(
    `${API_URI}/finan-transaction/api/v1/business-transaction/get-detail?${qs.stringify(
      {
        business_id,
        start_time: start_time || '2020-04-05T17:00:00.000Z',
        end_time: end_time || '2080-04-05T17:23:59.000Z',
        transaction_type,
        page: page + 1,
        page_size: pageSize,
        sort,
        search,
        status,
      }
    )}`,
    { authorization: true }
  );

  return { cashbooks: data, meta };
}
// thu chi
async function getCashbookTotalAmount({
  start_time,
  end_time,
}: {
  start_time: string;
  end_time: string;
}) {
  const business_id = await authService.getBusinessId();
  const { data } = await fetchAll<{
    data: CashbookTotalAmount;
  }>(
    `${API_URI}/finan-transaction/api/v1/business-transaction/get-amount-total?${qs.stringify(
      {
        business_id,
        start_time,
        end_time,
      }
    )}`,
    { authorization: true }
  );

  return data;
}
// so no
async function getTransactionTotalAmount({
  start_time,
  end_time,
}: {
  start_time: string;
  end_time: string;
}) {
  const business_id = await authService.getBusinessId();
  const { data } = await fetchAll<{
    data: CashbookTotalAmount;
  }>(
    `${API_URI}/finan-transaction/api/v1/contact-transaction/get-amount-total?${qs.stringify(
      {
        business_id,
        start_time,
        end_time,
      }
    )}`,
    { authorization: true }
  );

  return data;
}

async function createCashbook(cashbook: PendingCashbook) {
  return await post<JSONRecord<Cashbook>>(
    `${API_URI}/finan-transaction/api/v1/business-transaction/create`,
    { ...cashbook },
    { authorization: true }
  );
}

async function updateCashbook(id: string, cashbook: PendingCashbook) {
  return await put<JSONRecord<Cashbook>>(
    `${API_URI}/finan-transaction/api/v1/business-transaction/update/${id}`,
    { ...cashbook },
    { authorization: true }
  );
}

async function deleteCashbook(id: string) {
  const business_id = await authService.getBusinessId();
  return await fetchData(
    `${API_URI}/finan-transaction/api/v1/business-transaction/delete/${id}`,
    {
      method: 'DELETE',
      authorization: true,
    }
  );
}

async function getCashbookCategories({ type }: { type: string }) {
  const business_id = await authService.getBusinessId();
  const { data } = await fetchAll<{
    data: Array<CashbookCategory>;
  }>(
    `${API_URI}/finan-transaction/api/v1/category-transaction/get-list?${qs.stringify(
      {
        business_id,
        page: 1,
        page_size: 100,
        type,
      }
    )}`,
    { authorization: true }
  );

  return data;
}

async function createCashbookCategory(data: PendingCashbookCategory) {
  const business_id = await authService.getBusinessId();
  return await post<CashbookCategory>(
    `${API_URI}/finan-transaction/api/v1/category-transaction/create`,
    { ...data, business_id },
    { authorization: true }
  );
}

const cashbookService = {
  getCashbooks,
  getCashbookTotalAmount,
  getTransactionTotalAmount,
  createCashbook,
  updateCashbook,
  deleteCashbook,
  getCashbookCategories,
  createCashbookCategory,
};
export default cashbookService;
