import jwtDecode from 'jwt-decode';
import { REFRESH_TOKEN_BEFORE } from './constants';

export const shouldRefresh = (token: string) =>
  jwtDecode<JWT>(token).exp * 1000 - new Date().valueOf() <=
  REFRESH_TOKEN_BEFORE;

export const shouldLogout = (expire_time: string) => {
  if (Date.parse(expire_time) < Date.parse(new Date().toString())) {
    return true;
  }
  return false;
};
