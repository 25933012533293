import { ORDER_STATUS } from '../../utils/constants';

export const initialOrderPayment = ({
  buyer_pay,
  paymentMethod,
}: {
  buyer_pay: number;
  paymentMethod: PaymentMethod[];
}) => ({
  state: 'complete',
  payment_method: paymentMethod[0]?.name || '',
  payment_source_id: paymentMethod[0]?.id || '',
  payment_source_name: paymentMethod[0]?.name || '',
  debit: {
    buyer_pay: buyer_pay,
    description: '',
    is_debit: true,
  },
});

export const selectTypeInit = (business?: ExpectedAny) => [
  { id: '', name: 'Tất cả', color: '', count: 0 },
  {
    id: ORDER_STATUS.WAITING_CONFIRM,
    name: 'Chờ xác nhận',
    color: 'info',
    count: business?.custom_fields?.order_waiting_confirm_count || 0,
  },
  {
    id: ORDER_STATUS.DELIVERING,
    name: 'Đang giao',
    color: 'success',
    count: business?.custom_fields?.order_delivering_count || 0,
  },
  {
    id: ORDER_STATUS.COMPLETE,
    name: 'Đã giao',
    color: '',
    count: 0,
  },
  {
    id: ORDER_STATUS.CANCEL,
    name: 'Đã hủy',
    color: '',
    count: 0,
  },
];
