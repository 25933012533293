export const PATH_DASHBOARD = {
  dashboard: '/',
  order: {
    list: '/orders',
    detail: '/orders/:number',
  },
  product: {
    list: '/products',
    create: 'products/create',
    detail: '/products/:id',
    inventory: '/products/inventory',
  },
  cashbook: '/cashbook',
  chat: '/chat',
  pos: '/pos',

  contact: {
    list: '/contacts',
    detail: '/contacts/:id',
  },
};
