import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { AppDispatch } from '../store';
import { getErrorMessage } from './errorUtils';

type SearchState<T> = {
  state: 'loading' | 'error' | 'ready';
  error: string;
  orderBy: OrderBy;
  order: OrderDirection;
  page: number;
  pageSize: number;
  totalRows: number;
  rows: Array<T>;
};

type SearchSliceOption<T, SearchArg> = {
  handleSearch: (
    arg: SearchArg,
    api: {
      dispatch: AppDispatch;
      getState<T = ExpectedAny>(): T;
    }
  ) => Promise<[Array<T>, number]>;
};

const createSearchSlice = <Name extends string, RowType, SearchArg = void>(
  name: Name,
  { handleSearch }: SearchSliceOption<RowType, SearchArg>
) => {
  const search = createAsyncThunk(
    `${name}/search`,
    async (arg: SearchArg, api: ExpectedAny) => await handleSearch(arg, api)
  );

  const slice = createSlice({
    name,
    initialState: {
      state: 'ready',
      error: '',
      orderBy: null,
      order: null,
      page: 0,
      pageSize: 10,
      totalRows: 0,
      rows: [],
    } as SearchState<RowType>,
    reducers: {
      setOrder: (state, action: PayloadAction<[OrderBy, OrderDirection]>) => {
        state.orderBy = action.payload[0];
        state.order = action.payload[1];
      },
      setPage: (state, action: PayloadAction<number>) => {
        state.page = action.payload;
      },
      setPageSize: (state, action: PayloadAction<number>) => {
        state.pageSize = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(search.pending, (state) => {
          state.state = 'loading';
        })
        .addCase(search.rejected, (state, action) => {
          state.state = 'error';
          state.page = 0;
          state.totalRows = 0;
          state.rows = [];
          state.error = getErrorMessage(action.error);
        })
        .addCase(search.fulfilled, (state, action) => {
          state.state = 'ready';
          state.totalRows = action.payload[1];
          state.rows = castDraft(action.payload[0]);
        });
    },
  });

  return {
    ...slice,
    actions: {
      ...slice.actions,
      search,
    },
  };
};

export default createSearchSlice;
