import { styled, useTheme } from '@mui/material/styles';
import { Box, Tooltip, Typography } from '@mui/material';
import { selectApp } from '../../store/app';
import useAppSelector from '../../hooks/useAppSelector';
import { memo, useEffect } from 'react';
import ProductImage from '../ProductImage';
import { authActions, selectAppAuth } from '../../store/auth';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const Root = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 2.5),
}));

type ActiveProps = {
  active: boolean;
};

const StoreWrapper = styled('div')<ActiveProps>(
  ({ active = false, theme }) => ({
    borderRadius: theme.shape.borderRadius,
    border: `1px solid  ${active ? theme.palette.primary.main : 'transparent'}`,
    padding: theme.spacing(1, 2.5),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:not(last-of-type)': {
      marginBottom: theme.spacing(2),
    },
  })
);

const AvatarWrapper = styled(Box)<ActiveProps>(({ active = false, theme }) => ({
  borderRadius: '50%',
  border: `2px solid ${active ? theme.palette.primary.main : 'transparent'}`,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type AvatarStyleProps = {
  mr?: string;
};

const AvatarStyle = styled(ProductImage)<AvatarStyleProps>(
  ({ mr = '0px' }) => ({
    width: 40,
    height: 40,
    marginRight: mr,
  })
);

function MultiStore() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sidebar } = useAppSelector(selectApp);
  const {
    business: currentBusiness,
    listBusiness,
    state,
  } = useAppSelector(selectAppAuth);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (state === 'switch_success') {
      dispatch(authActions.clearData());
      if (pathname === '/') return;
      navigate('/');
    }
    if (state === 'changed_business' && currentBusiness) {
      dispatch(authActions.switchBusiness(currentBusiness?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusiness, dispatch, navigate, state]);

  return (
    <Root>
      {listBusiness.map((business) => (
        <>
          {sidebar.open ? (
            <StoreWrapper
              key={business.id}
              active={business.business_id === currentBusiness?.id}
              onClick={() => {
                dispatch(authActions.switchBusiness(business.business_id));
              }}
            >
              <AvatarStyle
                variant="circular"
                image={business.avatar || '/assets/default-avatar.png'}
                mr={theme.spacing(2)}
              />
              <Box overflow="hidden">
                <Tooltip title={business.user_name} placement="top">
                  <Typography variant="subtitle2" noWrap>
                    {business.user_name}
                  </Typography>
                </Tooltip>
              </Box>
            </StoreWrapper>
          ) : (
            <Box key={business.id} textAlign="center">
              <AvatarWrapper
                active={business.business_id === currentBusiness?.id}
              >
                <AvatarStyle
                  variant="circular"
                  image={business.avatar || '/assets/default-avatar.png'}
                />
              </AvatarWrapper>
            </Box>
          )}
        </>
      ))}
    </Root>
  );
}

export default memo(MultiStore);
