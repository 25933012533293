import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import useAppSelector from '../../hooks/useAppSelector';
import orderService from '../../services/order';
import { selectAppAuth } from '../../store/auth';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { pageOrdersActions, selectPageOrders } from './slice';
import { initialOrderPayment } from './utils';

const validationSchema = Yup.object().shape({});

const UserOrderPaymentForm = () => {
  const dispatch = useDispatch();
  const {
    main: { orderPaymentDialog, paymentMethod },
  } = useAppSelector(selectPageOrders);
  const { business } = useAppSelector(selectAppAuth);

  return useFormik({
    enableReinitialize: true,
    initialValues: initialOrderPayment({
      buyer_pay: orderPaymentDialog
        ? orderPaymentDialog.grand_total - orderPaymentDialog.amount_paid
        : 0,
      paymentMethod,
    }),
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const nextValue = {
        ...values,
        debit: {
          ...values.debit,

          buyer_pay: +values.debit.buyer_pay.toString().replaceAll('.', ''),
        },
      };

      try {
        orderPaymentDialog &&
          (await orderService.updateOrder(orderPaymentDialog?.id, nextValue));
        dispatch(pageOrdersActions.setOrderPaymentDialog(null));
        business && dispatch(pageOrdersActions.getOrderAnalysis(business.id));

        resetForm();
        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Xác nhận thanh toán thành công',
            dismissAfter: 3000,
          })
        );
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    },
  });
};

export default UserOrderPaymentForm;
