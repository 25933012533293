import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import MainLayout from '../../layouts/MainLayout';
import Header from './Header';
import Content from './Content';
import NoteTracking from './NoteTracking';
import { pageContactEditActions, selectPageContactEdit } from './slice';
import Loader from '../Loader';
import NotFound from '../NotFound';
import { memo } from 'react';

const ContactDetail = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const { state, editing: contact } = useAppSelector(selectPageContactEdit);
  const businessId = search.slice(13);


  useEffect(() => {
    if (id) {
      dispatch(pageContactEditActions.openContact({ id: id, business_id: businessId }));
    }
    return () => {
      dispatch(pageContactEditActions.reset());
    };
  }, [businessId, dispatch, id]);

  if (state === 'notFound') {
    return <NotFound />;
  }

  if (!contact) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  return (

    <MainLayout >
      <Header />
      <Content />
      <NoteTracking />
    </MainLayout>

  );
};

export default memo(ContactDetail);
