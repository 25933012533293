import { Dialog, DialogActions, Button, TextField, Box } from '@mui/material';
import {
  BoxStyle,
  DialogContentStyle,
  DialogTitleStyle,
} from './ContactDetailComponent';
import useUpdateContactForm from './useUpdateContactForm';
import { getFieldProps } from '../../utils/formik';
import { useParams, useLocation } from 'react-router-dom';
import { memo } from 'react';

type ContactDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ContactDialog = ({ open, setOpen }: ContactDialogProps) => {
  const { id } = useParams<{ id?: string }>();
  const { search } = useLocation();
  const businessId = search.slice(13);
  const formik = useUpdateContactForm({ setOpen, id: id || '', businessId });
  const { name, phone_number } = formik.values;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
      <DialogTitleStyle>Sửa thông tin</DialogTitleStyle>
      <DialogContentStyle>
        <BoxStyle mt={1}>
          <TextField
            fullWidth
            label="Tên khách hàng"
            {...getFieldProps(formik, 'name')}
            autoFocus
          />
        </BoxStyle>
        <BoxStyle>
          <TextField
            fullWidth
            label="Số điện thoại"
            type="tel"
            {...getFieldProps(formik, 'phone_number')}
          />
        </BoxStyle>
        <BoxStyle>
          <TextField
            fullWidth
            label="Địa chỉ"
            {...getFieldProps(formik, 'address')}
          />
        </BoxStyle>
      </DialogContentStyle>
      <DialogActions sx={{ justifyContent: 'space-end' }}>
        {/* <Button variant="outlined" color="error">
          Xoá khách hàng
        </Button> */}
        <Box display="flex">
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              formik.resetForm();
              setOpen(false);
            }}
            sx={{ mr: 1.5 }}
          >
            Huỷ
          </Button>
          <Button
            variant="contained"
            disabled={formik.isSubmitting || !phone_number || !name}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Lưu
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ContactDialog);
