type Option<T> = {
  byId: Record<string, T>;
};

function getOfflineDataByIds<T extends ISyncRecord>(
  ids: Array<string>,
  cache: Option<T>
) {
  const { byId } = cache;
  return ids
    .map((id) => byId[id])
    .filter((product): product is T => !!product && !product.deleted_at);
}

export default getOfflineDataByIds;
