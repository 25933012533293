import { Box, Typography, Tabs, Tab, Avatar, styled } from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import useAppSelector from '../../hooks/useAppSelector';
import { loginActions, selectAppLogin } from '../../store/login';
import useAppDispatch from '../../hooks/useAppDispatch';
import AuthLayout from '../../layouts/AuthLayout';
import NotSupportDevice from './NotSupportDevice';
import { getDeviceName } from '../../utils/utils';
import LoginOTP from './LoginOTP';
import TutorialQRCode from './TutorialQRCode';
import Iconify from '../../minimal/Iconify';
import { useGeolocation } from 'react-use';
const TABS = [
  { id: 'qrcode', label: 'Mã QR' },
  { id: 'otp', label: 'Số điện thoại' },
];

const InvalidQRCode = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgb(22 28 36 / 72%)',
  zIndex: 1,
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: `3px solid ${theme.palette.common.white}`,
  borderRadius: '50%',
}));

const Requirement = styled(Typography)(() => ({
  position: 'absolute',
  bottom: '6%',
  left: '50%',
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
}));

const Login = () => {
  const dispatch = useAppDispatch();
  const { loginType } = useAppSelector(selectAppLogin);
  const isMobile = getDeviceName() === 'mobile';

  const handleChangeType = (
    _event: SyntheticEvent,
    newValue: 'qrcode' | 'otp'
  ) => {
    dispatch(loginActions.setLoginType(newValue));
  };

  const { qrCodeChallenge, QRImage, userInfo, userLocation, state, error } =
    useAppSelector(selectAppLogin);
  const loc = useGeolocation();
  const [tutorial, setTutorial] = useState(false);

  const options = useMemo(
    () => ({
      geoLocationReady: loc.latitude !== null && loc.longitude !== null,
      latitude: loc.latitude || 0,
      longitude: loc.longitude || 0,
    }),
    [loc.latitude, loc.longitude]
  );

  useEffect(() => {
    if (
      options.geoLocationReady &&
      userLocation?.latitude !== options.latitude
    ) {
      dispatch(
        loginActions.setGeolocation({
          latitude: options.latitude,
          longitude: options.longitude,
        })
      );
    }
  }, [dispatch, options, userLocation?.latitude]);

  useEffect(() => {
    fetchQRCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchQRCode = async () => {
    if (userInfo) {
      dispatch(loginActions.setUserInfo(null));
      dispatch(loginActions.setChallenge(null));
    }
    await dispatch(loginActions.generateQRCode()).unwrap();
  };

  if (isMobile) return <NotSupportDevice />;

  return (
    <AuthLayout>
      <Box>
        <Typography variant="h4" component="h1">
          Đăng nhập
        </Typography>
        <Tabs value={loginType} onChange={handleChangeType} sx={{ mb: 2 }}>
          {TABS.map((tab) => (
            <Tab
              key={tab.id}
              value={tab.id}
              label={tab.label}
              sx={{ textTransform: 'none' }}
            />
          ))}
        </Tabs>
        {loginType === 'otp' ? (
          <LoginOTP />
        ) : (
          <Box>
            <Typography
              variant="body2"
              color={(theme) => theme.palette.grey[600]}
              mb={(theme) => theme.spacing(1)}
            >
              Dùng ứng dụng Sổ Bán Hàng để quét mã QR và đăng nhập
            </Typography>
            {qrCodeChallenge && (
              <Box width="100%" textAlign="center" mt={2}>
                <Box textAlign="center">
                  <Box width={200} margin="0 auto" position="relative">
                    {QRImage && <img src={QRImage} width="100%" height={200} />}
                    {state === 'error' && (
                      <InvalidQRCode>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          height="100%"
                        >
                          <Box sx={{ cursor: 'pointer' }} onClick={fetchQRCode}>
                            <Iconify
                              icon="eva:refresh-fill"
                              color={(theme) => theme.palette.common.white}
                              fontSize={50}
                            />
                            <Typography
                              variant="subtitle2"
                              color={(theme) => theme.palette.common.white}
                            >
                              Bấm để tải lại mã QR
                            </Typography>
                          </Box>
                        </Box>
                      </InvalidQRCode>
                    )}
                    {userInfo && (
                      <AvatarWrapper>
                        <Avatar
                          variant="rounded"
                          src={userInfo.avatar || '/assets/default-avatar.png'}
                          alt={userInfo.full_name}
                          sx={{ width: 58, height: 58, borderRadius: '50%' }}
                        />
                      </AvatarWrapper>
                    )}
                  </Box>
                  {userInfo && (
                    <Box mt={2}>
                      <Typography variant="h4" mb={(theme) => theme.spacing(2)}>
                        {userInfo.full_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={(theme) => theme.palette.success.dark}
                      >
                        {
                          'Vui lòng nhấn “Đăng nhập" trên thiết bị điện thoại của bạn.'
                        }
                      </Typography>
                    </Box>
                  )}
                  {!userInfo && (
                    <TutorialQRCode
                      tutorial={tutorial}
                      setTutorial={setTutorial}
                    />
                  )}
                </Box>
                {error && (
                  <Typography
                    variant="body2"
                    color={(theme) => theme.palette.error.main}
                    mt={(theme) => theme.spacing(3)}
                  >
                    {error}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        )}
        <Requirement
          variant="caption"
          color={(theme) => theme.palette.grey[600]}
        >
          Yêu cầu hệ điều hành tối thiểu MacOS, Linux và Windows 8, 10, 11
        </Requirement>
      </Box>
    </AuthLayout>
  );
};

export default Login;
