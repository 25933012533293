const OrdersDelivered = () => (
  <svg
    width="106"
    height="100"
    viewBox="0 0 106 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4195_491348)">
      <circle cx="60.5" cy="84.5" r="60.5" fill="#919EAB" fillOpacity="0.12" />
      <g filter="url(#filter0_d_4195_491348)">
        <g filter="url(#filter1_d_4195_491348)">
          <rect
            x="31.4268"
            y="14.457"
            width="58.8111"
            height="77.5415"
            rx="12"
            fill="white"
          />
          <rect
            x="31.4268"
            y="14.457"
            width="58.8111"
            height="77.5415"
            rx="12"
            stroke="#F4F6F8"
          />
        </g>
        <g filter="url(#filter2_d_4195_491348)">
          <rect
            x="42.0781"
            y="54.8633"
            width="9.37569"
            height="6.81684"
            rx="3.15148"
            fill="#005249"
          />
          <rect
            x="56.5674"
            y="54.8633"
            width="9.37569"
            height="6.81684"
            rx="3.15148"
            fill="#007B55"
          />
          <rect
            x="70.6748"
            y="54.8633"
            width="9.37569"
            height="6.81684"
            rx="3.15148"
            fill="#00AB55"
          />
          <rect
            x="42.0781"
            y="65.0859"
            width="9.37569"
            height="6.81684"
            rx="3.15148"
            fill="#007B55"
          />
          <rect
            x="56.5674"
            y="65.0859"
            width="9.37569"
            height="6.81684"
            rx="3.15148"
            fill="#00AB55"
          />
          <rect
            x="42.0781"
            y="75.3164"
            width="9.37569"
            height="6.81684"
            rx="3.15148"
            fill="#00AB55"
          />
          <rect
            x="71.0576"
            y="65.0859"
            width="9.37569"
            height="6.81684"
            rx="3.15148"
            fill="#5BE584"
          />
          <rect
            x="71.0576"
            y="75.3164"
            width="9.37569"
            height="6.81684"
            rx="3.15148"
            fill="#C8FACD"
          />
        </g>
        <rect
          x="41.6553"
          y="26.8242"
          width="38.3551"
          height="1.70421"
          rx="0.852105"
          fill="#F4F6F8"
        />
        <rect
          x="41.6553"
          y="36.625"
          width="38.3551"
          height="1.70421"
          rx="0.852105"
          fill="#F4F6F8"
        />
        <rect
          x="41.6553"
          y="46.8477"
          width="38.3551"
          height="1.70421"
          rx="0.852105"
          fill="#F4F6F8"
        />
        <ellipse
          cx="60.4056"
          cy="27.6696"
          rx="2.55701"
          ry="2.55631"
          fill="url(#paint0_linear_4195_491348)"
        />
        <ellipse
          cx="48.474"
          cy="37.8923"
          rx="2.55701"
          ry="2.55631"
          fill="url(#paint1_linear_4195_491348)"
        />
        <ellipse
          cx="71.4877"
          cy="47.693"
          rx="2.55701"
          ry="2.55631"
          fill="url(#paint2_linear_4195_491348)"
        />
        <g filter="url(#filter3_d_4195_491348)">
          <g filter="url(#filter4_d_4195_491348)">
            <ellipse
              cx="37.9252"
              cy="14.9233"
              rx="6.92518"
              ry="6.9233"
              fill="white"
            />
          </g>
          <mask
            id="mask0_4195_491348"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="31"
            y="8"
            width="14"
            height="14"
          >
            <ellipse
              cx="37.9252"
              cy="14.9233"
              rx="6.92518"
              ry="6.9233"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_4195_491348)">
            <path
              opacity="0.16"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.9902 14.7921C38.9892 17.2144 40.1665 19.4753 42.1243 20.811C43.9196 19.3923 44.9395 17.1652 44.862 14.8328C44.7844 12.5004 43.6189 10.3507 41.7335 9.0625C40.0035 10.4265 38.9891 12.5453 38.9902 14.7921Z"
              fill="#919EAB"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.7018 15.0829C38.7046 15.1037 38.706 15.1248 38.706 15.1463C38.706 15.4126 38.4904 15.6285 38.2246 15.6285C37.9587 15.6285 37.7432 15.4126 37.7432 15.1463C37.7432 14.88 37.9587 14.6641 38.2246 14.6641C38.2444 14.6641 38.264 14.6653 38.2832 14.6676L42.3424 10.6016L42.7598 11.0181L38.7018 15.0829Z"
              fill="#212B36"
            />
          </g>
          <g filter="url(#filter5_d_4195_491348)">
            <ellipse
              cx="55.4681"
              cy="14.9233"
              rx="6.92518"
              ry="6.9233"
              fill="white"
            />
          </g>
          <mask
            id="mask1_4195_491348"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="48"
            y="8"
            width="15"
            height="14"
          >
            <ellipse
              cx="55.4681"
              cy="14.9233"
              rx="6.92518"
              ry="6.9233"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_4195_491348)">
            <path
              opacity="0.16"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.8564 14.7921C56.8554 17.2144 58.0327 19.4753 59.9905 20.811C61.7858 19.3923 62.8057 17.1652 62.7282 14.8328C62.6506 12.5004 61.4851 10.3507 59.5997 9.0625C57.8697 10.4265 56.8553 12.5453 56.8564 14.7921Z"
              fill="#919EAB"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M56.3475 15.0838C56.3501 15.1042 56.3515 15.1251 56.3515 15.1463C56.3515 15.4126 56.1359 15.6285 55.8701 15.6285C55.6042 15.6285 55.3887 15.4126 55.3887 15.1463C55.3887 14.88 55.6042 14.6641 55.8701 14.6641C55.8902 14.6641 55.9101 14.6653 55.9296 14.6677L59.9888 10.6016L60.4063 11.0181L56.3475 15.0838Z"
              fill="#212B36"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_4195_491348"
        x="18.9268"
        y="0"
        width="83.811"
        height="108.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.168627 0 0 0 0 0.211765 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4195_491348"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4195_491348"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_4195_491348"
        x="26.9268"
        y="11.957"
        width="67.811"
        height="86.543"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.168627 0 0 0 0 0.211765 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4195_491348"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4195_491348"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_4195_491348"
        x="32.0781"
        y="50.8633"
        width="54.355"
        height="43.2695"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4195_491348"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4195_491348"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_4195_491348"
        x="27"
        y="8"
        width="39.3936"
        height="21.8477"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.168627 0 0 0 0 0.211765 0 0 0 0.02 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4195_491348"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4195_491348"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_4195_491348"
        x="12"
        y="8"
        width="39.8506"
        height="39.8477"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="13" />
        <feGaussianBlur stdDeviation="6.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.25098 0 0 0 0 0.501961 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4195_491348"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4195_491348"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_d_4195_491348"
        x="29.543"
        y="8"
        width="39.8506"
        height="39.8477"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="13" />
        <feGaussianBlur stdDeviation="6.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.25098 0 0 0 0 0.501961 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4195_491348"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4195_491348"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4195_491348"
        x1="57.8486"
        y1="25.1133"
        x2="57.8486"
        y2="30.2259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" stopOpacity="0.8" />
        <stop offset="1" stopColor="#00AB55" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4195_491348"
        x1="45.917"
        y1="35.3359"
        x2="45.917"
        y2="40.4486"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" stopOpacity="0.8" />
        <stop offset="1" stopColor="#00AB55" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4195_491348"
        x1="68.9307"
        y1="45.1367"
        x2="68.9307"
        y2="50.2493"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" stopOpacity="0.8" />
        <stop offset="1" stopColor="#00AB55" />
      </linearGradient>
      <clipPath id="clip0_4195_491348">
        <path
          d="M0 0H106V84C106 92.8366 98.8366 100 90 100H0V0Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default OrdersDelivered;
