import React, { memo, useCallback, useEffect, useState } from 'react';
import { useThrottle } from 'react-use';
import { contactOffline } from '../../config/offline/contact';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import MainLayout from '../../layouts/MainLayout';
import EmptyState from './EmptyState';
import Header from './Header';
import ContactPagination from './ContactPagination';
import ContactsTable from './ContactsTable';
import {
  ContactTabs,
  pageContactsActions,
  selectLocalSearchRows,
  selectedLocalSelectedTab,
} from './slice';
import { TABLE_HEAD } from './settings';
import { TableContainer, Table, Card, Typography, Button } from '@mui/material';
import { TableHeader } from '../../components/TableHeader';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import { selectAppContact } from '../../store/contact';
import Iconify from '../../minimal/Iconify';
import AddContactDialog from './AddContactDIalog';
import { useTheme } from '@mui/material/styles';
import HeaderBreadcrumbs from '../../minimal/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../config/routeConfig';

const Contacts = () => {
  const dispatch = useAppDispatch();
  const [term, setTerm] = useState<string>('');
  const throttledTerm = useThrottle(term, 200);
  const contacts = useAppSelector(selectLocalSearchRows);
  const selectedTab = useAppSelector(selectedLocalSelectedTab);
  const { fuseVersion } = useAppSelector(selectAppContact);
  const theme = useTheme();
  const [openAddCustomer, setOpenAddCustomer] = useState(false);

  useEffect(() => {
    const query = buildOfflineFuseQuery(
      throttledTerm,
      {
        option: selectedTab?.id || null,
      },
      contactOffline
    );

    dispatch(pageContactsActions.search(query));
  }, [dispatch, selectedTab?.id, throttledTerm, fuseVersion]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTerm(e.target.value),
    []
  );

  return (
    <MainLayout>
      <HeaderBreadcrumbs
        action={
          <Button
            variant="contained"
            startIcon={
              <Iconify
                icon="eva:plus-fill"
                color={theme.palette.common.white}
              />
            }
            sx={{ minWidth: 205 }}
            onClick={() => setOpenAddCustomer(true)}
          >
            Thêm khách hàng
          </Button>
        }
        links={[]}
      >
        <Typography variant="h4" gutterBottom fontWeight={500}>
          Khách hàng
        </Typography>
      </HeaderBreadcrumbs>
      <Card>
        <Header onChangeSearch={handleSearchChange} />
        {contacts.length === 0 && (
          <>
            <TableContainer>
              <Table>
                <TableHeader
                  headLabel={TABLE_HEAD[(selectedTab?.id || '') as ContactTabs]}
                />
              </Table>
            </TableContainer>
            <EmptyState />
          </>
        )}

        {contacts.length > 0 && (
          <>
            <ContactsTable
              tableHead={TABLE_HEAD[(selectedTab?.id || '') as ContactTabs]}
            />
            <ContactPagination />
          </>
        )}
      </Card>

      <AddContactDialog open={openAddCustomer} setOpen={setOpenAddCustomer} />
    </MainLayout>
  );
};

export default memo(Contacts);
