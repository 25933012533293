import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  pagePosActions,
  selectLocalSearchContacts,
  selectLocalSelectedOrder,
  selectPagePos,
} from './slice';
import { createOrder, initialBuyerInfo, POSSchema } from './utils';
import orderService from '../../services/order';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { getGrandTotalPrice, getTotalPrice } from '../../utils/orderUtils';
import { posActions, selectAppPos } from '../../store/pos';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from '../../utils/utils';
import { RETAILCUSTOMER } from '../../utils/constants';

type PosFormProps = {
  setLoading: (value: boolean) => void;
  children: React.ReactNode;
};

const PosForm = ({ setLoading, children }: PosFormProps) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const {
    main: { otherDiscountUnit },
  } = useAppSelector(selectPagePos);
  const { pendingOrders: orders } = useAppSelector(selectAppPos);
  const contacts = useAppSelector(selectLocalSearchContacts);
  const selectedOrder = useAppSelector(selectLocalSelectedOrder);
  const [initialValues, setInitialValues] = useState<PendingOrderForm>(
    createOrder()
  );
  const params = getQueryParams(search);

  const getBuyerInfo = () => {
    const contact: Pick<
      Contact,
      'name' | 'phone_number' | 'address' | 'avatar' | 'debt_amount' | 'option'
    > = selectedOrder?.buyer_info.phone_number
      ? selectedOrder.buyer_info
      : { ...initialBuyerInfo };
    if (params && !contact.phone_number) {
      const existContact = contacts.find((c) => c.id === params.contact_id);
      if (existContact) {
        contact.name = existContact.name;
        contact.phone_number = existContact.phone_number;
        contact.address = existContact.address;
        contact.avatar = existContact.avatar;
        contact.debt_amount = existContact.debt_amount;
        contact.option = existContact.option;
      }
    }
    return contact;
  };

  useEffect(() => {
    const buyerInfo = getBuyerInfo();
    setInitialValues({
      ...initialValues,
      ...selectedOrder,
      list_order_item: selectedOrder?.items || [],
      selectedOrderId: selectedOrder?.id,
      buyer_info: buyerInfo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder, contacts]);

  return (
    <Formik<PendingOrderForm>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={POSSchema}
      onSubmit={async (values) => {
        try {
          if (values.list_order_item.length === 0) {
            return dispatch(
              toastActions.create({
                severity: 'error',
                message: 'Đơn hàng chưa có sản phẩm',
                dismissAfter: 3000,
              })
            );
          }
          if (
            !values.buyer_info.name &&
            values.delivery_method === 'seller_delivery'
          ) {
            return dispatch(
              toastActions.create({
                severity: 'error',
                message: 'Thông tin khách hàng không được để trống',
                dismissAfter: 3000,
              })
            );
          }
          const totalPrice = getTotalPrice(values.list_order_item);

          let validPromotion = true;
          if (
            values.selected_promotion?.discount &&
            values.selected_promotion.min_order_price > totalPrice
          ) {
            validPromotion = false;
          }
          if (!validPromotion) return;

          values.ordered_grand_total = totalPrice;
          values.grand_total = getGrandTotalPrice({
            ...values,
            otherDiscountUnit,
            validPromotion,
          });
          values.other_discount =
            otherDiscountUnit === 'percent'
              ? (+values.other_discount / 100) * totalPrice
              : +values.other_discount;

          if (!values.buyer_info.phone_number) {
            values.buyer_info.name = RETAILCUSTOMER.name;
            values.buyer_info.phone_number = RETAILCUSTOMER.phone_number;
          }

          setLoading(true);

          const response = await orderService.createOrder(values);
          if (response?.status) {
            dispatch(pagePosActions.setSubmitResponse(response));
          } else {
            // delete order when success (must have atleast 1 order)
            if (orders.length > 1) {
              dispatch(posActions.deleteOrder(values?.selectedOrderId || ''));
            } else {
              const defaultOrder = createOrder();
              dispatch(
                posActions.save({
                  ...defaultOrder,
                  id: values.selectedOrderId || '',
                  items: [],
                  is_wholesale_price: false,
                  selected_promotion: null,
                })
              );
            }
            dispatch(
              toastActions.create({
                severity: 'success',
                message: 'Tạo đơn hàng thành công',
                buttonText: 'Xem đơn',
                link: `/orders/${response.id}`,
                dismissAfter: 10000,
              })
            );
          }
          setLoading(false);
        } catch (ex) {
          setLoading(false);
          dispatch(
            toastActions.create({
              severity: 'error',
              message: getErrorMessage(ex),
              dismissAfter: 3000,
            })
          );
        }
      }}
    >
      {children}
    </Formik>
  );
};

export default PosForm;
