export type SearchFieldsConfig = {
  name: string;
  searchFields: Array<string>;
};

export type OfflineConfig<
  Name extends string,
  Data extends ISyncRecord,
  SearchData
> = SearchFieldsConfig & {
  name: Name;
  fetchChange: (lastUpdate: Date) => Promise<Array<JSONRecord<Data>>>;
  makeSearchData: (item: Data) => SearchData;
  syncSince?: number;
  loop?: boolean;
  idle?: number;
};

function configOffline<
  Name extends string,
  Data extends ISyncRecord,
  SearchData
>(opt: Omit<OfflineConfig<Name, Data, SearchData>, 'pickCache'>) {
  return opt;
}

export default configOffline;
