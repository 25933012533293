import { Paper, styled, Typography } from '@mui/material';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import ProductHeader from './ProductHeader';
import ProductCarousel from './ProductCarousel';
import { pagePosActions, selectPagePos } from './slice';

const ToggleButton = styled('div', {
  shouldForwardProp: (name) => name !== 'expanded',
})(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#C4CDD5',
  top: -32,
  right: 0,
  // width: 52,
  paddingRight: theme.spacing(1),
  height: 26,
  borderRadius: '2px 2px 0px 0px',
  transition: 'transform ease-in-out 250ms',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ArrowIcon = styled(Iconify, {
  shouldForwardProp: (name) => name !== 'expanded',
})<{ expanded: boolean }>(
  () => ({
    width: 26,
    height: 26,
  }),
  ({ expanded }) => ({
    transform: expanded ? 'rotateX(0deg)' : 'rotateX(180deg)',
  })
);

const Products = () => {
  const dispatch = useAppDispatch();
  const {
    main: { expandedProducts },
  } = useAppSelector(selectPagePos);

  return (
    <Paper
      sx={{
        transition: 'height ease-in-out 250ms',
        height: expandedProducts ? '100%' : 0,
        maxHeight: 380,
        mr: 1.5,
        position: 'relative',
      }}
      square
    >
      <ToggleButton onClick={() => dispatch(pagePosActions.toggleProducts())}>
        <ArrowIcon icon="bx:bx-chevron-down" expanded={expandedProducts} />
        <Typography variant="body2">
          {expandedProducts ? 'Thu gọn' : 'Mở rộng'}
        </Typography>
      </ToggleButton>
      {expandedProducts && (
        <>
          <ProductHeader />
          <ProductCarousel />
        </>
      )}
    </Paper>
  );
};

export default Products;
