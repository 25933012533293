import { Box, Typography } from '@mui/material';
import MainLayout from '../../layouts/MainLayout';
import NotFound404Icon from '../../components/Icons/NotFound404';
import { HEADER } from '../../config';

const NotFound = () => (
  <MainLayout>
    <Box
      height={`calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px)`}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box textAlign="center">
        <Typography variant="h3">
          Chúng tôi không thể tìm thấy trang này!
        </Typography>
        <Typography variant="body1" sx={{ mb: 10 }}>
          Rất tiếc chúng tôi không thể tìm thấy trang bạn vừa nhập! Vui lòng
          kiểm tra lại địa chỉ URL hoặc tải lại trang. Xin cảm ơn!
        </Typography>
        <NotFound404Icon />
      </Box>
    </Box>
  </MainLayout>
);

export default NotFound;
