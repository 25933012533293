import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import useLocationState from '../../hooks/useLocationState';
import AuthLayout from '../../layouts/AuthLayout';
import { loginActions, selectAppLogin } from '../../store/login';
import LoginOTP from './LoginOTP';
import useVerifyOTPForm from './useVerifyOTPForm';

function isChallengeDead(challenge: OTPChallenge) {
  return (
    new Date(challenge.time_life || '').valueOf() - new Date().valueOf() < 0
  );
}

const SubTitle = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.grey[600]}`,
  fontSize: '16px',
}));

const OTPWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: `${theme.spacing(5)}`,
}));

const OTPTextField = styled(TextField)(({ theme }) => ({
  width: '56px',
  marginRight: `${theme.spacing(2)}`,
  '& input': {
    textAlign: 'center',
  },
}));

const TextWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: `${theme.spacing(3)}`,
  '& .MuiTypography-root': {
    fontSize: '14px',
  },
}));

const LoginVerifyOTP = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const state = useLocationState<{ phoneNumber: string | null }>();
  const { otpChallenge, errorOTP, cooldown } = useAppSelector(selectAppLogin);
  const phoneNumber = state?.phoneNumber;
  const firstRef = useRef<HTMLInputElement>(null);
  const refs = [
    firstRef,
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];
  const [otp, setOtp] = useState(['', '', '', '']);
  const otpString = otp.join('');

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(loginActions.resetCooldown(abortController.signal));
    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (!phoneNumber) {
      navigate('/');
      return;
    }
    setTimeout(() => {
      firstRef?.current?.focus();
    }, 0);
    if (!otpChallenge || isChallengeDead(otpChallenge)) {
      dispatch(loginActions.renewOTPChallenge(phoneNumber));
    }
  }, [dispatch, phoneNumber, otpChallenge, navigate]);

  useEffect(
    () => {
      if (otpString.length === 4) {
        (async function () {
          await formik.setFieldValue('otp', otpString);
          formik.handleSubmit();
        })();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [otpString]
  );

  const handleSendOTPAgain = () => {
    if (phoneNumber) {
      dispatch(loginActions.renewOTPChallenge(phoneNumber));
      dispatch(loginActions.resetCooldown(null));
      setOtp(['', '', '', '']);
    }
  };

  const formik = useVerifyOTPForm(phoneNumber || '');

  if (!phoneNumber) return <LoginOTP />;

  return (
    <AuthLayout>
      <Box sx={{ width: '480px' }}>
        <Typography
          variant="h4"
          component="h1"
          mb={(theme) => theme.spacing(1)}
        >
          Nhập mã OTP xác thực
        </Typography>
        <SubTitle>
          Mã xác thực đã được gửi đến số điện thoại {phoneNumber} của quý khách
        </SubTitle>
        <Box
          width="100%"
          component={'form'}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <OTPWrapper>
            {[0, 1, 2, 3].map((index) => (
              <OTPTextField
                key={`digit-${index}`}
                inputRef={refs[index]}
                value={otp[index]}
                onChange={(e) => {
                  const regex = /^\d+$/;
                  if (regex.test(e.target.value) || e.target.value === '') {
                    const digits = e.target.value.split('');
                    if (digits.length > 0) {
                      e.preventDefault();
                      e.stopPropagation();
                      refs[index + digits.length]?.current?.focus();
                      refs[index + digits.length]?.current?.select();
                    }

                    const nextOtp = [...otp];
                    for (let i = 0; i + index < 4; i++) {
                      nextOtp[index + i] = digits[i] || '';
                    }

                    setOtp(nextOtp);
                    return false;
                  }
                }}
                onKeyUp={(e) => {
                  if (!otp[index] && e.key === 'Backspace') {
                    refs[index - 1]?.current?.focus();
                  }
                }}
              />
            ))}
          </OTPWrapper>
          {errorOTP && (
            <Alert severity="error" sx={{ my: (theme) => theme.spacing(2) }}>
              {errorOTP}
            </Alert>
          )}
          <TextWrapper mt={(theme) => (errorOTP ? 0 : theme.spacing(5))}>
            {cooldown > 0 ? (
              <Typography color="#7F7F7F">
                Gửi lại mã sau{' '}
                <Typography component="span" color="#2464A8">
                  {cooldown}s
                </Typography>
              </Typography>
            ) : (
              <div />
            )}
            <Typography
              color="#2464A8"
              sx={{ cursor: 'pointer' }}
              onClick={() => navigate('/', { state: phoneNumber })}
            >
              Nhập lại số điện thoại
            </Typography>
          </TextWrapper>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            disabled={cooldown > 0}
            sx={{ padding: '12px 0' }}
            onClick={handleSendOTPAgain}
          >
            Gửi lại mã xác thực
          </Button>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default LoginVerifyOTP;
