import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import cashbookService from '../../services/cashbook';
import productService from '../../services/product';
import { selectAppAuth } from '../../store/auth';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { isLocalImage } from '../../utils/productUtils';
import { initialCashbook } from './settings';
import {
  pageCashbooksActions,
  selectLocalSearchResult,
  selectPageCashbooks,
} from './slice';

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .min(1, 'Số tiền phải lớn hơn 0')
    .required('Số tiền không được bỏ trống')
    .typeError('Số tiền chỉ được nhập số'),
  day: Yup.string()
    .required('Thời gian giao dịch không được bỏ trống')
    .nullable(),
});

type useCashbookFormProps = {
  detail: Cashbook | null;
  setSelectedRow: (value: null) => void;
};

const useCashbookForm = ({ detail, setSelectedRow }: useCashbookFormProps) => {
  const dispatch = useAppDispatch();
  const { business } = useAppSelector(selectAppAuth);
  const { page, pageSize } = useAppSelector(selectLocalSearchResult);
  const {
    main: { dialogType, transactionType, selectedStatusId, dateRange },
  } = useAppSelector(selectPageCashbooks);

  return useFormik({
    enableReinitialize: true,
    initialValues: detail || initialCashbook(),
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const nextValues = {
          currency: 'VND',
          description: values.description,
          payment_information: null,
          images: values.images
            ? await Promise.all(
                values.images.map(async (image) => {
                  if (isLocalImage(image)) {
                    try {
                      return await productService.uploadProductImage(image);
                    } catch (ex) {
                      dispatch(
                        toastActions.create({
                          severity: 'error',
                          message:
                            'Không thể tải ảnh sản phẩm lên, vui lòng kiểm tra lại kết nối.',
                          dismissAfter: 3000,
                        })
                      );
                      return image;
                    }
                  }

                  return image;
                })
              )
            : [],
          business_id: business?.id || '',
          amount: +values.amount,
          day: moment(values.day).toISOString(),
          transaction_type: dialogType,
          status: 'paid',
        };

        if (values.category_id) {
          Object.assign(nextValues, {
            category_id: values.category_id,
            category_name: values.category_name,
          });
        }
        if (values.payment_source_id) {
          Object.assign(nextValues, {
            payment_source_id: values.payment_source_id,
            payment_source_name: values.payment_source_name,
            payment_method: values.payment_method,
          });
        }

        const allImagesReady = nextValues.images.every(
          (image) => !isLocalImage(image)
        );

        if (allImagesReady) {
          if (detail?.id) {
            await cashbookService.updateCashbook(detail.id, nextValues);
          } else {
            await cashbookService.createCashbook(nextValues);
          }
          const params = {
            page,
            pageSize,
            search: '',
            transaction_type: transactionType,
          };
          if (selectedStatusId !== 'all') {
            Object.assign(params, { status: selectedStatusId });
          }
          dateRange.forEach((time, index) => {
            if (time !== null) {
              if (index === 0) {
                Object.assign(params, { start_time: time.toISOString() });
              } else {
                Object.assign(params, { end_time: time.toISOString() });
              }
            }
          });
          dispatch(pageCashbooksActions.search(params));
          dispatch(pageCashbooksActions.setDialogType(''));
          setSelectedRow(null);
          resetForm();
          dispatch(
            toastActions.create({
              severity: 'success',
              message: 'Xác nhận giao dịch thành công',
              dismissAfter: 3000,
            })
          );
        } else {
          dispatch(
            toastActions.create({
              severity: 'error',
              message: 'Sản phẩm chưa sẵn sàng để tải lên.',
              dismissAfter: 3000,
            })
          );
        }
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    },
  });
};

export default useCashbookForm;
