import * as Yup from 'yup';

export function getTotalPrice(
  items: Array<{ quantity: number; price: number }>
) {
  const total = items?.reduce((acc, item) => {
    return acc + (item.price || 0) * item.quantity;
  }, 0);

  return total;
}

export const initialOrderPayment = ({
  buyer_pay,
  paymentMethod,
}: {
  buyer_pay: number;
  paymentMethod: PaymentMethod[];
}) => ({
  state: 'complete',
  payment_method: paymentMethod[0]?.name || '',
  payment_source_id: paymentMethod[0]?.id || '',
  payment_source_name: paymentMethod[0]?.name || '',
  debit: {
    buyer_pay: buyer_pay,
    description: '',
    is_debit: true,
  },
});

export const createOrderProducts = (productInit: ExpectedAny) => {
  return productInit.map((product: ExpectedAny) => ({
    sku_code: product.sku_code,
    sku_id: product.sku_id || product.id,
    product_name: product.product_name,
    sku_name: product.sku_name || product.name,
    uom: product.uom,
    normal_price: product.normal_price || product.product_normal_price,
    quantity: 1,
    total_amount:
      product.wholesale_price ||
      product.selling_price ||
      product.normal_price ||
      product.total_amount,
    price:
      product.wholesale_price ||
      product.selling_price ||
      product.normal_price ||
      product.price,
    product_images: product.media || product.product_images,
    product_normal_price:
      product.normal_price || product.product_normal_price || 0,
    product_selling_price:
      product.selling_price || product.product_selling_price || 0,
    wholesale_price: product.wholesale_price || 0,
    media: product.media,
    type: product.type,
    can_pick_quantity: product.can_pick_quantity,
  }));
};

export const orderSchema = Yup.object().shape({
  // name: Yup.string().required('Tên khách hàng không được để trống'),
  // phone_number: Yup.string().required('Số điện thoại khách hàng không được để trống'),
  // address: Yup.string().required('Địa chỉ khách hàng không được để trống'),
  other_discount: Yup.number().test(
    'ordered_grand_total',
    'Chiết khấu không được lớn hơn tổng sản phẩm',
    function Validate(value) {
      const { ordered_grand_total } = this.parent;
      if (value || value === 0) {
        if (+value <= ordered_grand_total) return true;
        return false;
      }
      return true;
    }
  ),
  // list_order_item: Yup.array(
  //   Yup.object().shape({

  //   })
  // ),
});
