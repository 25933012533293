import { PayloadActionCreator } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

type PaginationHookOptions = {
  selectResult: (state: ExpectedAny) => {
    page: number;
    pageSize: number;
    totalRows: number;
  };
  actions: {
    setPage: PayloadActionCreator<number>;
    setPageSize: PayloadActionCreator<number>;
  };
};

const createPaginationHook = ({
  selectResult,
  actions,
}: PaginationHookOptions) => {
  const usePaginationHook = () => {
    const dispatch = useAppDispatch();
    const { page, pageSize, totalRows } = useAppSelector(selectResult);
    const onPageChange = useCallback(
      (_e: Unused, page: number) => {
        dispatch(actions.setPage(page));
      },
      [dispatch]
    );

    const onRowsPerPageChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(actions.setPageSize(+event.target.value));
      },
      [dispatch]
    );

    return {
      count: totalRows,
      page,
      rowsPerPage: pageSize,
      onPageChange,
      onRowsPerPageChange,
    };
  };

  return usePaginationHook;
};

export default createPaginationHook;
