import { v4 } from 'uuid';
import * as Yup from 'yup';

export const createProduct = (): PendingProduct => ({
  id: '',
  client_id: v4(),
  name: '',
  description: '',
  description_rtf: '',
  category: [],
  uom: '',
  images: [],
  is_variant: false,
  is_advance_stock: false,
  is_active: true,
  priority: 1,
  sku_code: '',
  barcode: '',
  product_type: 'non_variant',
  skus: [createSku({})],
});

export const createSku = ({
  skusLength,
}: {
  skusLength?: number;
}): PendingSku => ({
  id: '',
  name: `Phân loại ${(skusLength && skusLength + 1) || 1}`,
  business_id: '',
  uom: '',
  media: [],
  barcode: '',
  sku_code: '',
  normal_price: 0,
  selling_price: 0,
  historical_cost: 0,
  wholesale_price: 0,
  total_quantity: 0,
  // can_pick_quantity: 0,
  is_active: true,
  // po_details: {
  //   pricing: 0,
  //   quantity: 0,
  //   note: '',
  //   blocked_quantity: 0,
  //   warning_value: 0,
  //   delivering_quantity: 0,
  // },
  sku_type: 'non_stock',
});

export const toPendingProduct = (
  product: Omit<Product, 'updated_at' | 'deleted_at' | 'created_at'>
) => ({
  ...product,
  is_advance_stock:
    (product.list_sku[0]?.sku_type &&
      !product.list_sku[0].sku_type.match(/non_stock/)) ||
    false,
  // TODO: stupid, remove it if possible
  is_variant: !product.product_type.match(/non_variant/),
  category: product?.category?.map((c) => c.id) || [],
  barcode: product.list_sku[0]?.barcode || '',
  sku_code: product.list_sku[0]?.sku_code || '',
  skus: product.product_type.match(/non_variant/)
    ? product.list_sku.map((sku) => ({ ...sku, name: 'Phân loại 1' }))
    : product.list_sku,
});

export const productSchema = Yup.object().shape({
  name: Yup.string().required('Tên sản phẩm không được để trống'),
  barcode: Yup.string().matches(/^[0-9]+$/, 'Mã vạch chỉ được nhập số'),
  skus: Yup.array(
    Yup.object().shape({
      name: Yup.string().required('Tên phân loại không được để trống'),
      barcode: Yup.string().matches(/^[0-9]+$/, 'Mã vạch chỉ được nhập số'),
      normal_price: Yup.number()
        .min(1, 'Giá bán phải lớn hơn 0')
        .typeError('Chỉ được nhập số'),
      selling_price: Yup.number()
        .test(
          'normal_price',
          'Giá khuyến mãi không được lớn hơn giá bán',
          function Validate(value) {
            const { normal_price } = this.parent;
            const normalPrice = normal_price.toString().replace('.', '');
            if (isNaN(+normalPrice)) return true;
            if (value || value === 0) {
              if (+value <= +normalPrice) return true;
              return false;
            }
            return true;
          }
        )
        .typeError('Chỉ được nhập số'),
      po_details: Yup.object()
        .shape({})
        .when('sku_type', {
          is: 'stock',
          then: (schema) =>
            schema.shape({
              blocked_quantity: Yup.number()
                .test(
                  'quantity',
                  'Lưu kho không bán không được lớn hơn số lượng tồn kho',
                  function Validate(value) {
                    const { quantity } = this.parent;
                    if (quantity) {
                      const formatQuantity = quantity
                        .toString()
                        .replace('.', '');
                      if (isNaN(+formatQuantity)) return true;
                      if (value || value === 0) {
                        if (+value <= +formatQuantity) return true;
                        return false;
                      }
                      return true;
                    }
                    return true;
                  }
                )
                .typeError('Chỉ được nhập số'),
            }),
        }),
    })
  ),
});
