import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { Card, Typography, Stack, styled } from '@mui/material';
import BaseOptionChart from '../../minimal/chart/BaseOptionChart';
import { numberFormat } from '../../utils/constants';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageDashboard } from './slice';

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingBottom: 0,
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const VisitedCustomers = () => {
  const { visitedCustomers } = useAppSelector(selectPageDashboard);
  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      type: 'category',
      categories: [
        '0h',
        '',
        '',
        '',
        '',
        '',
        '6h',
        '',
        '',
        '',
        '',
        '',
        '12h',
        '',
        '',
        '',
        '',
        '',
        '18h',
        '',
        '',
        '',
        '',
        '24h',
      ],
    },
    yaxis: { labels: { show: false } },
    stroke: { width: 4 },
    legend: { show: false },
    grid: { show: false },
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName: number) => numberFormat.format(seriesName),
        title: { formatter: () => '' },
      },
    },
    fill: { gradient: { opacityFrom: 0, opacityTo: 0 } },
  });

  const CHART_DATA = [
    {
      data: (visitedCustomers &&
        visitedCustomers?.chart.map((detail) => detail.value || 0)) || [
        ...Array(24).fill(0),
      ],
    },
  ];

  return (
    <RootStyle>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <div>
          <Typography variant="h6" fontWeight={600} sx={{ mb: 1 }}>
            Khách online ghé thăm
          </Typography>
          <Typography sx={{ typography: 'h3' }}>
            {numberFormat.format(
              visitedCustomers?.total_user_count?.value || 0
            )}
          </Typography>
        </div>
      </Stack>

      <ReactApexChart
        type="area"
        series={CHART_DATA}
        options={chartOptions}
        height={112}
      />
    </RootStyle>
  );
};

export default VisitedCustomers;
