const ProVersion = () => (
  <svg
    width="328"
    height="240"
    viewBox="0 0 328 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333328 132.52C0.333328 160.159 10.5252 185.344 27.2968 204.378C46.5374 226.221 74.4383 239.954 105.539 240C119.131 240.02 132.127 237.409 144.065 232.642C150.238 230.177 157.146 230.46 163.125 233.381C170.137 236.803 177.99 238.721 186.281 238.721C189.707 238.721 193.061 238.391 196.306 237.766C205.567 235.985 213.995 231.772 220.917 225.805C225.267 222.054 230.838 220.168 236.531 220.175H236.628C255.442 220.175 272.918 214.353 287.428 204.378C300.399 195.471 310.992 183.247 318.05 168.894C324.203 156.387 327.667 142.259 327.667 127.305C327.667 76.0181 286.904 34.4356 236.628 34.4356C231.503 34.4356 226.481 34.8772 221.583 35.7012C208.611 14.28 185.383 0 158.884 0C147.697 0 137.091 2.54512 127.597 7.10748C118.568 11.4322 110.541 17.5775 103.974 25.0667C81.6766 25.4293 61.0591 32.86 44.1844 45.261C17.6216 64.7702 0.333328 96.5882 0.333328 132.52Z"
        fill="url(#paint0_linear_5060_358305)"
      />
    </g>
    <path
      d="M101.262 115.444C107.249 115.444 112.273 116.656 116.336 119.079C120.398 121.502 123.392 124.674 125.316 128.594C127.24 132.513 128.202 136.718 128.202 141.208C128.202 145.698 127.24 149.868 125.316 153.716C123.463 157.494 120.505 160.558 116.443 162.91C112.38 165.262 107.32 166.438 101.262 166.438H88.1129V190.278H73.5738V115.444H101.262ZM99.0172 154.251C103.222 154.251 106.75 153.253 109.601 151.257C112.452 149.262 113.877 145.912 113.877 141.208C113.877 136.505 112.452 133.226 109.601 131.373C106.821 129.449 103.293 128.487 99.0172 128.487H88.1129V154.251H99.0172Z"
      fill="url(#paint1_linear_5060_358305)"
    />
    <path
      d="M162.055 163.124H152.219V190.278H137.466V115.444H165.155C171.142 115.444 176.166 116.585 180.228 118.865C184.291 121.146 187.284 124.104 189.209 127.738C191.133 131.373 192.095 135.329 192.095 139.605C192.095 144.522 190.812 148.977 188.246 152.968C185.681 156.888 181.796 159.703 176.594 161.413L193.485 190.278H177.983L162.055 163.124ZM152.219 151.044H162.91C167.043 151.044 170.536 150.188 173.387 148.478C176.309 146.696 177.77 143.738 177.77 139.605C177.77 135.471 176.344 132.585 173.493 130.945C170.643 129.235 167.115 128.38 162.91 128.38H152.219V151.044Z"
      fill="url(#paint2_linear_5060_358305)"
    />
    <path
      d="M235.114 191.133C227.773 191.133 221.501 189.458 216.298 186.109C211.167 182.688 207.282 178.091 204.645 172.318C202.08 166.474 200.797 159.988 200.797 152.861C200.797 145.663 202.115 139.142 204.752 133.297C207.389 127.382 211.274 122.678 216.405 119.186C221.608 115.694 227.844 113.948 235.114 113.948C242.383 113.948 248.584 115.694 253.715 119.186C258.846 122.607 262.731 127.275 265.368 133.19C268.005 139.035 269.323 145.591 269.323 152.861C269.323 160.059 268.005 166.581 265.368 172.425C262.731 178.198 258.811 182.759 253.608 186.109C248.477 189.458 242.312 191.133 235.114 191.133ZM235.114 177.877C240.958 177.877 245.483 175.525 248.69 170.821C251.898 166.046 253.501 159.988 253.501 152.647C253.501 147.943 252.789 143.667 251.363 139.819C250.009 135.97 247.942 132.905 245.163 130.625C242.383 128.344 239.033 127.204 235.114 127.204C231.194 127.204 227.808 128.38 224.957 130.732C222.178 133.012 220.075 136.077 218.65 139.926C217.225 143.774 216.512 148.05 216.512 152.754C216.512 159.952 218.116 165.939 221.323 170.714C224.601 175.489 229.198 177.877 235.114 177.877Z"
      fill="url(#paint3_linear_5060_358305)"
    />
    <path
      d="M233.126 108.861C233.126 108.861 232.137 113.631 232.568 116.938C232.649 117.587 232.987 118.177 233.506 118.576C234.025 118.974 234.683 119.148 235.331 119.058C236.992 118.817 239.168 118.012 240.009 115.524L241.959 111.461C242.583 109.711 242.036 107.758 240.596 106.585C238.094 104.282 233.586 105.956 233.126 108.861Z"
      fill="#F4A28C"
    />
    <path
      d="M241.606 112.175L242.242 128.152L233.416 127.87L236.497 116.359L241.606 112.175Z"
      fill="#F4A28C"
    />
    <path
      d="M234.306 109.2C235.794 109.439 237.305 109.503 238.808 109.391C237.767 110.632 237.562 112.372 238.285 113.821C238.966 115.253 240.566 116 242.101 115.602L242.758 109.37C243.016 107.56 242.255 105.756 240.779 104.678C240.156 104.212 239.502 103.79 238.822 103.413C237.119 102.48 234.356 103.363 232.893 101.964C232.591 101.682 232.163 101.579 231.766 101.692C231.369 101.804 231.059 102.117 230.95 102.515C230.441 104.374 230.427 107.391 232.865 108.727C233.315 108.965 233.802 109.125 234.306 109.2Z"
      fill="#24285B"
    />
    <path
      d="M237.783 113.617C237.783 113.617 238.037 111.751 239.422 112.062C240.807 112.373 240.454 115.065 238.518 115.086L237.783 113.617Z"
      fill="#F4A28C"
    />
    <path
      d="M232.659 111.899L231.084 113.602C230.902 113.801 230.835 114.078 230.905 114.337C230.975 114.597 231.173 114.802 231.43 114.881L233.239 115.447L232.659 111.899Z"
      fill="#F4A28C"
    />
    <path
      opacity="0.31"
      d="M235.713 118.853C236.836 118.706 237.892 118.234 238.751 117.496C238.751 117.496 238.285 120.386 234.752 122.874L235.713 118.853Z"
      fill="#CE8172"
    />
    <path
      d="M242.228 128.152L233.402 127.87C233.402 127.87 219.537 125.516 214.725 136.823C209.913 148.129 216.082 164.106 216.082 164.106C216.082 164.106 221.099 180.974 242.228 179.108C242.228 179.108 259.802 178.091 261.787 154.001C261.877 152.95 262.038 151.907 262.268 150.878C263.073 147.111 263.54 135.685 242.228 128.152Z"
      fill="url(#paint4_linear_5060_358305)"
    />
    <path
      opacity="0.08"
      d="M254.05 143.161C254.05 143.161 249.343 143.677 242.807 154.623C236.271 165.569 223.438 161.555 216.202 153.457L229.628 171.222L244.666 170.014L252.841 148.249L254.05 143.161Z"
      fill="black"
    />
    <rect
      width="2.6075"
      height="7.58932"
      transform="matrix(-0.991192 -0.13243 -0.132429 0.991192 205.098 118.172)"
      fill="#FFD200"
    />
    <rect
      opacity="0.08"
      width="2.6075"
      height="7.58932"
      transform="matrix(-0.991192 -0.13243 -0.132429 0.991192 205.098 118.172)"
      fill="black"
    />
    <rect
      width="4.00666"
      height="9.74458"
      transform="matrix(-0.991192 -0.132429 -0.13243 0.991192 205.031 123.694)"
      fill="#24285B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M213.528 111.87C214.155 107.19 210.869 102.888 206.189 102.261C203.941 101.96 201.665 102.564 199.863 103.941C198.06 105.318 196.879 107.355 196.579 109.603C195.953 114.283 199.24 118.584 203.92 119.21C208.6 119.836 212.902 116.55 213.528 111.87ZM210.285 113.768C209.057 115.881 206.675 117.046 204.252 116.719H204.224C200.936 116.255 198.637 113.227 199.073 109.935C199.395 107.512 201.146 105.521 203.509 104.893C205.871 104.264 208.38 105.122 209.864 107.065C211.347 109.008 211.514 111.654 210.285 113.768Z"
      fill="#FFE16A"
    />
    <circle
      r="6.07005"
      transform="matrix(-1 0 0 1 205.009 110.762)"
      fill="#B78103"
    />
    <path
      opacity="0.39"
      d="M262.014 144.348C261.574 142.653 260.164 141.384 258.432 141.125C256.699 140.867 254.98 141.668 254.064 143.161C250.691 148.621 247.852 154.393 245.584 160.396C240.532 173.865 215.757 164.982 206.613 133.579L201.398 136.823C201.398 136.823 208.592 177.61 237.55 179.108C237.55 179.108 255.626 182.768 261.116 160.587C261.116 160.587 262.53 156.411 262.614 154.05L262.996 151.394C263.055 149.034 262.74 146.68 262.063 144.419L262.014 144.348Z"
      fill="white"
    />
    <path
      d="M261.949 144.348C261.509 142.653 260.099 141.384 258.367 141.125C256.635 140.866 254.915 141.668 253.999 143.161C250.627 148.621 247.787 154.392 245.52 160.396C240.467 173.864 215.692 164.982 206.548 133.579L201.333 136.822C201.333 136.822 208.527 177.61 237.485 179.108C237.485 179.108 255.561 182.768 261.052 160.587C261.052 160.587 262.465 156.41 262.55 154.05L262.931 151.393C262.99 149.034 262.675 146.68 261.999 144.419L261.949 144.348Z"
      fill="#54D62C"
    />
    <path
      d="M205.228 134.37C205.228 134.37 204.698 127.65 201.928 127.664C199.158 127.678 192.65 132.653 202.698 135.939L205.228 134.37Z"
      fill="#F4A28C"
    />
    <g filter="url(#filter0_d_5060_358305)">
      <ellipse
        opacity="0.35"
        cx="85.5103"
        cy="65.4864"
        rx="29.7489"
        ry="29.7489"
        fill="#FFDE7C"
      />
      <ellipse
        cx="85.51"
        cy="65.4864"
        rx="15.6041"
        ry="15.6041"
        fill="url(#paint5_linear_5060_358305)"
      />
      <path
        opacity="0.24"
        d="M271.528 73.7999C271.528 70.7562 269.061 68.2887 266.017 68.2887C265.719 68.2885 265.422 68.3131 265.128 68.3621C263.839 65.9577 261.334 64.4548 258.606 64.4489H258.337C259.223 60.8877 257.812 57.1501 254.793 55.0634C251.774 52.9766 247.779 52.9766 244.761 55.0634C241.742 57.1501 240.331 60.8877 241.217 64.4489H240.948C238.234 64.359 235.687 65.7553 234.304 68.0915C232.92 70.4277 232.92 73.3322 234.304 75.6684C235.687 78.0046 238.234 79.401 240.948 79.3111H266.734V79.2622C269.475 78.8988 271.523 76.5642 271.528 73.7999Z"
        fill="#00AB55"
      />
      <path
        opacity="0.24"
        d="M48.9911 120.927C48.9911 119.673 48.4929 118.47 47.6061 117.583C46.7193 116.697 45.5166 116.198 44.2625 116.198C44.0086 116.203 43.7553 116.225 43.5043 116.264C42.3925 114.197 40.234 112.909 37.8872 112.913H37.6589C38.4273 109.856 37.2201 106.643 34.6289 104.849C32.0377 103.054 28.6055 103.054 26.0142 104.849C23.423 106.643 22.2158 109.856 22.9842 112.913H22.756C20.4254 112.83 18.2352 114.025 17.0452 116.031C15.8552 118.037 15.8552 120.532 17.0452 122.538C18.2352 124.543 20.4254 125.739 22.756 125.656H44.874V125.615C47.227 125.304 48.9869 123.3 48.9911 120.927Z"
        fill="#00AB55"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5060_358305"
        x="4.15266"
        y="23.7375"
        width="303.375"
        height="137.922"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="12" dy="12" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5060_358305"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5060_358305"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_5060_358305"
        x1="224.563"
        y1="282.688"
        x2="340.493"
        y2="15.4176"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#00AB55" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5060_358305"
        x1="52.2974"
        y1="94.9988"
        x2="271.242"
        y2="209.447"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#007B55" />
        <stop offset="0.286458" stopColor="#00AB55" />
        <stop offset="0.447917" stopColor="#007B55" />
        <stop offset="0.572917" stopColor="#00AB55" />
        <stop offset="0.713542" stopColor="#007B55" />
        <stop offset="0.901042" stopColor="#00AB55" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5060_358305"
        x1="52.2974"
        y1="94.9988"
        x2="271.242"
        y2="209.447"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#007B55" />
        <stop offset="0.286458" stopColor="#00AB55" />
        <stop offset="0.447917" stopColor="#007B55" />
        <stop offset="0.572917" stopColor="#00AB55" />
        <stop offset="0.713542" stopColor="#007B55" />
        <stop offset="0.901042" stopColor="#00AB55" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5060_358305"
        x1="52.2974"
        y1="94.9988"
        x2="271.242"
        y2="209.447"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.125" stopColor="#007B55" />
        <stop offset="0.286458" stopColor="#00AB55" />
        <stop offset="0.447917" stopColor="#007B55" />
        <stop offset="0.572917" stopColor="#00AB55" />
        <stop offset="0.713542" stopColor="#007B55" />
        <stop offset="0.901042" stopColor="#00AB55" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5060_358305"
        x1="262.545"
        y1="127.666"
        x2="262.545"
        y2="179.252"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8EE847" />
        <stop offset="1" stopColor="#8CFF31" stopOpacity="0.16" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5060_358305"
        x1="54.3018"
        y1="65.4864"
        x2="85.51"
        y2="96.6946"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE16A" />
        <stop offset="1" stopColor="#DDAA33" />
      </linearGradient>
    </defs>
  </svg>
);

export default ProVersion;
