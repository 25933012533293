import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
type EditorProps = {
  editorState: EditorState;
  onEditorStateChange: (value: EditorState) => void;
};

function EditorComponent({ editorState, onEditorStateChange }: EditorProps) {
  const theme = useTheme();
  return (
    <Editor
      editorState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      toolbarClassName="toolbar-class"
      onEditorStateChange={onEditorStateChange}
      editorStyle={{
        border: `1px solid ${theme.palette.divider}`,
        minHeight: '160px',
        padding: '0px 12px',
      }}
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'fontFamily',
          'list',
          'textAlign',
          'emoji',
          'image',
          'remove',
          'history',
        ],
      }}
    />
  );
}

export default memo(EditorComponent);
