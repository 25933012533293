import { PayloadActionCreator } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

export type SearchOrderProps = {
  orderBy: OrderBy;
  order: OrderDirection;
  onSort(
    nextOrderBy: string,
    prev: { lastOrderBy: OrderBy; lastOrder: OrderDirection }
  ): void;
};

type SearchOrderHookOptions = {
  selectResult: (state: ExpectedAny) => {
    orderBy: OrderBy;
    order: OrderDirection;
  };
  actions: {
    setOrder: PayloadActionCreator<[OrderBy, OrderDirection]>;
  };
};

const createSearchOrderHook = ({
  selectResult,
  actions,
}: SearchOrderHookOptions) => {
  const useSearchOrder = () => {
    const dispatch = useAppDispatch();
    const { order, orderBy } = useAppSelector(selectResult);

    const onSort = useCallback(
      (
        nextOrderBy: string,
        prev: { lastOrderBy: OrderBy; lastOrder: OrderDirection }
      ) => {
        if (prev.lastOrderBy === nextOrderBy) {
          switch (prev.lastOrder) {
            case 'asc':
              dispatch(actions.setOrder([nextOrderBy, 'desc']));
              return;
            case 'desc':
              dispatch(actions.setOrder([null, null]));
              return;
          }
        } else {
          dispatch(actions.setOrder([nextOrderBy, 'asc']));
        }
      },
      [dispatch]
    );

    return { order, orderBy, onSort };
  };

  return useSearchOrder;
};

export default createSearchOrderHook;
