const NotePen = ({ className }: { className?: string }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.7292 9.16667C16.2689 9.16667 15.8958 9.53976 15.8958 10V15C15.8958 15.4602 15.5227 15.8333 15.0625 15.8333H5.0625C4.60226 15.8333 4.22917 15.4602 4.22917 15V5C4.22917 4.53976 4.60226 4.16667 5.0625 4.16667H10.0625C10.5227 4.16667 10.8958 3.79357 10.8958 3.33333C10.8958 2.8731 10.5227 2.5 10.0625 2.5H5.0625C3.68179 2.5 2.5625 3.61929 2.5625 5V15C2.5625 16.3807 3.68179 17.5 5.0625 17.5H15.0625C16.4432 17.5 17.5625 16.3807 17.5625 15V10C17.5625 9.53976 17.1894 9.16667 16.7292 9.16667Z"
      fill="#1890FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3107 2.00297L18.0232 3.71549C18.502 4.17159 18.5216 4.92904 18.067 5.40925L12.4419 11.0343C12.2382 11.2363 11.9712 11.3621 11.6857 11.3906L9.07939 11.6281H9.02314C8.85702 11.629 8.69734 11.5638 8.57939 11.4468C8.44754 11.3155 8.38109 11.1322 8.39814 10.9468L8.66689 8.34053C8.69533 8.05503 8.82112 7.78801 9.02314 7.58428L14.6482 1.95922C15.1331 1.54956 15.848 1.56837 16.3107 2.00297ZM14.2241 4.12801L15.8991 5.80302L17.1491 4.58426L15.4428 2.87799L14.2241 4.12801Z"
      fill="#1890FF"
    />
  </svg>
);

export default NotePen;
