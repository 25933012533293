import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import vn from '../i18n/vn';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: { vn },
    lng: 'vn',
    defaultNS: 'app',
    fallbackNS: 'default',
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
