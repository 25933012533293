import { Buffer } from 'buffer';
import crypto from 'crypto';
import { assert } from 'ts-essentials';
import { SECRET_KEY } from '../config/env';

const algorithm = 'aes-256-gcm';
const IV_LEN = 12;
const AUTH_TAG_LEN = 16;

export function encrypt(message: string) {
  assert(SECRET_KEY, 'SECRET_KEY can not be null');
  const iv = Buffer.from(crypto.randomBytes(IV_LEN));
  const cipher = crypto.createCipheriv(algorithm, SECRET_KEY, iv, {
    authTagLength: AUTH_TAG_LEN,
  });
  const encryptedData = Buffer.concat([cipher.update(message), cipher.final()]);
  const authTag = cipher.getAuthTag();

  const raw = Buffer.concat([iv, encryptedData, authTag]).toString('hex');
  return raw;
}
