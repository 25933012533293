const DeliveringOrder = ({
  className,
  width = 112,
  height = 112,
  opacity = 0.3,
  fill = 'white',
  fillOpacity = 0.48,
}: {
  className?: string;
  width?: number;
  height?: number;
  opacity?: number;
  fill?: string;
  fillOpacity?: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g opacity={opacity}>
      <path
        d="M98.3761 16.3352H79.5754L79.5596 16.332H59.9222L59.9064 16.3352H17.0811C14.9997 16.3352 13.3119 17.9298 13.3119 19.8961C13.3119 21.8624 14.9997 23.457 17.0811 23.457H56.153V44.8906C56.153 46.849 57.8492 48.4514 59.9222 48.4514H79.5596C81.6326 48.4514 83.3288 46.849 83.3288 44.8906V23.457H98.3761C100.929 23.457 103.005 25.4183 103.005 27.8297V84.1709C103.005 86.5823 100.929 88.5436 98.3761 88.5436H38.7391C36.1881 88.5436 34.1124 86.5823 34.1124 84.1709V45.0291C34.1124 43.0628 32.4246 41.4682 30.3433 41.4682H20.3776C18.2962 41.4682 16.6084 43.0628 16.6084 45.0291C16.6084 46.9954 18.2962 48.59 20.3776 48.59H26.5741V84.1709C26.5741 90.5085 32.0311 95.6654 38.7391 95.6654H98.3761C105.084 95.6654 110.543 90.5085 110.543 84.1709V27.8301C110.543 21.4924 105.084 16.3352 98.3761 16.3352Z"
        fill={fill}
        fillOpacity={fillOpacity}
        stroke="white"
        strokeOpacity="0.48"
        strokeWidth="0.25"
      />
      <path
        d="M5.22754 36.0202H28.4848C30.5661 36.0202 32.254 34.4256 32.254 32.4593C32.254 30.493 30.5661 28.8984 28.4848 28.8984H5.22754C3.14621 28.8984 1.45837 30.493 1.45837 32.4593C1.45837 34.4256 3.14583 36.0202 5.22754 36.0202Z"
        fill={fill}
        fillOpacity={fillOpacity}
        stroke="white"
        strokeOpacity="0.48"
        strokeWidth="0.25"
      />
      <path
        d="M91.3091 81.8014C93.3904 81.8014 95.0783 80.2069 95.0783 78.2406C95.0783 76.2742 93.3904 74.6797 91.3091 74.6797H79.538C77.4566 74.6797 75.7688 76.2742 75.7688 78.2406C75.7688 80.2069 77.4566 81.8014 79.538 81.8014H91.3091Z"
        fill={fill}
        fillOpacity={fillOpacity}
        stroke="white"
        strokeOpacity="0.48"
        strokeWidth="0.25"
      />
    </g>
  </svg>
);

export default DeliveringOrder;
