import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CommingSoon from '../Icons/CommingSoon';

type ConfirmationProps = {
  open: boolean;
  onClose(
    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>
  ): void;
};

const CommingSoonDialog = ({ open, onClose }: ConfirmationProps) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle color={theme.palette.grey[800]} sx={{ textAlign: 'center' }}>
        <Typography variant="h3">Tính năng sắp ra mắt!</Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: theme.spacing(2), textAlign: 'center' }}>
        <Typography variant="body1" color={theme.palette.grey[600]}>
          Chúng tôi đang phát triển tính năng này thật hoàn chỉnh. Hãy đón chờ
          nhé!
        </Typography>
        <CommingSoon />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Quay lại
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommingSoonDialog;
