const Contact = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.48"
      d="M16.0637 9.38135C17.7903 9.38135 19.1899 7.98169 19.1899 6.25513C19.1899 4.52856 17.7903 3.12891 16.0637 3.12891C14.3372 3.12891 12.9375 4.52856 12.9375 6.25513C12.9375 7.98169 14.3372 9.38135 16.0637 9.38135Z"
      fill="#637381"
    />
    <path
      d="M20.7525 17.1978C21.1841 17.1978 21.5341 16.8478 21.5341 16.4162C21.5341 13.3947 19.0847 10.9453 16.0632 10.9453C13.0417 10.9453 10.5923 13.3947 10.5923 16.4162C10.5923 16.8478 10.9422 17.1978 11.3738 17.1978H20.7525Z"
      fill="#637381"
    />
    <path
      opacity="0.48"
      d="M8.00084 12.6667C9.89438 12.6667 11.4294 11.1743 11.4294 9.33333C11.4294 7.49238 9.89438 6 8.00084 6C6.10729 6 4.57227 7.49238 4.57227 9.33333C4.57227 11.1743 6.10729 12.6667 8.00084 12.6667Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1524 14.8385C10.0163 15.4226 9.96097 16.0642 10 16.7626C10 17.2612 10.4158 17.8573 10.9286 17.8573L13.2683 17.866C13.6602 18.5946 13.9186 19.38 14 20.1654C14 20.6256 13.6162 20.9987 13.1429 20.9987H2.85714C2.38376 20.9987 2 20.6256 2 20.1654C2 16.9437 4.68629 14.332 8 14.332C8.74265 14.332 9.4719 14.5163 10.1524 14.8385Z"
      fill="#637381"
    />
  </svg>
);

export default Contact;
