import { DateRange } from '@mui/lab/DateRangePicker';
import {
  createSelector,
  createSlice,
  PayloadAction,
  combineReducers,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import { PURGE } from 'redux-persist';
import authService from '../../services/auth';
import orderService from '../../services/order';
import paymentService from '../../services/payment';
import type { RootState } from '../../store/rootReducer';
import createSearchSlice from '../../utils/createSearchSlice';
import { selectTypeInit } from './utils';

export const selectPageOrders = (state: RootState) => state.page_orders;

export const selectLocalSearchResult = createSelector(
  selectPageOrders,
  ({ search }) => search
);

type OrderPagesState = {
  selectedStatusId: string;
  dateRange: DateRange<Date>;
  orderAnalysis: ExpectedAny;
  orderAnalysisState: string;
  statusType: ExpectedAny[];
  orderPaymentDialog: Order | null;
  paymentMethod: PaymentMethod[];
  paymentMethodState: string;
  getOrdersAgain: ExpectedAny;
  configInvoice: ConfigInvoice | null;
  configInvoiceState: string;
  paymentsInfo: PaymentInfo[] | null;
  paymentsInfoState: string;
  arraySelected: string[];
  openDrawer: boolean;
};

const initialState: OrderPagesState = {
  selectedStatusId: '',
  dateRange: [null, null],
  orderAnalysis: {},
  orderAnalysisState: 'loading',
  statusType: [],
  orderPaymentDialog: null,
  paymentMethod: [],
  paymentMethodState: 'loading',
  getOrdersAgain: null,
  configInvoice: null,
  configInvoiceState: 'loading',
  paymentsInfo: null,
  paymentsInfoState: 'loading',
  arraySelected: [],
  openDrawer: false,
};

const name = 'page_orders' as const;

const getOrderAnalysis = createAsyncThunk(
  'page_orders/getOrderAnalysis/get',
  async () => {
    const business_id = await authService.getBusinessId();
    const orderAnalysis = await orderService.getOrderAnalysis(
      business_id || ''
    );

    if (!orderAnalysis) {
      throw new Error('NO_ORDERANALYSIS');
    }

    return orderAnalysis;
  }
);

const getPaymentMethod = createAsyncThunk(
  'page_orders/getPaymentMethod/get',
  async () => {
    const business_id = await authService.getBusinessId();
    const paymentMethod = await orderService.getPaymentMethod(
      business_id || ''
    );

    if (!paymentMethod) {
      throw new Error('NO_PRODUCT');
    }

    return paymentMethod;
  }
);

const getConfigInvoice = createAsyncThunk(
  'page_orders/getConfigInvoice',
  async (phone: string) => {
    const config = await orderService.getConfigInvoice(phone);

    if (!config) {
      throw new Error('NO_CONFIG_INVOICE');
    }

    return config;
  }
);

const getPaymentsInfo = createAsyncThunk(
  'page_orders/getPaymentsInfo',
  async () => {
    const paymentsInfo = await paymentService.getPaymentsInfo();

    if (!paymentsInfo) {
      throw new Error('NO_PAYMENTS_INFO');
    }

    return paymentsInfo;
  }
);

const mainSlice = createSlice({
  name,
  initialState,
  reducers: {
    selectStatus: (state, action: PayloadAction<string>) => {
      state.selectedStatusId = action.payload;
    },

    setDateRange: (state, action: PayloadAction<DateRange<Date>>) => {
      state.dateRange = action.payload;
    },

    setStatusType: (state, action: PayloadAction<ExpectedAny[]>) => {
      state.statusType = action.payload;
    },

    setOrderPaymentDialog: (state, action: PayloadAction<Order | null>) => {
      state.orderPaymentDialog = action.payload;
    },

    setEmptyArraySelected: (state) => {
      state.arraySelected = [];
    },

    setOpenDrawer: (state) => {
      state.openDrawer = !state.openDrawer;
    },

    setArraySelected: (
      state,
      action: PayloadAction<
        [number | null, string | null, Order[] | null, boolean]
      >
    ) => {
      if (action.payload[2]) {
        [...state.arraySelected].every(Boolean) && action.payload[3]
          ? (state.arraySelected = [])
          : action.payload[2].map((value, index) => {
              if (!state.arraySelected[index]) {
                state.arraySelected[index] = value.id;
              }
            });
      } else if (action.payload[1] && action.payload[0] !== null) {
        state.arraySelected[action.payload[0]]
          ? (state.arraySelected[action.payload[0]] = '')
          : (state.arraySelected[action.payload[0]] = action.payload[1]);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);

    builder
      .addCase(getOrderAnalysis.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderAnalysis = action.payload;
          state.statusType = selectTypeInit(action.payload);
        }
      })
      .addCase(getOrderAnalysis.rejected, (state) => {
        state.orderAnalysisState = 'notFound';
      });

    builder
      .addCase(getPaymentMethod.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentMethod = action.payload.reverse();
        }
      })
      .addCase(getPaymentMethod.rejected, (state) => {
        state.paymentMethodState = 'notFound';
      });

    builder
      .addCase(getConfigInvoice.fulfilled, (state, action) => {
        if (action.payload) {
          state.configInvoice = action.payload;
        }
      })
      .addCase(getConfigInvoice.rejected, (state) => {
        state.configInvoiceState = 'notFound';
      });
    builder
      .addCase(getPaymentsInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentsInfo = action.payload;
        }
      })
      .addCase(getPaymentsInfo.rejected, (state) => {
        state.paymentsInfoState = 'notFound';
      });
  },
});

export const selectedLocalSelectedStatus = createSelector(
  selectPageOrders,
  ({ main: { selectedStatusId, statusType } }) =>
    statusType.find((s) => s.id === selectedStatusId)
);

const searchSlice = createSearchSlice(name, {
  handleSearch: async (arg: OrdersParams) => {
    const { orders, meta } = await orderService.getOrders({
      ...arg,
    });
    return [orders, meta.total_rows];
  },
});

export const pageOrdersActions = {
  ...mainSlice.actions,
  ...searchSlice.actions,
  getOrderAnalysis,
  getPaymentMethod,
  getConfigInvoice,
  getPaymentsInfo,
};

const pageOrdersSlice = {
  name,
  reducer: combineReducers({
    main: mainSlice.reducer,
    search: searchSlice.reducer,
  }),
};

export default pageOrdersSlice;
