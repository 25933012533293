import { styled } from '@mui/system';
import { DialogTitle, DialogContent, Box } from '@mui/material';

export const DialogTitleStyle = styled(DialogTitle)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 600,
}));

export const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  mb: 2,
}));

export const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  width: '100%',
}));
