import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Header from '../../components/Header';
import NavbarVertical from '../../components/NavbarVertical';
import { HEADER, NAVBAR } from '../../config/styles';
import useAppSelector from '../../hooks/useAppSelector';
import { selectApp } from '../../store/app';

type MainLayoutProps = {
  height?: string;
  children: React.ReactNode;
  paddingVertical?: string;
  fullWidth?: boolean;
};

type MainStyleProps = {
  open: boolean;
  paddingVertical?: string;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<MainStyleProps>(({ open, paddingVertical, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.LAYOUT_HEIGHT,
  paddingLeft: 16,
  paddingRight: 16,
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: paddingVertical || HEADER.DASHBOARD_DESKTOP_HEIGHT,
    paddingBottom: paddingVertical || HEADER.LAYOUT_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(!open && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const MainLayout = ({
  children,
  paddingVertical,
  fullWidth = false,
}: MainLayoutProps) => {
  const { sidebar } = useAppSelector(selectApp);
  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        background: (theme) => theme.palette.common.white,
      }}
    >
      <Header />
      <NavbarVertical />
      <MainStyle open={sidebar.open} paddingVertical={paddingVertical}>
        <Container maxWidth={fullWidth ? false : 'lg'}>{children}</Container>
      </MainStyle>
    </Box>
  );
};

export default MainLayout;
