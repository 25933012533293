import styled from '@emotion/styled';
import { TableRow, TableCell, Typography, Avatar, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { numberFormat } from '../../utils/constants';
import { getOptimizeImage } from '../../utils/imageUtils';
import { getProductStock } from '../../utils/productUtils';

const OverflowText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Inventory = ({ product }: { product: SkuMixed }) => {
  const theme = useTheme();
  const stock = getProductStock(product);
  if (product.sku_type === 'stock') {
    return (
      <TableCell
        align="right"
        sx={{
          color: stock ? theme.palette.primary.main : theme.palette.error.main,
        }}
      >
        {stock}
      </TableCell>
    );
  }
  if (stock) {
    return (
      <TableCell align="right" sx={{ color: theme.palette.primary.main }}>
        Còn hàng
      </TableCell>
    );
  }
  return (
    <TableCell align="right" sx={{ color: theme.palette.error.main }}>
      Hết hàng
    </TableCell>
  );
};

const ProductsRow = ({
  product,
  index,
}: {
  product: SkuMixed;
  index: number;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isVariant = !product.type.match(/non_variant/);

  return (
    <TableRow
      hover
      sx={{
        backgroundColor: index % 2 != 0 ? '#919eab14' : 'white',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/products/${product.product_id}`)}
    >
      <TableCell align="left">{product.sku_code}</TableCell>
      <TableCell align="left" width={40}>
        <Avatar
          alt={product.name}
          src={getOptimizeImage(
            product?.media[0] ||
              product.product_images?.[0] ||
              '/assets/default-avatar.png',
            { w: 128, h: 128, fit: 'crop' }
          )}
          sx={{
            borderRadius: 1.5,
            width: 46,
            height: 46,
          }}
          variant="rounded"
        />
      </TableCell>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          width="280px"
        >
          <OverflowText variant="subtitle2" fontWeight={500} noWrap>
            {product.product_name}
          </OverflowText>
          {isVariant && (
            <OverflowText
              variant="subtitle2"
              fontSize={12}
              fontWeight={500}
              color={theme.palette.grey[600]}
              noWrap
            >
              {product.name}
            </OverflowText>
          )}
        </Box>
      </TableCell>
      <TableCell align="right">
        {numberFormat.format(product.normal_price || 0)}
      </TableCell>
      <TableCell align="right">
        {numberFormat.format(product.historical_cost || 0)}
      </TableCell>
      <TableCell align="right">
        {product.can_pick_quantity > 0 ? product.can_pick_quantity : '-'}
      </TableCell>
      <Inventory product={product} />
    </TableRow>
  );
};

export default memo(ProductsRow);
