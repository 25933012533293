import { Typography, Box, styled, Card, Button, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo, useEffect } from 'react';
import Scrollbar from '../../minimal/Scrollbar';
import Iconify from '../../minimal/Iconify';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageContactEdit, pageContactEditActions } from './slice';
import { numberFormat, ORDER_STATUS } from '../../utils/constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import EmptyOrderState from './EmptyOrderState';
import { useParams, useLocation } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';

const Step = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

const Dot = styled(Box)(({ theme }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  margin: theme.spacing(0.5, 2, 1.5),
}));

const Divider = styled(Box)(({ theme }) => ({
  width: 1,
  height: 36,
  backgroundColor: theme.palette.divider,
  margin: `0 auto ${theme.spacing(1)}`,
}));

const OrderTracking = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    ordersContact,
    stateOrdersContact,
    editing: contact,
  } = useAppSelector(selectPageContactEdit);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const businessId = search.slice(13);

  useEffect(() => {
    if (id) {
      dispatch(
        pageContactEditActions.getContactOrders({
          business_id: businessId,
          contact_id: id,
          page: 1,
          page_size: 100,
        })
      );
    }
  }, [dispatch, businessId, id]);

  const renderBgColor = (type: string) => {
    switch (type) {
      case ORDER_STATUS.COMPLETE:
      case ORDER_STATUS.DELIVERING:
        return theme.palette.primary.main;
      case ORDER_STATUS.WAITING_CONFIRM:
        return theme.palette.warning.main;
      default:
        return theme.palette.error.main;
    }
  };

  return (
    <Card sx={{ p: 2 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h6" fontWeight={600}>
          Đơn hàng
        </Typography>
        <Button
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => navigate(`/pos?contact_id=${contact?.id}`)}
        >
          Tạo mới đơn hàng
        </Button>
      </Box>

      <Scrollbar sx={{ height: 232 }}>
        {stateOrdersContact === 'loading' && (
          <Box sx={{ height: 232 }}>
            {[1, 2, 3].map((value) => (
              <Box
                key={value}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  mt: 2,
                }}
              >
                <Step>
                  <Box>
                    <Skeleton animation="wave" width={50} height={24} />
                    <Skeleton animation="wave" width={50} height={24} />
                  </Box>
                  <Box>
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      width={10}
                      height={10}
                    />
                    <Divider />
                  </Box>
                  <Box>
                    <Skeleton animation="wave" width={150} height={24} />
                    <Skeleton animation="wave" width={200} height={24} />
                  </Box>
                </Step>
                <Box sx={{ float: 'right', textAlign: 'right' }}>
                  <Skeleton animation="wave" width={50} height={24} />
                  <Skeleton animation="wave" width={90} height={24} />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {stateOrdersContact !== 'loading' && ordersContact.length == 0 ? (
          <EmptyOrderState classCus={{ height: 150, mt: 10 }} />
        ) : (
          ordersContact.map((step) => (
            <Box key={step.id} display="flex" justifyContent="space-between">
              <Step>
                <Box>
                  <Typography variant="body2">
                    {moment(step.updated_at).format('DD/MM')}
                  </Typography>
                  <Typography variant="body2" color={theme.palette.grey[600]}>
                    {moment(step.updated_at).format('HH:mm')}
                  </Typography>
                </Box>
                <Box>
                  <Dot bgcolor={renderBgColor(step.state)} />
                  <Divider />
                </Box>

                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    {t('order_state', { context: step.state })}
                  </Typography>

                  <Typography variant="body2">
                    Mã đơn hàng:
                    <Button
                      onClick={() => navigate(`/orders/${step.order_number}`)}
                      variant="text"
                      sx={{ color: theme.palette.grey[600], fontWeight: '500' }}
                    >
                      {step.order_number}
                    </Button>
                  </Typography>
                </Box>
              </Step>
              <Box textAlign="right">
                <Typography
                  variant="body2"
                  color={theme.palette.grey[600]}
                  sx={{ mb: 0.5 }}
                >
                  {step.order_item.length} sản phẩm
                </Typography>
                <Typography variant="subtitle2">
                  {numberFormat.format(step.grand_total || 0)}
                </Typography>
              </Box>
            </Box>
          ))
        )}
      </Scrollbar>
    </Card>
  );
};

export default memo(OrderTracking);
