import promotionService from '../../services/promotion';
import noAccents from '../../utils/encoding';
import configOffline from '../../utils/offline/configOffline';

export const promotionOffline = configOffline({
  name: 'cache_promotion',
  fetchChange: (lastUpdate) => promotionService.getPromotions(lastUpdate),
  makeSearchData: (item: Promotion) => ({
    id: item.id,
    name: noAccents(item.name),
    promotion_code: item.promotion_code,
  }),
  searchFields: ['name', 'promotion_code'],
});
