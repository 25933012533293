import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import _orderBy from 'lodash/orderBy';
import { productOffline } from '../../config/offline/product';
import { selectAppProduct, selectCacheSkus } from '../../store/product';
import { RootState } from '../../store/rootReducer';
import createSearchSlice from '../../utils/createSearchSlice';
import getOfflineDataByIds from '../../utils/offline/getOfflineDataByIds';
import searchOfflineIds from '../../utils/offline/searchOfflineIds';
import { selectLocalSearchResult } from '../Products/slice';
// import { selectAppContact } from '../../store/contact';
// import { selectLocalSearchResultContacts } from '../Contacts/slice';
import orderService from '../../services/order';
import { canPickItem } from '../../utils/orderUtils';
import { isAdvanceStock } from '../../utils/productUtils';

export const selectPageOrderDetail = (state: RootState) =>
  state.page_order_detail;

export const selectLocalOrderItem = createSelector(
  selectCacheSkus,
  selectPageOrderDetail,
  (skus, { main: { orderDetail } }) => {
    const listOrderItem = (orderDetail?.order_item || []).map((product) => {
      const sku = skus.find((value) => value.id == product.sku_id);
      return {
        sku_code: product.sku_code,
        sku_id: product.sku_id,
        product_name: product.product_name,
        sku_name: product.sku_name,
        uom: product.uom,
        normal_price: product.product_normal_price,
        quantity: product.quantity,
        total_amount: product.total_amount,
        price: product.price,
        product_images: product.product_images || product.media,
        product_normal_price: product.product_normal_price,
        product_selling_price: product.product_selling_price,
        wholesale_price: product.wholesale_price,
        note: product.note,
        media: product.media,
        type: sku?.type || '',
        can_pick_quantity: sku?.can_pick_quantity || 0,
      };
    });

    const productRemoved =
      listOrderItem && listOrderItem.filter((value) => !value.type);

    const buyerInfo: BuyerInfo = {
      phone_number: orderDetail?.buyer_info?.phone_number,
      name: orderDetail?.buyer_info?.name,
      address: orderDetail?.buyer_info?.address,
    };
    const createOrderInputCus: CreateOrderInput = {
      promotion_code: orderDetail?.promotion_code,
      ordered_grand_total: orderDetail?.ordered_grand_total,
      promotion_discount: orderDetail?.promotion_discount,
      delivery_fee: orderDetail?.delivery_fee,
      grand_total: orderDetail?.grand_total,
      note: orderDetail?.note,
      email: orderDetail?.email,
      buyer_info: buyerInfo,
      list_order_item: listOrderItem,
      paymentType: orderDetail?.payment_method,
      shippingType: '',
      other_discount: orderDetail?.other_discount,
      delivery_method: orderDetail?.delivery_method,
    };

    return {
      createOrderInputCus: createOrderInputCus,
      listOrderItem: listOrderItem,
      removeProductOrder: productRemoved,
    };
  }
);

export const selectLocalSearchProducts = createSelector(
  selectAppProduct,
  selectLocalSearchResult,
  selectPageOrderDetail,
  (
    cacheProduct,
    { rows: ids, order, orderBy },
    { main: { selectedOrderBy } }
  ) => {
    const searchProducts = getOfflineDataByIds(ids, cacheProduct);
    const skus = searchProducts
      .map((product) =>
        product.list_sku.map((sku) => {
          const isStock = isAdvanceStock(sku);
          const canPick = canPickItem(sku, isStock);

          return {
            ...sku,
            product_id: product.id,
            product_name: product.name,
            sold_quantity: product.sold_quantity,
            uom: product.uom,
            type: `${sku.sku_type}_${product.product_type}`,
            disabled: !canPick ? true : false,
          };
        })
      )
      .reduce((a, b) => a.concat(b), []);

    const sortedProducts = _orderBy(
      skus,
      [orderBy || selectedOrderBy],
      [order || 'desc']
    );

    return sortedProducts;
  }
);

// export const selectLocalSearchContacts = createSelector(
//   selectAppContact,
//   selectLocalSearchResultContacts,
//   (cacheContact, { rows: ids }) => {
//     const contacts = getOfflineDataByIds(ids, cacheContact);

//     return contacts;
//   }
// );

const name = 'page_order_detail' as const;

const fetchOrderDetail = createAsyncThunk(
  'page_order_detail/getOrderDetail/get',
  async (number: string) => {
    const order = await orderService.getOrder(number);

    if (!order) {
      throw new Error('NO_PRODUCT');
    }

    return order;
  }
);

const fetchOrderTracking = createAsyncThunk(
  'page_order_detail/getOrderTracking/get',
  async (order_id: string) => {
    const orderTracking = await orderService.getOrderTracking(order_id);

    if (!orderTracking) {
      throw new Error('NO_PRODUCT');
    }

    return orderTracking;
  }
);

const getPaymentMethod = createAsyncThunk(
  'page_order_detail/getPaymentMethod/get',
  async (business_id: string) => {
    const paymentMethod = await orderService.getPaymentMethod(business_id);

    if (!paymentMethod) {
      throw new Error('NO_PRODUCT');
    }

    return paymentMethod;
  }
);

const getCustomPaid = createAsyncThunk(
  'page_order_detail/getCustomPaid/get',
  async ({
    business_id,
    order_id,
  }: {
    business_id: string;
    order_id: string;
  }) => {
    const customerPaid = await orderService.getCustomPaid(
      business_id,
      order_id
    );

    if (!customerPaid) {
      throw new Error('NO_PRODUCT');
    }

    return customerPaid;
  }
);

type PageOrderDetailSliceState = {
  orderDetail: Order;
  state: string;
  orderTracking: OrderTracking[];
  paymentMethod: PaymentMethod[];
  customerPaid: PaymentOrderHistory[];
  createOrderInput: CreateOrderInput;
  otherDiscountUnit: string;
  isEdit: boolean;
  selectedOrderBy: string;
  productCheck: ExpectedAny[];
  submitResponse: CreateOrderResponse | null;
  orderPaymentDialog: Order | null;
  paymentMethodState: string;
  contactDetail: Contact | null;
};

const initialState: PageOrderDetailSliceState = {
  orderDetail: <Order>{},
  state: 'loading',
  orderTracking: [],
  paymentMethod: [],
  customerPaid: [],
  createOrderInput: <CreateOrderInput>{},
  otherDiscountUnit: 'cash',
  isEdit: false,
  selectedOrderBy: 'created_at',
  productCheck: [],
  submitResponse: null,
  orderPaymentDialog: null,
  paymentMethodState: 'loading',
  contactDetail: null,
};

const mainSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,

    fillOrderInput: (state, action: PayloadAction<CreateOrderInput>) => {
      state.createOrderInput = action.payload;
    },

    setOtherDiscountUnit: (state, action: PayloadAction<string>) => {
      state.otherDiscountUnit = action.payload;
    },

    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },

    setSelectedOrderBy: (state, action: PayloadAction<string>) => {
      state.selectedOrderBy = action.payload;
    },

    setProductCheck: (state, action: PayloadAction<ExpectedAny[]>) => {
      state.productCheck = action.payload;
    },

    setSubmitResponse: (
      state,
      action: PayloadAction<CreateOrderResponse | null>
    ) => {
      state.submitResponse = action.payload;
    },

    setOrderPaymentDialog: (state, action: PayloadAction<Order | null>) => {
      state.orderPaymentDialog = action.payload;
    },

    setContactDetail: (state, action: PayloadAction<Contact | null>) => {
      state.contactDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderDetail.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderDetail = action.payload;
          state.isEdit = false;
        }
      })
      .addCase(fetchOrderDetail.rejected, (state) => {
        state.state = 'notFound';
      });

    builder
      .addCase(fetchOrderTracking.fulfilled, (state, action) => {
        if (action.payload) {
          state.orderTracking = action.payload;
        }
      })
      .addCase(fetchOrderTracking.rejected, (state) => {
        state.state = 'notFound';
      });

    builder
      .addCase(getPaymentMethod.fulfilled, (state, action) => {
        if (action.payload) {
          state.paymentMethod = action.payload.reverse();
        }
      })
      .addCase(getPaymentMethod.rejected, (state) => {
        state.state = 'notFound';
      });

    builder
      .addCase(getCustomPaid.fulfilled, (state, action) => {
        if (action.payload) {
          state.customerPaid = action.payload;
        }
      })
      .addCase(getCustomPaid.rejected, (state) => {
        state.state = 'notFound';
      });
  },
});

const productsSlice = createSearchSlice('page_order_detail/products', {
  handleSearch: async (query: FuseQuery, api) => {
    const ids = await searchOfflineIds(
      api.getState<ExpectedAny>(),
      query,
      productOffline
    );
    return [ids, ids.length];
  },
});

const searchProductsSlice = createSearchSlice(
  'page_order_detail/searchProducts',
  {
    handleSearch: async (query: FuseQuery, api) => {
      const ids = await searchOfflineIds(
        api.getState<ExpectedAny>(),
        query,
        productOffline
      );
      return [ids, ids.length];
    },
  }
);

export const pageOrderDetailActions = {
  ...mainSlice.actions,
  products: productsSlice.actions,
  fetchOrderDetail,
  fetchOrderTracking,
  getPaymentMethod,
  getCustomPaid,
};

const pageOrderDetailSlice = {
  name,
  reducer: combineReducers({
    main: mainSlice.reducer,
    products: productsSlice.reducer,
    searchProducts: searchProductsSlice.reducer,
  }),
} as const;

export default pageOrderDetailSlice;
