import { Formik } from 'formik';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import MainLayout from '../../layouts/MainLayout';
import { convertFromRaw, convertToRaw } from 'draft-js';
import { productActions } from '../../store/product';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import Loader from '../../components/Loader';
import NotFound from '../NotFound';
import ProductEditComponent from './ProductEdit';
import { pageProductEditActions, selectPageProductEdit } from './slice';
import { productSchema } from './utils';
import { v4 } from 'uuid';
import { pageProductsActions } from '../Products/slice';

const ProductEdit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    state,
    loading,
    editing: product,
  } = useAppSelector(selectPageProductEdit);
  const { id } = useParams<{ id?: string }>();

  useEffect(() => {
    if (id) {
      dispatch(pageProductEditActions.openProduct(id));
    } else {
      dispatch(pageProductEditActions.createProduct());
    }

    return () => {
      dispatch(pageProductEditActions.reset());
    };
  }, [dispatch, id]);

  if (state === 'notFound') {
    return <NotFound />;
  }

  if (!product || loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  return (
    <Formik<PendingProduct>
      initialValues={product}
      enableReinitialize
      validationSchema={productSchema}
      onSubmit={async (values) => {
        try {
          let valid = true;
          if (values.is_advance_stock && !values.is_variant) {
            valid = values.skus.every(
              (sku) =>
                sku.po_details &&
                (sku.po_details?.warning_value || 0) <= sku.po_details.quantity
            );
          }
          if (values.is_variant) {
            valid = values.skus.every((sku) => {
              if (sku.po_details) {
                return (
                  (sku.po_details?.warning_value || 0) <=
                  sku.po_details.quantity
                );
              }
              return true;
            });
          }
          if (!valid) return;

          if (id) {
            // check quantity sku before commit
            const skus: Array<PendingSku> = [];
            values.skus.map((sku) => {
              product.skus.map((pSku) => {
                if (sku.id === '') {
                  const exited = skus.find(
                    (item) => item.name.trim() === sku.name.trim()
                  );
                  if (!exited) {
                    skus.push({ ...sku, name: sku.name.trim() });
                  }
                } else if (
                  sku.id === pSku.id &&
                  sku.po_details &&
                  pSku.po_details
                ) {
                  if (sku.po_details.quantity === pSku.po_details.quantity) {
                    const existed = skus.find((item) => item.id === sku.id);
                    if (!existed) {
                      skus.push({
                        ...sku,
                        po_details: { ...sku.po_details, quantity: 0 },
                      });
                    }
                  } else {
                    skus.push({
                      ...sku,
                      po_details: {
                        ...sku.po_details,
                        quantity:
                          sku.po_details.quantity - pSku.po_details.quantity,
                      },
                    });
                  }
                }
              });
            });
            values.skus = skus;
          }

          const richText = convertFromRaw(JSON.parse(values.description_rtf));
          values.description = convertToRaw(richText).blocks[0]?.text || '';
          dispatch(pageProductEditActions.setLoading(true));

          if (!id) {
            values.client_id = v4();
          }
          await dispatch(
            productActions.commitProduct({ id, product: values })
          ).unwrap();
          dispatch(pageProductsActions.setPage(0));
          dispatch(
            toastActions.create({
              severity: 'success',
              message: values.id
                ? 'Lưu sản phẩm thành công'
                : 'Tạo sản phẩm thành công',
              dismissAfter: 3000,
            })
          );
          await dispatch(productActions.refresh()).unwrap();
          dispatch(pageProductEditActions.setLoading(false));
          navigate(-1);
        } catch (ex) {
          dispatch(pageProductEditActions.setLoading(false));
          dispatch(
            toastActions.create({
              severity: 'error',
              message: getErrorMessage(ex),
              dismissAfter: 3000,
            })
          );
        }
      }}
    >
      <ProductEditComponent />
    </Formik>
  );
};

export default ProductEdit;
