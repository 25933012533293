import {
  TableCell,
  TableRow,
  Avatar,
  Checkbox,
  Typography,
  Box,
  ClickAwayListener,
} from '@mui/material';
import { getOptimizeImage } from '../../utils/imageUtils';
import { useFormikContext } from 'formik';
import { FlexBetween, OverflowText } from './OrderDetailComponent';
import QuantityControl from '../../components/QuantityControl';
import { numberFormat } from '../../utils/constants';
import { getFieldProps } from '../../utils/formik';
import { useTheme } from '@mui/material/styles';
import { memo, useState } from 'react';
import TextFieldNumber from '../../components/TextFieldNumber';

type OrderItemRowProps = {
  product: list_order_item;
  index: number;
  handleCheckbox: ({
    event,
    product,
  }: {
    event: React.ChangeEvent<HTMLInputElement>;
    product: list_order_item;
  }) => void;
  isEdit: boolean;
};

const OrderItemRow = ({
  product,
  index,
  handleCheckbox,
  isEdit,
}: OrderItemRowProps) => {
  const formik = useFormikContext<CreateOrderInput>();
  const { list_order_item } = formik.values;
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState<list_order_item | null>(
    null
  );
  const handleRemove = () => {
    formik.setFieldValue('list_order_item', [
      ...list_order_item.slice(0, index),
      ...list_order_item.slice(index + 1, list_order_item.length),
    ]);
  };

  const handleOpenInput = (item: list_order_item) => {
    setSelectedItem(item);
  };

  return (
    <TableRow
      key={product.sku_id}
      sx={{
        backgroundColor: index % 2 != 0 ? '#919eab14' : 'white',
      }}
    >
      <TableCell align="left" width={40}>
        {isEdit ? (
          <Checkbox
            key={product.sku_id}
            inputProps={{ 'aria-label': 'order product checkbox' }}
            onChange={(e) => handleCheckbox({ event: e, product: product })}
            sx={{ pl: theme.spacing(1) }}
          />
        ) : (
          <>{index + 1}</>
        )}
      </TableCell>
      <TableCell align="left" width={40} sx={{ p: 0 }}>
        <Avatar
          alt={product.product_name}
          src={getOptimizeImage(
            `${
              product?.product_images?.[0] ||
              product?.media?.[0] ||
              '/assets/product-image.png'
            }`,
            {
              w: 128,
              h: 128,
              fit: 'crop',
            }
          )}
          sx={{
            borderRadius: 1.5,
            width: 46,
            height: 46,
          }}
          variant="rounded"
        />
      </TableCell>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <FlexBetween flexDirection="column" width="25vw">
          <OverflowText variant="subtitle2" fontWeight={500} noWrap>
            {product.product_name}
          </OverflowText>
          <OverflowText
            variant="subtitle2"
            fontSize={12}
            fontWeight={500}
            color={theme.palette.grey[600]}
            noWrap
          >
            {product.sku_name}
          </OverflowText>
        </FlexBetween>
      </TableCell>
      <TableCell align="left">{product.uom}</TableCell>
      <TableCell align="left">
        {isEdit ? (
          <>
            <QuantityControl
              {...getFieldProps(formik, `list_order_item.${index}.quantity`)}
              canPickQuantity={product.can_pick_quantity}
              isAdvanceStock={!product.type.match(/non_stock/)}
              onRemove={handleRemove}
            />
            {list_order_item[index]?.quantity === product.can_pick_quantity && (
              <Typography
                variant="caption"
                color={theme.palette.error.main}
                sx={{ mt: 0.5 }}
              >
                Đã đạt số lượng tối đa
              </Typography>
            )}
            {/* <Typography
                            fontSize={12}
                            color={theme.palette.grey[600]}
                            sx={{ mt: 0.5, textAlign: 'center' }}
                        >
                            Có thể bán: {product.can_pick_quantity || 0}
                        </Typography> */}
          </>
        ) : (
          <>{product.quantity}</>
        )}
      </TableCell>
      <TableCell align="right">
        <Box display="flex" alignItems="center">
          {isEdit && selectedItem && selectedItem.sku_id === product.sku_id ? (
            <ClickAwayListener onClickAway={() => setSelectedItem(null)}>
              <TextFieldNumber
                id={`list_order_item.${index}.price`}
                autoFocus
                variant="standard"
                textAlign="right"
                value={product.price}
                onSetField={(value) =>
                  formik.setFieldValue(`list_order_item.${index}.price`, value)
                }
                sx={{ width: '100%', '& input': { textAlign: 'right' } }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    setSelectedItem(null);
                  }
                }}
              />
            </ClickAwayListener>
          ) : (
            <Box width="100%" onClick={() => handleOpenInput(product)}>
              {numberFormat.format(product.price || 0)}
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell align="right">
        {numberFormat.format(product.price * product.quantity || 0)}
      </TableCell>
    </TableRow>
  );
};

export default memo(OrderItemRow);
