import pMemoize from 'p-memoize';
import { spawn } from 'threads';
import logger from '../logger';

type SearchWorker<T> = {
  init(keys: Array<string>): Promise<void>;
  replace(doc: T): Promise<void>;
  delete(id: string): Promise<void>;
  search(query: ExpectedAny): Promise<Array<string>>;
};

export const getSearchWorker = pMemoize(
  async <T>(_name: string, { keys }: { keys: Array<string> }) => {
    const instance = await spawn<SearchWorker<T>>(
      new Worker(new URL('../../fuse-worker', import.meta.url))
    );
    await instance.init(keys);
    logger.debug(`* Create FUSE instance, ${_name}: successfully`);
    return instance;
  }
);