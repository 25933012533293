import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { memo } from 'react';
import { useFormikContext } from 'formik';
import TextFieldNumber from '../../components/TextFieldNumber';

const BoxStyle = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const ProductPrice = () => {
  const formik = useFormikContext<PendingProduct>();
  return (
    <BoxStyle>
      <Typography sx={{ mb: 2 }}>Giá sản phẩm</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <TextFieldNumber
            id="skus[0].normal_price"
            fullWidth
            label="Giá bán"
            placeholder="Nhập giá bán"
            error={
              formik.touched.skus?.[0]?.normal_price &&
              formik.errors?.skus?.[0]?.normal_price
            }
            helperText={
              formik.touched.skus?.[0]?.normal_price &&
              formik.errors?.skus?.[0]?.normal_price
            }
            value={
              formik.values.skus[0] ? formik.values.skus[0].normal_price : 0
            }
            onSetField={(value) =>
              formik.setFieldValue('skus[0].normal_price', value)
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextFieldNumber
            id="skus[0].historical_cost"
            fullWidth
            label="Giá vốn"
            placeholder="Nhập giá vốn"
            value={formik.values.skus?.[0]?.historical_cost || 0}
            onSetField={(value) => {
              formik.setFieldValue('skus[0].historical_cost', value);
              formik.setFieldValue('skus[0].pricing', value);
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextFieldNumber
            id="skus[0].wholesale_price"
            fullWidth
            label="Giá sỉ"
            placeholder="Nhập giá sỉ"
            value={
              formik.values.skus[0] ? formik.values.skus[0].wholesale_price : 0
            }
            onSetField={(value) =>
              formik.setFieldValue('skus[0].wholesale_price', value)
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextFieldNumber
            id="skus[0].selling_price"
            fullWidth
            label="Giá khuyến mãi"
            placeholder="Nhập giá KM"
            error={formik.errors?.skus?.[0]?.selling_price}
            helperText={formik.errors?.skus?.[0]?.selling_price}
            value={
              formik.values.skus[0] ? formik.values.skus[0].selling_price : 0
            }
            onSetField={(value) =>
              formik.setFieldValue('skus[0].selling_price', value)
            }
          />
        </Grid>
      </Grid>
    </BoxStyle>
  );
};

export default memo(ProductPrice);
