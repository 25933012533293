import {
  Box,
  Typography,
  Switch,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Checkbox,
  Popover,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import { selectPageProductEdit } from './slice';
import useAppSelector from '../../hooks/useAppSelector';
import { getFinalPrice } from '../../utils/productUtils';
import InventoryBar from '../../components/InventoryBar';
import TextFieldNumber from '../../components/TextFieldNumber';
import TextFieldDecimal from '../../components/TextFieldDecimal';

type BoxStyleProps = {
  display?: string;
  mb?: string;
};

const BoxStyle = styled(Box)<BoxStyleProps>(
  ({ mb, display = 'flex', theme }) => ({
    marginBottom: mb || theme.spacing(2),
    display,
    width: '100%',
  })
);

const PopoverStyle = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const PopoverContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  maxWidth: '300px',
  padding: theme.spacing(1.5),
}));

const ToggleButtonStyle = styled(ToggleButton)(({ theme }) => ({
  paddingTop: theme.spacing(0.8),
  paddingBottom: theme.spacing(0.8),
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.grey[500],
}));

const SkuInventory = () => {
  const { selectedSku: indexSku, isConfirmed } = useAppSelector(
    selectPageProductEdit
  );
  const formik = useFormikContext<PendingProduct>();
  const theme = useTheme();
  const selectedSku = formik.values.skus?.[indexSku];
  const [openInventory, setOpenInventory] = useState(false);
  const [warningInventory, setWarningInventory] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [agreeConfirm, setAgreeConfirm] = useState(false);

  useEffect(() => {
    if (selectedSku) {
      setOpenInventory(selectedSku.sku_type === 'stock' ? true : false);
    }
  }, [selectedSku]);

  useEffect(() => {
    if (
      selectedSku &&
      selectedSku.po_details &&
      selectedSku.po_details.warning_value > 0
    ) {
      setWarningInventory(true);
    } else {
      setWarningInventory(false);
    }
  }, [selectedSku]);

  const validWarningInventory = useMemo(() => {
    return (
      selectedSku &&
      selectedSku.po_details &&
      selectedSku.po_details.warning_value <= selectedSku.po_details.quantity
    );
  }, [selectedSku]);

  const displayPercent = useMemo(() => {
    if (formik.values.skus.length > 0 && selectedSku) {
      const percent =
        ((getFinalPrice(selectedSku) - selectedSku.historical_cost) /
          selectedSku.historical_cost) *
        100;
      const percentValue = Math.abs(percent) > 500 ? 500 : Math.abs(percent);
      return selectedSku.historical_cost > 0
        ? percentValue?.toFixed(percentValue < 10 ? 1 : 0)
        : '0';
    }
  }, [formik.values.skus.length, selectedSku]);

  const handleChangeWarning = () => {
    setWarningInventory((state) => {
      if (!state) {
        formik.setFieldValue(
          `skus[${indexSku}].po_details.warning_value`,
          ((formik.values.skus?.[indexSku]?.po_details?.quantity || 0) * 10) /
            100
        );
      } else {
        formik.setFieldValue(`skus[${indexSku}].po_details.warning_value`, 0);
      }
      return !warningInventory;
    });
  };

  const handleChangeOpenAvanced = (event: ExpectedAny) => {
    if (isConfirmed) {
      formik.setFieldValue(`skus[${indexSku}].sku_type`, 'stock');
      formik.setFieldValue(`skus[${indexSku}].po_details`, {
        pricing: selectedSku?.historical_cost || 0,
        quantity: 0,
        note: '',
        blocked_quantity: 0,
        warning_value: 0,
        delivering_quantity: 0,
      });
      return setOpenInventory?.(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleConfirmInventory = () => setAgreeConfirm(!agreeConfirm);

  const handleClose = () => setAnchorEl(null);

  return (
    <Box>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Tồn kho
      </Typography>
      {openInventory && selectedSku && selectedSku.po_details ? (
        <Box>
          <BoxStyle>
            <TextFieldDecimal
              id={`skus[${indexSku}].po_details.quantity`}
              fullWidth
              label="Tồn kho"
              placeholder="Nhập tồn kho"
              value={selectedSku.po_details.quantity || 0}
              onSetField={(value) => {
                if (warningInventory) {
                  formik.setFieldValue(
                    `skus[${indexSku}].po_details.warning_value`,
                    (value * 10) / 100
                  );
                }
                formik.setFieldValue(
                  `skus[${indexSku}].po_details.quantity`,
                  value
                );
              }}
              sx={{ mr: 1.5 }}
            />
            <TextFieldNumber
              id={`skus[${indexSku}].po_details.blocked_quantity`}
              fullWidth
              label="Lưu kho không bán"
              placeholder="Nhập lượng sản phẩm lưu kho không bán"
              error={
                formik.errors?.skus?.[indexSku]?.po_details?.blocked_quantity
              }
              helperText={
                formik.errors?.skus?.[indexSku]?.po_details?.blocked_quantity
              }
              value={selectedSku.po_details.blocked_quantity || 0}
              onSetField={(value) =>
                formik.setFieldValue(
                  `skus[${indexSku}].po_details.blocked_quantity`,
                  value
                )
              }
            />
          </BoxStyle>
          <BoxStyle
            mb={!validWarningInventory ? '0px' : theme.spacing(2)}
            display="block"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2">
              Lãi lỗ trên giá vốn:{' '}
              <Typography
                variant="body2"
                component="span"
                color={theme.palette.primary.main}
              >
                {displayPercent}%
              </Typography>
            </Typography>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <FormControlLabel
                control={<Switch color="primary" checked={warningInventory} />}
                onChange={handleChangeWarning}
                label="Cảnh báo tồn kho"
                labelPlacement="start"
                sx={{ mr: 2, ml: 0 }}
              />
              {warningInventory && (
                <TextFieldDecimal
                  id={`skus[${indexSku}].po_details.warning_value`}
                  placeholder="00"
                  sx={{ width: '120px' }}
                  error={!validWarningInventory}
                  value={selectedSku.po_details.warning_value || 0}
                  onSetField={(value) =>
                    formik.setFieldValue(
                      `skus[${indexSku}].po_details.warning_value`,
                      value
                    )
                  }
                />
              )}
            </Box>
          </BoxStyle>
          {warningInventory && !validWarningInventory && (
            <Box textAlign="right" mb={2}>
              <Typography variant="caption" color={theme.palette.error.main}>
                Cảnh báo tồn kho không được lớn hơn số lượng tồn kho
              </Typography>
            </Box>
          )}
          <Box>
            <Box width="100%" display="flex" justifyContent="space-between">
              <Typography variant="body2">Tổng sản phẩm</Typography>
              <Typography variant="body2">
                {selectedSku.po_details.quantity || 0}
              </Typography>
            </Box>
            <InventoryBar data={selectedSku.po_details} />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between">
          <ToggleButtonGroup
            color="primary"
            value={selectedSku?.is_active}
            exclusive
            onChange={(_e, value) => {
              formik.setFieldValue('is_active', value);
              formik.setFieldValue(`skus[${indexSku}].is_active`, value);
            }}
          >
            <ToggleButtonStyle value={true}>Còn hàng</ToggleButtonStyle>
            <ToggleButtonStyle value={false}>Hết hàng</ToggleButtonStyle>
          </ToggleButtonGroup>
          <FormControlLabel
            control={<Switch color="primary" checked={openInventory} />}
            onChange={handleChangeOpenAvanced}
            label="Bật tồn kho nâng cao"
            labelPlacement="start"
          />
        </Box>
      )}
      <PopoverStyle
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <PopoverContent>
          <Typography variant="body2" fontWeight={600}>
            Bật tồn kho nâng cao
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[500]}>
            Không thể quay về quản lý cơ bản cho sản phẩm này sau khi bật tồn
            kho. Bạn có muốn tiếp tục?
          </Typography>
          <Box textAlign="center" mb={1.5}>
            <FormControlLabel
              control={<Checkbox onChange={handleConfirmInventory} />}
              label="Không hiển thị lại nhắc nhở này"
            />
          </Box>
          <Button
            variant="outlined"
            fullWidth
            sx={{ textTransform: 'unset' }}
            onClick={() => {
              formik.setFieldValue(`skus[${indexSku}].sku_type`, 'stock');
              formik.setFieldValue(`skus[${indexSku}].po_details`, {
                pricing: 0,
                quantity: 0,
                note: '',
                blocked_quantity: 0,
                warning_value: 0,
                delivering_quantity: 0,
              });
              setOpenInventory?.(true);
              setAnchorEl(null);
              if (agreeConfirm) {
                localStorage.setItem('confirm-advance-inventory', 'true');
              }
            }}
          >
            Xác nhận bật tồn kho
          </Button>
        </PopoverContent>
      </PopoverStyle>
    </Box>
  );
};

export default memo(SkuInventory);
