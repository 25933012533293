import { useFormikContext } from 'formik';
import { getFieldProps } from '../../utils/formik';
import { Box, Card, styled, TextField, Typography } from '@mui/material';
import { memo } from 'react';
import PaymentInfo from './PaymentInfo';
import { selectPageOrderDetail } from './slice';
import useAppSelector from '../../hooks/useAppSelector';
import Scrollbar from '../../minimal/Scrollbar';

const NoteWrapper = styled(Card)(({ theme }) => ({
  height: 144,
  padding: theme.spacing(2),
  marginTop: 2,
}));

const RightPanel = () => {
  const {
    main: { isEdit, orderDetail },
  } = useAppSelector(selectPageOrderDetail);

  const formik = useFormikContext<CreateOrderInput>();
  const note = formik.values.note.replaceAll('\n', '<br />');
  return (
    <Box>
      <PaymentInfo orderDetail={orderDetail} />
      {isEdit ? (
        <Card sx={{ p: 2 }}>
          <TextField
            aria-label="minimum height"
            multiline
            maxRows={4}
            placeholder="Ghi chú"
            style={{
              width: '100%',
              maxHeight: '200px',
            }}
            label="Ghi chú"
            {...getFieldProps(formik, 'note')}
          />
        </Card>
      ) : (
        formik.values.note != '' && (
          <NoteWrapper>
            <Scrollbar>
              <Typography
                dangerouslySetInnerHTML={{ __html: note }}
              ></Typography>
            </Scrollbar>
          </NoteWrapper>
        )
      )}
    </Box>
  );
};

export default memo(RightPanel);
