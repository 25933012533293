import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ThunkApiConfig } from '../index';
import { selectApp } from '../app';
import contactService from '../../services/contact';

export const commitContact = createAsyncThunk<
  void,
  { contact: PendingContact },
  ThunkApiConfig
>('contact/commit', async ({ contact }, { getState }) => {
  const { offline } = selectApp(getState());
  if (offline) {
    // save offline
  } else {
    return await contactService.createContact(contact);
  }
});
