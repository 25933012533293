import MainLayout from '../../layouts/MainLayout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EmptyState from './EmptyState';
import Header from './Header';
import CashbookPagination from './CashbookPagination';
import CashbookTable from './CashbookTable';
import { TableContainer, Table, Card, Typography } from '@mui/material';
import { TABLE_HEAD } from './settings';
import { TableHeader } from '../../components/TableHeader';
import TableFilter from './TableFilter';
import CashbookDialog from './CasbookDIalog';
import Confirmation from '../../components/Confirmation';
import useAppSelector from '../../hooks/useAppSelector';
import {
  pageCashbooksActions,
  selectLocalSearchResult,
  selectPageCashbooks,
} from './slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import cashbookService from '../../services/cashbook';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import CashbooksHeaderBreadcrumbs from './CashbooksHeaderBreadcrumbs';
import HeaderBreadcrumbs from '../../minimal/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../config/routeConfig';

const Cashbook = () => {
  const dispatch = useAppDispatch();
  const {
    rows: cashbooks,
    page,
    pageSize,
  } = useAppSelector(selectLocalSearchResult);
  const [search, setSearch] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<Cashbook | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const {
    main: {
      transactionType,
      selectedStatusId,
      dateRange,
      dialogType,
      categoryType,
    },
  } = useAppSelector(selectPageCashbooks);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTimeout(() => {
        setSearch(e.target.value);
      }, 150);
    },
    []
  );

  const fetchCashbooks = useCallback(() => {
    const params = {
      page,
      pageSize,
      search,
      transaction_type: transactionType,
    };
    if (selectedStatusId !== 'all') {
      Object.assign(params, { status: selectedStatusId });
    }
    dateRange.forEach((time, index) => {
      if (time !== null) {
        if (index === 0) {
          Object.assign(params, { start_time: time.toISOString() });
        } else {
          Object.assign(params, { end_time: time.toISOString() });
        }
      }
    });
    dispatch(pageCashbooksActions.search(params));
  }, [
    dateRange,
    dispatch,
    page,
    pageSize,
    search,
    selectedStatusId,
    transactionType,
  ]);

  useEffect(() => {
    dispatch(pageCashbooksActions.getPayments());
  }, [dispatch]);

  useEffect(() => {
    if (categoryType) {
      dispatch(
        pageCashbooksActions.getCashbookCategories({ type: categoryType })
      );
    }
  }, [dispatch, categoryType]);

  useEffect(() => {
    const params = {
      start_time: '',
      end_time: '',
    };
    dateRange.forEach((time, index) => {
      if (time !== null) {
        if (index === 0) {
          Object.assign(params, { start_time: time.toISOString() });
        } else {
          Object.assign(params, { end_time: time.toISOString() });
        }
      }
    });
    dispatch(pageCashbooksActions.getTotalAmount(params));
  }, [dateRange, dispatch]);

  useEffect(
    () => fetchCashbooks(),
    [
      dispatch,
      page,
      pageSize,
      selectedStatusId,
      search,
      transactionType,
      dateRange,
      fetchCashbooks,
    ]
  );

  const handleDelete = async () => {
    if (selectedRow) {
      try {
        await cashbookService.deleteCashbook(selectedRow.id);
        fetchCashbooks();
        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Xóa giao dịch thành công',
            dismissAfter: 3000,
          })
        );
        setOpenConfirm(false);
        dispatch(pageCashbooksActions.setDialogType(''));
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    }
  };

  const tableHead = useMemo(() => {
    if (transactionType === 'out')
      return TABLE_HEAD.filter((item) => item.id !== 'out');
    if (transactionType === 'in')
      return TABLE_HEAD.filter((item) => item.id !== 'in');
    return TABLE_HEAD;
  }, [transactionType]);

  return (
    <MainLayout>
      <HeaderBreadcrumbs action={<CashbooksHeaderBreadcrumbs />} links={[]}>
        <Typography variant="h4" gutterBottom fontWeight={500}>
          Thu chi
        </Typography>
      </HeaderBreadcrumbs>
      <Header />

      <Card>
        <TableFilter onSearchChange={handleSearchChange} />
        {cashbooks.length === 0 && (
          <TableContainer>
            <Table>
              <TableHeader headLabel={tableHead} />
            </Table>
            <EmptyState />
          </TableContainer>
        )}

        {cashbooks.length > 0 && (
          <>
            <CashbookTable
              tableHead={tableHead}
              setSelectedRow={setSelectedRow}
            />
            <CashbookPagination />
          </>
        )}
        <CashbookDialog
          detail={selectedRow}
          setSelectedRow={setSelectedRow}
          onConfirmDelete={setOpenConfirm}
        />
        {dialogType === 'out' && openConfirm && (
          <Confirmation
            title="Xoá giao dịch chi"
            description="Sau khi xoá giao dịch này sẽ làm ảnh hưởng đến số liệu thu chi.
        Bạn có chắc muốn xoá?"
            open={openConfirm}
            onClose={(e) => {
              e.stopPropagation();
              setOpenConfirm(false);
            }}
            onConfirm={handleDelete}
          />
        )}
        {dialogType === 'in' && openConfirm && (
          <Confirmation
            title="Xoá giao dịch thu"
            description="Sau khi xoá giao dịch này sẽ làm ảnh hưởng đến số liệu thu chi.
        Bạn có chắc muốn xoá?"
            open={openConfirm}
            onClose={(e) => {
              e.stopPropagation();
              setOpenConfirm(false);
            }}
            onConfirm={handleDelete}
          />
        )}
      </Card>
    </MainLayout>
  );
};

export default Cashbook;
