import noAccents from '../../utils/encoding';
import { getProductStock } from '../../utils/productUtils';

export const TABLE_HEAD: Array<HeadCellConfig> = [
  { id: 'sku_code', label: 'Mã SKU' },
  { id: 'hidden', label: '' },
  {
    id: 'name',
    label: 'Thông tin sản phẩm',
    orderBy: (sku: SkuMixed) => noAccents(sku.product_name.toLowerCase()),
  },
  { id: 'normal_price', label: 'Giá bán', align: 'right' },
  {
    id: 'historical_cost',
    label: 'Giá vốn',
    align: 'right',
    orderBy: (product: SkuMixed) => Math.round(product.historical_cost),
  },
  { id: 'can_pick_quantity', label: 'Có thể bán', align: 'right' },
  {
    id: 'stock',
    label: 'Tồn kho',
    align: 'right',
    orderBy: (product: SkuMixed) => {
      const stock = getProductStock(product);
      if (stock) {
        if (stock === true) {
          return Number.MAX_VALUE;
        }

        return stock;
      }

      return 0;
    },
  },
];
