import { Paper } from '@mui/material';
import { useFormikContext } from 'formik';
import OrderItemsTable from './OrderItemsTable';
import EmptyState from './EmptyState';
import useResponsive from '../../hooks/useResponsive';

const OrderItems = () => {
  const formik = useFormikContext<PendingOrderForm>();
  const isDesktop = useResponsive('up', 'lg');
  return (
    <Paper
      elevation={4}
      sx={{
        m: 1.5,
        flexGrow: 1,
        height: `calc(100% - ${isDesktop ? '470px' : '390px'})`,
      }}
    >
      {formik.values?.list_order_item &&
      formik.values.list_order_item.length > 0 ? (
        <OrderItemsTable />
      ) : (
        <EmptyState />
      )}
    </Paper>
  );
};

export default OrderItems;
