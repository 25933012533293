import {
  Box,
  TextField,
  Typography,
  Card,
  MenuItem,
  Divider,
  Checkbox,
  InputLabel,
  FormControl,
  Select,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { useFormikContext } from 'formik';
import EditorComponent from '../../components/Editor';
import RightArrow from '../../components/Icons/RightArrow';
import { getFieldProps } from '../../utils/formik';
import ProductInventory from './ProductInventory';
import ProductPrice from './ProductPrice';
import ProductVariant from './ProductVariant';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageProductEdit } from './slice';
import { selectCategories } from '../../store/selectors';

const BoxStyle = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

const Product = () => {
  const theme = useTheme();
  const formik = useFormikContext<PendingProduct>();
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [collapseEditor, setCollapseEditor] = useState(false);
  const [openInventory, setOpenInventory] = useState(
    formik.values.skus[0]?.sku_type === 'stock'
  );
  const categories = useAppSelector(selectCategories);
  const { editing } = useAppSelector(selectPageProductEdit);

  const { setFieldValue } = formik;

  useEffect(() => {
    setFieldValue(
      'description_rtf',
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );
  }, [setFieldValue, editorState]);

  useEffect(() => {
    const raw = editing?.description_rtf || editing?.description;

    if (!raw) {
      setEditorState(EditorState.createEmpty());
      return;
    }

    try {
      const state = EditorState.createWithContent(
        convertFromRaw(JSON.parse(raw))
      );
      setEditorState(state);
    } catch (ex) {
      const blocksFromHTML = convertFromHTML(
        raw.replace(/(\r\n|\r|\n)/g, '<br/>')
      );
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, [editing]);

  const [selectedCategories, setSelectedCategories] = useState<Array<string>>(
    formik.values.category
  );
  const isAllSelected =
    categories.length > 0 && selectedCategories.length === categories.length;

  return (
    <Box>
      <Card>
        <Box
          width="100%"
          component={'form'}
          noValidate
          autoComplete="off"
          p={2}
          onSubmit={formik.handleSubmit}
        >
          <BoxStyle>
            <TextField
              fullWidth
              label="Tên sản phẩm"
              {...getFieldProps(formik, 'name')}
            />
          </BoxStyle>
          <BoxStyle>
            <FormControl fullWidth sx={{ mr: 1.5 }}>
              <InputLabel>Danh mục</InputLabel>
              <Select
                label="Danh mục"
                multiple
                value={selectedCategories}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.includes('all')) {
                    const newValue =
                      selectedCategories.length === categories.length
                        ? []
                        : categories.map((c) => c.id);
                    setSelectedCategories(newValue);
                    formik.setFieldValue('category', newValue);
                    return;
                  }
                  formik.setFieldValue('category', value);
                  setSelectedCategories(value as Array<string>);
                }}
                renderValue={(selected) => {
                  const names = categories
                    .filter((c) => selected.some((s) => s === c.id))
                    .map((category) => category.name)
                    .join(', ');
                  return names;
                }}
              >
                {categories.length > 0 ? (
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={
                          selectedCategories.length > 0 &&
                          selectedCategories.length < categories.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Chọn tất cả" />
                  </MenuItem>
                ) : (
                  <MenuItem disabled sx={{ m: 2 }}>
                    Bạn chưa có danh mục nào
                  </MenuItem>
                )}
                {categories.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedCategories.indexOf(c.id) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={c.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Đơn vị"
              placeholder="Ví dụ: 1 kilogram, 1 đôi, 1 chiếc,..."
              {...getFieldProps(formik, 'uom')}
            />
          </BoxStyle>
          {!formik.values.is_variant && (
            <BoxStyle>
              <TextField
                fullWidth
                label="Mã vạch"
                placeholder="Nhập mã vạch"
                {...getFieldProps(formik, 'barcode')}
                sx={{ mr: 1.5 }}
              />
              <TextField
                fullWidth
                label="Mã sản phẩm SKU"
                placeholder="Nhập mã SKU cho sản phẩm"
                {...getFieldProps(formik, 'sku_code')}
              />
            </BoxStyle>
          )}

          <BoxStyle>
            <Box width="100%">
              <BoxStyle
                onClick={() => setCollapseEditor(!collapseEditor)}
                sx={{ cursor: 'pointer' }}
              >
                <Typography color={theme.palette.info.main} sx={{ mr: 0.5 }}>
                  Mô tả sản phẩm
                </Typography>
                <Box
                  sx={{
                    transform: collapseEditor
                      ? 'rotate(0deg)'
                      : 'rotate(90deg)',
                    transition: (theme) =>
                      theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shorter,
                      }),
                    width: '20px',
                    height: '20px',
                  }}
                >
                  <RightArrow fill={theme.palette.info.main} />
                </Box>
              </BoxStyle>
              {!collapseEditor && (
                <EditorComponent
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                />
              )}
            </Box>
          </BoxStyle>
          <Divider sx={{ mb: 2 }} />
          {!formik.values.is_variant && (
            <>
              <ProductPrice />
              <Divider sx={{ mb: 2 }} />
              <ProductInventory
                openInventory={openInventory}
                setOpenInventory={setOpenInventory}
              />
              <Divider sx={{ my: 2 }} />
            </>
          )}
          <ProductVariant
            openVariant={formik.values.is_variant}
            setOpenVariant={() => {
              formik.setValues({
                ...formik.values,
                is_variant: true,
                skus: formik.values.skus.map((sku) => ({
                  ...sku,
                  sku_type: formik.values.is_advance_stock
                    ? 'stock'
                    : 'non_stock',
                })),
              });
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default memo(Product);
