import { Box, Typography, Card, CardHeader, Grid } from '@mui/material';
import merge from 'lodash/merge';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import ReactApexChart from 'react-apexcharts';
import useAppSelector from '../../hooks/useAppSelector';
import BaseOptionChart from '../../minimal/chart/BaseOptionChart';
import { numberFormat } from '../../utils/constants';
import DebtReminder from './DebtReminder';
import { selectPageDashboard } from './slice';
import useResponsive from '../../hooks/useResponsive';

const FinanAnalytics = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const { cashbookAnalyticsIn, cashbookAnalyticsOut } =
    useAppSelector(selectPageDashboard);

  const handleFormatLabel = () => {
    const lastDayOfMonth = moment(moment().endOf('M'), 'DD-MM-YYYY').format(
      'DD'
    );
    return (
      cashbookAnalyticsIn?.chart_data_details || handleRenderDefaultLabel()
    ).map((detail) => {
      switch (detail.index) {
        case 1:
          return moment().startOf('M').format('DD/MM');
        case 9:
          return moment(moment().startOf('M'), 'DD-MM-YYYY')
            .add(8, 'days')
            .format('DD/MM');
        case 17:
          return moment(moment().startOf('M'), 'DD-MM-YYYY')
            .add(16, 'days')
            .format('DD/MM');
        case 25:
          return moment(moment().startOf('M'), 'DD-MM-YYYY')
            .add(24, 'days')
            .format('DD/MM');
        case 30:
          return lastDayOfMonth === '30'
            ? moment(moment().endOf('M'), 'DD-MM-YYYY').format('DD/MM')
            : '';
        case 31:
          return lastDayOfMonth === '31'
            ? moment(moment().endOf('M'), 'DD-MM-YYYY').format('DD/MM')
            : '';
        default:
          return '';
      }
    });
  };

  const handleRenderDefaultLabel = () => {
    const lastDayOfMonth = moment(moment().endOf('M'), 'DD-MM-YYYY').format(
      'DD'
    );
    return Array.from(Array(+lastDayOfMonth), (_, i) => ({ index: i + 1 }));
  };

  const handleRenderDefaultData = () => {
    const lastDayOfMonth = moment(moment().endOf('M'), 'DD-MM-YYYY').format(
      'DD'
    );
    return [...Array(+lastDayOfMonth).fill(0)];
  };

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.primary.main, theme.palette.error.main],
    yaxis: {
      labels: {
        show: true,
        formatter: (val: number) => numberFormat.format(val),
      },
    },
    xaxis: {
      categories: handleFormatLabel(),
    },
    tooltip: {
      y: {
        formatter: (val: number) => numberFormat.format(val),
      },
    },
  });

  const CHART_DATA = [
    {
      year: 'Month',
      data: [
        {
          name: 'Tổng thu',
          data:
            (cashbookAnalyticsIn &&
              cashbookAnalyticsIn?.chart_data_details.map(
                (value) => value.value || 0
              )) ||
            handleRenderDefaultData(),
        },
        {
          name: 'Tổng chi',
          data:
            (cashbookAnalyticsOut &&
              cashbookAnalyticsOut?.chart_data_details.map(
                (value) => value.value || 0
              )) ||
            handleRenderDefaultData(),
        },
      ],
    },
  ];

  return (
    <Box mb={5}>
      <Typography variant="h4" fontWeight={600} mb={3}>
        Tài chính
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Card sx={{ height: '100%' }}>
            <CardHeader title="Tổng thu chi tháng này" />
            {CHART_DATA.map((item) => (
              <Box
                key={item.year}
                sx={{ mt: isDesktop ? 6 : 8, mx: 3 }}
                dir="ltr"
              >
                <ReactApexChart
                  type="line"
                  series={item.data}
                  options={chartOptions}
                  height={364}
                />
              </Box>
            ))}
          </Card>
        </Grid>
        <Grid item xs={4}>
          <DebtReminder />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinanAnalytics;
