import {
  createSelector,
  createSlice,
  PayloadAction,
  combineReducers,
} from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import { PURGE } from 'redux-persist';
import { contactOffline } from '../../config/offline/contact';
import { selectAppContact } from '../../store/contact';
import { RootState } from '../../store/rootReducer';
import createSearchSlice from '../../utils/createSearchSlice';
import getOfflineDataByIds from '../../utils/offline/getOfflineDataByIds';
import searchOfflineIds from '../../utils/offline/searchOfflineIds';
import { TABLE_HEAD, TABS } from './settings';

export const selectPageContacts = (state: RootState) => state.page_contacts;
export const selectLocalSearchResultContacts = createSelector(
  selectPageContacts,
  ({ search }) => search
);

export const selectLocalSearchRowsNoPagination = createSelector(
  selectAppContact,
  selectPageContacts,
  (
    cacheContact,
    { main: { selectedTab }, search: { rows: ids, orderBy, order } }
  ) => {
    const contacts = getOfflineDataByIds(ids, cacheContact);

    const orderFnOrString =
      TABLE_HEAD[selectedTab].find((o) => o.id === orderBy)?.orderBy || orderBy;
    const sortedContacts = _orderBy(
      contacts,
      [orderFnOrString || 'name'],
      [order || 'asc']
    );

    return sortedContacts;
  }
);

export const selectLocalSearchRows = createSelector(
  selectAppContact,
  selectPageContacts,
  (
    cacheContact,
    {
      main: { selectedTab },
      search: { rows: ids, orderBy, order, page, pageSize },
    }
  ) => {
    const contacts = getOfflineDataByIds(ids, cacheContact);

    const orderFnOrString =
      TABLE_HEAD[selectedTab].find((o) => o.id === orderBy)?.orderBy || orderBy;
    const sortedContacts = _orderBy(
      contacts,
      [orderFnOrString || 'updated_at'],
      [order || 'desc']
    );

    return (
      sortedContacts
        // pagination
        .slice(page * pageSize, page * pageSize + pageSize)
    );
  }
);

export type ContactTabs = 'in' | 'out' | '';

type ContactPagesState = {
  selectedTab: ContactTabs;
};

const initialState: ContactPagesState = {
  selectedTab: '',
};

const name = 'page_contacts' as const;

const mainSlice = createSlice({
  name,
  initialState,
  reducers: {
    selectTab: (state, action: PayloadAction<ContactTabs>) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const searchSlice = createSearchSlice(name, {
  handleSearch: async (query: FuseQuery, api) => {
    const ids = await searchOfflineIds(
      api.getState<ExpectedAny>(),
      query,
      contactOffline
    );
    return [ids, ids.length];
  },
});

export const selectedLocalSelectedTab = createSelector(
  selectPageContacts,
  ({ main: { selectedTab } }) => TABS.find((s) => s.id === selectedTab)
);

export const selectedLocalCountContact = createSelector(
  selectAppContact,
  (cacheContact) => {
    const contacts = getOfflineDataByIds(cacheContact.ids, cacheContact);
    const countContact = [0, 0, 0];
    countContact[0] = contacts.length;
    contacts.map((value) => {
      if (value.option === 'in') countContact[1] += 1;
      else if (value.option === 'out') countContact[2] += 1;
    });

    return countContact;
  }
);

const pageContactsSlice = {
  name,
  reducer: combineReducers({
    main: mainSlice.reducer,
    search: searchSlice.reducer,
  }),
  actions: {
    ...mainSlice.actions,
    ...searchSlice.actions,
  },
} as const;

export const pageContactsActions = pageContactsSlice.actions;

export default pageContactsSlice;
