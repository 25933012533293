import authService from '../services/auth';

type AppRequestInit = RequestInit & {
  authorization?: boolean;
};

export type ApiResult<T> = {
  data: T;
};

export async function fetchAll<TResult>(
  input: RequestInfo,
  init?: AppRequestInit | undefined
) {
  const headers: HeadersInit = {};

  const store = await (await import('../store')).getStore();

  if (store.getState().app.offline) {
    throw new Error(
      'Offline mode: All connections to internet is being blocked.'
    );
  }

  if (init?.authorization) {
    const token = await authService.getAccessToken();
    if (!token) {
      throw new Error('UNAUTHORIZE');
    }
    headers.authorization = `Bearer ${token}`;
  }

  const rs = await fetch(input, {
    ...init,
    headers: {
      ...headers,
      ...init?.headers,
    },
  });

  if (rs.status >= 400) {
    const json = await rs.json();
    throw new Error(json?.message || json.error.detail || 'ERR_SYSTEM');
  }

  const json = (await rs.json()) as TResult;

  return json;
}

export async function fetchData<TResult>(
  input: RequestInfo,
  init?: AppRequestInit | undefined
) {
  return (await fetchAll<ApiResult<TResult>>(input, init)).data;
}

export function post<T, Body = ExpectedAny>(
  input: RequestInfo,
  body: Body,
  init?: AppRequestInit | undefined
) {
  return fetchData<T>(input, {
    ...init,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}

export function put<T, Body = ExpectedAny>(
  input: RequestInfo,
  body: Body,
  init?: AppRequestInit | undefined
) {
  return fetchData<T>(input, {
    ...init,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
}
