import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import { TableHeader } from '../../components/TableHeader';
import useAppSelector from '../../hooks/useAppSelector';
import {
  pageCashbooksActions,
  selectLocalSearchResult,
  selectPageCashbooks,
} from './slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import { numberFormat } from '../../utils/constants';

type CashbookTableProps = {
  tableHead: Array<HeadCellConfig>;
  setSelectedRow: (value: Cashbook) => void;
};

const CashbookTable = ({ tableHead, setSelectedRow }: CashbookTableProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { rows: cashbooks } = useAppSelector(selectLocalSearchResult);
  const {
    main: { transactionType },
  } = useAppSelector(selectPageCashbooks);

  const renderMoneyTypeCollumn = (item: Cashbook) => {
    switch (transactionType) {
      case 'out':
        return (
          <TableCell align="right" sx={{ color: theme.palette.error.main }}>
            {numberFormat.format(item.amount) || '-'}
          </TableCell>
        );
      case 'in':
        return (
          <TableCell align="right" sx={{ color: theme.palette.primary.main }}>
            {numberFormat.format(item.amount) || '-'}
          </TableCell>
        );
      default:
        return (
          <>
            <TableCell align="right" sx={{ color: theme.palette.error.main }}>
              {item.transaction_type === 'out'
                ? numberFormat.format(item.amount)
                : '-'}
            </TableCell>
            <TableCell align="right" sx={{ color: theme.palette.primary.main }}>
              {item.transaction_type === 'in'
                ? numberFormat.format(item.amount)
                : '-'}
            </TableCell>
          </>
        );
    }
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHeader headLabel={tableHead} />
        <TableBody>
          {cashbooks.map((item, index) => (
            <TableRow
              key={item.id}
              sx={{ cursor: 'pointer' }}
              hover
              onClick={() => {
                dispatch(
                  pageCashbooksActions.setDialogType(item.transaction_type)
                );
                dispatch(
                  pageCashbooksActions.setCategoryType(item.transaction_type)
                );
                setSelectedRow(item);
              }}
            >
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{item.category_name}</TableCell>
              <TableCell align="left">
                {moment(item.day).format('DD/MM/YYYY HH:mm')}
              </TableCell>
              <TableCell align="left">{item.payment_source_name}</TableCell>
              {renderMoneyTypeCollumn(item)}
              <TableCell align="left">{item.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(CashbookTable);
