import { SxProps, TextField, Theme } from '@mui/material';
import { Ref, useEffect, useState } from 'react';
import { changeToNumberFormat } from '../../utils/utils';

const MAX_LENGTH = 10;
const ERRORS = [`Chỉ được nhập tối đa ${MAX_LENGTH} số`];

type TextFieldNumberProps = {
  id: string;
  name?: string;
  label?: string;
  autoFocus?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  value?: number;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  inputRef?: Ref<unknown> | undefined;
  endAdornment?: React.ReactNode;
  textAlign?: 'left' | 'right';
  readOnly?: boolean;
  max?: number;
  clickOutSide?: boolean;
  onClick?: () => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSetField?: (value: number) => void;
  sx?: SxProps<Theme>;
  error?: boolean;
  helperText?: string | false;
};

export default function TextFieldNumber({
  id,
  name,
  label,
  autoFocus,
  placeholder,
  fullWidth,
  value,
  variant = 'outlined',
  inputRef,
  endAdornment,
  textAlign = 'left',
  max,
  sx,
  clickOutSide,
  onClick,
  onBlur,
  onKeyUp,
  onSetField,
  readOnly = false,
  error,
  helperText,
  ...props
}: TextFieldNumberProps) {
  const [valueString, setValueString] = useState(String(value || ''));
  const [errorMessage, setErrorMessage] = useState<null | number>(null);

  useEffect(() => {
    setValueString(String(value));
  }, [id, value]);

  return (
    <TextField
      id={id}
      name={name}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      label={label}
      placeholder={placeholder}
      variant={variant}
      inputRef={inputRef}
      InputProps={{
        endAdornment,
        readOnly,
      }}
      inputProps={{ style: { textAlign } }}
      value={changeToNumberFormat(valueString)}
      onFocus={() => setValueString(String(value || ''))}
      onClick={onClick}
      onKeyUp={() => onKeyUp && onKeyUp}
      error={error || errorMessage !== null}
      helperText={helperText || (errorMessage !== null && ERRORS[errorMessage])}
      onChange={() => {
        // prettier-ignore
        const textField = document.getElementById(id) as HTMLInputElement;
        if (textField) {
          let value = textField.value;
          const convertToNumber = value.replace(/\./g, '');

          if (max && +convertToNumber > max) {
            return setValueString(String(max));
          }
          if (convertToNumber.length > MAX_LENGTH) {
            return setErrorMessage(0);
          }
          if (errorMessage !== null) {
            setErrorMessage(null);
          }
          // prettier-ignore
          value = changeToNumberFormat(value);
          textField.value = value;
          setValueString(value);

          if (!clickOutSide) {
            value = value.replace(/\./g, '');
            const regex = /^[0-9]+([,.][0-9]+)?$/;
            if (regex.test(value)) {
              setValueString(String(+value));
              onSetField?.(+value);
            }
          }
        }
      }}
      onBlur={(e) => {
        if (e.target.value === '') {
          onBlur?.(e);
          setValueString('0');
          onSetField?.(0);
          return;
        }

        if (clickOutSide) {
          let value = e.target.value;
          value = e.target.value.replace(/\./g, '');
          const regex = /^[0-9]+([,.][0-9]+)?$/;
          if (value.length === MAX_LENGTH && errorMessage !== null) {
            setErrorMessage(null);
          }
          if (regex.test(value)) {
            setValueString(String(+value));
            onSetField?.(+value);
          }
        }
      }}
      sx={sx}
      {...props}
    />
  );
}
