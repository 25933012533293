const ProfitDashboard = () => (
  <svg
    width="106"
    height="100"
    viewBox="0 0 106 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4195_491276)">
      <circle cx="60.5" cy="84.5" r="60.5" fill="#919EAB" fillOpacity="0.12" />
      <g filter="url(#filter0_d_4195_491276)">
        <path
          d="M95.4469 52.5129C94.8128 70.723 80.2475 85.6528 61.7404 86.445C42.2977 87.2762 25.862 72.1883 25.0308 52.7456C25.0275 52.6666 25.0236 52.5914 25.0236 52.5129C24.3209 33.1674 39.3666 16.8607 58.7302 16.0328C78.1728 15.2016 94.6085 30.2895 95.4397 49.736C95.4793 50.6704 95.4826 51.5949 95.4469 52.5129Z"
          fill="#FFF7CD"
        />
        <path
          d="M66.0661 76.9422C65.6362 77.0388 65.2091 77.125 64.7798 77.2003C51.021 79.623 37.6427 70.8284 34.5172 57.0554C31.2989 42.852 40.2011 28.732 54.404 25.5098C57.9374 24.7105 61.4676 24.6572 64.8259 25.2518C74.9612 27.0509 83.5307 34.7277 85.9496 45.3966C89.1712 59.5995 80.269 73.7239 66.0661 76.9422Z"
          fill="#B78103"
        />
        <path
          d="M65.9068 76.8936C65.4769 76.9902 65.0498 77.0764 64.6204 77.1516C54.489 75.3525 45.9162 67.6724 43.4967 57.0067C40.2784 42.8033 49.1806 28.6833 63.3835 25.4612C63.8101 25.3645 64.2405 25.2783 64.6665 25.2031C74.8018 27.0022 83.3713 34.679 85.7903 45.348C89.0119 59.5509 80.1097 73.6753 65.9068 76.8936Z"
          fill="#FFC107"
        />
        <path
          d="M95.4486 52.5039C94.8145 70.714 80.2493 85.6438 61.7421 86.4361C42.2995 87.2673 25.8637 72.1794 25.0325 52.7367C25.0292 52.6576 25.0254 52.5824 25.0254 52.5039H33.8914C33.9666 54.0126 34.1741 55.5361 34.5189 57.059C37.741 71.2625 51.8649 80.168 66.0678 76.9459C77.8841 74.2683 86.0298 64.0474 86.5782 52.5039H95.4486Z"
          fill="#FFE16A"
        />
        <path
          d="M63.0296 47.4893L62.6445 47.3433C60.462 46.5199 60.2399 46.2588 60.2399 45.4993C60.2399 44.8599 60.4694 44.6762 60.6844 44.5539C61.0193 44.3636 61.545 44.2586 62.1642 44.2586C63.2469 44.2586 63.792 44.547 64.2777 44.8032C64.6493 44.9994 65.0708 45.2215 65.6192 45.2215C67.0367 45.2215 67.9889 43.8587 67.9889 42.5858C67.9889 41.0345 66.6376 39.9653 64.1582 39.5274V39.3673C64.1582 37.9458 63.0315 36.7891 61.6469 36.7891C60.2623 36.7891 59.1356 37.9458 59.1356 39.3673V39.8564C56.6891 40.7674 55.3048 42.8394 55.3048 45.6509C55.3048 49.9901 58.3757 51.1747 60.6186 52.0401C62.757 52.8652 63.4967 53.2264 63.4967 54.7201C63.4967 55.6537 63.252 56.5175 61.4743 56.5175C60.0773 56.5175 59.5858 55.9736 59.11 55.4467C58.739 55.0356 58.1776 54.4153 57.255 54.4153C55.8845 54.4153 54.8604 55.8066 54.8604 57.0506C54.8604 58.8132 56.6457 60.486 59.1359 61.1647V61.3576C59.1359 62.7791 60.2626 63.9358 61.6472 63.9358C63.0318 63.9358 64.1585 62.7791 64.1585 61.3576V61.0972C66.8526 60.2078 68.4337 57.8264 68.4337 54.5682C68.4321 49.5574 64.9181 48.212 63.0296 47.4893Z"
          fill="#B78103"
        />
      </g>
      <path
        opacity="0.24"
        d="M95.6163 53.1198L101.318 47.8818L81.9099 35.9883L95.6163 53.1198Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.6038 35.4836C74.6038 35.4836 76.603 35.7965 76.3744 35.2113C76.1457 34.6262 75.0955 34.6211 75.0955 34.6211L74.6038 35.4836Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.6038 35.4836C74.6038 35.4836 76.603 35.7965 76.3744 35.2113C76.1457 34.6262 75.0955 34.6211 75.0955 34.6211L74.6038 35.4836Z"
        fill="#BA8B72"
        fillOpacity="0.8"
      />
      <path
        d="M81.2118 85.8717C81.2118 85.8717 81.4999 85.6015 81.626 85.6556C81.7521 85.7095 82.4904 85.7095 82.7246 86.8984C82.9588 88.0871 83.0127 87.8709 83.0127 87.8709L82.7066 88.2671L82.3104 88.051C82.3104 88.051 80.6715 90.4644 79.4467 91.0047C78.222 91.5449 78.7623 90.2482 78.7623 90.2482C78.7623 90.2482 79.1945 88.8435 79.7348 88.0151C80.2751 87.1865 81.2118 85.8717 81.2118 85.8717Z"
        fill="#7A4F01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.7783 88.0262L79.5915 88.2667C79.5915 88.2667 79.7451 88.0363 79.9108 87.7355L80.2046 87.1195C80.2841 86.9113 80.3323 86.7154 80.312 86.5739C80.24 86.0697 79.1953 84.9169 79.1953 84.9169L80.1679 83.9805L81.3856 85.7391C81.4846 85.7812 81.6463 85.9238 81.4888 86.4058C81.2727 87.0662 79.6278 89.6836 79.0754 90.1878C79.0754 90.1878 78.9559 89.8588 79.7783 88.0262Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.7783 88.0262L79.5915 88.2667C79.5915 88.2667 79.7451 88.0363 79.9108 87.7355L80.2046 87.1195C80.2841 86.9113 80.3323 86.7154 80.312 86.5739C80.24 86.0697 79.1953 84.9169 79.1953 84.9169L80.1679 83.9805L81.3856 85.7391C81.4846 85.7812 81.6463 85.9238 81.4888 86.4058C81.2727 87.0662 79.6278 89.6836 79.0754 90.1878C79.0754 90.1878 78.9559 89.8588 79.7783 88.0262Z"
        fill="#BA8B72"
        fillOpacity="0.5"
      />
      <path
        d="M88.0555 84.0709C88.0555 84.0709 88.3436 83.8009 88.4697 83.8548C88.5958 83.9089 89.3342 83.9089 89.5684 85.0976C89.8025 86.2862 89.8564 86.0701 89.8564 86.0701L89.5503 86.4662L89.1542 86.2501C89.1542 86.2501 87.5152 88.6635 86.2905 89.2038C85.0657 89.7441 85.606 88.4474 85.606 88.4474C85.606 88.4474 86.0383 87.0426 86.5786 86.2143C87.1189 85.3857 88.0555 84.0709 88.0555 84.0709Z"
        fill="#7A4F01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.6211 86.2256L86.4342 86.4662C86.4342 86.4662 86.5879 86.2358 86.7536 85.9349L87.0474 85.3189C87.1268 85.1107 87.175 84.9148 87.1548 84.7731C87.0828 84.2689 86.0381 83.1161 86.0381 83.1161L87.0106 82.1797L88.2283 83.9386C88.3274 83.9806 88.4891 84.1232 88.3315 84.6052C88.1154 85.2656 86.4705 87.8831 85.9181 88.3873C85.9181 88.3875 85.7986 88.0585 86.6211 86.2256Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.6211 86.2256L86.4342 86.4662C86.4342 86.4662 86.5879 86.2358 86.7536 85.9349L87.0474 85.3189C87.1268 85.1107 87.175 84.9148 87.1548 84.7731C87.0828 84.2689 86.0381 83.1161 86.0381 83.1161L87.0106 82.1797L88.2283 83.9386C88.3274 83.9806 88.4891 84.1232 88.3315 84.6052C88.1154 85.2656 86.4705 87.8831 85.9181 88.3873C85.9181 88.3875 85.7986 88.0585 86.6211 86.2256Z"
        fill="#BA8B72"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.8593 58.7017C87.7283 58.1775 78.134 58.7017 78.134 58.7017C78.134 58.7017 72.1907 65.1853 72.731 71.7769C73.2713 78.3685 78.7823 85.5004 78.7823 85.5004L80.1871 84.2037C80.1871 84.2037 77.3775 74.8026 77.8098 72.9656C78.242 71.1286 79.971 65.8695 79.971 65.8695C79.971 65.8695 78.7823 73.1817 79.6468 75.3429C80.5113 77.5041 85.6981 83.9876 85.6981 83.9876L87.5351 82.2948C87.5351 82.2948 83.825 75.6671 83.7169 73.722C83.6089 71.7769 87.1029 67.7787 87.5351 65.4014C87.9674 63.0241 88.9399 63.0241 87.8593 58.7017Z"
        fill="#005249"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1512 36.3433L74.7275 35.5149C74.7275 35.5149 76.4565 35.3349 76.5285 34.0741C76.6004 32.8134 75.2678 31.6968 74.2231 32.8493C74.2231 32.8493 73.2867 33.5696 73.3225 34.5422C73.3586 35.5147 73.2506 35.983 73.2506 35.983L74.1512 36.3433Z"
        fill="#BA8B72"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.1582 29.5883L86.7878 28.6497C86.7878 28.6497 87.5955 27.1104 86.586 26.352C85.5763 25.5937 83.9083 26.0847 84.2895 27.5929C84.2895 27.5929 84.3704 28.7716 85.2002 29.2804C86.0299 29.7893 86.3598 30.1385 86.3598 30.1385L87.1582 29.5883Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.1582 29.5883L86.7878 28.6497C86.7878 28.6497 87.5955 27.1104 86.586 26.352C85.5763 25.5937 83.9083 26.0847 84.2895 27.5929C84.2895 27.5929 84.3704 28.7716 85.2002 29.2804C86.0299 29.7893 86.3598 30.1385 86.3598 30.1385L87.1582 29.5883Z"
        fill="#BA8B72"
      />
      <path
        d="M89.4961 44.3704C89.4961 44.3704 87.7671 46.7477 87.9833 49.125C88.1994 51.5023 89.4241 57.1934 88.5597 58.3461C87.6952 59.4987 84.4534 58.4903 82.0041 58.7064C79.5549 58.9225 77.7537 59.715 76.5292 58.9225C75.3045 58.13 77.5378 56.4011 77.0334 52.2948C76.5292 48.1886 76.0248 45.3429 72.3508 44.6946C68.6767 44.0462 67.272 42.2092 68.5687 40.8045C69.8654 39.3997 73.1072 35.4015 73.1072 35.4015L74.8361 36.1579C74.8361 36.1579 71.9185 40.8045 72.5669 41.4528C73.2152 42.1012 78.186 42.1012 80.023 42.6415C81.86 43.1818 84.2373 42.9656 85.4259 42.6415C86.6984 42.2944 89.4241 38.8594 88.8838 36.4821C88.3435 34.1048 85.3179 29.9985 85.3179 29.9985L87.5871 28.5938C87.5871 28.5938 94.5029 35.2934 93.2062 37.7788C91.9095 40.2644 89.4961 44.3704 89.4961 44.3704Z"
        fill="url(#paint0_linear_4195_491276)"
      />
      <path
        d="M82.2686 42.2351C82.2686 42.2351 85.1983 43.0037 85.5343 40.8905C85.8704 38.7772 86.2067 34.3589 82.1244 34.0226C78.0421 33.6864 77.802 41.2265 78.5223 41.707C79.2429 42.1869 82.2686 42.2351 82.2686 42.2351Z"
        fill="#B72136"
      />
      <ellipse
        rx="0.432237"
        ry="0.684449"
        transform="matrix(-1 0 0 1 84.099 38.8837)"
        fill="url(#paint1_linear_4195_491276)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.2046 41.4712L83.4207 43.5124C83.4207 43.5124 83.4687 44.689 82.0038 44.689C80.539 44.689 80.587 43.6324 80.587 43.6324L80.8511 41.375L83.2046 41.4712Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.2046 41.4712L83.4207 43.5124C83.4207 43.5124 83.4687 44.689 82.0038 44.689C80.539 44.689 80.587 43.6324 80.587 43.6324L80.8511 41.375L83.2046 41.4712Z"
        fill="#BA8B72"
        fillOpacity="0.8"
      />
      <ellipse
        rx="2.28135"
        ry="3.22967"
        transform="matrix(-1 0 0 1 81.8368 39.5617)"
        fill="white"
      />
      <ellipse
        rx="2.28135"
        ry="3.22967"
        transform="matrix(-1 0 0 1 81.8368 39.5617)"
        fill="#BA8B72"
        fillOpacity="0.5"
      />
      <path
        d="M84.4296 37.4325C84.4296 37.4325 84.0214 38.5131 83.5891 38.5131C83.1569 38.5131 82.0283 38.345 81.452 38.249C81.404 38.1531 81.3318 37.7209 81.404 37.3126C81.404 37.3126 81.2598 38.2972 81.0917 38.2972C80.9235 38.2972 79.3387 38.3212 79.3387 38.3212C79.3387 38.3212 78.4263 37.2886 79.6028 36.3042C80.7796 35.3196 84.5016 35.1034 84.4296 37.4325Z"
        fill="#B72136"
      />
      <path
        d="M84.0994 38.1992C84.0994 38.1992 82.0043 35.7859 79.7711 37.0286C79.7711 37.0286 80.4914 36.2003 81.7523 36.1281C83.0129 36.0561 84.1296 37.2628 84.3638 38.1273C84.3638 38.1271 84.4298 38.2893 84.0994 38.1992Z"
        fill="#FFC107"
      />
      <path
        opacity="0.12"
        d="M57.1951 43.9845L69.7031 32.418L56.0007 42.502L57.1951 43.9845Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8931 34.0459C75.8931 34.0459 74.9925 35.0904 74.6085 34.7783C74.2242 34.466 73.4799 33.5775 73.9601 33.1693C74.4404 32.761 76.0612 32.677 75.8931 34.0459Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.8931 34.0459C75.8931 34.0459 74.9925 35.0904 74.6085 34.7783C74.2242 34.466 73.4799 33.5775 73.9601 33.1693C74.4404 32.761 76.0612 32.677 75.8931 34.0459Z"
        fill="#BA8B72"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.0961 33.9768C76.0961 33.9768 75.0635 34.7933 74.6792 34.4811C74.295 34.169 73.5507 33.2803 74.0309 32.8723C74.5113 32.464 76.2642 32.6082 76.0961 33.9768Z"
        fill="#BA8B72"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.7881 28.6499C86.7881 28.6499 85.3895 26.8699 85.1014 27.176C84.8133 27.4822 85.917 29.6227 85.917 29.6227L86.7881 28.6499Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.7881 28.6499C86.7881 28.6499 85.3895 26.8699 85.1014 27.176C84.8133 27.4822 85.917 29.6227 85.917 29.6227L86.7881 28.6499Z"
        fill="#BA8B72"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4195_491276"
        x="13"
        y="8"
        width="94.4717"
        height="94.4766"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.168627 0 0 0 0 0.211765 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4195_491276"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4195_491276"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4195_491276"
        x1="90.3438"
        y1="36.8232"
        x2="89.3892"
        y2="59.9353"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4195_491276"
        x1="-6.6024e-05"
        y1="1.36896"
        x2="0.864408"
        y2="1.36896"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEBD9F" />
        <stop offset="1" stopColor="#C8FACD" />
      </linearGradient>
      <clipPath id="clip0_4195_491276">
        <path
          d="M0 0H106V84C106 92.8366 98.8366 100 90 100H0V0Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ProfitDashboard;
