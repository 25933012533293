export const TABLE_HEAD: HeadCellConfig[] = [
  { id: 'name', label: 'Thông tin đơn hàng' },
  {
    id: 'created_at',
    label: 'Ngày tạo',
    align: 'right',
  },
  { id: 'status', label: 'Trạng thái', padding: '8px 8px 8px 40px' },

  { id: 'total', label: 'Tổng tiền', align: 'right' },

  { id: 'action', label: 'Hành động', align: 'center' },
];

export const TABLE_HEAD_HIDE_ACTION: HeadCellConfig[] = [
  { id: 'name', label: 'Thông tin đơn hàng' },
  {
    id: 'created_at',
    label: 'Ngày tạo',
    align: 'right',
  },
  { id: 'status', label: 'Trạng thái', padding: '8px 8px 8px 40px' },

  { id: 'total', label: 'Tổng tiền', align: 'right' },
];
