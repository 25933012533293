import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
  DialogActions,
  Button,
  FormControl,
  Typography,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import TextFieldNumber from '../../components/TextFieldNumber';
import useAppSelector from '../../hooks/useAppSelector';
import { numberFormat } from '../../utils/constants';
import { selectPageOrderDetail, pageOrderDetailActions } from './slice';
import UserOrderPaymentForm from './userOrderPaymentForm';

const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const DialogTitleStyle = styled(DialogTitle)(() => ({
  //   marginBottom: theme.spacing(2),
  fontWeight: 600,
}));

const OrderPaymentDialog = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    main: { orderPaymentDialog, paymentMethod },
  } = useAppSelector(selectPageOrderDetail);

  const payMethodHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (paymentMethod.length > 0) {
      const payment = paymentMethod.find(
        (value) => value.name == e.currentTarget.value
      );

      setFieldValue('payment_source_id', payment?.id);
      setFieldValue('payment_source_name', payment?.name);
      setFieldValue('payment_method', payment?.name);
    }
  };
  const formik = UserOrderPaymentForm();
  const total = orderPaymentDialog
    ? orderPaymentDialog?.grand_total - orderPaymentDialog?.amount_paid
    : 0;

  const { values, setFieldValue } = formik;

  const handleClose = () => {
    dispatch(pageOrderDetailActions.setOrderPaymentDialog(null));
  };

  return (
    <Dialog
      open={Boolean(orderPaymentDialog)}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleStyle>Xác nhận thanh toán</DialogTitleStyle>
      <DialogContent>
        <Box textAlign={'center'}>
          <Typography
            variant="body2"
            sx={{ fontSize: theme.spacing(1.5) }}
            color={theme.palette.grey[500]}
          >
            Tổng tiền
          </Typography>
          <Typography variant="h3">
            {orderPaymentDialog && numberFormat.format(total || 0)}đ
          </Typography>
        </Box>
        <BoxStyle mt={1}>
          <TextFieldNumber
            id="buyer_pay"
            fullWidth
            autoFocus
            label="Số tiền đã trả"
            placeholder="Số tiền đã trả"
            value={values.debit.buyer_pay}
            endAdornment="&nbsp;đ"
            onSetField={(value) =>
              formik.setFieldValue('debit.buyer_pay', value)
            }
          />
        </BoxStyle>

        <BoxStyle flexDirection={'column'}>
          <Typography id="variant" pt={0.6}>
            Hình thức thanh toán
          </Typography>
          <FormControl fullWidth>
            <RadioGroup
              row
              value={values.payment_source_name}
              onChange={payMethodHandle}
              sx={{ justifyContent: 'space-between' }}
            >
              {paymentMethod.map(
                (
                  value: { name: string },
                  index: React.Key | null | undefined
                ) => (
                  <FormControlLabel
                    key={index}
                    value={value.name}
                    control={<Radio />}
                    label={value.name}
                  />
                )
              )}
            </RadioGroup>
          </FormControl>
        </BoxStyle>

        {orderPaymentDialog &&
          +values.debit.buyer_pay.toString().replaceAll('.', '') > total && (
            <Typography
              variant="body2"
              color={theme.palette.grey[600]}
              sx={{ float: 'right' }}
            >
              Tiền thừa: &nbsp;
              {numberFormat.format(
                +values.debit.buyer_pay.toString().replaceAll('.', '') - total
              )}
              đ
            </Typography>
          )}

        {orderPaymentDialog &&
          +values.debit.buyer_pay.toString().replaceAll('.', '') < total && (
            <BoxStyle alignItems={'center'} justifyContent={'space-between'}>
              <Box display={'flex'} alignItems={'center'}>
                <Switch
                  defaultChecked
                  onChange={(event) =>
                    setFieldValue('debit.is_debit', event.target.checked)
                  }
                />
                <Typography ml={1}>Ghi nợ phải thu</Typography>
              </Box>
              <Box>
                <Typography sx={{ float: 'right' }} color={'error'}>
                  {numberFormat.format(
                    total -
                      +values.debit.buyer_pay.toString().replaceAll('.', '')
                  )}
                  đ
                </Typography>
              </Box>
            </BoxStyle>
          )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'end' }}>
        <Box display="flex">
          <Button
            variant="outlined"
            color="inherit"
            sx={{ mr: 1.5 }}
            onClick={handleClose}
          >
            Huỷ
          </Button>
          <Button variant="contained" onClick={() => formik.handleSubmit()}>
            Xác nhận
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default OrderPaymentDialog;
