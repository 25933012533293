import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { numberFormat } from '../../utils/constants';
import { getCanPickQuantity } from '../../utils/productUtils';

const PercentBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '12px',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1.5),
  backgroundColor: theme.palette.grey[500_16],
  borderRadius: '16px !important',
}));

const Dot = styled(Box)(({ theme }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  marginTop: theme.spacing(0.5),
  marginRight: theme.spacing(1),
}));

type InventoryBarProps = {
  data: InventoryDetail;
};

export default function InventoryBar({ data }: InventoryBarProps) {
  const theme = useTheme();

  return (
    <Box>
      <PercentBar>
        <Box
          width={
            ((data.blocked_quantity || 0) / (data.quantity || 0)) * 100 + '%'
          }
          height={'100%'}
          bgcolor={theme.palette.primary.lighter || theme.palette.grey[300]}
          sx={{ borderTopLeftRadius: 16, borderBottomLeftRadius: 16 }}
        />
        <Box
          width={
            ((data?.delivering_quantity || 0) / (data.quantity || 0)) * 100 +
            '%'
          }
          height={'100%'}
          bgcolor={theme.palette.primary.light || theme.palette.grey[300]}
        />
        <Box
          width={
            (getCanPickQuantity({ ...data }) / (data.quantity || 0)) * 100 + '%'
          }
          height={'100%'}
          bgcolor={theme.palette.primary.main || theme.palette.grey[300]}
          sx={{ borderTopRightRadius: 16, borderBottomRightRadius: 16 }}
        />
      </PercentBar>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mx={0.5}
      >
        <Box display="flex">
          <Dot bgcolor={theme.palette.primary.lighter} />
          <Box>
            <Typography fontSize={13} color={theme.palette.grey[500]}>
              Không bán
            </Typography>
            <Typography fontSize={13}>
              {numberFormat.format(data.blocked_quantity) || 0}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Dot bgcolor={theme.palette.primary.light} />
          <Box>
            <Typography fontSize={13} color={theme.palette.grey[500]}>
              Khách đặt
            </Typography>
            <Typography fontSize={13}>
              {numberFormat.format(data?.delivering_quantity) || 0}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Dot bgcolor={theme.palette.primary.main} />
          <Box>
            <Typography fontSize={13} color={theme.palette.grey[500]}>
              Có thể bán
            </Typography>
            <Typography fontSize={13}>
              {numberFormat.format(getCanPickQuantity({ ...data }))}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
