import { ButtonBase, ButtonBaseProps, styled } from '@mui/material';
import { alpha } from '@mui/system';

export const Tabs = styled('div')(({ theme }) => ({
  width: 'fit-content',
  // width: `calc(100vw - 304px - 96px - ${isDesktop ? '424px' : '324px'})`,
  overflowX: 'auto',
  overflowY: 'hidden',
  float: 'left',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  marginBottom: 4,
  '& > button': {
    marginRight: theme.spacing(1),
  },
  '&::-webkit-scrollbar': {
    width: 1,
    height: 6,
    left: '-100px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.grey[600], 0.48),
    borderRadius: 6,
  },
}));

type UnstyledTabProps = {
  active?: boolean;
} & ButtonBaseProps;

export const Tab = styled(ButtonBase, {
  shouldForwardProp: (name) => name !== 'active' && name !== 'isCollapse',
})<UnstyledTabProps>(({ active, theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  padding: theme.spacing(1.5, 1),
  backgroundColor: theme.palette.primary.darker,
  color: theme.palette.getContrastText(theme.palette.primary.darker),
  margin: theme.spacing(0, 0, 0, 0),
  transition: 'all ease-in-out 100ms',
  borderRadius: theme.shape.borderRadius,
  height: 42,
  ...(active && {
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    height: '100%',
    borderRadius: 0,
  }),
  '.MuiBox-root': {
    marginLeft: theme.spacing(0.5),
    width: 16,
    height: 16,
  },
  '&.Mui-focusVisible': {
    boxShadow: `inset 0 0 0 2px ${theme.palette.secondary.dark}`,
  },
}));
