import {
  Typography,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import EmptyOrder from '../../components/Icons/EmptyOrder';

const EmptyState = () => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt={10}
    >
      <EmptyOrder />
      <Typography color={theme.palette.grey[500]} sx={{ my: 3, textAlign: 'center' }}>
        Bạn chưa có khách hàng nào! <br />
        Hãy thêm mới khách hàng nhé!
      </Typography>
    </Box>
  );
};

export default memo(EmptyState);
