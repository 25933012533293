import {
  Box,
  Button,
  IconButton,
  Typography,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  FormGroup,
  Checkbox,
  TextField,
  DialogActions,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Filter from '../../components/Icons/Filter';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import { selectCategoriesAndAll } from '../../store/selectors';
import useAppDispatch from '../../hooks/useAppDispatch';
import {
  pagePosActions,
  selectLocalProducts,
  selectLocalSearchCategories,
  selectPagePos,
} from './slice';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import { productOffline } from '../../config/offline/product';
import Scrollbar from '../../minimal/Scrollbar';
import { selectCategory } from '../../store/category';
import { useThrottle } from 'react-use';
import useResponsive from '../../hooks/useResponsive';

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  paddingRight: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

type ButtonsWrapperProps = {
  isDesktop: boolean;
};

const ButtonsWrapper = styled(Scrollbar)<ButtonsWrapperProps>(
  ({ isDesktop, theme }) => ({
    width: `calc(100vw - ${isDesktop ? '445px' : '350px'}  - 300px)`,
    whiteSpace: 'nowrap',
    flexWrap: 'nowrap',
    paddingBottom: theme.spacing(0.5),
  })
);

type ButtonsStyleProps = {
  active: boolean;
};

const ButtonsStyle = styled(Button)<ButtonsStyleProps>(({ active, theme }) => ({
  textTransform: 'none',
  minWidth: 'fit-content',
  color: theme.palette.grey[600],
  backgroundColor: theme.palette.grey[500_8],
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.grey[500_24],
  },
  '&:not(:last-of-type)': { marginRight: theme.spacing(1) },
  ...(active && {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 171, 85, 0.08)',
  }),
}));

const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
  paddingTop: theme.spacing(0),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2),
  '& .MuiTypography-root': {
    marginLeft: theme.spacing(2),
  },
}));

const availableOrders: Array<ProductsOrder> = [
  'created_at',
  'sold_quantity',
  'low_price',
  'high_price',
];

const labels: { [key in ProductsOrder]: string } = {
  created_at: 'Mới nhất',
  sold_quantity: 'Sản phẩm bán chạy',
  low_price: 'Giá từ thấp đến cao',
  high_price: 'Giá từ cao đến thấp',
};

const ProductHeader = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const categories = useAppSelector(selectCategoriesAndAll);
  const {
    main: { selectedCategoryIds, selectedOrderBy },
    products: { page, pageSize },
  } = useAppSelector(selectPagePos);
  const [openMoreCategory, setOpenMoreCategory] = useState(false);
  const [newCategoryIds, setNewCategoryIds] =
    useState<Array<string>>(selectedCategoryIds);
  const [openFilter, setOpenFilter] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const { totalItems } = useAppSelector(selectLocalProducts);

  const searchCategories = useAppSelector(selectLocalSearchCategories);
  const [term, setTerm] = useState<string>('');
  const throttledTerm = useThrottle(term, 200);
  const { fuseVersion } = useAppSelector(selectCategory);

  useEffect(
    () => setNewCategoryIds(selectedCategoryIds),
    [selectedCategoryIds]
  );

  useEffect(() => {
    // filter skus
    const isAllCategory = selectedCategoryIds.indexOf('');
    const query = buildOfflineFuseQuery(
      '',
      {
        ...(isAllCategory === -1
          ? { $or: selectedCategoryIds.map((id) => ({ category: id })) }
          : null),
      },
      productOffline
    );
    dispatch(pagePosActions.products.search(query)).unwrap();
  }, [dispatch, selectedCategoryIds]);

  useEffect(() => {
    // search categories
    const query = buildOfflineFuseQuery(throttledTerm, {}, productOffline);

    dispatch(pagePosActions.categories.search(query));
  }, [dispatch, throttledTerm, fuseVersion]);

  const handleClose = () => setOpenFilter(null);

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let order: OrderDirection = 'desc';
    let orderBy = value;
    if (value === 'low_price' || value === 'high_price') {
      orderBy = 'sort_price';
    }
    if (value === 'low_price') {
      order = 'asc';
    }

    dispatch(pagePosActions.products.setOrder([orderBy, order]));
    dispatch(pagePosActions.setSelectedOrderBy(value));
    dispatch(pagePosActions.products.setPage(0));
    setOpenFilter(null);
  };

  const handleSelectedCategories = useCallback(
    (ids: Array<string>, category: Category) => {
      let newIds = [...ids];
      const existed = ids.indexOf(category.id);
      if (newIds.length > 0) {
        newIds = newIds.filter((id) => id !== '');
      }
      existed === -1 ? newIds.push(category.id) : newIds.splice(existed, 1);
      if (category.id === '' || newIds.length === 0) {
        newIds = [''];
      }
      return newIds;
    },
    []
  );

  const handleCategoryChange = useCallback(
    (category) => {
      const newIds = handleSelectedCategories(selectedCategoryIds, category);

      dispatch(pagePosActions.setSelectedCategoryIds(newIds));
      dispatch(pagePosActions.products.setPage(0));
    },
    [dispatch, handleSelectedCategories, selectedCategoryIds]
  );

  const handleCheckCategory = (category: Category) => {
    const newIds = handleSelectedCategories(newCategoryIds, category);
    setNewCategoryIds(newIds);
  };

  const handleConfirmChangeCategory = () => {
    dispatch(pagePosActions.setSelectedCategoryIds(newCategoryIds));
    dispatch(pagePosActions.products.setPage(0));
    setOpenMoreCategory(false);
  };

  return (
    <Root>
      <Box display="flex" alignItems="center" ml={1}>
        <IconButton
          sx={{ height: '40px', mr: 2 }}
          onClick={() => setOpenMoreCategory(true)}
        >
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
        <ButtonsWrapper isDesktop={isDesktop || false}>
          {categories.map((category) => (
            <ButtonsStyle
              key={category.id}
              variant="contained"
              onClick={() => handleCategoryChange(category)}
              active={selectedCategoryIds.indexOf(category.id) !== -1}
            >
              {category.name}
            </ButtonsStyle>
          ))}
        </ButtonsWrapper>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          mr={3}
          sx={{ cursor: 'pointer' }}
          onClick={(event) => setOpenFilter(event.currentTarget)}
        >
          <Typography
            fontSize={14}
            color={
              openFilter ? theme.palette.primary.main : theme.palette.grey[800]
            }
            sx={{ mr: 1 }}
          >
            Xếp theo
          </Typography>
          <Filter fill={openFilter ? '#00AB55' : undefined} />
        </Box>
        <Box>
          {page + 1} / {Math.ceil(totalItems / pageSize) || 1}
        </Box>
      </Box>
      <Popover
        open={Boolean(openFilter)}
        anchorEl={openFilter}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={`${theme.spacing(3)} ${theme.spacing(4)}`}>
          <RadioGroup value={selectedOrderBy} onChange={handleChangeFilter}>
            {availableOrders.map((orderBy) => (
              <FormControlLabel
                key={orderBy}
                value={orderBy}
                control={<Radio checked={selectedOrderBy === orderBy} />}
                label={labels[orderBy]}
              />
            ))}
          </RadioGroup>
        </Box>
      </Popover>
      <Dialog
        open={openMoreCategory}
        onClose={() => setOpenMoreCategory(false)}
        fullWidth
      >
        <DialogTitle>Chọn danh mục xem nhanh</DialogTitle>
        <TextField
          placeholder="Tìm kiếm danh mục"
          sx={{ m: 3 }}
          value={term}
          onChange={(e) => setTerm(e.target.value)}
        />
        <DialogContentStyle>
          {searchCategories.map((category) => (
            <Box
              key={category.id}
              display="flex"
              alignItems="center"
              mt={0.5}
              sx={{ cursor: 'pointer' }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="medium"
                      onChange={() => handleCheckCategory(category)}
                      checked={newCategoryIds.indexOf(category.id) !== -1}
                    />
                  }
                  label={category.name}
                />
              </FormGroup>
            </Box>
          ))}
        </DialogContentStyle>
        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setOpenMoreCategory(false)}
          >
            Quay lại
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmChangeCategory}
          >
            Hoàn thành
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default ProductHeader;
