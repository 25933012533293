import { useMemo } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import { v4 } from 'uuid';
import PosHeader, { PosHeaderProps } from '../../components/PosHeader';
import useAppDispatch from '../../hooks/useAppDispatch';
import { pagePosActions } from '../../screens/POS/slice';
import { createInitialOrder, posActions } from '../../store/pos';

type PosLayoutProps = {
  children: React.ReactNode;
} & PosHeaderProps;

const keyMap = {
  SEARCH: 'command+/',
  SEARCH_CUSTOMER: 'command+k',
  NEW_ORDER: 'command+d',
};

const PosLayout = ({
  formValues,
  selectedOrder,
  onSelectedOrderChange,
  onSelectedItem,
  onResetData,
  children,
}: PosLayoutProps) => {
  const dispatch = useAppDispatch();
  const handlers = useMemo(
    () => ({
      SEARCH: (e: KeyboardEvent | undefined) => {
        e?.preventDefault();
        (document.querySelector('#search') as HTMLInputElement | null)?.focus();
      },
      SEARCH_CUSTOMER: (e: KeyboardEvent | undefined) => {
        e?.preventDefault();
        const el = document.querySelector(
          '#search-contact'
        ) as HTMLInputElement | null;
        el?.select();
        el?.focus();
      },
      NEW_ORDER: (e: KeyboardEvent | undefined) => {
        e?.preventDefault();
        const id = v4();
        const newOrder = createInitialOrder();
        newOrder.id = id;
        dispatch(posActions.newOrder(newOrder));
        dispatch(pagePosActions.setSelectedOrderId(id));
      },
    }),
    [dispatch]
  );
  return (
    <>
      <GlobalHotKeys {...{ keyMap, handlers }} />
      <PosHeader
        {...{
          formValues,
          selectedOrder,
          onSelectedOrderChange,
          onSelectedItem,
          onResetData,
        }}
      />
      {children}
    </>
  );
};

export default PosLayout;
