const Maintenence = () => (
  <svg
    width="356"
    height="284"
    viewBox="0 0 356 284"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 145.564C0.5 175.505 11.5534 202.789 29.7424 223.409C50.6093 247.072 80.8685 261.95 114.598 262C129.338 262.021 143.433 259.193 156.38 254.029C163.074 251.358 170.567 251.665 177.051 254.829C184.656 258.536 193.172 260.615 202.165 260.615C205.879 260.615 209.517 260.257 213.036 259.579C223.081 257.651 232.221 253.087 239.727 246.622C244.445 242.558 250.487 240.516 256.662 240.523H256.767C277.171 240.523 296.124 234.216 311.86 223.409C325.928 213.76 337.416 200.517 345.07 184.969C351.743 171.42 355.5 156.114 355.5 139.914C355.5 84.353 311.292 39.3052 256.767 39.3052C251.208 39.3052 245.762 39.7836 240.45 40.6763C226.382 17.47 201.19 2 172.452 2C160.319 2 148.816 4.75722 138.52 9.69978C128.728 14.3849 120.022 21.0423 112.901 29.1556C88.7185 29.5484 66.3584 37.5983 48.0574 51.0327C19.2495 72.1677 0.5 106.637 0.5 145.564Z"
        fill="url(#paint0_linear_5296_328282)"
      />
    </g>
    <g filter="url(#filter0_d_5296_328282)">
      <g opacity="0.24">
        <path
          d="M83.903 246.129C91.6072 246.832 99.3428 247.132 107.073 247.179C113.764 247.21 120.67 247.006 126.756 244.227C130.091 242.704 133.1 240.436 136.603 239.354C139.901 238.335 143.43 238.424 146.839 237.967C150.247 237.511 153.85 236.313 155.851 233.504L142.784 229.16C141.13 228.555 139.426 228.098 137.69 227.795C135.474 227.553 133.237 227.576 131.026 227.863C122.643 228.671 114.32 230.011 106.107 231.876C101.507 232.926 99.117 235.657 95.1258 237.411C91.9118 238.824 89.9844 237.71 86.7232 237.621C79.1345 237.416 73.7201 245.199 83.903 246.129Z"
          fill="#005249"
        />
        <path
          d="M155.677 211.956C153.755 211.988 151.171 212.586 151.197 214.508C158.13 216.656 165.592 214.283 172.85 214.54C182.608 214.887 191.493 219.954 199.927 224.886C196.604 224.805 193.496 226.523 191.798 229.381C210.804 231.387 229.841 226.54 248.946 226.23C268.945 225.926 288.98 230.621 308.815 228.157C308.468 226.545 306.525 225.931 304.897 225.684C296.552 224.424 288.05 224.996 279.632 224.455C271.213 223.914 262.495 222.087 255.931 216.788C262.746 215.166 269.33 212.696 275.53 209.435C271.591 207.582 266.996 208.149 262.674 208.669C233.992 212.134 204.974 211.746 176.395 207.513C175.408 209.483 178.417 210.948 180.596 211.121L228.491 214.923C233.979 215.359 239.913 215.974 243.947 219.729C222.767 223.793 193.662 219.592 173.711 211.357C170.434 210.003 175.759 206.185 172.225 206.557C168.124 206.988 160.608 211.867 155.677 211.956Z"
          fill="#005249"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.328 111.324C107.008 112.188 107.328 113.281 107.22 114.376C107.168 114.853 107.042 115.326 107.016 115.804C106.966 116.288 107.12 116.772 107.441 117.138C107.725 117.411 108.171 117.584 108.239 117.968C108.339 118.493 107.688 118.803 107.304 119.181C106.964 119.602 106.729 120.099 106.622 120.63C106.384 121.406 106.06 122.153 105.655 122.857C105.304 123.598 104.701 124.189 103.954 124.527C103.429 124.716 102.788 124.669 102.289 124.958C101.113 125.635 101.391 127.583 100.299 128.418C99.9853 128.629 99.6244 128.759 99.2484 128.797C98.3859 128.927 97.5042 128.808 96.7066 128.455C96.3472 128.317 96.0366 128.076 95.8138 127.762C95.6053 127.337 95.5302 126.859 95.5985 126.391C95.6878 124.816 95.9031 123.209 95.8506 121.618C95.8243 120.767 95.7246 119.916 95.7351 119.065C95.7584 116.51 96.9122 114.097 98.886 112.474C100.905 110.878 103.609 110.449 106.023 111.34L105.556 110.815"
        fill="#BA8B72"
      />
      <path
        d="M97.9145 115.657C97.1778 115.848 96.7175 116.58 96.8642 117.327C97.0544 118.05 97.6929 118.565 98.4397 118.598L96.7644 121.817C96.6226 122.09 96.3863 122.416 96.0869 122.342C95.7876 122.269 95.7141 121.859 95.6563 121.539C95.4777 120.525 94.6795 119.748 94.2278 118.824C93.5661 117.474 93.7027 115.888 93.8182 114.391C93.939 113.057 94.0756 111.66 94.8003 110.531C95.226 109.924 95.7495 109.392 96.3495 108.956C97.3493 108.107 98.5593 107.543 99.8524 107.323C100.943 107.245 102.038 107.381 103.077 107.722C104.119 107.946 105.099 108.4 105.944 109.05C106.792 109.729 107.169 110.837 106.911 111.891C105.335 112.102 102.273 111.891 101.039 113.042C99.9889 114.034 100.703 116.193 99.3429 116.87C99.2904 115.961 98.6707 115.772 97.9145 115.657Z"
        fill="#25233A"
      />
      <path
        d="M111.364 110.022C110.881 109.733 108.738 108.835 108.25 108.546C108.155 106.845 106.322 104.623 103.807 103.683C101.927 103.006 98.0615 103.006 94.9945 105.306C91.5127 107.932 92.0011 112.333 91.9643 113.483C91.9516 113.983 92.195 114.455 92.6098 114.735C93.0247 115.014 93.5535 115.063 94.0125 114.864C94.9423 114.444 95.8963 114.079 96.8693 113.771L101.071 112.721C104.038 111.933 108.386 110.789 111.364 110.022Z"
        fill="#00AB55"
      />
      <path
        d="M110.723 109.744L98.303 112.958L102.399 110.778L103.523 110.185L105.424 109.523L107.562 108.788L108.25 108.546L110.723 109.744Z"
        fill="url(#paint1_linear_5296_328282)"
      />
      <path
        d="M98.3031 113.372L94.5902 120.199L93.7395 118.12L94.9106 116.066L93.5557 115.09L94.9946 114.438L95.9294 115.184L96.3128 113.95L98.3031 113.372Z"
        fill="#65657F"
      />
      <path
        d="M92.4843 114.633C92.4843 114.633 93.0725 114.785 94.5849 114.108C95.7978 113.619 97.0482 113.229 98.3241 112.942L103.544 110.169C103.544 110.169 96.9062 106.829 92.9884 110.615C92.9674 110.626 91.4444 112.721 92.4843 114.633Z"
        fill="#00AB55"
      />
      <path
        d="M92.4055 109.129C92.4055 109.129 95.2467 104.25 99.7998 104.266C101.948 104.283 104.019 105.063 105.645 106.467C105.645 106.467 97.82 101.546 92.4055 109.129Z"
        fill="url(#paint2_linear_5296_328282)"
      />
      <path
        d="M92.3005 114.218C92.3005 114.218 92.3531 114.969 93.6082 114.481C94.8633 113.992 98.6445 112.905 98.6445 112.905L100.393 111.855C100.393 111.855 92.9938 114.696 92.3005 114.218Z"
        fill="url(#paint3_linear_5296_328282)"
      />
      <path
        d="M94.317 231.85C102.889 215.432 108.033 197.443 109.436 178.976C109.563 177.295 109.631 175.489 108.743 174.055C107.047 171.303 103.056 171.466 99.8627 171.955C98.4133 172.186 96.7748 172.548 96.0501 173.819C95.5249 174.759 89.9949 178.162 90.4991 179.118C94.1752 186.055 95.6667 194.951 93.8286 204.2C92.022 213.301 88.7765 220.028 87.4741 229.113C88.2146 229.176 94.0334 232.385 94.317 231.85Z"
        fill="#212B36"
      />
      <rect
        x="264.113"
        y="74.8831"
        width="9.31116"
        height="133.817"
        fill="#00AB55"
      />
      <rect
        x="268.068"
        y="74.8831"
        width="5.35667"
        height="133.817"
        fill="url(#paint4_linear_5296_328282)"
      />
      <path
        d="M273.424 103.368C273.424 103.074 306.888 114.801 306.888 114.801L307.686 126.297L272.684 113.63L272.594 103.363L273.424 103.368Z"
        fill="#00AB55"
      />
      <path
        d="M272.589 104.355C272.589 104.061 306.888 116.408 306.888 116.408L307.686 125.813L272.684 113.147L272.589 104.355Z"
        fill="url(#paint5_linear_5296_328282)"
      />
      <path
        d="M270.804 105.658C270.804 105.364 304.262 117.091 304.262 117.091L305.06 127.851L270.058 115.184L269.953 105.632L270.804 105.658Z"
        fill="#00AB55"
      />
      <path
        d="M269.969 106.393C269.969 106.099 304.262 118.446 304.262 118.446L305.06 127.851L270.058 115.184L269.969 106.393Z"
        fill="url(#paint6_linear_5296_328282)"
      />
      <rect
        x="301.164"
        y="86.2634"
        width="9.31116"
        height="137.898"
        fill="#00AB55"
      />
      <rect
        x="302.229"
        y="86.2634"
        width="9.31116"
        height="137.898"
        fill="url(#paint7_linear_5296_328282)"
      />
      <rect
        x="304.882"
        y="86.2634"
        width="1.31291"
        height="137.898"
        fill="#00AB55"
      />
      <path
        d="M304.955 159.014V160.742L304.792 168.415L270.205 156.819L270.147 156.798L270.594 147.755L304.955 159.014Z"
        fill="#00AB55"
      />
      <path
        d="M304.792 160.348V169.234L270.205 156.819L270.594 149.068L304.792 160.348Z"
        fill="url(#paint8_linear_5296_328282)"
      />
      <rect
        x="167.194"
        y="72.0369"
        width="9.31116"
        height="136.663"
        fill="#00AB55"
      />
      <rect
        x="168.26"
        y="72.0369"
        width="9.31116"
        height="136.663"
        fill="url(#paint9_linear_5296_328282)"
      />
      <rect
        x="172.918"
        y="147.618"
        width="92.3816"
        height="8.70722"
        fill="#00AB55"
      />
      <rect
        x="172.918"
        y="147.618"
        width="92.3816"
        height="8.70722"
        fill="url(#paint10_linear_5296_328282)"
      />
      <rect
        x="176.416"
        y="147.618"
        width="87.6972"
        height="1.19212"
        fill="url(#paint11_linear_5296_328282)"
      />
      <rect
        x="172.918"
        y="102.901"
        width="92.3816"
        height="8.70722"
        fill="#00AB55"
      />
      <rect
        x="172.918"
        y="102.901"
        width="92.3816"
        height="8.70722"
        fill="url(#paint12_linear_5296_328282)"
      />
      <rect
        x="174.126"
        y="105.196"
        width="92.3816"
        height="8.70722"
        fill="#00AB55"
      />
      <rect
        x="172.918"
        y="103.988"
        width="92.3816"
        height="9.9151"
        fill="url(#paint13_linear_5296_328282)"
      />
      <path
        d="M204.418 115.589L176.505 105.196V107.464L204.418 117.852V115.589Z"
        fill="#00AB55"
      />
      <path
        d="M204.418 115.589L176.505 105.196V107.464L204.418 117.852V115.589Z"
        fill="url(#paint14_linear_5296_328282)"
      />
      <rect
        x="176.505"
        y="105.196"
        width="87.6079"
        height="0.908534"
        fill="#00AB55"
      />
      <rect
        x="176.505"
        y="102.292"
        width="87.6079"
        height="0.908534"
        fill="#00AB55"
      />
      <rect
        x="170.912"
        y="72.0369"
        width="5.593"
        height="136.663"
        fill="#00AB55"
      />
      <rect
        x="170.912"
        y="72.0369"
        width="5.593"
        height="136.663"
        fill="url(#paint15_linear_5296_328282)"
      />
      <rect
        x="212.411"
        y="161.33"
        width="92.3816"
        height="8.70722"
        fill="#00AB55"
      />
      <rect
        x="212.411"
        y="162.533"
        width="92.3816"
        height="7.49934"
        fill="url(#paint16_linear_5296_328282)"
      />
      <path
        d="M130.244 138.517C129.718 142.078 128.253 145.607 125.559 147.97C122.865 150.333 118.842 151.384 115.523 149.982C112.052 148.516 110.072 144.908 108.36 141.579C108.323 150.018 111.711 158.384 110.534 166.719C110.447 167.938 110.011 169.106 109.279 170.085C108.376 171.135 106.979 171.571 105.65 171.939C101.009 173.202 96.2634 174.04 91.4707 174.444C91.0086 174.48 90.5517 174.522 90.0895 174.549C89.5329 171.797 89.3018 168.21 89.2598 163.841C89.2598 163.41 89.2598 162.964 89.2598 162.517C89.2598 159.009 89.3438 155.039 89.4751 150.638C89.4751 150.318 89.4751 149.997 89.4751 149.677C89.4751 149.64 89.4751 149.609 89.4751 149.572C89.4751 149.535 89.4751 149.456 89.4751 149.362C89.4751 149.115 89.4751 148.732 89.5066 148.312C89.5066 148.206 89.5066 148.096 89.5066 147.986C89.5066 147.876 89.5066 147.765 89.5066 147.65C89.5066 147.44 89.5066 147.23 89.5066 147.03C89.5066 146.915 89.5066 146.794 89.5066 146.684C89.5066 146.158 89.5066 145.759 89.5066 145.759C89.5976 143.028 89.6851 140.154 89.7692 137.136C89.7692 136.747 89.7692 136.364 89.7954 135.97C89.8099 134.899 89.9367 133.832 90.1736 132.788C90.548 131.05 91.5815 129.525 93.0567 128.534C94.1597 127.81 95.3534 127.234 96.6068 126.822C101.627 125.063 107.598 126.113 111.191 129.894C111.809 130.552 112.363 131.268 112.845 132.031C113.643 133.25 114.305 134.552 114.998 135.844C115.69 137.219 116.5 138.532 117.419 139.767C118.249 140.817 119.457 141.82 120.764 141.589C122.644 141.253 123.159 138.838 123.18 136.942C123.218 133.798 123.043 130.656 122.655 127.536C125.056 127.316 127.476 127.404 129.855 127.799C130.152 129.723 130.35 131.662 130.448 133.607C130.528 135.247 130.46 136.89 130.244 138.517Z"
        fill="url(#paint17_linear_5296_328282)"
      />
      <path
        d="M100.152 153.773C98.7442 156.068 96.8274 158.006 94.9 159.865C93.9915 160.732 94.5219 171.183 93.5819 172.023C93.2405 172.327 92.4055 173.357 91.4812 174.439C91.0191 174.475 90.5622 174.517 90.1 174.544C89.5433 171.792 89.3123 168.205 89.2703 163.836C89.2703 163.405 89.2703 162.958 89.2703 162.512C89.2703 159.004 89.3543 155.034 89.4856 150.633C89.4856 150.313 89.4856 149.992 89.4856 149.672C89.4856 149.635 89.4856 149.604 89.4856 149.567C89.4856 149.53 89.4856 149.451 89.4856 149.357C89.4856 149.11 89.4856 148.727 89.5171 148.306C89.5171 148.201 89.5171 148.091 89.5171 147.981C89.5171 147.871 89.5171 147.76 89.5171 147.645C89.5171 147.435 89.5171 147.225 89.5171 147.025C89.5171 146.909 89.5171 146.789 89.5171 146.678C89.5171 146.153 89.5171 145.754 89.5171 145.754C90.1735 145.182 90.6514 147.582 90.7722 147.897C90.8122 148.001 90.8709 148.097 90.9455 148.18C91.0525 148.265 91.1881 148.304 91.3237 148.291C91.9486 148.191 92.5119 147.856 92.8991 147.356C93.9285 146.327 94.907 145.252 95.8348 144.131L102.042 146.106C102.447 148.774 101.564 151.478 100.152 153.773Z"
        fill="url(#paint18_linear_5296_328282)"
      />
      <path
        d="M101.296 145.938C99.4741 153.416 94.0492 160.642 89.2649 162.512C87.3061 163.273 85.4575 163.142 84.0133 161.761C81.3875 159.413 80.8623 150.732 81.6658 141.006C81.7562 139.806 81.9998 138.622 82.3905 137.483C82.949 135.928 83.7952 134.492 84.8851 133.25C87.1273 130.833 90.0194 129.115 93.2142 128.303L93.1669 128.513L92.3161 132.651L91.4181 136.999L89.6168 145.743L88.5665 150.78C88.5665 151.352 88.9183 151.2 89.454 150.633C90.9507 149.057 93.9336 144.241 95.194 143.238C95.2317 143.21 95.2669 143.178 95.2991 143.144L101.296 145.938Z"
        fill="url(#paint19_linear_5296_328282)"
      />
      <path
        d="M114.998 135.844C114.825 135.881 114.641 135.902 114.473 135.928C110.618 136.385 106.727 136.233 102.851 136.317C98.9752 136.401 95.2255 136.716 91.4128 137L89.8741 137.12C87.3848 137.288 84.885 137.435 82.3853 137.483C82.9437 135.928 83.7899 134.492 84.8798 133.25C85.2478 133.166 85.6218 133.109 85.9984 133.082C87.4268 132.966 88.85 132.861 90.2785 132.772C90.9559 132.725 91.6334 132.688 92.3109 132.651C96.8483 132.389 101.38 132.226 105.913 132.063C108.223 131.984 110.534 131.905 112.84 132.032C113.643 133.25 114.305 134.552 114.998 135.844Z"
        fill="url(#paint20_linear_5296_328282)"
      />
      <path
        d="M104.568 200.308C110.251 207.723 115.623 216.063 116.122 225.369C116.217 227.044 116.406 229.124 117.971 229.733C118.721 229.941 119.516 229.929 120.26 229.696L126.037 228.504L122.44 212.397C121.536 208.333 120.617 204.231 118.905 200.434C117.703 197.772 116.127 195.293 114.83 192.667C111.558 186.039 110.161 178.671 108.796 171.403C102.037 171.844 86.6866 168.835 90.5097 179.139C93.5452 187.363 100.246 192.951 104.568 200.308Z"
        fill="#454F5B"
      />
      <path
        d="M119.971 231.928C120.076 232.302 120.254 232.65 120.497 232.952C121.003 233.442 121.723 233.64 122.408 233.478C123.081 233.312 123.72 233.033 124.299 232.653C124.623 232.501 124.9 232.266 125.102 231.97C125.294 231.459 125.275 230.892 125.05 230.395L124.099 227.386C124.07 227.203 123.963 227.042 123.805 226.944C123.598 226.905 123.384 226.965 123.227 227.107C122.177 227.806 120.602 227.895 119.635 228.588C118.669 229.281 119.599 230.883 119.971 231.928Z"
        fill="#B78103"
      />
      <path
        d="M87.8787 233.351C88.8221 233.972 89.8647 234.427 90.9614 234.696C91.1322 234.75 91.3158 234.75 91.4865 234.696C91.7386 234.606 91.8542 234.323 91.9382 234.071L93.2984 230.174C93.1356 230.647 90.3837 228.262 89.7325 228.504C88.5194 228.982 88.115 232.254 87.8787 233.351Z"
        fill="#B78103"
      />
      <path
        d="M123.842 230.568C124.431 230.358 125.046 230.229 125.669 230.185C127.418 230.143 129.088 231.429 130.79 231.03C131.23 230.877 131.679 230.749 132.134 230.647C133.253 230.604 134.235 231.389 134.44 232.49C134.604 233.575 134.252 234.674 133.489 235.463C132.727 236.232 131.823 236.848 130.827 237.274C128.781 238.28 126.588 238.954 124.33 239.27C123.763 239.384 123.175 239.351 122.623 239.175C122.173 238.971 121.779 238.661 121.473 238.272C120.381 237.022 119.142 234.827 119.336 233.084C119.541 231.24 122.377 231.02 123.842 230.568Z"
        fill="#7A4F01"
      />
      <path
        d="M92.6207 233.882C92.8678 233.952 93.0995 234.067 93.3034 234.223C93.5317 234.479 93.6971 234.785 93.7866 235.116C94.381 236.686 95.4035 238.058 96.738 239.076C97.293 239.419 97.7807 239.86 98.1769 240.378C98.5697 240.926 98.4883 241.681 97.9879 242.132C97.6852 242.299 97.3397 242.372 96.9953 242.342C95.8564 242.311 94.7211 242.2 93.5975 242.011C90.5463 241.57 87.2955 241.04 85.0058 238.976C84.3798 238.487 83.957 237.784 83.819 237.001C83.7244 236.004 84.3441 235.216 84.8693 234.439C85.2474 233.871 85.7358 232.606 86.3292 232.233C87.6684 231.408 91.25 233.409 92.6207 233.882Z"
        fill="#7A4F01"
      />
      <path
        d="M159.705 122.274C159.705 122.274 159.668 122.846 159.621 123.723C159.464 126.465 159.133 132.158 159.096 132.257C159.059 132.357 33.1565 137.835 33.1565 137.835L32.6313 130.482L32.5 129.059L39.8523 128.991L159.705 122.274Z"
        fill="#00AB55"
      />
      <path
        d="M159.621 123.723C159.464 126.465 159.133 132.158 159.096 132.257C159.059 132.357 33.1565 137.835 33.1565 137.835L32.6313 130.482L39.8524 130.424L159.621 123.723Z"
        fill="#005249"
      />
      <path
        d="M159.705 125.798C159.705 125.798 159.668 126.37 159.621 127.247C159.464 129.994 159.133 135.687 159.096 135.781C159.059 135.876 33.1565 141.343 33.1565 141.343L32.6313 133.99L32.5 132.583L39.8523 132.515L159.705 125.798Z"
        fill="url(#paint21_linear_5296_328282)"
      />
      <path
        d="M170.912 158.4L211.775 170.915L211.749 160.49L170.912 147.949V158.4Z"
        fill="#00AB55"
      />
      <path
        d="M211.775 170.915H211.77L170.912 158.405V147.949L211.749 160.49L211.775 170.915Z"
        fill="url(#paint22_linear_5296_328282)"
      />
      <path
        d="M211.77 170.915L170.912 158.405V149.204L211.749 161.745L211.77 170.915Z"
        fill="url(#paint23_linear_5296_328282)"
      />
      <rect
        x="212.411"
        y="116.613"
        width="92.3816"
        height="8.43414"
        fill="#00AB55"
      />
      <rect
        x="212.411"
        y="117.852"
        width="92.3816"
        height="7.19475"
        fill="url(#paint24_linear_5296_328282)"
      />
      <rect
        x="212.411"
        y="119.081"
        width="92.3816"
        height="9.64201"
        fill="#00AB55"
      />
      <rect
        x="212.411"
        y="120.32"
        width="92.3816"
        height="8.36061"
        fill="url(#paint25_linear_5296_328282)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.9258 145.019C101.254 143.412 101.774 141.8 102.389 139.804C102.573 139.205 101.99 139.179 101.302 140.817C101.475 139.657 101.827 137.782 101.827 137.336C101.212 136.768 101.076 137.803 100.903 138.223C100.63 138.779 100.387 139.349 100.173 139.93C100.278 138.932 100.267 138.465 100.372 137.467C100.372 137.283 100.372 137.031 100.188 136.989C99.243 137.178 99.6159 139.379 98.7757 140.219C98.9122 139.3 99.054 137.126 98.7757 137.267C98.2505 137.267 97.9984 137.94 97.9039 138.47L97.3787 141.448C97.0846 141.358 97.0269 140.98 96.9533 140.681C96.8798 140.382 96.5595 140.051 96.3126 140.235C96.2322 140.305 96.1799 140.402 96.1656 140.508C96.139 140.884 96.1673 141.263 96.2496 141.631C96.3177 142.359 96.0645 143.081 95.5564 143.606C96.9466 144.261 98.415 144.736 99.9258 145.019Z"
        fill="#BA8B72"
      />
      <path
        d="M170.912 147.949H180.224L204.418 154.54V158.248L170.912 147.949Z"
        fill="url(#paint26_linear_5296_328282)"
      />
      <rect
        x="170.912"
        y="72.0369"
        width="1.31291"
        height="136.663"
        fill="#00AB55"
      />
      <rect
        x="204.418"
        y="86.1375"
        width="9.31116"
        height="137.898"
        fill="#00AB55"
      />
      <rect
        x="205.484"
        y="86.1375"
        width="9.31116"
        height="137.898"
        fill="url(#paint27_linear_5296_328282)"
      />
      <rect
        x="208.136"
        y="86.1375"
        width="1.31291"
        height="137.898"
        fill="#00AB55"
      />
      <path
        d="M172.225 105.868L204.418 117.852V128.681L172.225 114.701V105.868Z"
        fill="#00AB55"
      />
      <path
        d="M172.225 107.254L204.418 119.244V128.681L172.225 114.701V107.254Z"
        fill="url(#paint28_linear_5296_328282)"
      />
      <path
        d="M176.505 105.196L204.418 115.589V116.828L176.505 106.466V105.196Z"
        fill="#00AB55"
      />
      <rect
        x="264.113"
        y="14.8464"
        width="9.31116"
        height="81.8311"
        fill="#00AB55"
      />
      <rect
        x="268.068"
        y="14.8464"
        width="5.35667"
        height="133.817"
        fill="url(#paint29_linear_5296_328282)"
      />
      <path
        d="M273.424 43.3259C273.424 43.0371 306.888 54.764 306.888 54.764L307.686 66.2441L272.684 53.5771L272.594 43.3049L273.424 43.3259Z"
        fill="#00AB55"
      />
      <path
        d="M272.589 44.3133C272.589 44.0245 306.888 56.3921 306.888 56.3921L307.686 65.7768L272.668 53.1151L272.589 44.3133Z"
        fill="url(#paint30_linear_5296_328282)"
      />
      <path
        d="M270.804 45.6209C270.804 45.3268 304.262 57.0537 304.262 57.0537L305.06 67.8196L270.058 55.1526L269.969 45.6209H270.804Z"
        fill="#00AB55"
      />
      <path
        d="M269.969 46.3562C269.969 46.0621 304.262 58.435 304.262 58.435L305.06 67.8197L270.058 55.1527L269.969 46.3562Z"
        fill="url(#paint31_linear_5296_328282)"
      />
      <rect
        x="301.164"
        y="26.2268"
        width="9.31116"
        height="137.898"
        fill="#00AB55"
      />
      <rect
        x="302.229"
        y="26.2268"
        width="9.31116"
        height="137.898"
        fill="url(#paint32_linear_5296_328282)"
      />
      <rect
        x="304.882"
        y="26.2268"
        width="1.31291"
        height="137.898"
        fill="#00AB55"
      />
      <rect
        x="167.194"
        y="12"
        width="9.31116"
        height="136.663"
        fill="#00AB55"
      />
      <rect
        x="168.26"
        y="12"
        width="9.31116"
        height="136.663"
        fill="url(#paint33_linear_5296_328282)"
      />
      <rect
        x="172.918"
        y="42.864"
        width="92.3816"
        height="8.70722"
        fill="#00AB55"
      />
      <rect
        x="172.918"
        y="42.864"
        width="92.3816"
        height="8.70722"
        fill="url(#paint34_linear_5296_328282)"
      />
      <rect
        x="174.126"
        y="45.1589"
        width="92.3816"
        height="8.70722"
        fill="#00AB55"
      />
      <rect
        x="172.918"
        y="43.9509"
        width="92.3816"
        height="9.9151"
        fill="url(#paint35_linear_5296_328282)"
      />
      <path
        d="M204.418 55.5519L176.505 45.1589V47.4224L204.418 57.8154V55.5519Z"
        fill="#00AB55"
      />
      <path
        d="M204.418 55.5519L176.505 45.1589V47.4224L204.418 57.8154V55.5519Z"
        fill="url(#paint36_linear_5296_328282)"
      />
      <rect
        x="176.505"
        y="45.1589"
        width="87.6079"
        height="0.908534"
        fill="#00AB55"
      />
      <rect
        x="176.505"
        y="42.2546"
        width="87.6079"
        height="0.908534"
        fill="#00AB55"
      />
      <rect x="170.912" y="12" width="5.593" height="136.663" fill="#00AB55" />
      <rect
        x="170.912"
        y="12"
        width="5.593"
        height="136.663"
        fill="url(#paint37_linear_5296_328282)"
      />
      <rect
        x="212.411"
        y="56.5759"
        width="92.3816"
        height="8.43414"
        fill="#00AB55"
      />
      <rect
        x="212.411"
        y="57.8154"
        width="92.3816"
        height="7.19475"
        fill="url(#paint38_linear_5296_328282)"
      />
      <rect
        x="212.411"
        y="59.0442"
        width="92.3816"
        height="9.64201"
        fill="#00AB55"
      />
      <rect
        x="212.411"
        y="60.2837"
        width="92.3816"
        height="8.36061"
        fill="url(#paint39_linear_5296_328282)"
      />
      <rect
        x="170.912"
        y="12"
        width="1.31291"
        height="136.663"
        fill="#00AB55"
      />
      <rect
        x="204.418"
        y="26.1006"
        width="9.31116"
        height="137.898"
        fill="#00AB55"
      />
      <rect
        x="205.484"
        y="26.1006"
        width="9.31116"
        height="137.898"
        fill="url(#paint40_linear_5296_328282)"
      />
      <rect
        x="208.136"
        y="26.1006"
        width="1.31291"
        height="137.898"
        fill="#00AB55"
      />
      <path
        d="M172.225 45.8311L204.418 57.8153V68.6442L172.225 54.6643V45.8311Z"
        fill="#00AB55"
      />
      <path
        d="M172.225 47.2175L204.418 59.207V68.6442L172.225 54.6644V47.2175Z"
        fill="url(#paint41_linear_5296_328282)"
      />
      <path
        d="M176.505 45.1589L204.418 55.5519V56.7861L176.505 46.4246V45.1589Z"
        fill="#00AB55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.367 123.198C124.091 123.537 123.879 123.923 123.742 124.338C123.592 124.657 123.515 125.005 123.516 125.357C123.524 125.717 123.754 126.035 124.094 126.155C124.183 126.186 124.281 126.176 124.362 126.129C124.418 126.085 124.461 126.027 124.488 125.961C124.772 125.422 125.014 124.862 125.212 124.285C124.775 124.832 124.688 125.58 124.987 126.213C125.092 126.428 125.296 126.638 125.512 126.586C125.727 126.533 125.811 126.302 125.869 126.097C126.005 125.614 126.116 125.126 126.205 124.637C126.132 124.951 126.084 125.271 126.063 125.593C126.046 125.758 126.068 125.926 126.126 126.081C126.191 126.24 126.349 126.339 126.52 126.328C126.661 126.288 126.781 126.196 126.856 126.071C127.228 125.587 127.537 125.058 127.775 124.495C127.662 125.017 127.684 125.56 127.838 126.071C127.86 126.149 127.906 126.219 127.97 126.27C128.185 126.433 128.495 126.192 128.621 125.961C128.897 125.523 129.103 125.044 129.23 124.543C129.313 124.278 129.331 123.997 129.282 123.723C129.23 123.442 129.029 123.21 128.757 123.119C128.481 123.049 128.199 123.212 128.122 123.487C128.169 123.135 127.775 122.852 127.423 122.888C127.074 122.959 126.766 123.165 126.567 123.461C126.54 123.123 126.27 122.855 125.932 122.831C125.317 122.773 124.871 122.636 124.367 123.198Z"
        fill="#BA8B72"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_5296_328282"
        x="20.5"
        y="0"
        width="327.041"
        height="283.182"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="12" dy="12" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.32 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5296_328282"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5296_328282"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_5296_328282"
        x1="243.682"
        y1="308.245"
        x2="369.178"
        y2="18.602"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#00AB55" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5296_328282"
        x1="98.7677"
        y1="110.061"
        x2="99.0458"
        y2="113.537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5296_328282"
        x1="63.2425"
        y1="100.689"
        x2="63.2999"
        y2="126.89"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5296_328282"
        x1="74.8504"
        y1="109.88"
        x2="74.8791"
        y2="124.669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5296_328282"
        x1="283.605"
        y1="-789.321"
        x2="209.094"
        y2="-789.317"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5296_328282"
        x1="249.563"
        y1="99.9437"
        x2="248.496"
        y2="150.581"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5296_328282"
        x1="259.538"
        y1="88.5221"
        x2="240.09"
        y2="147.259"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_5296_328282"
        x1="310.816"
        y1="-29.8819"
        x2="285.839"
        y2="-29.8777"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_5296_328282"
        x1="270.205"
        y1="169.234"
        x2="304.792"
        y2="169.234"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_5296_328282"
        x1="176.831"
        y1="-42.4948"
        x2="151.933"
        y2="-42.4905"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_5296_328282"
        x1="440.9"
        y1="159.201"
        x2="440.713"
        y2="118.091"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_5296_328282"
        x1="-80.3591"
        y1="144.116"
        x2="-80.3592"
        y2="152.298"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_5296_328282"
        x1="73.1515"
        y1="97.0608"
        x2="73.1515"
        y2="124.574"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_5296_328282"
        x1="73.0967"
        y1="97.334"
        x2="73.0967"
        y2="128.676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_5296_328282"
        x1="172.585"
        y1="107.014"
        x2="172.002"
        y2="123.891"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_5296_328282"
        x1="169.986"
        y1="323.144"
        x2="184.943"
        y2="323.144"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_5296_328282"
        x1="54.2685"
        y1="157.743"
        x2="54.2709"
        y2="190.902"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint17_linear_5296_328282"
        x1="94.1927"
        y1="139.02"
        x2="95.6594"
        y2="175.61"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
      <linearGradient
        id="paint18_linear_5296_328282"
        x1="101.853"
        y1="95.2181"
        x2="48.6964"
        y2="96.6364"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint19_linear_5296_328282"
        x1="83.7109"
        y1="137.605"
        x2="85.2512"
        y2="163.683"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_5296_328282"
        x1="14.3917"
        y1="132.234"
        x2="14.569"
        y2="160.426"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint21_linear_5296_328282"
        x1="47.7229"
        y1="129.968"
        x2="47.7717"
        y2="141.701"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_5296_328282"
        x1="161.822"
        y1="146.577"
        x2="160.952"
        y2="180.897"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_5296_328282"
        x1="162.056"
        y1="148.062"
        x2="161.327"
        y2="180.192"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_5296_328282"
        x1="-4.52956"
        y1="111.116"
        x2="-4.53657"
        y2="152.148"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_5296_328282"
        x1="5.28117"
        y1="112.925"
        x2="5.27059"
        y2="158.835"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_5296_328282"
        x1="164.442"
        y1="147.423"
        x2="164.332"
        y2="161.978"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_5296_328282"
        x1="214.066"
        y1="-30.0131"
        x2="189.089"
        y2="-30.0089"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_5296_328282"
        x1="166.435"
        y1="107.118"
        x2="164.497"
        y2="138.164"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_5296_328282"
        x1="283.605"
        y1="-849.358"
        x2="209.094"
        y2="-849.354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint30_linear_5296_328282"
        x1="249.573"
        y1="39.9068"
        x2="248.505"
        y2="90.544"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint31_linear_5296_328282"
        x1="259.544"
        y1="28.4766"
        x2="240.086"
        y2="87.2194"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_5296_328282"
        x1="310.816"
        y1="-89.9185"
        x2="285.839"
        y2="-89.9143"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_5296_328282"
        x1="176.831"
        y1="-102.532"
        x2="151.933"
        y2="-102.527"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_5296_328282"
        x1="73.1515"
        y1="37.0242"
        x2="73.1515"
        y2="64.5375"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_5296_328282"
        x1="73.1212"
        y1="37.2971"
        x2="73.1212"
        y2="68.6336"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_5296_328282"
        x1="172.585"
        y1="46.9773"
        x2="172.002"
        y2="63.8546"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_5296_328282"
        x1="169.986"
        y1="263.108"
        x2="184.943"
        y2="263.107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_5296_328282"
        x1="-4.56328"
        y1="51.0746"
        x2="-4.57028"
        y2="92.1111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint39_linear_5296_328282"
        x1="5.28117"
        y1="52.8887"
        x2="5.27059"
        y2="98.7988"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_5296_328282"
        x1="214.066"
        y1="-90.05"
        x2="189.089"
        y2="-90.0458"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
      <linearGradient
        id="paint41_linear_5296_328282"
        x1="166.435"
        y1="47.0816"
        x2="164.497"
        y2="78.1278"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.01" />
        <stop offset="0.97" />
      </linearGradient>
    </defs>
  </svg>
);

export default Maintenence;
