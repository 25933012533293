import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../rootReducer';
import { contactOffline } from '../../config/offline/contact';
import configOfflineCacheSlice from '../../utils/offline/configOfflineCacheSlice';
import { commitContact } from './commitContact';

export const selectAppContact = (state: RootState) => state.cache_contact;
export const selectCacheContacts = createSelector(
  selectAppContact,
  (state) => state.byId
);

const contactSlice = configOfflineCacheSlice(contactOffline);
export const contactActions = { commitContact, ...contactSlice.actions };
export default contactSlice;
