import qs from 'qs';
import { assert } from 'ts-essentials';
import { API_URI } from '../config/env';
import { fetchData, post, put } from '../utils/fetch';
import authService from './auth';
import mediaService from './media';

async function getProducts(lastUpdate: Date) {
  const business_id = await authService.getBusinessId();
  return fetchData<Array<JSONRecord<Product>>>(
    `${API_URI}/finan-product/api/v1/product/seller/get-list?${qs.stringify({
      business_id,
      page: 0,
      page_size: 1000,
      sort: '',
      name: '',
      update_after: lastUpdate.toISOString(),
      has_record_deleted: true,
    })}`,
    {
      authorization: true,
    }
  );
}

async function getProduct(id: string) {
  const product = await fetchData<JSONRecord<Product>>(
    `${API_URI}/finan-product/api/v1/product/seller/get-detail/${id}`,
    {
      authorization: true,
    }
  );
  return product;
}

async function getCategories(lastUpdate: Date) {
  const business_id = await authService.getBusinessId();
  const result = await fetchData<
    Array<
      JSONRecord<
        {
          category: Omit<Category, keyof CategoryStatistic>;
        } & CategoryStatistic
      >
    >
  >(
    `${API_URI}/finan-product/api/v1/pro-web/category/get-list?${qs.stringify({
      business_id,
      update_after: lastUpdate.toISOString(),
      has_record_deleted: true,
      page: 1,
      page_size: 1000,
    })}`,
    {
      authorization: true,
    }
  );
  return result.map((r) => {
    const { category, ...other } = r;
    return {
      ...category,
      ...other,
    };
  });
}

async function updateProduct(id: string, product: PendingProduct) {
  const business_id = (await authService.getBusinessId()) || '';

  return await put(
    `${API_URI}/finan-product/api/v1/product/update/${id}`,
    {
      business_id,
      ...prepaireBackendProduct(product, business_id),
      category_ids: product.category,
    },
    { authorization: true }
  );
}

async function createProduct(product: PendingProduct) {
  const business_id = (await authService.getBusinessId()) || '';

  return await post<JSONRecord<Product>>(
    `${API_URI}/finan-product/api/v1/product/create`,
    {
      business_id,
      ...prepaireBackendProduct(product, business_id),
    },
    {
      authorization: true,
    }
  );
}

export async function addProductToCategory({
  product_ids,
  category_ids,
}: {
  product_ids: Array<string>;
  category_ids: Array<string>;
}) {
  const business_id = await authService.getBusinessId();
  return await post(
    `${API_URI}/ms-product-management/api/create-category-has-product`,
    {
      category_id: category_ids,
      product_id: product_ids,
      business_id,
    },
    {
      authorization: true,
    }
  );
}

async function deleteProduct(id: string) {
  return await fetchData(
    `${API_URI}/ms-product-management/api/product/v2/${id}`,
    {
      method: 'DELETE',
      authorization: true,
    }
  );
}

async function uploadProductImage(image: PendingUploadImage) {
  const media_type: string | undefined = /\/([^$]+$)/.exec(
    image.mime_type
  )?.[1];
  const content = await mediaService.readLocal(image.name);

  assert(!!media_type, 'media_type can not be empty');
  assert(!!content, 'upload file can not be empty');

  const uploadParams = {
    name: image.name,
    media_type,
  };
  const uploadLink = await mediaService.createUploadLink(uploadParams);
  await mediaService.upload(uploadLink, {
    mime_type: image.mime_type,
    content,
  });
  const { upload_url } = await mediaService.commitUploadLink(uploadParams);

  return upload_url;
}

const productService = {
  createProduct,
  deleteProduct,
  updateProduct,
  addProductToCategory,
  getProducts,
  getProduct,
  getCategories,
  uploadProductImage,
};

function prepaireBackendProduct(
  {
    skus,
    is_advance_stock,
    is_variant,
    id: _omitId,
    category: _omitCategory,
    ...others
  }: PendingProduct,
  business_id: string
) {
  // if is_variant = undefined (update price in table)
  const sku = skus[0];
  assert(sku, 'A product need to have atleast one sku');
  const data = {
    ...others,
    product_type: is_variant ? 'variant' : 'non_variant',
    // barcode: product.barcode,
    // sku_code: product.sku_code,
    // TODO: stupid remove it if you can
    list_sku: skus.map(({ id, client_id: _client_id, ...other }, index) => ({
      ...(id
        ? { id, ...other }
        : {
            id: '00000000-0000-0000-0000-000000000000',
            ...other,
            product_id: null,
            business_id,
          }),
      priority: index + 1,
      attribute_types: is_variant
        ? [
            {
              attribute: other.name,
              attribute_type: 'default_attribute',
            },
          ]
        : [],
      number_attribute_type: 0,
      ...(!is_variant
        ? { barcode: other.barcode, sku_code: other.sku_code }
        : {}),
    })),
  };

  return data;
}

export default productService;
