import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './screens/Loader';
import './config/i18n';
import App from './App';
import ThemeProvider from './theme';
import { createStore, getPersistor } from './store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { DSN_SENTRY } from './config/env';

(async function () {
  const store = await createStore();
  const persistor = getPersistor();
  Sentry.init({
    dsn: DSN_SENTRY,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  ReactDOM.render(
    <Router>
      <ThemeProvider>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <App />
            </PersistGate>
          </LocalizationProvider>
        </Provider>
      </ThemeProvider>
    </Router>,
    document.getElementById('root')
  );
})();
