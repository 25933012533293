import { Table, TableBody, TableContainer } from '@mui/material';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { TableHeader } from '../../components/TableHeader';
import useResponsive from '../../hooks/useResponsive';
import OrderItem from './OrderItem';
import { TABLE_HEAD } from './settings';

const OrderItemsTable = () => {
  const formik = useFormikContext<PendingOrderForm>();
  const { list_order_item } = formik.values;
  const isDesktop = useResponsive('up', 'lg');

  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader>
        <TableHeader
          headLabel={
            isDesktop
              ? TABLE_HEAD
              : TABLE_HEAD.filter(
                  (column) => column.id !== 'id' && column.id !== 'sku_code'
                )
          }
          padding="8px"
        />
        <TableBody>
          {list_order_item.map((item, index) => (
            <OrderItem item={item} index={index} key={item.sku_id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(OrderItemsTable);
