import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Stack,
  styled,
  Toolbar,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HEADER } from '../../config/styles';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import { selectAppAuth } from '../../store/auth';
import ReportDialog from './ReportDialog';
import AccountPopover from '../AccountPopover';
// import OfflineButton from '../OfflineButon';
import CommingSoonDialog from '../../components/CommingSoonDialog';

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isOffset' && prop !== 'verticalLayout' && prop !== 'isCollapse',
})<RootStyleProps>(({ isOffset, verticalLayout, theme }) => ({
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: '100%',
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.darker),
}));

type HeaderProps = {
  children?: React.ReactNode;
};

const Header = ({ children }: HeaderProps) => {
  const navigate = useNavigate();
  const { business, user_info } = useAppSelector(selectAppAuth);
  const [openDownload, setOpenDownload] = useState(false);
  const [openAccount, setOpenAccount] = useState<HTMLElement | null>(null);
  const [openCommingSoon, setOpenCommingSoon] = useState(false);
  return (
    <RootStyle isCollapse={true} verticalLayout={false} isOffset={true}>
      <Toolbar style={{ paddingLeft: 0 }}>
        {children}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          alignContent="space-around"
          spacing={2}
        >
          {/* <OfflineButton isPosHeader /> */}
          {/* <CustomIconButton onClick={() => setOpenCommingSoon(true)}>
            <Iconify icon="feather:download" />
          </CustomIconButton>
          <CustomIconButton onClick={() => setOpenCommingSoon(true)}>
            <Iconify icon="fluent:print-28-filled" />
          </CustomIconButton> */}
          <Tooltip title="Trang chủ">
            <CustomIconButton onClick={() => navigate('/')}>
              <Iconify icon="eva:home-fill" />
            </CustomIconButton>
          </Tooltip>
          <CustomIconButton onClick={(e) => setOpenAccount(e.currentTarget)}>
            <Avatar src={business?.avatar || user_info?.avatar} />
          </CustomIconButton>
        </Stack>
      </Toolbar>
      <ReportDialog open={openDownload} setOpen={setOpenDownload} />
      <AccountPopover open={openAccount} setOpen={setOpenAccount} />
      <CommingSoonDialog
        open={openCommingSoon}
        onClose={() => setOpenCommingSoon(false)}
      />
    </RootStyle>
  );
};

export default Header;
