import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

type ConfirmationProps = {
  open: boolean;
  title: string;
  description: string;
  cancelLabel?: string;
  confirmLabel?: string;
  onConfirm(
    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>
  ): void;
  onClose(
    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>
  ): void;
};

const Confirmation = ({
  open,
  title,
  description,
  cancelLabel = 'Huỷ',
  confirmLabel = 'Xác nhận',
  onConfirm,
  onClose,
}: ConfirmationProps) => {
  const theme = useTheme();
  return (
    <Dialog open={Boolean(open)} onClose={onClose}>
      <DialogTitle
        color={theme.palette.grey[800]}
        sx={{ fontSize: 18, fontWeight: 600 }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ mt: theme.spacing(2), textAlign: 'center' }}>
        <DialogContentText
          color={theme.palette.grey[800]}
          sx={{ m: theme.spacing(1.5) }}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button variant="contained" onClick={onConfirm} autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
