import { memo } from 'react';
import { styled } from '@mui/material';
import MainLayout from '../../layouts/MainLayout';
import { createPortal } from 'react-dom';
import useAppSelector from '../../hooks/useAppSelector';
import { selectAppAuth } from '../../store/auth';
import { selectApp } from '../../store/app';
import useResponsive from '../../hooks/useResponsive';

type IframeStyleProps = {
  openSidebar: boolean;
  isDesktop: boolean;
};

const IframeStyle = styled('iframe')<IframeStyleProps>(
  ({ openSidebar, isDesktop }) => ({
    position: 'fixed',
    width: `calc(100vw - ${
      !isDesktop ? '0px' : openSidebar ? '281px' : '88px'
    })`,
    height: '93%',
    top: 64,
    right: 0,
    zIndex: 999,
    border: 0,
  })
);

const Chat = () => {
  const { sidebar } = useAppSelector(selectApp);
  const { business } = useAppSelector(selectAppAuth);
  const portalEl = document.getElementById('portal');
  const isDesktop = useResponsive('up', 'lg');

  return (
    portalEl &&
    createPortal(
      <MainLayout paddingVertical="0px">
        <IframeStyle
          openSidebar={sidebar.open}
          isDesktop={isDesktop || true}
          src={`https://chat.finan.me/chat/${business?.id}`}
        />
      </MainLayout>,
      portalEl
    )
  );
};

export default memo(Chat);
