const Shop = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      opacity="0.48"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5659 3.65235C14.5659 2.74299 15.3095 2 16.2189 2C17.1283 2 17.8745 2.74299 17.8745 3.65235C17.8745 4.56173 17.1283 5.30535 16.2189 5.30535C15.3095 5.30535 14.5659 4.56173 14.5659 3.65235ZM8.09277 5.9694C8.52567 4.44402 9.92817 3.32162 11.59 3.32162C13.2518 3.32162 14.6543 4.44402 15.0872 5.9694H8.09277Z"
      fill="#919EAB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99154 4.64648C2.68832 4.64648 2.42022 4.77141 2.24934 4.96365C2.07846 5.1559 2 5.39848 2 5.63738C2 5.87628 2.07846 6.11886 2.24934 6.31111C2.42022 6.50335 2.68832 6.62892 2.99154 6.62892H4.82024L6.64829 16.4113C6.73561 16.8781 7.14962 17.2123 7.62046 17.2123H18.2038C18.6577 17.2123 19.0645 16.9023 19.1682 16.4533L21.1532 7.8543C21.2951 7.2399 20.8246 6.63086 20.1888 6.63086H6.8466L6.60954 5.44489C6.51763 4.98374 6.10824 4.64775 5.63802 4.64648H2.99154ZM9.60483 17.8737C8.69547 17.8737 7.95248 18.6174 7.95248 19.5267C7.95247 20.4361 8.69546 21.1817 9.60483 21.1817C10.5142 21.1817 11.2578 20.4361 11.2578 19.5267C11.2578 18.6174 10.5142 17.8737 9.60483 17.8737ZM14.5658 19.5267C14.5658 18.6174 15.3094 17.8737 16.2188 17.8737C17.1281 17.8737 17.8743 18.6174 17.8744 19.5267C17.8744 20.4361 17.1281 21.1817 16.2188 21.1817C15.3094 21.1817 14.5658 20.4361 14.5658 19.5267Z"
      fill="#637381"
    />
  </svg>
);

export default Shop;
