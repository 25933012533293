import {
  Avatar,
  Button,
  Checkbox,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import orderService from '../../services/order';
import { toastActions } from '../../store/toast';
import { numberFormat, ORDER_STATUS } from '../../utils/constants';
import { getErrorMessage } from '../../utils/errorUtils';
import { AlertCustom } from './OrderComponent';
import {
  pageOrdersActions,
  selectedLocalSelectedStatus,
  selectPageOrders,
} from './slice';
import { useNavigate } from 'react-router-dom';
import Confirmation from '../../components/Confirmation';
import { getOptimizeImage } from '../../utils/imageUtils';
import { selectAppAuth } from '../../store/auth';
import Iconify from '../../minimal/Iconify';

type OrderRow = {
  order: Order;
  index: number;
};

const OrderRow = ({ order, index }: OrderRow) => {
  const theme = useTheme();
  const selectedStatus = useAppSelector(selectedLocalSelectedStatus);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openCancel, setOpenCancel] = useState(false);
  const { business } = useAppSelector(selectAppAuth);
  const {
    main: { paymentMethod, arraySelected },
  } = useAppSelector(selectPageOrders);
  const orderItem = order.order_item;
  const handleCancel = async (id: string) => {
    if (id) {
      try {
        await orderService.updateOrder(id, {
          state: ORDER_STATUS.CANCEL,
          payment_method: paymentMethod[0]?.name || '',
          payment_source_id: paymentMethod[0]?.id || '',
          payment_source_name: paymentMethod[0]?.name || '',
          debit: {
            buyer_pay: order.amount_paid || 0,
            description: '',
            is_debit: true,
          },
        });
        business && dispatch(pageOrdersActions.getOrderAnalysis());

        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Hủy đơn hàng thành công',
            dismissAfter: 3000,
          })
        );
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    }
  };

  const handleDelivering = async (id: string, state: string) => {
    if (id) {
      try {
        const res = await orderService.updateOrder(id, {
          state: state,
          payment_method: paymentMethod[0]?.name || '',
          payment_source_id: paymentMethod[0]?.id || '',
          payment_source_name: paymentMethod[0]?.name || '',
          debit: {
            buyer_pay: order.amount_paid || 0,
            description: '',
            is_debit: true,
          },
        });
        if (res.status && res.status === 'sold_out') {
          dispatch(
            toastActions.create({
              severity: 'error',
              message: 'Có sản phẩm hết hàng trong đơn hàng của Bạn',
              dismissAfter: 3000,
            })
          );
          return;
        }
        business && dispatch(pageOrdersActions.getOrderAnalysis());

        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Đã cập nhật thành công',
            dismissAfter: 3000,
          })
        );
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    }
  };
  return (
    <>
      <TableRow
        key={order.id + order.order_number}
        sx={{ pr: 0, cursor: 'pointer' }}
        onClick={() => navigate(`/orders/${order.order_number}`)}
        hover
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={!!arraySelected[index]}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                pageOrdersActions.setArraySelected([
                  index,
                  order.id,
                  null,
                  false,
                ])
              );
            }}
          />
        </TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            key={orderItem[0]?.id}
            alt={orderItem[0]?.product_name}
            sx={{ boxShadow: 3, border: '2px solid #FFFFFF', mr: 2 }}
            src={getOptimizeImage(
              orderItem[0]?.product_images[0] || '/assets/product-image.png',
              { w: 128, h: 128, fit: 'crop' }
            )}
          >
            {order.buyer_info.name}
          </Avatar>

          <Stack>
            <Typography variant="subtitle2" noWrap>
              {order.buyer_info.name}
            </Typography>

            <Typography
              noWrap
              variant="body2"
              // onClick={onViewRow}
              sx={{ color: 'text.disabled' }}
            >
              {order.order_number}|{' '}
              {t('delivery_state', { context: order.delivery_method })}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="right">
          {' '}
          {selectedStatus?.id == ORDER_STATUS.CANCEL
            ? moment(order.updated_at).format('DD/MM/YYYY HH:mm')
            : moment(order.created_at).format('DD/MM/YYYY HH:mm')}
        </TableCell>

        <TableCell align="left">
          {/* {!selectedStatus?.id && ( */}
          <AlertCustom orderState={order.state} icon={false}>
            {t('order_state', { context: order.state })}
          </AlertCustom>
          {/* )} */}
        </TableCell>

        <TableCell align="right">
          <Typography variant="h6" fontWeight={500}>
            {numberFormat.format(order.grand_total)}
          </Typography>
          {order.amount_paid == order.grand_total ? (
            <Typography
              variant="body2"
              fontWeight={500}
              color={theme.palette.primary.main}
            >
              Đã thanh toán
            </Typography>
          ) : order.amount_paid > 0 && order.amount_paid < order.grand_total ? (
            <Typography variant="body2" fontWeight={500} color={'#FFA800'}>
              Thanh toán một phần
            </Typography>
          ) : (
            <Typography
              variant="body2"
              fontWeight={500}
              color={theme.palette.error.main}
            >
              Chưa thanh toán
            </Typography>
          )}
        </TableCell>
        {order.state !== ORDER_STATUS.CANCEL && (
          <TableCell align="right" sx={{ textTransform: 'capitalize' }}>
            {order.state === ORDER_STATUS.WAITING_CONFIRM && (
              <Button
                variant="contained"
                sx={{ mr: 1, fontSize: 13 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelivering(order.id, ORDER_STATUS.DELIVERING);
                }}
              >
                Xác nhận
              </Button>
            )}
            {order.state === ORDER_STATUS.DELIVERING && (
              <Button
                variant="outlined"
                sx={{ mr: 1, fontSize: 13 }}
                onClick={(e) => {
                  e.stopPropagation();

                  dispatch(pageOrdersActions.setOrderPaymentDialog(order));
                }}
              >
                Đã giao
              </Button>
            )}
            <Tooltip title="Huỷ đơn hàng">
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenCancel(true);
                }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      <Confirmation
        open={openCancel}
        title="Huỷ đơn hàng"
        description="Sau khi huỷ đơn hàng sẽ ảnh hưởng đến số liệu thu chi.
        Bạn có chắc muốn huỷ đơn hàng này?"
        cancelLabel="Quay lại"
        confirmLabel="Huỷ đơn hàng"
        onClose={() => setOpenCancel(false)}
        onConfirm={() => {
          handleCancel(order.id);
          setOpenCancel(false);
        }}
      />
    </>
  );
};

export default memo(OrderRow);
