import {
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  styled,
} from '@mui/material';
import { SearchOrderProps } from '../../hooks/createSearchOrderHook';

export type TableHeaderSortProps = {
  headLabel: Array<{
    id: string;
    label: string;
    hidden?: boolean;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  }>;
} & SearchOrderProps;

type TableCellStyleProps = {
  hidden?: boolean;
};

const TableCellStyle = styled(TableCell)<TableCellStyleProps>(({ hidden }) => ({
  '& svg': {
    display: hidden ? 'none' : 'block',
  },
}));

export default function TableHeaderSort({
  headLabel,
  orderBy,
  order,
  onSort,
}: TableHeaderSortProps) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCellStyle
            key={headCell.id}
            sortDirection={order || undefined}
            hidden={headCell.id === 'hidden'}
            sx={{ backgroundColor: '#E6E9EC', flexDirection: 'row-reverse' }}
            align={headCell.align}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order || undefined}
              onClick={() => {
                onSort(headCell.id, { lastOrderBy: orderBy, lastOrder: order });
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCellStyle>
        ))}
      </TableRow>
    </TableHead>
  );
}
