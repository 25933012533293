import { Box, styled, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import Transaction from '../../components/Icons/Transaction';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { numberFormat } from '../../utils/constants';
import { pageCashbooksActions, selectPageCashbooks } from './slice';

type BoxStyleProps = {
  bgColor: string;
  type: string;
  isActive: boolean;
};



const BoxStyle = styled(Box)<BoxStyleProps>(
  ({ bgColor, type, isActive = false, theme }) => ({
    padding: theme.spacing(0, 4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: bgColor,
    borderRadius: +theme.shape.borderRadius * 2,
    cursor: 'pointer',
    ...(isActive && {
      border: `2px solid ${type === 'in'
        ? theme.palette.primary.darker
        : theme.palette.error.darker
        }`,
    }),
  })
);

const Header = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const {
    main: { transactionType, amount },
  } = useAppSelector(selectPageCashbooks);

  return (
    <>

      <Grid container spacing={2} sx={{ my: 2, '& .MuiGrid-item': { pt: 0 } }}>
        <Grid item xs={12} md={6}>
          <BoxStyle
            bgColor="#FFF2EB"
            type={transactionType}
            isActive={transactionType === 'out'}
            onClick={() => {
              if (transactionType === 'out')
                dispatch(pageCashbooksActions.selectTransactionType('all'));
              else dispatch(pageCashbooksActions.selectTransactionType('out'));
            }}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color={theme.palette.error.darker}
              >
                Tổng tiền chi
              </Typography>

              <Typography variant="h3" color={theme.palette.error.darker}>
                {numberFormat.format(amount.total_amount_out)}
              </Typography>
            </Box>
            <Transaction />
          </BoxStyle>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxStyle
            bgColor="#F6FFEC"
            type={transactionType}
            isActive={transactionType === 'in'}
            onClick={() => {
              if (transactionType === 'in')
                dispatch(pageCashbooksActions.selectTransactionType('all'));
              else dispatch(pageCashbooksActions.selectTransactionType('in'));
            }}
          >
            <Box>
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.darker}
              >
                Tổng tiền thu
              </Typography>
              <Typography variant="h3" color={theme.palette.primary.darker}>
                {numberFormat.format(amount.total_amount_in)}
              </Typography>
            </Box>
            <Transaction fill={theme.palette.primary.light} />
          </BoxStyle>
        </Grid>
      </Grid>

    </>
  );
};

export default memo(Header);
