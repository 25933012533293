import { DatePicker } from '@mui/lab';
import { Box, TextField, InputAdornment, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BoxStyle } from './ContactDetailComponent';
import { getFieldProps } from '../../utils/formik';
import { memo } from 'react';
import TextFieldNumber from '../../components/TextFieldNumber';

type DeptFormProps = {
  formik: ExpectedAny;
};

const DeptForm = ({ formik }: DeptFormProps) => {
  const theme = useTheme();
  const { start_time } = formik && formik.values;
  const { errors, touched, setFieldValue } = formik;
  return (
    <Box>
      <BoxStyle>
        <TextFieldNumber
          id="amount"
          fullWidth
          label="Số tiền"
          placeholder="Nhập số tiền"
          value={formik.values.amount}
          onSetField={(value) => formik.setFieldValue('amount', value)}
          sx={{ mt: 2 }}
          autoFocus
          endAdornment={
            <InputAdornment position="end">
              <Typography variant="body1" color={theme.palette.grey[800]}>
                đ
              </Typography>
            </InputAdornment>
          }
        />
      </BoxStyle>
      <BoxStyle>
        <DatePicker
          label="Chọn thời gian thanh toán"
          value={start_time}
          onChange={(newValue) => setFieldValue('start_time', newValue)}
          inputFormat="dd/MM/yyyy"
          renderInput={(props) => <TextField {...props} fullWidth />}
        />
        {touched.start_time && errors.start_time && (
          <Typography
            variant="caption"
            color={theme.palette.error.main}
            sx={{ ml: '14px' }}
          >
            {errors.start_time}
          </Typography>
        )}
      </BoxStyle>
      <BoxStyle>
        <TextField
          fullWidth
          placeholder="Nhập ghi chú"
          {...getFieldProps(formik, 'description')}
        />
      </BoxStyle>
    </Box>
  );
};

export default memo(DeptForm);
