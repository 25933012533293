import { Avatar, Box, Button, styled, Typography } from '@mui/material';
import { memo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import NotePen from '../../components/Icons/NotePen';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import { numberFormat } from '../../utils/constants';
import ContactDialog from './ContactDialog';
import NewDeptDialog from './NewDeptDialog';
import PayingDeptDialog from './PayingDeptDialog';
import { pageContactEditActions, selectPageContactEdit } from './slice';
import useAppDispatch from '../../hooks/useAppDispatch';
import NoteDialog from './NoteDialog';
import { getOptimizeImage } from '../../utils/imageUtils';
import { formatPhone } from '../Dashboard/utils';

const TopContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: theme.palette.primary.darker,
  borderTopLeftRadius: +theme.shape.borderRadius * 2,
  borderTopRightRadius: +theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
}));

const BottomContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.common.white,
  borderBottomLeftRadius: +theme.shape.borderRadius * 2,
  borderBottomRightRadius: +theme.shape.borderRadius * 2,
  padding: theme.spacing(2),
}));

const AvatarStyle = styled(Avatar)(() => ({
  width: 128,
  height: 128,
  marginBottom: -28,
  zIndex: 1,
  display: 'inline-block',
  marginRight: 30,
}));

const LargeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const SmallText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  opacity: 0.72,
}));

const InfoWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(5),
}));

const IconButtonStyle = styled(Button)(({ theme }) => ({
  textTransform: 'inherit',
  '&:not(:last-of-type)': { marginRight: theme.spacing(2.5) },
}));

const Header = () => {
  // const navigate = useNavigate();
  const [openAddNote, setOpenAddNote] = useState(false);
  const [openPayingDept, setOpenPayingDept] = useState(false);
  const [openCustomer, setOpenCustomer] = useState(false);
  const dispatch = useAppDispatch();

  const { editing: contact } = useAppSelector(selectPageContactEdit);
  const checkPayment = contact
    ? contact?.total_amount_in - contact?.total_amount_out
    : 0;
  return (
    <Box>
      <TopContent>
        <Box ml={5} sx={{ display: 'flex', alignItems: 'end' }}>
          <AvatarStyle
            src={getOptimizeImage(
              contact?.avatar ||
                contact?.social_avatar ||
                '/assets/default-avatar.png',
              { w: 128, h: 128, fit: 'crop' }
            )}
          />
          <Box>
            <LargeText variant="h4">{contact?.name || ''}</LargeText>
            <SmallText variant="body1">
              {formatPhone(contact?.phone_number || '')}
            </SmallText>
          </Box>
        </Box>
        <Box display="flex">
          <InfoWrapper>
            <LargeText variant="h4">
              {contact?.total_quantity_order || 0}
            </LargeText>
            <SmallText variant="body1">Đơn đã giao</SmallText>
          </InfoWrapper>
          <InfoWrapper>
            <LargeText variant="h4">
              {numberFormat.format(contact?.total_amount_order || 0)}
            </LargeText>
            <SmallText variant="body1">Tổng tiền đơn</SmallText>
          </InfoWrapper>
          <InfoWrapper>
            <LargeText variant="h4">
              {numberFormat.format((contact && Math.abs(checkPayment)) || 0)}
            </LargeText>
            <SmallText variant="body1">
              {contact && checkPayment >= 0 ? 'Tôi phải thu' : 'Tôi phải trả'}
            </SmallText>
          </InfoWrapper>
          {checkPayment !== 0 && (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:checkmark-fill" />}
              sx={{ height: 48 }}
              onClick={() => {
                setOpenPayingDept(true);
              }}
            >
              Thanh toán
            </Button>
          )}
        </Box>
      </TopContent>
      <BottomContent>
        <Box display="flex">
          <IconButtonStyle
            variant="text"
            startIcon={<Iconify icon="eva:edit-fill" />}
            onClick={() => {
              setOpenCustomer(true);
            }}
          >
            Sửa thông tin
          </IconButtonStyle>
          {/* <IconButtonStyle
            startIcon={<Iconify icon="eva:edit-fill" />}
            onClick={() => navigate('/chat')}
          >
            Chat
          </IconButtonStyle> */}
        </Box>
        <Box display="flex">
          <IconButtonStyle
            variant="outlined"
            startIcon={<Iconify icon="eva:plus-fill" />}
            color="primary"
            onClick={() => {
              // setOpenCommingSoon(true);
              dispatch(pageContactEditActions.setOpenNewDept('lent'));
            }}
          >
            Tôi mượn nợ
          </IconButtonStyle>
          <IconButtonStyle
            variant="outlined"
            startIcon={<Iconify icon="eva:close-circle-fill" />}
            color="error"
            onClick={() => {
              dispatch(pageContactEditActions.setOpenNewDept('borrowed'));
            }}
          >
            Tôi cho nợ
          </IconButtonStyle>
          <IconButtonStyle
            variant="outlined"
            startIcon={<NotePen />}
            color="secondary"
            onClick={() => {
              dispatch(pageContactEditActions.createNote(true));
              setOpenAddNote(true);
            }}
          >
            Tạo ghi chú
          </IconButtonStyle>
        </Box>
      </BottomContent>
      <ContactDialog open={openCustomer} setOpen={setOpenCustomer} />
      <NewDeptDialog />
      <NoteDialog
        open={openAddNote}
        setOpen={setOpenAddNote}
        onSubmit={() => setOpenAddNote(false)}
      />
      <PayingDeptDialog open={openPayingDept} setOpen={setOpenPayingDept} />
    </Box>
  );
};

export default memo(Header);
