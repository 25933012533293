import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import type { RootState } from '../rootReducer';
import { productOffline } from '../../config/offline/product';
import { commitProduct } from './commitProduct';
import configOfflineCacheSlice from '../../utils/offline/configOfflineCacheSlice';

export const selectAppProduct = (store: RootState) => store.cache_product;

export const selectCacheProducts = createSelector(
  selectAppProduct,
  (state) => state.byId
);

export const selectCacheSkus = createSelector(selectAppProduct, (state) =>
  Object.values(state.byId)
    .map((product) =>
      product.list_sku.map((sku) => ({
        ...sku,
        product_name: product.name,
        uom: product.uom,
        type: `${sku.sku_type}_${product.product_type}`,
        name: product.product_type === 'non_variant' ? 'Phân loại 1' : sku.name,
        product_id: product.id,
        sold_quantity: product.sold_quantity,
        product_images: product.images,
        is_active: sku.is_active,
        historical_cost: Math.round(sku.historical_cost),
      }))
    )
    .reduce((a, b) => a.concat(b), [])
);

const saveProduct = createAsyncThunk(
  'product/save',
  (product: PendingProduct) => {
    return {
      requestId: nanoid(),
      ...product,
    };
  }
);

const productSlice = configOfflineCacheSlice(productOffline);

export const productActions = {
  saveProduct,
  commitProduct,
  ...productSlice.actions,
};

export default productSlice;
