import {
  Box,
  Avatar,
  styled,
  Typography,
  ClickAwayListener,
  TextField,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useThrottle } from 'react-use';
import useAppDispatch from '../../hooks/useAppDispatch';
import { contactOffline } from '../../config/offline/contact';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import HotkeyInfo from '../HotkeyInfo';
import { pageContactsActions } from '../../screens/Contacts/slice';
import { selectAppContact } from '../../store/contact';
import useAppSelector from '../../hooks/useAppSelector';
import { selectLocalSearchContacts } from '../../screens/POS/slice';
import Scrollbar from '../../minimal/Scrollbar';
import { getOptimizeImage } from '../../utils/imageUtils';
import Iconify from '../../minimal/Iconify';
import useResponsive from '../../hooks/useResponsive';
import { toastActions } from '../../store/toast';

const SearchInputStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  width: 400,
}));

const CustomInput = styled(TextField)(({ theme }) => ({
  '.MuiInput-input': {
    padding: theme.spacing(0),
  },
}));

type DropdownWrapperProps = {
  isDesktop: boolean;
};

const DropdownWrapper = styled(Box)<DropdownWrapperProps>(
  ({ isDesktop, theme }) => ({
    position: 'absolute',
    top: 40,
    left: isDesktop ? 0 : -80,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[600],
    maxHeight: 400,
    width: 400,
    zIndex: 1,
    boxShadow: theme.customShadows.z8,
    borderRadius: 16,
  })
);

const ScrollbarStyle = styled(Scrollbar)(({ theme }) => ({
  maxHeight: '330px',
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: '32px',
  height: '32px',
  marginRight: theme.spacing(2),
}));

const ItemWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2.5),
  cursor: 'pointer',
}));

const AddCusomterWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: theme.spacing(1.5, 3.5),
}));

type SearchContactProps = {
  onSelectedContact: (contact: Contact) => void;
  onOpenAddContact: (value: string) => void;
};

const SearchContact = ({
  onSelectedContact,
  onOpenAddContact,
}: SearchContactProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { fuseVersion } = useAppSelector(selectAppContact);
  const [term, setTerm] = useState<string>('');
  const searchRef = useRef(null);
  const [openDropdown, setDropdown] = useState(false);
  const throttledTerm = useThrottle(term, 200);
  const contacts = useAppSelector(selectLocalSearchContacts);
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    const query = buildOfflineFuseQuery(
      throttledTerm,
      {
        category: null,
      },
      contactOffline
    );
    dispatch(pageContactsActions.search(query));
  }, [dispatch, throttledTerm, fuseVersion]);

  useEffect(() => {
    if (document.activeElement === searchRef.current) {
      setDropdown(true);
    }
  }, [contacts.length]);

  const handleNotFound = () => {
    setTerm('');
    dispatch(
      toastActions.create({
        severity: 'error',
        message: 'Không tìm thấy khách hàng',
        dismissAfter: 3000,
      })
    );
  };

  return (
    <SearchInputStyle>
      <CustomInput
        fullWidth
        InputProps={{
          id: 'search-contact',
          endAdornment: (
            <HotkeyInfo title={'Bấm "⌘-K" để tìm kiếm khách hàng'}>
              ⌘-K
            </HotkeyInfo>
          ),
        }}
        size="small"
        placeholder="Tên / Số điện thoại khách hàng"
        variant="outlined"
        value={term}
        inputRef={searchRef}
        sx={{ '& input': { fontSize: isDesktop ? 'inherit' : '12px' } }}
        onChange={(e) => setTerm(e.target.value)}
        onFocus={() => setDropdown(true)}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && term) {
            if (contacts.length === 0) {
              handleNotFound();
            }
            if (contacts.length === 1 && contacts[0]) {
              onSelectedContact(contacts[0]);
            }
          }
        }}
        onBlur={() => {
          if (term && contacts.length === 0) {
            handleNotFound();
          }
        }}
      />
      {openDropdown && (
        <ClickAwayListener
          onClickAway={() => {
            if (document.activeElement === searchRef.current) return;
            setDropdown(false);
          }}
        >
          <DropdownWrapper isDesktop={isDesktop || false}>
            <Box width="100%" overflow="hidden">
              <AddCusomterWrapper>
                <Typography variant="overline">KHÁCH HÀNG</Typography>
                <Button
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => onOpenAddContact(term)}
                >
                  Thêm khách hàng
                </Button>
              </AddCusomterWrapper>
            </Box>
            <ScrollbarStyle>
              {contacts.length === 0 && (
                <Box textAlign="center" my={2}>
                  <Typography variant="body1" color={theme.palette.grey[500]}>
                    Không tìm thấy kết quả phù hợp
                  </Typography>
                </Box>
              )}
              {contacts.length > 0 && (
                <Box>
                  {contacts.map((contact) => (
                    <ItemWrapper
                      key={contact.id}
                      onClick={() => {
                        onSelectedContact(contact);
                        setDropdown(false);
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <AvatarStyle
                          src={getOptimizeImage(
                            contact.avatar || '/assets/default-avatar.png',
                            { w: 128, h: 128, fit: 'crop' }
                          )}
                          alt={contact.name}
                        />
                        <Box>
                          <Typography
                            variant="body2"
                            color={theme.palette.grey[800]}
                            noWrap
                          >
                            {contact.name}
                          </Typography>
                          <Typography variant="caption" noWrap>
                            {contact.phone_number}
                          </Typography>
                        </Box>
                      </Box>
                    </ItemWrapper>
                  ))}
                </Box>
              )}
            </ScrollbarStyle>
          </DropdownWrapper>
        </ClickAwayListener>
      )}
    </SearchInputStyle>
  );
};

export default SearchContact;
