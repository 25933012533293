import { ReactNode } from 'react';
// @mui
import { Checkbox, Typography, Stack } from '@mui/material';

// ----------------------------------------------------------------------

type TableSelectedActionsProps = {
  actions: ReactNode;
  rowCount: number;
  numSelected: number;
  onSelectAllRows: () => void;
};

export default function TableSelectedActions({
  actions,
  rowCount,
  numSelected,
  onSelectAllRows,
}: TableSelectedActionsProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        px: 2,
        top: 0,
        right: 8,
        zIndex: 9,
        height: 56,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        bgcolor: 'primary.lighter',
      }}
    >
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllRows}
      />

      <Typography
        variant="subtitle1"
        sx={{
          ml: 2,
          flexGrow: 1,
          color: 'primary.main',
        }}
      >
        Đã chọn {numSelected}
      </Typography>

      {actions && actions}
    </Stack>
  );
}
