import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import AuthLayout from '../../layouts/AuthLayout';
import LeftArrow from '../../components/Icons/LeftArrow';
import { useNavigate } from 'react-router-dom';
import useLocationState from '../../hooks/useLocationState';
import {
  LINK_DOWNLOAD_ANDROID,
  LINK_DOWNLOAD_IOS,
} from '../../utils/constants';

const Text = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.grey[600]}`,
  fontSize: '14px',
}));

const DownloadWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: `${theme.spacing(2)}`,
  '& .MuiButton-root': {
    padding: 0,
  },
}));

const Register = () => {
  const navigate = useNavigate();
  const phoneNumber = useLocationState<{ phoneNumber: string | null }>();

  return (
    <AuthLayout>
      <Box sx={{ width: '490px' }}>
        <Text>
          Số điện thoại {phoneNumber} chưa được đăng ký tài khoản Sobanhang Pro
        </Text>
        <Typography
          variant="h4"
          component="h1"
          my={(theme) => theme.spacing(2)}
        >
          Đăng ký tài khoản Sobanhang tại
        </Typography>
        <DownloadWrapper>
          <Button
            sx={{ mr: (theme) => theme.spacing(2) }}
            onClick={() => window.open(LINK_DOWNLOAD_ANDROID)}
          >
            <img src="/assets/chplay.png" width={172} />
          </Button>
          <Button onClick={() => window.open(LINK_DOWNLOAD_IOS)}>
            <img src="/assets/appstore.png" width={172} />
          </Button>
        </DownloadWrapper>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<LeftArrow />}
          sx={{ py: '12px' }}
          onClick={() => navigate('/')}
        >
          Quay lại
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default Register;
