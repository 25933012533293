import { Card, Table, TableBody, TableContainer } from '@mui/material';
import { memo } from 'react';
import { TableHeader } from '../../components/TableHeader';
import useAppSelector from '../../hooks/useAppSelector';
import { TABLE_HEAD } from './settings';
import { selectPageDashboard } from './slice';
import TopOrdersRow from './TopOrdersRow';

const TopOrdersTable = memo(() => {
  const { latestOrders } = useAppSelector(selectPageDashboard);
  return (
    <Card>
      <TableContainer>
        <Table stickyHeader>
          <TableHeader headLabel={TABLE_HEAD} />
          <TableBody>
            {(latestOrders?.orders || []).map((order, index) => (
              <TopOrdersRow
                key={order.order_number}
                index={index}
                order={order}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
});

TopOrdersTable.displayName = 'TopOrdersTable';

export default TopOrdersTable;
