import {
  configureStore,
  Dispatch,
  ThunkDispatch,
  AnyAction,
} from '@reduxjs/toolkit';
import { Persistor } from 'redux-persist';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist/es/constants';
import persistStore from 'redux-persist/es/persistStore';
import configOfflineFuse from '../utils/offline/configOfflineFuse';
import { productOffline } from '../config/offline/product';
import { categoryOffline } from '../config/offline/category';
import { contactOffline } from '../config/offline/contact';
import { promotionOffline } from '../config/offline/promotion';
import rootReducer from './rootReducer';

const instance: [ExpectedAny, Persistor | null] = [null, null];

export async function createStore() {
  const fuseMiddlewares = [
    await configOfflineFuse(productOffline),
    await configOfflineFuse(categoryOffline),
    await configOfflineFuse(contactOffline),
    await configOfflineFuse(promotionOffline),
  ];

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        thunk: true,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(fuseMiddlewares);
    },
  });

  instance[0] = store;
  instance[1] = persistStore(store);

  return store;
}

export const getStore = () =>
  instance[0] as Awaited<ReturnType<typeof createStore>>;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const getPersistor = () => instance[1]!;

export type AppDispatch = Dispatch<AnyAction> &
  ThunkDispatch<ExpectedAny, null, AnyAction>;
