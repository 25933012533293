import { Button, Card, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useThrottle } from 'react-use';
import { productOffline } from '../../config/offline/product';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import MainLayout from '../../layouts/MainLayout';
import { selectAppProduct } from '../../store/product';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import EmptyState from './EmptyState';
import Header from './Header';
import ProductsPagination from './ProductPagination';
import ProductsTable from './ProductTable';
import {
  pageProductsActions,
  selectLocalSearchRows,
  selectLocalSelectedCategory,
} from './slice';
import HeaderBreadcrumbs from '../../minimal/HeaderBreadcrumbs';

// import Upload from '../../components/Icons/Upload';
import Iconify from '../../minimal/Iconify';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CommingSoonDialog from '../../components/CommingSoonDialog';

const Products = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [term, setTerm] = useState<string>('');
  const throttledTerm = useThrottle(term, 200);
  const selectedCategory = useAppSelector(selectLocalSelectedCategory);
  const products = useAppSelector(selectLocalSearchRows);
  const { fuseVersion } = useAppSelector(selectAppProduct);
  const [openCommingSoon, setOpenCommingSoon] = useState(false);
  const handleCreate = useCallback(() => {
    navigate('/products/create');
  }, [navigate]);

  useEffect(() => {
    const query = buildOfflineFuseQuery(
      throttledTerm,
      {
        category: selectedCategory?.id || null,
      },
      productOffline
    );

    dispatch(pageProductsActions.search(query));
  }, [dispatch, selectedCategory.id, throttledTerm, fuseVersion]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTerm(e.target.value),
    []
  );

  return (
    <MainLayout>
      <HeaderBreadcrumbs
        action={
          <>
            {/* <Button
              variant="outlined"
              startIcon={<Upload fill={theme.palette.primary.main} />}
              sx={{ minWidth: 230, mr: 3 }}
              onClick={() => setOpenCommingSoon(true)}
            >
              Thêm nhiều sản phẩm
            </Button> */}
            <Button
              variant="contained"
              startIcon={
                <Iconify
                  icon="eva:plus-fill"
                  color={theme.palette.common.white}
                />
              }
              sx={{ minWidth: 180 }}
              onClick={handleCreate}
            >
              Tạo sản phẩm
            </Button>
          </>
        }
        links={[]}
      >
        <Typography variant="h4" gutterBottom fontWeight={500}>
          Sản phẩm
        </Typography>
      </HeaderBreadcrumbs>

      <Card>
        <Header onChangeSearch={handleSearchChange} />
        {products.length === 0 && <EmptyState />}
        {products.length > 0 && (
          <>
            <ProductsTable />
            <ProductsPagination />
          </>
        )}
      </Card>
      <CommingSoonDialog
        open={openCommingSoon}
        onClose={() => setOpenCommingSoon(false)}
      />
    </MainLayout>
  );
};

export default memo(Products);
