export function getTotalPrice(
  items: Array<{ quantity: number; price: number }>
) {
  const total = items.reduce((acc, item) => {
    return acc + item.price * item.quantity;
  }, 0);

  return total;
}

export function getGrandTotalPrice({
  list_order_item,
  other_discount = 0,
  promotion_discount = 0,
  delivery_fee = 0,
  otherDiscountUnit,
  validPromotion,
}: PendingOrderForm & {
  otherDiscountUnit: 'percent' | 'cash';
  validPromotion: boolean;
}) {
  const promotionPrice = validPromotion ? promotion_discount : 0;
  if (otherDiscountUnit === 'percent') {
    return Math.round(
      getTotalPrice(list_order_item) -
        (other_discount / 100) *
          (getTotalPrice(list_order_item) - promotionPrice) -
        promotionPrice +
        delivery_fee
    );
  }
  return Math.round(
    getTotalPrice(list_order_item) -
      other_discount -
      promotionPrice +
      delivery_fee
  );
}

export function formatDescriptionPromotion(promotion: Promotion) {
  const maxPrice =
    promotion.max_price_discount > 0
      ? `tối đa ${shorttenPrice(promotion.max_price_discount)}`
      : '';
  return `Giảm ${formatDiscount(
    promotion.value,
    promotion.type
  )} ${maxPrice} cho đơn từ ${shorttenPrice(promotion.min_order_price)}`;
}

export function shorttenPrice(num: number) {
  if (num >= 1000000) {
    return `${num / 1000000}tr`;
  }

  if (num >= 1000) {
    return `${num / 1000}k`;
  }

  return `${num}đ`;
}

export function formatDiscount(num: number, type: string) {
  if (type === 'percent') {
    return `${num}%`;
  }

  return shorttenPrice(num);
}

export function getDeliveryMethod(
  delivery_method: 'seller_delivery' | 'buyer_pick_up'
) {
  return delivery_method === 'seller_delivery';
}

export function canPickItem(
  sku: Pick<Sku, 'can_pick_quantity' | 'is_active'>,
  isAdvanceStock: boolean
) {
  if (
    (isAdvanceStock && sku.can_pick_quantity > 0) ||
    (!isAdvanceStock && sku.is_active)
  ) {
    return true;
  }
  return false;
}
