import { Dialog, DialogActions, Button } from '@mui/material';
import { DialogContentStyle, DialogTitleStyle } from './ContactDetailComponent';
import DeptForm from './DeptForm';
import { useParams } from 'react-router-dom';
import useTransactionForm from './useTransactionForm';
import { memo } from 'react';

type PayingDeptDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const PayingDeptDialog = ({ open, setOpen }: PayingDeptDialogProps) => {
  const { id } = useParams<{ id?: string }>();
  const formik = useTransactionForm({
    id: id || '',
    isPayTransaction: true,
  });
  const { start_time } = formik.values;
  return (
    <Dialog
      open={open}
      onClose={() => {
        formik.resetForm();
        setOpen(false);
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitleStyle>Khách thanh toán nợ cho bạn</DialogTitleStyle>
      <DialogContentStyle>
        <DeptForm formik={formik} />
      </DialogContentStyle>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            formik.resetForm();
            setOpen(false);
          }}
        >
          Huỷ
        </Button>
        <Button
          variant="contained"
          disabled={
            formik.isSubmitting ||
            formik.values.amount <= 0 ||
            formik.values.start_time == ''
          }
          onClick={() => {
            formik.handleSubmit();
            setOpen(false);
          }}
        >
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(PayingDeptDialog);
