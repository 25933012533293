import { SxProps, TextField, Theme } from '@mui/material';
import { Ref, useEffect, useState } from 'react';

type TextFieldNumberProps = {
  id: string;
  label?: string;
  autoFocus?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  value?: number;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  inputRef?: Ref<unknown> | undefined;
  endAdornment?: React.ReactNode;
  textAlign?: 'left' | 'right';
  error?: boolean;
  readOnly?: boolean;
  onClick?: () => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSetField?: (value: number) => void;
  sx?: SxProps<Theme>;
};

export default function TextFieldDecimal({
  id,
  label,
  autoFocus,
  placeholder,
  fullWidth,
  value,
  variant = 'outlined',
  inputRef,
  endAdornment,
  textAlign = 'left',
  error,
  sx,
  onClick,
  onBlur,
  onKeyUp,
  onSetField,
  readOnly = false,
  ...props
}: TextFieldNumberProps) {
  const [valueString, setValueString] = useState(String(value || ''));

  useEffect(() => {
    setValueString(String(value || ''));
  }, [id, value]);

  return (
    <TextField
      id={id}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      label={label}
      placeholder={placeholder}
      variant={variant}
      inputRef={inputRef}
      InputProps={{
        endAdornment,
        readOnly,
        style: { textAlign },
      }}
      error={error}
      value={valueString}
      onClick={() => {
        setValueString(String(value || ''));
        onClick?.();
      }}
      onKeyUp={() => onKeyUp && onKeyUp}
      onChange={(e) => {
        const { value } = e.target;
        // allow input numbers, dot, comma
        const regex = /^[0-9]+([,.][0-9]+)?$/;

        const replaceValue = value.replace(',', '.');
        const countSpecialCharacter = replaceValue.split('.').length - 1;
        if (countSpecialCharacter > 1) return;
        const convertNumber = +value.replace(',', '.');
        const isLastDot =
          replaceValue.charAt(replaceValue.length - 1).lastIndexOf('.') > -1;
        // sometimes values has many decimal part, so has to fixed 2 decimal part
        // otherwise allow input max 2 decimal part, ex: 100,11
        const newValue =
          value.length <= 13
            ? replaceValue.substring(0, replaceValue.indexOf('.') + 3)
            : convertNumber.toFixed(2);

        if (isLastDot) {
          return setValueString(newValue);
        }
        const finalValue = replaceValue.includes('.') ? newValue : value;
        if (
          (finalValue.length <= 13 && regex.test(finalValue)) ||
          finalValue === ''
        ) {
          setValueString(String(finalValue));
        }
      }}
      onBlur={(e) => {
        if (e.target.value === '') {
          onBlur?.(e);
          setValueString('0');
          onSetField?.(0);
          return;
        }

        onSetField?.(+valueString);
      }}
      sx={sx}
      {...props}
    />
  );
}
