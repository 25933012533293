const Order = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3 2.33018L19.74 7.33018C19.9067 7.51358 19.9994 7.75234 20 8.00018V19.5002C19.9921 20.1712 19.7179 20.8116 19.2378 21.2804C18.7577 21.7493 18.111 22.0082 17.44 22.0002H6.56C5.88899 22.0082 5.24229 21.7493 4.76218 21.2804C4.28208 20.8116 4.00791 20.1712 4 19.5002V4.50018C4.00791 3.82917 4.28208 3.18879 4.76218 2.71993C5.24229 2.25108 5.88899 1.99218 6.56 2.00018H14.56C14.8422 2.00085 15.1109 2.1207 15.3 2.33018ZM14.5537 4.59242C14.3555 4.38037 14 4.52067 14 4.81097V7.15018C13.9835 7.36146 14.0526 7.57051 14.1918 7.73034C14.3309 7.89018 14.5285 7.98741 14.74 8.00018H17.0027C17.2826 8.00018 17.4276 7.66611 17.2365 7.46163L14.5537 4.59242Z"
      fill="#637381"
    />
  </svg>
);

export default Order;
