import {
    Typography,
    Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import EmptyPaybookIcon from './EmptyPaybookIcon';

type EmptyPaybookStateProps = {
    classCus: ExpectedAny,
    textCus?: string,

}
const EmptyPaybookState = ({ classCus, textCus }: EmptyPaybookStateProps) => {
    const theme = useTheme();
    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={10}
            sx={classCus}
        >
            <EmptyPaybookIcon />
            <Typography color={theme.palette.grey[500]} sx={{ my: 3, textAlign: 'center' }}>
                {textCus ? textCus : 'Bạn chưa có giao dịch nào với khách hàng này!'}
            </Typography>
        </Box>
    );
};

export default memo(EmptyPaybookState);
