import { Typography, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import Iconify from '../../minimal/Iconify';
import EmptyProduct from '../../components/Icons/EmptyProduct';
import useResponsive from '../../hooks/useResponsive';
import { useDispatch } from 'react-redux';
import { pagePosActions } from './slice';

const EmptyState = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'lg');
  return (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding={theme.spacing(2, 0)}
    >
      <EmptyProduct height={isDesktop ? '150' : '120'} />
      <Typography
        variant={isDesktop ? 'h5' : 'h6'}
        fontWeight={600}
        color={theme.palette.grey[500]}
        sx={{ my: isDesktop ? 3 : 1.5 }}
      >
        Đơn hàng của bạn chưa có sản phẩm nào
      </Typography>
      <Button
        variant="contained"
        startIcon={
          <Iconify icon="eva:plus-fill" color={theme.palette.common.white} />
        }
        sx={{ minWidth: 180, textTransform: 'inherit' }}
        onClick={() => {
          (
            document.querySelector('#search') as HTMLInputElement | null
          )?.focus();
          dispatch(pagePosActions.toggleDropdownProduct(true));
        }}
      >
        Thêm sản phẩm
      </Button>
    </Box>
  );
};

export default memo(EmptyState);
