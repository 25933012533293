import { Card, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';
import Banner from './Banner';
import ImageBanner from './ImageBanner';

const LoginWrapper = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const isDesktop = useResponsive('up', 'md');
  const { pathname } = useLocation();
  const isVerifyPage = pathname.includes('verify');
  const isLoginPage = !pathname.includes('register') && !isVerifyPage;

  const renderBannerText = () => {
    if (isVerifyPage) {
      return 'Quản lý cửa hàng thông minh với Sổ Bán Hàng phiên bản';
    }
    return 'Tạo tài khoản Sổ Bán Hàng để có trải nghiệm tốt nhất!';
  };

  const renderBannerImage = () => {
    if (isVerifyPage) {
      return '/assets/verify-banner.png';
    }
    return '/assets/register-banner.png';
  };

  return (
    <Grid container alignItems="stretch" height="100%" bgcolor="#FFFFFF">
      {isDesktop ? (
        <>
          <Grid item md={4} lg={3} sx={{ p: (theme) => theme.spacing(2) }}>
            <Card sx={{ height: '100%' }}>
              {isLoginPage ? (
                <ImageBanner imageUrl="/assets/login-banner.png" />
              ) : (
                <Banner
                  text={renderBannerText()}
                  primaryText={isVerifyPage ? 'website' : ''}
                  imageUrl={renderBannerImage()}
                  height={isVerifyPage ? '90%' : '80%'}
                  imageWidth={pathname.includes('register') ? '80%' : '100%'}
                />
              )}
            </Card>
          </Grid>
          <LoginWrapper item md={8} lg={9}>
            {children}
          </LoginWrapper>
        </>
      ) : (
        <LoginWrapper item xs={12}>
          {children}
        </LoginWrapper>
      )}
    </Grid>
  );
};

export default AuthLayout;
