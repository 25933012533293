import { DateRangePicker } from '@mui/lab';
import { Box, TextField, InputAdornment, Grid } from '@mui/material';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import { getDateRangePicker } from '../../utils/utils';
import { pageCashbooksActions, selectPageCashbooks } from './slice';

type TableFilterProps = {
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TableFilter = ({ onSearchChange }: TableFilterProps) => {
  const dispatch = useDispatch();
  const {
    main: { dateRange },
  } = useAppSelector(selectPageCashbooks);

  return (
    <Grid container sx={{ px: 1.5, mb: 1, mt: 2 }}>
      <Grid item xs={5}>
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            placeholder="Tìm kiếm giao dịch"
            onChange={onSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{ mr: 2 }}
          />
        </Box>
      </Grid>
      <Grid item xs={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DateRangePicker
          startText="Từ ngày"
          endText="Đến ngày"
          value={dateRange}
          inputFormat="dd/MM/yyyy"
          onChange={(newValue) => {
            const result = getDateRangePicker(newValue);
            if (result === false) return;

            dispatch(pageCashbooksActions.setDateRange(result));
            dispatch(pageCashbooksActions.setPage(0));
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                sx={{ mr: 1 }}
                InputProps={{
                  endAdornment: (
                    <Iconify
                      icon={'healthicons:i-schedule-school-date-time'}
                      sx={{ color: 'text.disabled', width: 40, height: 40 }}
                    />
                  ),
                }}
              />
              <TextField
                {...endProps}
                InputProps={{
                  endAdornment: (
                    <Iconify
                      icon={'healthicons:i-schedule-school-date-time'}
                      sx={{ color: 'text.disabled', width: 40, height: 40 }}
                    />
                  ),
                }}
              />
            </>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default memo(TableFilter);
