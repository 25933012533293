import { TablePagination } from '@mui/material';
import createPaginationHook from '../../hooks/createPaginationHook';
import {
  pageContactsActions,
  selectLocalSearchResultContacts,
} from '../Contacts/slice';

const usePagination = createPaginationHook({
  selectResult: selectLocalSearchResultContacts,
  actions: pageContactsActions,
});

const ProductsPagination = () => {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 20]}
      component="div"
      labelRowsPerPage="Số khách hàng trên trang"
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
      {...usePagination()}
    />
  );
};

export default ProductsPagination;
