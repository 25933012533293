import {
  Box,
  Button,
  Table,
  TableContainer,
  Typography,
  Card,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import EmptyOrder from '../../components/Icons/EmptyOrder';
import { TableHeader } from '../../components/TableHeader';
import { PATH_DASHBOARD } from '../../config/routeConfig';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import { TABLE_HEAD } from './settings';
import { selectPageDashboard } from './slice';
import TopOrdersTable from './TopOrdersTable';

const TopOrders = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { latestOrders } = useAppSelector(selectPageDashboard);

  return (
    <Box mb={2}>
      <Typography variant="h4" fontWeight={600} mb={3}>
        10 đơn hàng gần nhất
      </Typography>
      {!latestOrders || latestOrders.orders.length === 0 ? (
        <Card>
          <TableContainer>
            <Table>
              <TableHeader headLabel={TABLE_HEAD} />
            </Table>
            <Box
              my={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <EmptyOrder />
              <Box my={3} textAlign="center">
                <Typography variant="h5" color={theme.palette.grey[500]}>
                  Bạn chưa có đơn hàng nào mới.
                </Typography>
                <Typography variant="h5" color={theme.palette.grey[500]}>
                  Hãy tạo đơn hàng để bắt đầu việc kinh doanh nhé!
                </Typography>
              </Box>

              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => navigate(PATH_DASHBOARD.pos)}
              >
                Tạo đơn hàng
              </Button>
            </Box>
          </TableContainer>
        </Card>
      ) : (
        <TopOrdersTable />
      )}
    </Box>
  );
};

export default TopOrders;
