export const TABLE_HEAD: HeadCellConfig[] = [
  { id: 'date', label: 'Ngày' },
  { id: 'lent', label: 'Tôi cho nợ', align: 'right' },
  { id: 'borrowed', label: 'Tôi mượn nợ', align: 'right' },
];

export const initialTransaction = () => ({
  amount: 0,
  business_id: '',
  contact_id: '',
  currency: 'VND',
  start_time: new Date().toString(),
  description: '',
  transaction_type: '',
  images: [],
});
