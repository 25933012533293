import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useAppDispatch from '../../hooks/useAppDispatch';
import { loginActions } from '../../store/login';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Số điện thoại không được để trống')
    .matches(/[0-9]{10,}/, 'Số điện thoại không hợp lệ'),
});

const useLoginForm = (phoneNumber: string | null) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return useFormik({
    initialValues: {
      phoneNumber: phoneNumber || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await dispatch(
          loginActions.renewOTPChallenge(values.phoneNumber)
        ).unwrap();
        navigate('/verify-otp', { state: values });
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    },
  });
};

export default useLoginForm;
