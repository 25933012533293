function configureTableHead(p4: HeadCellConfig) {
  return [
    { id: 'stt_p_0', label: 'STT', align: 'left' },
    { id: 'avat_p_5', label: '' },
    {
      id: 'tkh_p_1',
      label: 'Tên khách hàng',
      align: 'left',
      orderBy: (contact: Contact) => contact.name,
    },
    { id: 'sdt_p_2', label: 'Số điện thoại', align: 'left' },
    {
      id: 'ddg_p_3',
      label: 'Đơn đang giao',
      align: 'right',
      orderBy: (contact: Contact) => {
        if (contact?.state) {
          return contact?.state;
        }
        return 0;
      },
    },
    p4,
    // { id: 'chat_p_5', label: '' },
  ] as Array<HeadCellConfig>;
}

const orderDebtAmount = (contact: Contact) => {
  if (contact?.debt_amount) {
    return contact?.debt_amount;
  }
  return 0;
};

export const TABLE_HEAD = {
  in: configureTableHead({
    id: 'ptpt_p_4',
    label: 'Tôi phải thu',
    align: 'right',
    orderBy: (contact: Contact) => orderDebtAmount(contact),
  }),
  out: configureTableHead({
    id: 'ptpt_p_4',
    label: 'Tôi phải trả',
    align: 'right',
    orderBy: (contact: Contact) => orderDebtAmount(contact),
  }),
  '': configureTableHead({
    id: 'ptpt_p_4',
    label: 'Phải thu/ Phải trả',
    align: 'right',
    orderBy: (contact: Contact) => orderDebtAmount(contact),
  }),
};

export const TABS = [
  { id: '', label: 'Tất cả' },
  { id: 'in', label: 'Tôi phải thu' },
  { id: 'out', label: 'Tôi phải trả' },
];
