import productService from '../../services/product';
import noAccents from '../../utils/encoding';
import configOffline from '../../utils/offline/configOffline';

export const categoryOffline = configOffline({
  name: 'cache_category',
  fetchChange: (lastUpdate) => productService.getCategories(lastUpdate),
  makeSearchData: (item: Category) => ({
    id: item.id,
    name: noAccents(item.name),
  }),
  searchFields: ['name'],
});
