import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Typography, Link, Avatar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { NAVBAR } from '../../config/styles';
import useResponsive from '../../hooks/useResponsive';
import Scrollbar from '../../minimal/Scrollbar';
import Logo from '../../minimal/Logo';
import CollapseButton from './CollapseButton';
import cssStyles from '../../utils/cssStyles';
import useAppDispatch from '../../hooks/useAppDispatch';
import { appActions, selectApp } from '../../store/app';
import { selectAppAuth } from '../../store/auth';
import useAppSelector from '../../hooks/useAppSelector';
import sidebarConfig from '../../config/sidebar';
import NavSectionVertical from '../../minimal-override/nav-section/vertical';
import { memo } from 'react';
import MultiStore from './MultiStore';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[200],
}));

function NavbarVertical() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { sidebar } = useAppSelector(selectApp);
  const { business, listBusiness, user_info } = useAppSelector(selectAppAuth);
  const isDesktop = useResponsive('up', 'lg');

  const wide = sidebar.open;
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          flexShrink: 0,
          overflowX: 'hidden',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={sidebar.open ? 0 : 2.5}
        >
          {isDesktop && sidebar.open && <Logo logoFull />}

          <CollapseButton />
        </Stack>
        {wide && (
          <Box sx={{ mb: 2, mx: 2.5, px: 2.5 }}>
            <Link underline="none" component={RouterLink} to={'/'}>
              <AccountStyle>
                <Avatar
                  alt={business?.name}
                  src={business?.avatar || '/assets/default-avatar.png'}
                  sx={{ width: 40, height: 40 }}
                />
                <Box overflow="hidden" sx={{ ml: 2 }}>
                  <Typography
                    variant="subtitle1"
                    noWrap
                    sx={{ color: 'text.primary' }}
                  >
                    {business?.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    sx={{ color: theme.palette.grey[600], fontWeight: '500' }}
                  >
                    {user_info?.full_name}
                  </Typography>
                </Box>
              </AccountStyle>
            </Link>
          </Box>
        )}

        <NavSectionVertical
          navConfig={sidebarConfig}
          isCollapse={!sidebar.open}
        />
      </Stack>

      <Box sx={{ flexGrow: 1 }} />
      {listBusiness.length > 1 && <MultiStore />}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: wide ? NAVBAR.DASHBOARD_WIDTH : NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        },
        ...(!sidebar.open && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={sidebar.open}
          onClose={() => dispatch(appActions.toggleSidebar(false))}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}
      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              borderRightStyle: 'dashed',
              bgcolor: (theme) => theme.palette.common.white,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              width: wide
                ? NAVBAR.DASHBOARD_WIDTH
                : NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              ...(!sidebar.open && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}

export default memo(NavbarVertical);
