import { DatePicker } from '@mui/lab';
import {
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { assert } from 'ts-essentials';
import { v4 } from 'uuid';
import CameraPlus from '../../components/Icons/CameraPlus';
import ProductImage from '../../components/ProductImage';
import TextFieldNumber from '../../components/TextFieldNumber';
import useAppSelector from '../../hooks/useAppSelector';
import Iconify from '../../minimal/Iconify';
import mediaService from '../../services/media';
import { toastActions } from '../../store/toast';
import { getFieldProps } from '../../utils/formik';
import { isLocalImage } from '../../utils/productUtils';
import AddCashbookCategory from './AddCashbookCategory';
import AddPaymentSource from './AddPaymentSource';
import { pageCashbooksActions, selectPageCashbooks } from './slice';
import useCashbookForm from './useCashbookForm';

const BoxStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const DialogTitleStyle = styled(DialogTitle)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 600,
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: '100%',
  maxHeight: '100%',
  width: 56,
  height: 'auto',
  marginRight: theme.spacing(2),
  '&::after': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  },
}));

const AvatarStyle = styled(ProductImage)(({ theme }) => ({
  width: 56,
  height: 56,
  borderRadius: theme.shape.borderRadius,
  '&:not(:last-of-type)': {
    marginRight: theme.spacing(2),
  },
  position: 'absolute',
}));

const CloseIcon = styled(Iconify)(({ theme }) => ({
  width: 18,
  height: 18,
  position: 'absolute',
  top: '4px',
  right: '4px',
  color: theme.palette.grey[300],
  cursor: 'pointer',
}));

type PromotionDialogProps = {
  detail: Cashbook | null;
  setSelectedRow: (value: null) => void;
  onConfirmDelete: (value: boolean) => void;
};

const CashbookDialog = ({
  detail,
  setSelectedRow,
  onConfirmDelete,
}: PromotionDialogProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    main: { dialogType, categories, payments },
  } = useAppSelector(selectPageCashbooks);
  const formik = useCashbookForm({ detail, setSelectedRow });
  const [openCreateCategory, setOpenCreateCategory] = useState(false);
  const [openCreatePayment, setOpenCreatePayment] = useState(false);

  const { errors, touched, values, setFieldValue } = formik;

  const handleClose = () => {
    dispatch(pageCashbooksActions.setDialogType(''));
    setSelectedRow(null);
    setOpenCreatePayment(false);
    setOpenCreateCategory(false);
    formik.resetForm();
  };

  const renderTitle = () => {
    if (detail) {
      if (dialogType === 'out') return 'Chi tiết khoản chi';
      return 'Chi tiết khoản thu';
    }
    if (dialogType === 'out') return 'Tạo khoản chi';
    return 'Tạo khoản thu';
  };

  return (
    <Dialog
      open={Boolean(dialogType)}
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitleStyle>{renderTitle()}</DialogTitleStyle>
      <DialogContent
        sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
      >
        <BoxStyle mt={1}>
          <TextFieldNumber
            id="amount"
            fullWidth
            label="Số tiền"
            placeholder="Nhập số tiền giao dịch"
            value={values.amount}
            error={touched?.amount && Boolean(errors?.amount)}
            helperText={touched?.amount && errors?.amount}
            onSetField={(value) => setFieldValue('amount', value)}
          />
        </BoxStyle>
        <BoxStyle>
          <Box width="100%">
            <DatePicker
              label="Chọn thời gian giao dịch"
              value={values.day}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => setFieldValue('day', newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
            {touched.day && errors.day && (
              <Typography
                variant="caption"
                color={theme.palette.error.main}
                sx={{ ml: '14px' }}
              >
                {errors.day}
              </Typography>
            )}
          </Box>
        </BoxStyle>
        <BoxStyle>
          <FormControl fullWidth>
            <InputLabel id="variant">Phân loại</InputLabel>
            {openCreateCategory ? (
              <AddCashbookCategory
                setOpenCreateCategory={setOpenCreateCategory}
              />
            ) : (
              <Select
                labelId="variant"
                label="Phân loại"
                value={values.category_id}
                onChange={(event) => {
                  const { value } = event.target;
                  setFieldValue('category_id', value);
                  const category = categories.find((c) => c.id === value);
                  if (category) {
                    setFieldValue('category_name', category.name);
                  }
                }}
              >
                <MenuItem>
                  <Button
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => setOpenCreateCategory(true)}
                  >
                    Thêm phân loại
                  </Button>
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </BoxStyle>
        <BoxStyle>
          <FormControl fullWidth>
            <InputLabel id="funds">Nguồn tiền</InputLabel>
            {openCreatePayment ? (
              <AddPaymentSource setOpenCreatePayment={setOpenCreatePayment} />
            ) : (
              <Select
                labelId="funds"
                label="Nguồn tiền"
                value={values.payment_source_id}
                onChange={(event) => {
                  const { value } = event.target;
                  setFieldValue('payment_source_id', value);
                  const payment = payments.find((p) => p.id === value);
                  if (payment) {
                    setFieldValue('payment_method', payment.name);
                    setFieldValue('payment_source_name', payment.name);
                  }
                }}
              >
                <MenuItem>
                  <Button
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => setOpenCreatePayment(true)}
                  >
                    Thêm nguồn tiền
                  </Button>
                </MenuItem>
                {payments.map((payment) => (
                  <MenuItem key={payment.id} value={payment.id}>
                    {payment.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </BoxStyle>
        <BoxStyle>
          <Box width="100%">
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="body2">Thêm ảnh giao dịch</Typography>
              <Typography variant="body2">
                {values.images?.length || 0}/4
              </Typography>
            </Box>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<CameraPlus />}
              component="label"
            >
              Tải ảnh
              <input
                accept="image/*"
                multiple
                type="file"
                hidden
                onChange={async (e) => {
                  if (e.target.files) {
                    const { files } = e.target;
                    if (values.images && values.images.length === 4) {
                      return dispatch(
                        toastActions.create({
                          severity: 'error',
                          message: 'Tối đa 4 hình ảnh',
                          dismissAfter: 3000,
                        })
                      );
                    }

                    const localImages = [...(values.images || [])];
                    for (let i = 0; i < files.length; i++) {
                      if (i < 4) {
                        const file = files[i];
                        assert(!!file, 'File can not be null');
                        const dotExt = /\.[^.$]+$/.exec(file.name)?.[0];
                        if (dotExt) {
                          const name = v4() + dotExt;

                          await mediaService.saveLocal(
                            name,
                            await file.arrayBuffer()
                          );

                          localImages.push({
                            type: 'local',
                            name,
                            mime_type: file.type,
                          });
                        }
                      }
                    }
                    if (files.length > 4) {
                      dispatch(
                        toastActions.create({
                          severity: 'error',
                          message: 'Tối đa 4 hình ảnh',
                          dismissAfter: 3000,
                        })
                      );
                    }
                    formik.setFieldValue('images', localImages);
                  }
                }}
              />
            </Button>
            <Box display="flex" mt={2}>
              {values.images &&
                values.images.map((image) => (
                  <ImageWrapper key={isLocalImage(image) ? image.name : image}>
                    <AvatarStyle image={image} alt="image" />
                    <CloseIcon
                      icon="eva:close-circle-fill"
                      onClick={() => {
                        setFieldValue(
                          'images',
                          values.images.filter((i) => image !== i)
                        );
                      }}
                    />
                  </ImageWrapper>
                ))}
            </Box>
          </Box>
        </BoxStyle>
        <BoxStyle>
          <TextField
            fullWidth
            rows={3}
            multiline
            placeholder="Nhập ghi chú cho giao dịch này"
            {...getFieldProps(formik, 'description')}
          />
        </BoxStyle>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        {detail ? (
          <Button
            variant="outlined"
            color="error"
            onClick={() => onConfirmDelete(true)}
          >
            Xoá giao dịch
          </Button>
        ) : (
          <Box />
        )}
        <Box display="flex">
          <Button
            variant="outlined"
            color="inherit"
            sx={{ mr: 1.5 }}
            onClick={handleClose}
          >
            Huỷ
          </Button>
          <Button variant="contained" onClick={() => formik.handleSubmit()}>
            Xác nhận
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CashbookDialog;
