import {
  Box,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Grid,
  Divider,
  Button,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DatePicker from '@mui/lab/DatePicker';
import Iconify from '../../minimal/Iconify';
import LogoText from '../Icons/LogoText';

const TextFieldDatePicker = styled(TextField)(({ theme }) => ({
  '& input': {
    padding: theme.spacing(1),
  },
}));

const GridStyle = styled(Grid)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  '& .MuiGrid-item': { paddingLeft: 0 },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

const DialogActionsStyle = styled(DialogActions)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  justifyContent: 'space-between',
  '& button': { textTransform: 'inherit' },
}));

type ReportDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

const ReportDialog = ({ open, setOpen }: ReportDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle
        sx={{
          fontSize: '24px !important',
          fontWeight: 600,
          textAlign: 'center',
          mb: 1,
        }}
      >
        Báo cáo cuối ngày
      </DialogTitle>
      <DialogContent sx={{ px: 5 }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography sx={{ mr: 1.5 }}>Ngày bán:</Typography>
          <DatePicker
            value={null}
            onChange={(newValue) => {
              console.log(newValue);
            }}
            renderInput={(params) => <TextFieldDatePicker {...params} />}
          />
        </Box>
        <Box mt={4}>
          <SubTitle variant="subtitle2" color={theme.palette.grey[600]}>
            Chi tiết bán hàng
          </SubTitle>
          <GridStyle container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <Typography variant="subtitle2">Đơn hàng đang giao</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">12</Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <Typography variant="subtitle2">Giá trị đơn đang giao</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">8.000.000</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <Typography variant="subtitle2">Đơn hàng đã giao</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">40</Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <Typography variant="subtitle2">Giá trị đơn đã giao</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">32.000.000</Typography>
            </Grid>
          </GridStyle>
        </Box>
        <Box mt={4}>
          <SubTitle variant="subtitle2" color={theme.palette.grey[600]}>
            Chi tiết thanh toán
          </SubTitle>
          <GridStyle container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <Typography variant="subtitle2">Tiền mặt</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">20.000.000</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <Typography variant="subtitle2">Chuyển khoản</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">3.000.000</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <Typography variant="subtitle2">Momo</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="body2">17.000.000</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <Typography variant="h6">Tổng cộng</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h6">40.000.000</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </GridStyle>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <Typography>Được hỗ trợ miễn phí bởi</Typography>
          <LogoText height="28" />
        </Box>
      </DialogContent>
      <DialogActionsStyle>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
          onClick={() => setOpen(false)}
        >
          Tiếp tục bán hàng
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="fluent:print-28-filled" />}
          onClick={() => setOpen(false)}
        >
          In báo cáo
        </Button>
      </DialogActionsStyle>
    </Dialog>
  );
};

export default ReportDialog;
