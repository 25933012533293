import render from '@finan/fe-reports';
import { Box } from '@mui/material';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { selectAppAuth } from '../store/auth';
import useAppSelector from './useAppSelector';

function usePrinter({
  ordersPrint,
  configs,
  paymentsInfo,
}: {
  ordersPrint: Order[];
  configs: ConfigInvoice | null;
  paymentsInfo: PaymentInfo[] | null;
}) {
  const { business } = useAppSelector(selectAppAuth);
  let reports = '';
  if (ordersPrint && Array.isArray(ordersPrint) && ordersPrint.length > 0) {
    ordersPrint.map((order) => {
      const dataBill = {
        business,
        order,
        configs: configs || null,
        paymentInfo: paymentsInfo || [],
      };

      reports = reports.concat(
        '<br><br><br><br><br><br>',
        render('Bill', dataBill)
      );
    });
  }

  const componentPrintRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentPrintRef.current,
  });

  const showDataPrint = () => (
    <Box sx={{ display: 'none' }}>
      <Box
        ref={componentPrintRef}
        dangerouslySetInnerHTML={{ __html: reports }}
      ></Box>
    </Box>
  );

  return { handlePrint, showDataPrint };
}

export default usePrinter;
