import { Box, Typography, Grid, Card, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageDashboard } from './slice';
import { numberFormat, ORDER_STATUS } from '../../utils/constants';
import Revenue from '../../components/Icons/Revenue';
import ProfitDashboard from '../../components/Icons/ProfitDashboard';
import OrdersDelivered from '../../components/Icons/OrdersDelivered';
import DeliveringOrder from '../../components/Icons/DeliveringOrder';
import WaitingOrder from '../../components/Icons/WaitingOrder';
import TopSales from './TopSales';
import VisitedCustomers from './VisitedCustomers';
import useResponsive from '../../hooks/useResponsive';
import CommonSummary from './CommonSummary';
import Inventory from './Inventory';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../config/routeConfig';
import { pageOrdersActions } from '../Orders/slice';
import useAppDispatch from '../../hooks/useAppDispatch';

const CardStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 0, 0, 3),
}));

const General = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');
  const { shop } = useAppSelector(selectPageDashboard);
  return (
    <Box mb={5}>
      <Typography variant="h4" fontWeight={600} mb={3}>
        Cửa hàng
      </Typography>

      <Grid spacing={2} container>
        <Grid item xs={4}>
          <CommonSummary
            title="Doanh thu"
            value={shop?.revenue_total || 0}
            icon={<Revenue />}
          />
        </Grid>
        <Grid item xs={4}>
          <CommonSummary
            title="Lợi nhuận"
            value={shop?.profit_total || 0}
            icon={<ProfitDashboard />}
          />
        </Grid>
        <Grid item xs={4}>
          <CommonSummary
            title="Tổng đơn đã giao"
            value={shop?.order_complete_total || 0}
            icon={<OrdersDelivered />}
            onClick={() => {
              dispatch(pageOrdersActions.selectStatus(''));
              navigate(PATH_DASHBOARD.order.list);
            }}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <CardStyle sx={{ pr: 3, pb: 3 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="50%"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { color: theme.palette.primary.main },
                  }}
                  onClick={() => {
                    dispatch(
                      pageOrdersActions.selectStatus(
                        ORDER_STATUS.WAITING_CONFIRM
                      )
                    );
                    navigate(PATH_DASHBOARD.order.list);
                  }}
                >
                  <Box overflow="hidden" width={isDesktop ? '100%' : '60%'}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={500}
                      sx={{ mb: 1 }}
                    >
                      Chờ xử lý
                    </Typography>
                    <Typography
                      variant={isDesktop ? 'h3' : 'h5'}
                      fontWeight={600}
                      noWrap
                    >
                      {numberFormat.format(
                        shop?.order_waiting_confirm_total || 0
                      )}
                    </Typography>
                  </Box>
                  <WaitingOrder />
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: 3 }} />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="50%"
                  sx={{
                    cursor: 'pointer',
                    '&:hover': { color: theme.palette.primary.main },
                  }}
                  onClick={() => {
                    dispatch(
                      pageOrdersActions.selectStatus(ORDER_STATUS.DELIVERING)
                    );
                    navigate(PATH_DASHBOARD.order.list);
                  }}
                >
                  <Box overflow="hidden" width={isDesktop ? '100%' : '60%'}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={500}
                      sx={{ mb: 1 }}
                    >
                      Đang giao
                    </Typography>
                    <Typography
                      variant={isDesktop ? 'h3' : 'h5'}
                      fontWeight={600}
                      noWrap
                    >
                      {numberFormat.format(shop?.order_delivering_total || 0)}
                    </Typography>
                  </Box>
                  <DeliveringOrder
                    width={70}
                    height={70}
                    opacity={1}
                    fillOpacity={1}
                    fill={theme.palette.grey[500_16]}
                  />
                </Box>
              </CardStyle>
            </Grid>

            <Grid item xs={12} md={6}>
              <VisitedCustomers />
            </Grid>

            <Grid item xs={12} md={6}>
              <Inventory />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <TopSales />
        </Grid>
      </Grid>
    </Box>
  );
};

export default General;
