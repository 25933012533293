const CommingSoon = () => (
  <svg
    width="328"
    height="240"
    viewBox="0 0 328 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.153839 132.52C0.153839 160.159 10.3569 185.344 27.1468 204.378C46.4086 226.221 74.3401 239.954 105.475 240C119.082 240.02 132.092 237.409 144.043 232.642C150.223 230.177 157.139 230.46 163.124 233.381C170.144 236.803 178.005 238.721 186.306 238.721C189.735 238.721 193.093 238.391 196.341 237.765C205.613 235.985 214.05 231.772 220.979 225.805C225.334 222.054 230.911 220.168 236.611 220.175H236.708C255.543 220.175 273.038 214.353 287.563 204.378C300.549 195.471 311.153 183.247 318.218 168.894C324.378 156.387 327.846 142.259 327.846 127.305C327.846 76.0181 287.039 34.4356 236.708 34.4356C231.577 34.4356 226.55 34.8772 221.646 35.7012C208.66 14.28 185.406 0 158.879 0C147.679 0 137.061 2.54512 127.557 7.10748C118.518 11.4322 110.482 17.5775 103.908 25.0667C81.5863 25.4293 60.9462 32.86 44.053 45.261C17.4611 64.7702 0.153839 96.5882 0.153839 132.52Z"
        fill="url(#paint0_linear_3684_416084)"
      />
    </g>
    <path
      d="M200.675 159.254H153.493L144.914 122.715H192.095L200.675 159.254Z"
      fill="#C8FACD"
    />
    <path
      d="M197.884 159.638H153.493L144.914 122.715H189.305L197.884 159.638Z"
      fill="#00AB55"
      fillOpacity="0.48"
    />
    <rect
      x="94.9175"
      y="157.926"
      width="168.259"
      height="44.8256"
      rx="22.4128"
      fill="#00AB55"
    />
    <g filter="url(#filter0_d_3684_416084)">
      <rect
        x="83.4062"
        y="28.0598"
        width="166.697"
        height="106.158"
        rx="15.9005"
        fill="white"
      />
    </g>
    <path
      opacity="0.16"
      d="M250.103 113.158V119.532C250.094 127.634 243.528 134.2 235.426 134.209H98.0752C89.9691 134.209 83.3978 127.638 83.3978 119.532V113.158H250.103Z"
      fill="#00AB55"
    />
    <path
      d="M138.006 135.153H164.662C165.123 135.153 165.565 135.336 165.891 135.662C166.217 135.988 166.4 136.43 166.4 136.891C166.4 137.352 166.217 137.794 165.891 138.12C165.565 138.445 165.123 138.628 164.662 138.628H138.006C137.545 138.628 137.103 138.445 136.777 138.12C136.451 137.794 136.268 137.352 136.268 136.891C136.268 135.931 137.046 135.153 138.006 135.153V135.153Z"
      fill="#005249"
    />
    <path
      d="M142.316 125.856L141.522 131.094L143.719 131.344L146.117 125.806L142.316 125.856Z"
      fill="#FCBDBA"
    />
    <path
      d="M144.379 130.576C144.273 130.49 144.18 130.389 144.104 130.275C143.877 130.417 143.598 130.451 143.344 130.367L142.241 130.183C142.047 130.16 141.858 130.106 141.681 130.024H141.606L141.372 130.476C140.972 131.281 140.736 132.159 140.679 133.057C142.765 133.551 144.91 133.756 147.053 133.667C147.402 133.715 147.75 133.571 147.963 133.291C148.046 133.102 148.046 132.886 147.963 132.698C147.759 132.27 147.392 131.942 146.944 131.787C146.376 131.553 145.8 131.353 145.273 131.085C144.954 130.957 144.653 130.785 144.379 130.576Z"
      fill="#7A4F01"
    />
    <path
      d="M157.052 127.451L156.367 132.706L158.572 132.906L160.853 127.326L157.052 127.451Z"
      fill="#FCBDBA"
    />
    <path
      opacity="0.1"
      d="M231.959 54.2484V70.4628C231.959 74.901 228.361 78.499 223.923 78.499H176.867L157.795 100.436V46.2122H223.923C226.054 46.2122 228.098 47.0588 229.605 48.5659C231.112 50.073 231.959 52.117 231.959 54.2484Z"
      fill="#005249"
      fillOpacity="0.46"
    />
    <g filter="url(#filter1_d_3684_416084)">
      <rect
        x="160.736"
        y="39.3123"
        width="69.2851"
        height="32.2868"
        rx="8.70588"
        fill="#00AB55"
      />
    </g>
    <path
      opacity="0.1"
      d="M170.075 67.5975C169.805 67.6216 169.533 67.5815 169.282 67.4806C168.875 67.1972 168.596 66.7651 168.505 66.2776C167.193 62.2261 164.478 58.2832 160.402 57.0886C159.341 61.4075 159.416 65.9184 159.499 70.3709C159.499 71.5571 159.633 72.9188 160.585 73.6205C161.206 74.0035 161.928 74.1899 162.657 74.1551C167.28 74.3307 171.874 73.3517 176.023 71.3065C177.268 70.6883 179.832 68.2741 177.443 67.4889C176.725 67.2466 175.138 67.6142 174.369 67.6142L170.075 67.5975Z"
      fill="#020202"
    />
    <path
      d="M142.124 89.4005L145.399 107.603L142.124 127.184L147.428 127.593L155.156 105.54L152.541 88.064L142.124 89.4005Z"
      fill="#212B36"
    />
    <path
      d="M160.435 88.064L166.324 112.298L161.563 128.98L156.843 128.738L157.503 114.503L149.191 91.489L160.435 88.064Z"
      fill="#212B36"
    />
    <path
      d="M176.649 67.9653C177.358 67.5515 178.116 67.2287 178.905 67.0046C179.156 66.8922 179.447 66.911 179.682 67.0547C179.804 67.1695 179.884 67.3228 179.907 67.4891C180.03 68.024 179.986 68.5835 179.782 69.093C180.672 69.5861 181.606 69.9944 182.572 70.3126C182.98 70.3822 183.34 70.6183 183.566 70.9642C183.689 71.33 183.576 71.7336 183.282 71.9834C182.99 72.2256 182.638 72.3843 182.263 72.4428C179.841 73.0109 175.898 72.4428 175.321 69.5441C175.196 68.9092 175.43 68.8006 175.931 68.4414C176.198 68.266 176.441 68.1156 176.649 67.9653Z"
      fill="#FCBDBA"
    />
    <path
      d="M158.447 45.6025C158.998 46.7078 159.351 47.9008 159.491 49.1277C159.623 50.374 159.086 51.5971 158.079 52.3439C157.778 52.4832 157.497 52.663 157.244 52.8785C156.359 53.7891 157.369 55.4347 156.86 56.6126C156.453 57.4161 155.501 57.778 154.663 57.448C153.843 57.0896 153.229 56.3805 152.992 55.5183C152.767 54.6628 152.665 53.7795 152.691 52.8952L152.599 47.6324C152.482 46.4611 152.666 45.2793 153.134 44.1991C153.784 43.0775 155.006 42.4133 156.3 42.4782C157.628 42.6453 158.004 44.5666 158.447 45.6025Z"
      fill="#FCBDBA"
    />
    <path
      d="M176.808 71.883C176.808 71.883 166.717 80.8966 165.99 80.8966C165.263 80.8966 160.778 75.2328 160.778 75.2328L160.435 88.0974C159.189 88.8324 157.891 89.4746 156.551 90.0187C154.243 91.0721 151.727 91.5863 149.191 91.5224C144.288 91.0796 142.124 89.434 142.124 89.434L145.148 59.2189C145.148 59.2189 150.862 55.1089 152.817 55.7354C154.214 55.9859 155.646 55.9859 157.044 55.7354C159.867 55.3595 162.749 59.2189 162.749 59.2189L166.5 71.5405L175.296 68.4162C176.469 69.1479 177.07 70.5259 176.808 71.883Z"
      fill="url(#paint1_linear_3684_416084)"
    />
    <path
      d="M159.558 39.9303C159.623 39.2667 159.467 38.6002 159.115 38.034C158.439 37.09 157.052 37.0065 155.916 37.2571C155.135 37.4713 154.379 37.7682 153.66 38.1426C153.132 38.3602 152.556 38.4351 151.99 38.3598C150.49 38.3332 149.036 38.8765 147.921 39.8802C146.384 41.3337 146.025 43.4806 145.866 45.4938C145.699 47.7743 146.702 49.6706 148.214 51.4082C149.492 52.9202 151.822 54.4071 153.485 52.4942C154.226 51.5218 154.564 50.3011 154.429 49.0859C153.612 49.0024 152.94 48.4075 152.758 47.6073C152.641 47.1072 152.816 46.5839 153.209 46.254C153.448 46.1099 153.735 46.0667 154.006 46.1341C154.277 46.2015 154.51 46.3738 154.654 46.6132C154.702 46.6881 154.739 46.7698 154.763 46.8554C155.004 46.3708 155.181 45.8569 155.289 45.3267C155.481 44.8172 156.125 44.3744 156.542 44.7169C156.785 44.909 156.927 45.3351 157.236 45.2683C157.338 45.2297 157.428 45.1632 157.495 45.0761C158.103 44.4912 158.616 43.8147 159.015 43.0712C159.389 42.1941 159.585 41.2514 159.591 40.2978C159.56 40.1781 159.548 40.0538 159.558 39.9303Z"
      fill="#7A0C2E"
    />
    <path
      d="M139.726 38.8109C137.551 38.5528 135.444 39.6713 134.439 41.6178C133.277 43.9986 134.113 46.8137 134.305 49.4618C134.664 51.355 133.666 53.2448 131.899 54.0146C130.028 55.1423 127.873 55.9025 126.377 57.498C125.2 58.8096 124.505 60.4832 124.406 62.2429C124.312 62.9764 124.44 63.7212 124.773 64.3814C125.1 64.8698 125.527 65.2828 126.026 65.5927C128.823 67.6143 132.316 68.4243 135.717 67.8398C139.144 67.1898 141.888 64.6186 142.759 61.2405C143.494 57.7152 141.706 54.2151 141.03 50.6731C140.78 49.8348 140.879 48.9309 141.305 48.167C141.865 47.4152 142.901 47.2398 143.711 46.7636C148.097 44.1823 142.943 39.2453 139.726 38.8109Z"
      fill="#7A0C2E"
    />
    <rect
      x="144.237"
      y="42.2192"
      width="3.39993"
      height="3.39993"
      rx="1.69996"
      fill="#00AB55"
    />
    <path
      d="M159.207 132.129C159.102 132.042 159.01 131.941 158.931 131.829C158.708 131.978 158.429 132.021 158.171 131.946L157.069 131.779C156.873 131.757 156.681 131.706 156.501 131.628H156.425L156.2 132.088C155.823 132.905 155.607 133.787 155.565 134.686C157.661 135.13 159.808 135.293 161.947 135.17C162.299 135.213 162.645 135.059 162.849 134.769C162.933 134.58 162.933 134.365 162.849 134.176C162.64 133.752 162.265 133.431 161.813 133.291C161.237 133.065 160.652 132.881 160.093 132.614C159.776 132.494 159.478 132.331 159.207 132.129Z"
      fill="#7A4F01"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.675 138.628H156.292L153.593 145.929H139.651L142.349 138.628H138.966L115.234 202.751H118.617L120.964 196.411H134.881L132.534 202.751H135.917L138.265 196.411H138.365V196.143L159.474 139.121V202.751H162.858V138.628H159.675ZM152.675 148.419L149.158 157.925H135.207L138.724 148.419H152.675ZM134.28 160.465L130.763 169.963H144.714L148.247 160.465H134.28ZM143.795 172.469L140.278 181.967H126.327L129.869 172.419L143.795 172.469ZM125.4 184.465L121.883 193.971H135.834L139.35 184.465H125.4Z"
      fill="#007B55"
    />
    <g filter="url(#filter2_d_3684_416084)">
      <rect
        x="191.068"
        y="95.5823"
        width="58.3335"
        height="41.3087"
        rx="4.04525"
        fill="#C8FACD"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.99 131.185H240.48V130.45H200.716V120.289L209.497 114.895C209.786 115.319 210.273 115.598 210.824 115.598C211.496 115.598 212.072 115.184 212.31 114.598L223.769 116.362C223.83 117.191 224.523 117.845 225.368 117.845C226.254 117.845 226.972 117.127 226.972 116.241C226.972 116.081 226.948 115.927 226.905 115.781L237.257 109.808C237.551 110.151 237.987 110.368 238.475 110.368C239.361 110.368 240.079 109.65 240.079 108.764C240.079 107.878 239.361 107.16 238.475 107.16C237.589 107.16 236.871 107.878 236.871 108.764C236.871 108.899 236.888 109.03 236.919 109.155L226.538 115.143C226.245 114.832 225.829 114.637 225.368 114.637C224.695 114.637 224.12 115.051 223.881 115.638L212.424 113.872C212.361 113.043 211.669 112.39 210.824 112.39C209.938 112.39 209.22 113.108 209.22 113.994C209.22 114.061 209.224 114.127 209.233 114.192L200.716 119.426V101.296H199.99V131.185Z"
      fill="#00AB55"
    />
    <path
      d="M244.139 147.25C244.139 147.25 232.535 169.178 230.363 176.07C228.191 182.961 228.392 203.269 228.392 203.269H234.707C234.707 203.269 234.707 182.778 237.389 179.327C240.07 175.877 255.015 158.268 255.015 158.268L244.139 147.25Z"
      fill="#212B36"
    />
    <path
      d="M242.852 149.914C242.852 149.914 249.886 178.317 253.528 184.674C257.17 191.031 267.128 202.484 267.128 202.484L272.491 199.61C272.491 199.61 258.699 181.516 259.467 176.22C260.236 170.924 262.191 150.658 262.191 150.658C262.191 150.658 252.475 145.387 242.852 149.914Z"
      fill="#212B36"
    />
    <path
      d="M266.702 109.099C266.702 109.099 272.307 111.964 270.219 118.856C268.13 125.747 261.522 141.268 262.241 145.863C262.959 150.457 262.191 150.608 262.191 150.608C262.191 150.608 247.447 155.921 242.852 149.889C242.852 149.889 251.623 118.856 253.486 114.979C255.349 111.103 260.67 106.81 266.702 109.099Z"
      fill="url(#paint2_linear_3684_416084)"
    />
    <path
      d="M263.795 124.419C263.25 126.115 262.486 127.732 261.522 129.231C261.036 130.127 260.454 130.967 259.785 131.737C257.889 133.775 255.048 134.552 252.509 135.671C252.054 135.85 251.634 136.104 251.264 136.423C250.886 136.852 250.587 137.344 250.379 137.877C249 140.892 247.146 143.95 244.122 145.311C245.367 140.558 246.628 135.805 248.048 131.11C248.383 129.348 249.473 127.822 251.03 126.933C254.205 124.603 257.354 122.23 260.562 119.941C261.656 119.156 262.801 118.271 263.728 119.691C264.538 120.935 264.171 123.116 263.795 124.419Z"
      fill="url(#paint3_linear_3684_416084)"
    />
    <path
      d="M256.276 96.9856C255.959 97.3114 255.55 97.6455 255.55 98.0966C255.603 98.436 255.744 98.7555 255.959 99.0239C256.928 100.695 256.042 103.034 257.212 104.546C257.648 105.063 258.23 105.436 258.883 105.615C259.546 105.705 260.188 105.911 260.779 106.225C261.227 106.666 261.482 107.267 261.489 107.895C261.556 108.647 261.422 109.399 261.489 110.151C261.455 110.663 261.689 111.156 262.107 111.454C262.315 111.545 262.541 111.588 262.767 111.579C264.069 111.638 265.374 111.537 266.652 111.279C266.835 111.255 267.01 111.186 267.161 111.078C267.309 110.905 267.405 110.694 267.437 110.468C267.988 107.962 268.556 105.456 269.108 102.95C269.431 102.049 269.837 101.179 270.319 100.352C270.758 99.1017 270.907 97.7674 270.753 96.4509C270.611 95.4653 270.276 94.5172 269.768 93.6608C269.351 92.8527 268.743 92.1588 267.997 91.6392C267.446 91.315 266.853 91.0703 266.234 90.9125C264.095 90.2525 260.754 89.3671 258.899 91.2049C257.379 92.7419 257.88 95.39 256.276 96.9856Z"
      fill="#FCBDBA"
    />
    <path
      d="M256.051 99.7925C256.51 99.5084 257.997 100.102 258.557 100.093C260.32 100.043 261.756 100.929 263.168 99.8426C264.321 99.0072 264.78 97.5119 265.181 96.1419C265.19 96.067 265.19 95.9913 265.181 95.9164C265.165 95.8204 265.128 95.7292 265.073 95.6491C264.421 94.6967 263.093 94.5965 261.974 94.3208C259.977 93.7438 258.183 92.6179 256.794 91.0713C256.426 90.7547 256.179 90.321 256.093 89.8433C256.135 89.2833 256.446 88.7787 256.928 88.49C258.356 87.5877 260.083 87.2874 261.731 87.6546C263.356 88.03 264.96 88.4929 266.535 89.0413C268.055 89.4674 269.626 89.7263 271.138 90.2108C271.46 90.3035 271.767 90.4443 272.048 90.6285C272.417 90.9209 272.705 91.3036 272.883 91.7395C273.426 92.8519 273.426 94.1524 272.883 95.2648C272.774 95.4257 272.692 95.6035 272.641 95.7911C272.633 95.9806 272.658 96.1702 272.716 96.3508C273.092 98.0215 272.265 99.6922 271.463 101.137L269.375 105.03C269.163 105.5 268.849 105.917 268.456 106.25C268.047 106.582 267.467 106.603 267.036 106.3C266.601 105.924 266.576 105.172 266.067 104.922C265.717 104.807 265.335 104.853 265.023 105.047C264.104 105.49 263.352 106.191 262.45 106.718C260.89 107.587 259.052 107.812 257.329 107.344C256.823 107.219 256.349 106.985 255.942 106.659C255.048 105.891 255.107 104.863 255.291 103.819C255.474 102.775 255.107 100.377 256.051 99.7925Z"
      fill="#005249"
    />
    <path
      d="M265.399 119.916C265.399 119.916 259.367 135.429 235.426 138.395L234.59 135.053C234.59 135.053 250.579 128.73 254.029 122.314C255.021 120.511 255.878 118.638 256.594 116.709C257.68 113.701 261.163 112.081 263.953 113.643C265.607 114.57 266.635 116.408 265.399 119.916Z"
      fill="url(#paint4_linear_3684_416084)"
    />
    <path
      d="M230.539 134.36C230.566 134.783 230.744 135.183 231.04 135.488C229.506 136.06 227.883 136.36 226.245 136.373C225.947 136.338 225.647 136.422 225.41 136.607C225.092 136.949 225.343 137.509 225.635 137.868C226.879 139.272 228.6 140.165 230.464 140.374C231.678 140.61 232.937 140.406 234.014 139.798C234.758 139.24 235.385 138.542 235.86 137.743C235.957 137.621 236.03 137.482 236.077 137.334C236.113 137.04 236.042 136.744 235.877 136.498C235.685 136.139 235.417 135.429 235.041 135.254C234.666 135.078 234.148 135.254 233.763 135.187C232.661 134.936 231.675 134.235 230.539 134.36Z"
      fill="#FCBDBA"
    />
    <path
      d="M276.935 202.893C277.18 203.069 277.325 203.352 277.325 203.653C277.325 203.955 277.18 204.238 276.935 204.414C274.342 207.207 271.237 209.476 267.788 211.096C266.835 211.539 265.524 211.857 264.872 211.03C264.221 210.203 264.914 208.95 265.465 207.997C266.21 206.719 266.666 205.293 266.802 203.82C266.803 203.316 266.902 202.817 267.094 202.35C267.771 201.106 271.096 199.844 272.474 199.844C274.304 199.827 275.816 201.623 276.935 202.893Z"
      fill="#7A0C2E"
    />
    <path
      d="M236.796 207.763C236.929 208.035 236.92 208.355 236.771 208.619C236.623 208.883 236.354 209.056 236.052 209.083C232.417 210.219 228.596 210.645 224.8 210.336C223.747 210.244 222.453 209.868 222.294 208.824C222.135 207.78 223.363 207.037 224.315 206.494C225.588 205.751 226.687 204.746 227.54 203.545C227.787 203.105 228.123 202.721 228.526 202.417C229.729 201.665 233.254 202.191 234.457 202.885C236.044 203.795 236.462 206.101 236.796 207.763Z"
      fill="#7A0C2E"
    />
    <path
      d="M79.8977 200.187C79.8619 201.252 80.0586 202.312 80.4741 203.294C80.998 204.209 81.7875 204.943 82.7379 205.399C84.7741 206.46 87.1414 206.694 89.3456 206.051C89.6447 205.991 89.9136 205.828 90.1058 205.591C90.2197 205.391 90.2665 205.16 90.2395 204.931C90.0722 203.716 89.4552 202.607 88.5103 201.824C87.5686 201.057 86.7636 200.136 86.1295 199.101C85.1187 197.046 85.8705 191.582 82.0195 192.484C78.8033 193.211 79.8058 197.914 79.8977 200.187Z"
      fill="#FBB9AD"
    />
    <path
      d="M79.363 203.871C79.363 203.871 78.8117 205.224 79.4883 206.009C80.165 206.794 87.0901 211.096 91.7515 207.63C96.4128 204.163 85.4194 203.72 85.4194 203.72L79.363 203.871Z"
      fill="#B78103"
    />
    <path
      d="M79.6303 200.746C80.0663 200.526 80.5977 200.662 80.875 201.064C81.7993 202.117 83.2013 202.619 84.5841 202.392C86.1044 202.033 86.7059 200.278 87.4577 200.27C88.2095 200.262 92.236 203.695 92.4699 205.165C92.7038 206.635 90.6404 207.721 88.3683 207.863C86.0961 208.005 80.9335 206.427 79.9561 205.232C79.1194 203.88 78.9977 202.205 79.6303 200.746Z"
      fill="#7A4F01"
    />
    <path
      d="M66.6906 202.283C66.6459 203.349 66.843 204.412 67.267 205.391C67.791 206.304 68.5807 207.035 69.5308 207.488C71.5639 208.549 73.9288 208.782 76.1302 208.139C76.4328 208.082 76.7053 207.919 76.8987 207.68C77.0088 207.481 77.0554 207.254 77.0324 207.028C76.8636 205.813 76.2468 204.705 75.3032 203.921C74.368 203.15 73.5639 202.233 72.9224 201.206C71.9116 199.159 72.6634 193.687 68.8124 194.59C65.5962 195.325 66.5987 200.036 66.6906 202.283Z"
      fill="#FBB9AD"
    />
    <path
      d="M66.1559 205.984C66.1559 205.984 65.6046 207.329 66.2812 208.114C66.9579 208.899 73.8663 213.201 78.5277 209.743C83.189 206.285 72.2123 205.833 72.2123 205.833L66.1559 205.984Z"
      fill="#B78103"
    />
    <path
      d="M66.4232 202.851C66.8616 202.637 67.3909 202.775 67.6679 203.177C68.5875 204.237 69.9935 204.741 71.377 204.505C72.889 204.138 73.4988 202.392 74.2423 202.384C74.9857 202.375 79.0205 205.8 79.2544 207.27C79.4883 208.741 77.425 209.827 75.1528 209.969C72.8806 210.111 67.7264 208.54 66.749 207.346C65.908 205.992 65.7862 204.312 66.4232 202.851Z"
      fill="#7A4F01"
    />
    <path
      d="M63.9255 145.429C63.9255 145.429 63.2155 185 66.1392 198.198L73.5823 199.117L75.6123 145.036L63.9255 145.429Z"
      fill="#212B36"
    />
    <path
      d="M84.4838 141.151C84.4838 141.151 90.4065 162.453 86.1545 196.728L78.7114 197.647L74.0251 143.006L84.4838 141.151Z"
      fill="#212B36"
    />
    <path
      d="M95.6192 124.102C95.5357 125.539 95.0762 126.775 93.8315 127.193C93.7251 127.228 93.6162 127.256 93.5057 127.276C91.2252 127.736 88.2262 125.998 86.1462 124.478C85.2742 123.85 84.4375 123.176 83.6401 122.456V107.144L94.7838 118.521C95.3616 120.324 95.6437 122.209 95.6192 124.102Z"
      fill="white"
    />
    <path
      d="M86.2297 124.411C86.2297 125.129 86.3216 125.847 86.3216 126.566C86.3216 130.509 85.4111 134.585 86.5806 138.461C86.9653 139.285 87.0822 140.208 86.9147 141.101C86.5723 141.818 85.9774 142.383 85.244 142.688C81.7605 144.593 77.7257 145.194 73.8162 145.729C70.859 146.147 66.6655 147.024 63.9339 145.428C62.7727 144.752 62.3801 143.49 62.0042 142.229C60.9591 138.153 60.3431 133.978 60.1664 129.774C60.1664 129.222 60.0828 128.679 60.0578 128.103C59.8294 124.327 59.7515 120.546 59.8239 116.759C59.874 114.01 60.0912 111.045 61.8872 108.973C64.268 106.216 68.8792 104.387 72.3877 103.744C73.5219 103.509 74.6846 103.444 75.8378 103.552C77.4708 103.81 79.0475 104.347 80.4991 105.139C82.2367 105.974 83.7821 106.726 84.6759 108.438C85.6142 110.426 86.2694 112.535 86.6223 114.704C86.9508 116.737 86.9958 118.806 86.756 120.852C86.6558 121.854 86.3968 122.849 86.2966 123.843C86.2574 124.03 86.235 124.22 86.2297 124.411Z"
      fill="url(#paint5_linear_3684_416084)"
    />
    <path
      opacity="0.1"
      d="M70.1156 146.773C68.9711 147.108 66.3314 146.055 65.4542 145.938C64.9279 145.846 64.4518 145.579 63.9255 145.462C61.3108 144.81 61.0017 137.459 60.7511 135.479C60.0745 130.258 59.0303 124.845 58.9551 119.607C58.8376 117.586 59.0722 115.559 59.6484 113.618C60.2424 111.655 61.6778 110.057 63.5663 109.257C66.9078 108.054 67.9018 118.622 68.3613 120.81C68.8207 122.999 68.8792 126.875 70.3244 128.629C71.7696 130.384 74.6599 130.709 76.5479 129.824C78.6226 128.782 79.8847 126.612 79.764 124.294C79.6499 123.508 79.5857 122.715 79.5719 121.921C79.5736 121.108 80.0457 120.369 80.7832 120.025C81.4781 119.851 82.2117 120.084 82.6794 120.626C83.1245 121.174 83.4439 121.813 83.615 122.498C84.0472 123.929 84.3272 125.402 84.4504 126.892C84.7845 130.425 84.2499 134.051 85.0936 137.501C85.4711 138.573 85.6522 139.705 85.6283 140.842C85.2325 142.781 83.5305 144.176 81.5517 144.184C79.5971 144.109 77.8029 143.083 76.7483 141.435C76.5815 141.145 76.3569 140.892 76.0884 140.692C75.7887 140.541 75.4538 140.475 75.1194 140.5C73.5238 140.5 71.2349 140.867 70.717 142.655C70.3495 143.933 71.2349 145.161 70.5416 146.481C70.4396 146.627 70.2883 146.731 70.1156 146.773Z"
      fill="#020202"
    />
    <path
      d="M79.1542 98.715C78.8765 99.2691 78.4033 99.7006 77.826 99.9263C77.1218 100.085 76.4514 100.368 75.8462 100.762C75.3554 101.293 75.043 101.965 74.9523 102.683L74.4762 104.905L74.2255 106.024C74.1929 106.227 74.125 106.422 74.0251 106.601C73.7395 106.92 73.3247 107.092 72.8973 107.069C72.0169 107.105 71.1453 106.881 70.3912 106.425C70.2593 106.368 70.1505 106.268 70.0821 106.141C70.016 105.986 69.9985 105.814 70.032 105.648L70.3077 102.449C70.4608 101.574 70.3229 100.673 69.9151 99.8845C68.9945 98.2107 68.3113 96.417 67.8851 94.5549C67.5835 93.5082 67.7561 92.3818 68.3573 91.4735C68.9586 90.5652 69.928 89.9663 71.0094 89.8351C72.5721 89.3552 74.2403 89.3378 75.8127 89.7849C76.3975 89.9854 76.5729 90.3864 76.8486 90.9127C77.2579 91.748 77.684 92.5834 77.993 93.4188C78.3021 94.2541 78.7198 95.3568 79.0289 96.3592C79.349 97.1047 79.3934 97.9398 79.1542 98.715Z"
      fill="#FBB9AD"
    />
    <path
      d="M102.803 114.829C102.904 114.662 103.096 114.495 103.254 114.595C103.319 114.66 103.365 114.741 103.388 114.829C103.575 115.156 103.922 115.357 104.299 115.355C104.658 115.355 105.034 115.297 105.401 115.297C106.375 115.328 107.278 115.813 107.841 116.608C108.393 117.402 108.701 118.34 108.726 119.307C108.824 120.175 108.585 121.049 108.058 121.746C107.222 122.723 105.71 122.748 104.416 122.707H103.229C102.647 122.783 102.108 122.39 102.001 121.813C101.517 120.635 100.64 119.457 101.241 118.271C101.843 117.085 102.085 115.664 102.803 114.829Z"
      fill="#FBB9AD"
    />
    <path
      d="M79.4883 127.276V118.421C79.4879 117.712 79.9223 117.075 80.5827 116.817L102.511 108.088C103.647 107.636 105.961 108.99 105.961 110.209L104.883 130.701C104.885 131.401 104.462 132.031 103.814 132.296L82.93 141.92C81.7856 142.388 79.4549 141.034 79.4549 139.806L79.4883 127.276Z"
      fill="#00AB55"
    />
    <path
      d="M79.4883 127.276V118.421C79.4719 117.71 79.9077 117.066 80.5743 116.817L102.511 108.088C103.647 107.636 105.961 108.99 105.961 110.209L104.883 130.701C104.882 131.4 104.46 132.03 103.814 132.296L82.93 141.92C81.7939 142.388 79.4633 141.034 79.4633 139.806L79.4883 127.276Z"
      fill="#007B55"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.5827 127.794V118.939C80.5811 118.228 81.0153 117.588 81.677 117.327L103.605 108.606C104.138 108.392 104.742 108.457 105.217 108.778C105.692 109.099 105.977 109.636 105.978 110.209V131.244C105.977 131.943 105.555 132.573 104.908 132.839L82.9802 141.945C82.5549 142.121 82.0769 142.121 81.6519 141.944C81.227 141.767 80.8902 141.427 80.7163 141.001C80.6274 140.792 80.582 140.568 80.5827 140.341V127.794Z"
      fill="#007B55"
    />
    <path
      d="M80.5827 118.939L95.6192 126.674L105.56 109.132C105.56 109.132 105.944 108.906 105.944 110.209V131.244L80.5827 139.13V118.939Z"
      fill="url(#paint6_linear_3684_416084)"
    />
    <path
      d="M75.37 90.7788C75.0606 91.2476 74.5761 91.5726 74.0251 91.681C72.5381 91.9985 71.6359 91.2801 70.8841 93.0093C70.6416 93.6013 70.5665 94.2486 70.6669 94.8805C70.6669 95.0141 71.1347 96.4927 71.2516 96.4176C70.8674 96.6932 70.5667 95.9414 70.149 95.7242C69.7313 95.507 69.1131 96.1419 69.2134 96.6849C69.3877 97.218 69.6988 97.6962 70.1156 98.0716C70.7834 98.9005 71.1514 99.9309 71.1598 100.995C71.1598 101.238 71.0679 101.563 70.8173 101.572C70.5667 101.58 70.291 101.229 70.0988 101.096C69.7929 100.89 69.4717 100.709 69.1382 100.553C68.119 100.068 66.582 99.9177 65.7299 99.2327C65.2907 98.8673 64.9948 98.3584 64.8945 97.7959C64.8945 97.6622 64.8945 96.2254 65.1451 96.3006C64.6339 96.1485 64.2053 95.7973 63.9555 95.3261C63.7058 94.8548 63.6558 94.303 63.8169 93.7945C63.9291 93.4408 64.1339 93.1235 64.41 92.8756C63.3658 91.4221 63.8503 89.2668 65.1034 87.9887C66.4483 86.7868 68.1503 86.059 69.9485 85.917C71.902 85.609 73.8996 85.7403 75.796 86.3013C76.454 86.4833 77.0622 86.8117 77.5753 87.262C78.0843 87.7224 78.3368 88.402 78.252 89.0831C78.0265 89.1749 77.7682 89.1258 77.5921 88.9578C77.8222 89.5852 77.5008 90.2805 76.8736 90.5115C76.4124 90.6792 75.8956 90.5541 75.5621 90.1941C75.5454 90.4015 75.4795 90.6019 75.37 90.7788Z"
      fill="#005249"
    />
    <path
      d="M76.3223 100.394L76.0717 100.469C75.9966 100.477 75.9261 100.509 75.8712 100.561C75.8547 100.618 75.8547 100.679 75.8712 100.737C75.3805 101.268 75.068 101.94 74.9774 102.658L74.5012 104.88C73.6972 103.46 73.0902 101.936 72.6968 100.352C73.9006 100.491 75.1156 100.505 76.3223 100.394Z"
      fill="#DC9F95"
    />
    <path
      d="M77.5921 127.36C77.8776 127.009 78.3196 126.824 78.7699 126.867C79.0217 126.993 79.2621 127.141 79.4883 127.309C79.7015 127.405 79.9316 127.456 80.165 127.46C81.1096 127.528 82.0578 127.394 82.9467 127.067C83.3954 126.849 83.8848 126.726 84.3836 126.708C84.9869 126.826 85.5087 127.201 85.812 127.736C86.7203 129.038 86.7759 130.754 85.954 132.113C85.5809 132.653 85.0895 133.102 84.5172 133.424C83.2487 134.089 81.8391 134.438 80.4072 134.443C78.9871 134.51 77.5419 134.443 76.1302 134.51C75.7957 134.571 75.4505 134.525 75.1444 134.377C74.8016 134.058 74.6257 133.598 74.6683 133.132C74.6182 132.054 74.4344 131.144 75.0609 130.233C75.8484 129.227 76.6935 128.268 77.5921 127.36Z"
      fill="#FBB9AD"
    />
    <path
      d="M61.9039 108.981C61.9039 108.981 46.2074 129.506 51.1695 136.757C56.1316 144.008 77.0658 135.563 77.0658 135.563L75.0275 129.556C75.0275 129.556 60.3251 131.419 60.2165 129.774C60.1079 128.128 69.5392 118.705 69.5392 118.705C69.5392 118.705 71.2266 104.913 61.9039 108.981Z"
      fill="#5CB88F"
    />
    <defs>
      <filter
        id="filter0_d_3684_416084"
        x="79.4062"
        y="24.0598"
        width="182.697"
        height="122.158"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.32 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3684_416084"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3684_416084"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_3684_416084"
        x="154.736"
        y="33.3123"
        width="93.2851"
        height="56.2869"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6" dy="6" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3684_416084"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3684_416084"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_3684_416084"
        x="185.068"
        y="89.5823"
        width="82.3335"
        height="65.3088"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6" dy="6" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.48 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3684_416084"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3684_416084"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3684_416084"
        x1="224.63"
        y1="282.688"
        x2="340.472"
        y2="15.3249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#00AB55" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3684_416084"
        x1="146.282"
        y1="65.2906"
        x2="147.231"
        y2="92.3214"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#76DAA8" />
        <stop offset="1" stopColor="#26735B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3684_416084"
        x1="246.183"
        y1="120.347"
        x2="247.989"
        y2="153.694"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#76DAA8" />
        <stop offset="1" stopColor="#26735B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3684_416084"
        x1="207.864"
        y1="139.505"
        x2="272.741"
        y2="188.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" />
        <stop offset="0.08" stopOpacity="0.69" />
        <stop offset="0.21" stopOpacity="0.32" />
        <stop offset="1" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3684_416084"
        x1="238.343"
        y1="119.841"
        x2="238.869"
        y2="138.965"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#76DAA8" />
        <stop offset="1" stopColor="#26735B" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3684_416084"
        x1="63.0518"
        y1="114.997"
        x2="64.7809"
        y2="147.244"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#76DAA8" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3684_416084"
        x1="83.6177"
        y1="117.169"
        x2="84.5285"
        y2="139.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AB55" />
        <stop offset="1" stopColor="#007B55" />
      </linearGradient>
    </defs>
  </svg>
);

export default CommingSoon;
