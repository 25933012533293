import { Box } from '@mui/material';
import { memo } from 'react';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageOrderDetail } from './slice';
import CustomerPaid from './CustomerPaid';

const LeftPanel = () => {
  const {
    main: { orderDetail },
  } = useAppSelector(selectPageOrderDetail);

  return (
    <Box>
      <CustomerPaid orderDetail={orderDetail} />
    </Box>
  );
};

export default memo(LeftPanel);
