import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { useEffect } from 'react';
import { configure } from 'react-hotkeys';
import { AuthorizedRoute, UnauthorizedRoute } from './config/route';
import useAppDispatch from './hooks/useAppDispatch';
import useAppSelector from './hooks/useAppSelector';
import Loader from './screens/Loader';
import { authActions, selectAppAuth } from './store/auth';
import { selectAppProduct } from './store/product/index';
import { categoryActions } from './store/category';
import { contactActions } from './store/contact';
import { productActions } from './store/product';
import { promotionActions } from './store/promotion';
import { selectAppToast, toastActions } from './store/toast';
import { shouldLogout } from './utils/authUtils';
import { EXPIRE_PRO_TIME, FIRST_TIME_REDIRECT } from './utils/constants';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from './config/routeConfig';

configure({
  ignoreTags: [],
});

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state, user_info, isProUser } = useAppSelector(selectAppAuth);
  const { ids } = useAppSelector(selectAppProduct);
  const { toasts } = useAppSelector(selectAppToast);
  const expireTime = localStorage.getItem(EXPIRE_PRO_TIME);
  const firstTimeRedirect = localStorage.getItem(FIRST_TIME_REDIRECT);

  useEffect(() => {
    if (state === 'refresh_account' && ids.length > 0) {
      dispatch(authActions.setStateAuth('ready'));
      dispatch(authActions.logout()).unwrap();
    }
  }, [dispatch, ids.length, state]);

  useEffect(() => {
    if (firstTimeRedirect === 'true') {
      navigate(
        isProUser ? PATH_DASHBOARD.dashboard : PATH_DASHBOARD.product.list
      );
      localStorage.setItem(FIRST_TIME_REDIRECT, 'false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProUser, firstTimeRedirect]);

  useEffect(() => {
    if (expireTime) {
      const isLogout = shouldLogout(expireTime);
      if (isLogout) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message:
              'Gói PRO của bạn đã hết hạn. Vui lòng gia hạn để tiếp tục sử dụng các tiện ích nâng cao',
            dismissAfter: 10000,
          })
        );
        dispatch(authActions.logout()).unwrap();
      }
    }
  }, [dispatch, expireTime]);

  useEffect(() => {
    dispatch(authActions.initialize());
  }, [dispatch, state]);

  const user_id = user_info?.id;
  useEffect(() => {
    const abortController = new AbortController();
    if (user_id) {
      dispatch(categoryActions.synchronize(abortController.signal));
      dispatch(productActions.synchronize(abortController.signal));
      if (isProUser) {
        dispatch(contactActions.synchronize(abortController.signal));
        dispatch(promotionActions.synchronize(abortController.signal));
      }
      //dispatch(notificationActions.synchronize(abortController.signal));
    }

    return () => {
      abortController.abort();
    };
  }, [dispatch, isProUser, user_id]);

  if (state === 'loading') {
    return <Loader />;
  }

  return (
    <>
      {user_info ? <AuthorizedRoute /> : <UnauthorizedRoute />}
      {toasts.map((toast, index) => (
        <Snackbar open key={toast.id} style={{ bottom: 24 + index * 60 }}>
          <Alert
            sx={{
              width: '100%',
              '&.MuiPaper-root': { display: 'flex', alignItems: 'center' },
              '& .MuiAlert-action': { pt: 0 },
            }}
            severity={toast.severity}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(toastActions.dismiss(toast.id));
                }}
              >
                Đóng
              </Button>
            }
          >
            <Box display="flex" alignItems="center">
              <Typography variant="body2">{toast.message}</Typography>
              {toast.buttonText && (
                <Button
                  onClick={() => {
                    navigate(toast.link || '');
                    dispatch(toastActions.dismiss(toast.id));
                  }}
                  sx={{ ml: 1 }}
                >
                  {toast.buttonText}
                </Button>
              )}
            </Box>
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default App;
