import moment from 'moment';
import { DateRange } from '@mui/lab/DateRangePicker';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getQueryParams = (url: string) => {
  if (!url) return;
  return (url.match(/([^?=&]+)(=([^&]*))/g) ?? []).reduce((total, crr) => {
    const [key, value] = crr.split('=');
    if (key) {
      Object.assign(total, { [key]: decodeURI(value || '') });
    }
    return total;
  }, {} as any);
};

export const scrollTo = (el: any) => {
  if (el) {
    const elLeft = el.offsetLeft + el.offsetWidth;
    const elParentLeft = el.parentNode.offsetLeft + el.parentNode.offsetWidth;

    // check if element not in view
    if (elLeft >= elParentLeft + el.parentNode.scrollLeft) {
      el.parentNode.scrollLeft = 1000;
    } else if (elLeft <= el.parentNode.offsetLeft + el.parentNode.scrollLeft) {
      el.parentNode.scrollLeft = el.offsetLeft - el.parentNode.offsetLeft;
    }
  }
};

export const changeToNumberFormat = (value: string) => {
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const getDateRangePicker = (newValue: Array<Date | null>) => {
  if (
    (newValue[0] !== null && !moment(newValue[0]).isValid()) ||
    (newValue[1] !== null && !moment(newValue[1]).isValid())
  ) {
    return false;
  }
  let toDate = new Date();
  if (newValue[1]) {
    toDate = new Date(newValue[1].setHours(23, 59, 0, 0));
  }

  return [newValue[0], toDate] as DateRange<Date>;
};

export function validatePhone(phone: string) {
  let phoneNumber = phone;
  phoneNumber = phoneNumber.replace(/\s/g, '');

  phoneNumber = formatPhone(phoneNumber);
  if (phoneNumber.length !== 12) return false;

  return true;
}

export function formatPhone(phone: string) {
  if (phone.length === 13) return phone;

  const validLength = /^0/g.test(phone) ? 10 : 9;
  let _phone = phone.replace(/^(\+84)|\D/g, '');
  _phone = _phone.substring(0, validLength);

  if (_phone.length !== validLength) return '';

  let res = phone.replaceAll(' ', '');
  res = res.replaceAll('-', '');
  if (!res) return '';

  if (res[0] === '+') return res;

  if (res[0] === '0') return `+84${res.slice(1)}`;

  if (res[0] === '8') return `+${res}`;

  return `+84${res}`;
}

export function getDeviceName() {
  const userAgent = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return 'tablet';
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent
    )
  ) {
    return 'mobile';
  }
  return 'desktop';
}

export function getOperatingSystem() {
  if (typeof navigator !== 'undefined') {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) return 'Android';

    if (/iPad|iPhone|iPod/.test(userAgent)) return 'iOS';

    if (userAgent.indexOf('Win') !== -1) return 'Windows';

    if (userAgent.indexOf('Mac') !== -1) return 'MacOS';

    if (userAgent.indexOf('Linux') !== -1) return 'Linux';
  }

  return 'unknown';
}
