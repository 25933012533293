import {
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  Divider,
  Stack,
  Grid,
  Box,
  // MenuItem,
  // SelectChangeEvent,
} from '@mui/material';
import React, { memo, SyntheticEvent } from 'react';
import Iconify from '../../minimal/Iconify';
import { TABS } from './settings';
// import { SelectStyle } from './ContactsComponent';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import {
  ContactTabs,
  pageContactsActions,
  selectedLocalSelectedTab,
  selectedLocalCountContact,
} from './slice';
import Label from '../../minimal/Label';

type HeaderProps = {
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Header = ({ onChangeSearch }: HeaderProps) => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(selectedLocalSelectedTab);
  // const [select, setSelect] = useState('all');
  const countContact = useAppSelector(selectedLocalCountContact);

  const handleChangeTab = (
    _event: SyntheticEvent,
    newValue: { id: ContactTabs }
  ) => {
    dispatch(pageContactsActions.setPage(0));
    dispatch(pageContactsActions.selectTab(newValue.id));
  };

  // const handleOrderChange = (event: SelectChangeEvent<ExpectedAny>) => {
  //   setSelect(event.target.value);
  // };

  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={selectedTab}
        onChange={handleChangeTab}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        {TABS.map((tab, index) => (
          <Tab
            key={tab.id}
            value={tab}
            label={
              <Stack spacing={1} direction="row" alignItems="center">
                <div>{tab.label}</div>{' '}
                <Label color="success">{countContact[index]}</Label>
              </Stack>
            }
            sx={{
              px: 2,
              textTransform: 'none',
              '&:not(:last-of-type)': { mr: 1 },
            }}
          />
        ))}
      </Tabs>
      <Divider />
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ py: 2.5, px: 3 }}
      >
        <Grid container>
          <Grid item xs={5}>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                placeholder="Tìm kiếm Tên/ Số điện thoại khách hàng"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon={'eva:search-fill'}
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                }}
                sx={{ mr: 2 }}
                onChange={onChangeSearch}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack>

      {/* <Box display="flex">
          <SelectStyle value={select} onChange={handleOrderChange}>
            <MenuItem sx={{ fontWeight: 700, fontSize: 14 }} value="all">
              Tất cả
            </MenuItem>
            <MenuItem
              sx={{ fontWeight: 700, fontSize: 14 }}
              value="highlightCus"
            >
              Khách nổi bật
            </MenuItem>
          </SelectStyle>
        </Box> */}
    </>
  );
};

export default memo(Header);
