const DesktopMobile = () => (
  <svg
    width="83"
    height="82"
    viewBox="0 0 83 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4865_443755)">
      <path
        d="M78.3927 28.3973H68.5108V14.2267C68.5108 11.0011 65.8866 8.37695 62.6611 8.37695H6.06248C2.83693 8.37695 0.212769 11.0011 0.212769 14.2267V49.4929C0.212769 52.7183 2.83693 55.3425 6.06248 55.3425H33.1949V62.3973H19.5351C18.8907 62.3973 18.3681 62.9198 18.3681 63.5644C18.3681 64.2089 18.8905 64.7315 19.5351 64.7315H47.1384V69.8012C47.1384 71.9076 48.8521 73.6212 50.9586 73.6212H78.3927C80.4991 73.6212 82.2128 71.9076 82.2128 69.8012V32.2175C82.2128 30.111 80.4991 28.3973 78.3927 28.3973ZM47.1382 62.3975H35.5287V55.3426H47.1382V62.3975ZM47.1382 53.0087H6.06248C4.12394 53.0087 2.54673 51.4316 2.54673 49.4931V49.0546H47.1382V53.0087ZM47.1382 32.2175V46.7206H2.54673V14.2267C2.54673 12.2881 4.12378 10.7109 6.06248 10.7109H62.6611C64.5996 10.7109 66.1768 12.288 66.1768 14.2267V28.3973H50.9586C48.8519 28.3973 47.1382 30.111 47.1382 32.2175ZM79.8788 69.8012C79.8788 70.6207 79.2121 71.2873 78.3927 71.2873H50.9584C50.1389 71.2873 49.4722 70.6206 49.4722 69.8012V66.3047H79.8788V69.8012ZM79.8788 63.9707H49.4722V36.9588H79.8788V63.9707ZM79.8788 34.6248H49.4722V32.2175C49.4722 31.398 50.1389 30.7312 50.9584 30.7312H78.3926C79.2121 30.7312 79.8786 31.398 79.8786 32.2175V34.6248H79.8788ZM62.3886 70.0205H66.9624C67.6068 70.0205 68.1294 69.498 68.1294 68.8534C68.1294 68.2089 67.607 67.6863 66.9624 67.6863H62.3886C61.7442 67.6863 61.2216 68.2088 61.2216 68.8534C61.2217 69.4979 61.7442 70.0205 62.3886 70.0205ZM64.6755 31.4521C63.9871 31.4521 63.4292 32.0101 63.4292 32.6984C63.4292 33.3868 63.9871 33.9448 64.6755 33.9448C65.3638 33.9448 65.9218 33.3868 65.9218 32.6984C65.922 32.0101 65.3638 31.4521 64.6755 31.4521Z"
        fill="#919EAB"
        fillOpacity="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip0_4865_443755">
        <rect
          width="82"
          height="82"
          fill="white"
          transform="translate(0.212769)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DesktopMobile;
