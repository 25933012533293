import { API_URI } from '../config/env';
import { PERMISSIONS } from '../utils/constants';
import { fetchData } from '../utils/fetch';

const getUserProfile = async () => {
  return {
    ...(await fetchData<{
      user_info: UserInfo;
      business_info: {
        current_business: Business;
        list_business: ShortenBusiness[];
      };
    }>(`${API_URI}/ms-user-management/api/auth/v2`, {
      authorization: true,
    })),
    permissions: [PERMISSIONS.DEFAULT], // it should be returns from the above request
  };
};

export const userService = { getUserProfile };
