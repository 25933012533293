import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import type { RootState } from '../rootReducer';
import { promotionOffline } from '../../config/offline/promotion';
import configOfflineCacheSlice from '../../utils/offline/configOfflineCacheSlice';

export const selectAppPromotion = (store: RootState) => store.cache_promotion;

export const selectCachePromotions = createSelector(
  selectAppPromotion,
  (state) => state.byId
);

const savePromotion = createAsyncThunk(
  'promotion/save',
  (promotion: PendingProduct) => {
    return {
      requestId: nanoid(),
      ...promotion,
    };
  }
);

const promotionSlice = configOfflineCacheSlice(promotionOffline);

export const promotionActions = {
  savePromotion,
  ...promotionSlice.actions,
};

export default promotionSlice;
