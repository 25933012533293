import qs from 'qs';
import { API_URI } from '../config/env';
import { fetchData } from '../utils/fetch';
import authService from './auth';

async function getNotifications(opt: {
  page: number;
  lastUpdate: Date;
  type?: string;
}) {
  return fetchData<Array<JSONRecord<AppNotification>>>(
    `${API_URI}/ms-notification-management/api/notify-info?${qs.stringify({
      page: opt.page,
      type: opt.type || 'normal',
      size: 30,
      update_after: opt.lastUpdate.toISOString(),
      user_id: await authService.getUserId(),
    })}`,
    {
      authorization: true,
    }
  );
}

async function getNotificationCount() {
  return fetchData<NotificationStatistic>(
    `${API_URI}/ms-notification-management/api/notification/get-count-notify`,
    { authorization: true }
  );
}

const notificationService = { getNotifications, getNotificationCount };
export default notificationService;
