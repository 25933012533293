import {
    Typography,
    Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import EmptyOrder from './EmptyOrderIcon';

type EmptyOrderStateProps = {
    classCus: ExpectedAny,
    text?: string,
}
const EmptyOrderState = ({ classCus, text }: EmptyOrderStateProps) => {
    const theme = useTheme();
    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={10}
            sx={classCus}
        >
            <EmptyOrder />
            <Typography color={theme.palette.grey[500]} sx={{ my: 3 }}>
                {text ? text : 'Bạn chưa có đơn hàng nào!'}
            </Typography>
        </Box>
    );
};

export default memo(EmptyOrderState);
