import i18n from '../config/i18n';

type IError = {
  message: string;
};

function isErrorLike(ex: unknown): ex is IError {
  return !!ex && typeof ex === 'object' && 'message' in ex;
}

export function getErrorMessage(ex: unknown) {
  const { t } = i18n;
  if (isErrorLike(ex)) {
    return t(`${ex.message}`, { ns: 'error' });
  }

  if (typeof ex === 'object') {
    // NOT EXPECTED
    return JSON.stringify(ex);
  }

  return String(ex);
}
