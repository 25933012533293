import { Table, TableContainer, Card, Typography, Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TableHeader } from '../../components/TableHeader';
import noOffline from '../../hocs/noOffline';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import MainLayout from '../../layouts/MainLayout';
import Loader from '../Loader';
import EmptyState from './EmptyState';
import Header from './Header';
import OrdersPagination from './OrdersPagination';
import OrdersTable from './OrdersTable';
import { TABLE_HEAD } from './settings';
import {
  pageOrdersActions,
  selectLocalSearchResult,
  selectedLocalSelectedStatus,
  selectPageOrders,
} from './slice';
// import Download from '../../components/Icons/Download';
import Iconify from '../../minimal/Iconify';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import HeaderBreadcrumbs from '../../minimal/HeaderBreadcrumbs';
import { selectAppAuth } from '../../store/auth';

export const FILTER_STATE_OPTIONS = [
  'Chưa thanh toán',
  'Đang giao',
  'Đã giao',
  'Đã hủy',
];
const Orders = () => {
  const {
    main: { dateRange, orderPaymentDialog, orderAnalysis },
  } = useAppSelector(selectPageOrders);
  const {
    rows: orders,
    page,
    pageSize,
  } = useAppSelector(selectLocalSearchResult);
  const dispatch = useAppDispatch();
  const selectedStatus = useAppSelector(selectedLocalSelectedStatus);
  const { business } = useAppSelector(selectAppAuth);
  const [search, setSearch] = useState<string>('');
  const theme = useTheme();
  const navigate = useNavigate();

  const handleSearchChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>) => {
      setTimeout(() => {
        setSearch(_e.target.value);
      }, 150);
    },
    []
  );
  const fetchOrders = useCallback(() => {
    const params = {
      page,
      pageSize,
      state: selectedStatus ? selectedStatus.id : '',
      search,
    };

    dateRange.forEach((time, index) => {
      if (time !== null) {
        if (index === 0) {
          Object.assign(params, { start_time: time.toISOString() });
        } else {
          Object.assign(params, { end_time: time.toISOString() });
        }
      }
    });
    dispatch(pageOrdersActions.setEmptyArraySelected());
    dispatch(pageOrdersActions.search(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, dispatch, page, pageSize, search]);

  useEffect(() => {
    dispatch(pageOrdersActions.getOrderAnalysis());
    dispatch(pageOrdersActions.getPaymentMethod());
    dispatch(pageOrdersActions.getConfigInvoice(business?.phone_number || ''));
    dispatch(pageOrdersActions.getPaymentsInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !orderPaymentDialog && fetchOrders();
  }, [
    dispatch,
    page,
    pageSize,
    search,
    fetchOrders,
    orderPaymentDialog,
    orderAnalysis,
  ]);

  if (!orders) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }
  return (
    <MainLayout>
      <HeaderBreadcrumbs
        links={[]}
        action={
          <>
            {/* <Button
              variant="outlined"
              startIcon={<Download fill={theme.palette.primary.main} />}
              sx={{ minWidth: 147, mr: 2 }}
              onClick={() => setOpenCommingSoon(true)}
            >
              Xuất file
            </Button> */}
            <Button
              variant="contained"
              startIcon={
                <Iconify
                  icon="eva:plus-fill"
                  color={theme.palette.common.white}
                />
              }
              sx={{ minWidth: 180 }}
              // onClick={handleCreate}
              onClick={() => navigate('/pos')}
            >
              Tạo đơn hàng
            </Button>
          </>
        }
      >
        <Typography variant="h4" gutterBottom fontWeight={500}>
          Đơn hàng
        </Typography>
      </HeaderBreadcrumbs>

      <Card>
        <Header search={search} onChangeSearch={handleSearchChange} />
        {orders.length === 0 && (
          <>
            <TableContainer>
              <Table sx={{ width: '98%', mx: 'auto' }}>
                <TableHeader headLabel={TABLE_HEAD} />
              </Table>
            </TableContainer>
            <EmptyState />
          </>
        )}

        {orders.length > 0 && (
          <>
            <OrdersTable />
            <OrdersPagination />
          </>
        )}
      </Card>
    </MainLayout>
  );
};

export default noOffline(Orders);
