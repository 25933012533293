const Location = ({ className }: { className?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="10" cy="7.9165" r="1.25" fill="#00AB55" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33334 8.2665C3.3699 4.61067 6.34399 1.66632 10 1.6665C13.656 1.66632 16.6301 4.61067 16.6667 8.2665C16.6667 12.8332 10.8333 17.9165 10.5417 18.1332C10.2298 18.3999 9.7702 18.3999 9.45834 18.1332L9.45698 18.132C9.18789 17.8988 3.33334 12.8252 3.33334 8.2665ZM7.08334 7.9165C7.08334 9.52733 8.38918 10.8332 10 10.8332C10.7736 10.8332 11.5154 10.5259 12.0624 9.9789C12.6094 9.43192 12.9167 8.69005 12.9167 7.9165C12.9167 6.30567 11.6108 4.99984 10 4.99984C8.38918 4.99984 7.08334 6.30567 7.08334 7.9165Z"
      fill="#00AB55"
    />
  </svg>
);

export default Location;
