import {
  Typography,
  Box,
  styled,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControl,
  InputLabel,
  Button,
  ToggleButtonGroup,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { getFieldProps } from '../../utils/formik';
import { useTheme } from '@mui/material/styles';
import { memo, useEffect, useState, useCallback } from 'react';
import { useThrottle } from 'react-use';
import Contact from '../../components/Icons/Contact';
import useAppSelector from '../../hooks/useAppSelector';
import {
  pageContactsActions,
  selectLocalSearchRowsNoPagination,
} from '../Contacts/slice';
import buildOfflineFuseQuery from '../../utils/offline/buildOfflineFuseQquery';
import { contactOffline } from '../../config/offline/contact';
import { selectAppContact } from '../../store/contact';
import useAppDispatch from '../../hooks/useAppDispatch';
import AddContactDialog from '../Contacts/AddContactDIalog';
import { formatPhone } from '../Dashboard/utils';
import { useNavigate } from 'react-router-dom';
import { pageOrderDetailActions } from './slice';
import { ToggleButtonStyle } from './OrderDetailComponent';
import { useTranslation } from 'react-i18next';

const InfoWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(2),
  borderRadius: +theme.shape.borderRadius * 2,
}));

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

type CustomerInfoProps = {
  isEdit?: boolean;
};

const CustomerInfo = ({ isEdit }: CustomerInfoProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formik = useFormikContext<CreateOrderInput>();
  const theme = useTheme();
  const [term, setTerm] = useState<string>('');
  const throttledTerm = useThrottle(term, 200);
  const { fuseVersion } = useAppSelector(selectAppContact);
  const [buyerInfo, setBuyerInfo] = useState('');
  const [openAddCustomer, setOpenAddCustomer] = useState(false);
  const [contactDetail, setContactDetail] = useState<Contact | null>(null);
  const contacts = useAppSelector(selectLocalSearchRowsNoPagination);
  const navigate = useNavigate();

  useEffect(() => {
    if (contacts && !contactDetail) {
      const contactDetailInit: Contact | null =
        contacts.find(
          (value) =>
            value.phone_number === formik.values.buyer_info.phone_number
        ) || null;
      dispatch(pageOrderDetailActions.setContactDetail(contactDetailInit));
      setContactDetail(contactDetailInit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactDetail, contacts, formik.values.buyer_info.phone_number]);

  useEffect(() => {
    formik.values.buyer_info &&
      setBuyerInfo(
        `${formik.values.buyer_info.name} - ${formatPhone(
          formik.values.buyer_info.phone_number
        )}`
      );
  }, [formik.values.buyer_info]);

  useEffect(() => {
    const query = buildOfflineFuseQuery(throttledTerm, {}, contactOffline);
    dispatch(pageContactsActions.search(query));
  }, [throttledTerm, fuseVersion, dispatch]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setTerm(e.target.value),
    []
  );

  const setOpenAddCustomerCustom = (value: BuyerInfo) => {
    formik.setFieldValue('buyer_info.phone_number', value.phone_number);
    formik.setFieldValue('buyer_info.name', value.name);
    formik.setFieldValue('buyer_info.address', value.address);
  };

  return (
    <>
      {isEdit ? (
        <>
          <ToggleButtonGroup
            color="primary"
            value={formik.values.delivery_method}
            sx={{ mb: 3 }}
            exclusive
            onChange={(_e, value) =>
              formik.setFieldValue('delivery_method', value)
            }
          >
            <ToggleButtonStyle value="buyer_pick_up">
              {t('delivery_state', { context: 'buyer_pick_up' })}
            </ToggleButtonStyle>
            <ToggleButtonStyle value="seller_delivery">
              {t('delivery_state', { context: 'seller_delivery' })}
            </ToggleButtonStyle>
          </ToggleButtonGroup>
          <FormControl fullWidth variant="outlined" sx={{ mb: 2.5 }}>
            <InputLabel htmlFor="customer-name">
              Tên/ Số điện thoại khách hàng
            </InputLabel>
            <OutlinedInput
              id="customer-name"
              fullWidth
              label="Tên/ Số điện thoại khách hàng"
              onChange={(e) => {
                handleSearchChange(e);
                setBuyerInfo(e.target.value);
              }}
              disabled
              value={buyerInfo}
              sx={{ cursor: 'pointer' }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <Contact />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <TextFieldStyle
            fullWidth
            label="Địa chỉ"
            disabled={formik.values.delivery_method === 'buyer_pick_up'}
            {...getFieldProps(formik, 'buyer_info.address')}
          />
        </>
      ) : (
        <InfoWrapper>
          <Button
            sx={{ mb: 1, fontSize: theme.spacing(2) }}
            onClick={() =>
              contactDetail &&
              navigate(
                `/contacts/${contactDetail.id}?business_id=${contactDetail.business_id}`
              )
            }
          >
            {formik.values.buyer_info.name}
          </Button>

          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="body2" color={theme.palette.grey[600]}>
              Số điện thoại:
            </Typography>
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {formatPhone(formik.values.buyer_info.phone_number || '')}
            </Typography>
          </Box>
          {formik.values.buyer_info.address && (
            <Box display="flex" mb={1}>
              <Typography
                variant="body2"
                color={theme.palette.grey[600]}
                sx={{ minWidth: 56 }}
              >
                Địa chỉ:
              </Typography>
              <Typography variant="body2" sx={{ ml: 0.5 }}>
                {formik.values.buyer_info.address}
              </Typography>
            </Box>
          )}

          {/* <Box
            display="flex"
            alignItems="center"
            sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}
            onClick={() => navigate('/chat')}
          >
            <Chat fill={theme.palette.primary.main} />
            <Typography fontSize={13} fontWeight={700} sx={{ ml: 1 }}>
              Chat
            </Typography>
          </Box> */}
        </InfoWrapper>
      )}
      <AddContactDialog
        open={openAddCustomer}
        setOpen={setOpenAddCustomer}
        saveAnotherContact={setOpenAddCustomerCustom}
      />
    </>
  );
};

export default memo(CustomerInfo);
