const Revenue = () => (
  <svg
    width="106"
    height="100"
    viewBox="0 0 106 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4195_491382)">
      <circle cx="60.5" cy="84.5" r="60.5" fill="#919EAB" fillOpacity="0.12" />
      <g filter="url(#filter0_d_4195_491382)">
        <rect
          x="28"
          y="15.207"
          width="37.6216"
          height="26.6417"
          rx="4.04525"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7539 38.1714H59.8676V37.6973H34.2226V31.1447L39.8856 27.6664C40.0717 27.9399 40.3856 28.1196 40.7415 28.1196C41.1749 28.1196 41.5461 27.853 41.7 27.4748L49.0898 28.6124C49.1297 29.1473 49.5763 29.5688 50.1213 29.5688C50.6926 29.5688 51.1558 29.1057 51.1558 28.5344C51.1558 28.4314 51.1407 28.3318 51.1126 28.2379L57.79 24.3844C57.9797 24.605 58.2607 24.7446 58.5745 24.7446C59.1458 24.7446 59.6089 24.2815 59.6089 23.7102C59.6089 23.1389 59.1458 22.6758 58.5745 22.6758C58.0032 22.6758 57.54 23.1389 57.54 23.7102C57.54 23.7978 57.5509 23.8828 57.5714 23.964L50.8755 27.8264C50.6868 27.6255 50.4187 27.5 50.1213 27.5C49.6876 27.5 49.3162 27.767 49.1625 28.1455L41.7729 27.0065C41.7327 26.472 41.2863 26.0508 40.7415 26.0508C40.1702 26.0508 39.707 26.5139 39.707 27.0852C39.707 27.1285 39.7097 27.1711 39.7148 27.213L34.2226 30.5883V18.8945H33.7539V38.1714Z"
        fill="#229A16"
      />
      <path
        d="M62.2281 48.5352C62.2281 48.5352 54.7447 62.6776 53.344 67.1224C51.9432 71.5672 52.0725 84.6644 52.0725 84.6644H56.1455C56.1455 84.6644 56.1455 71.4486 57.8749 69.2236C59.6043 66.9985 69.2428 55.6414 69.2428 55.6414L62.2281 48.5352Z"
        fill="#212B36"
      />
      <path
        d="M61.3984 50.2545C61.3984 50.2545 65.9348 68.5724 68.2838 72.6723C70.6328 76.7723 77.0548 84.1587 77.0548 84.1587L80.5137 82.3054C80.5137 82.3054 71.6187 70.6358 72.1144 67.2201C72.61 63.8043 73.8707 50.734 73.8707 50.734C73.8707 50.734 67.605 47.3344 61.3984 50.2545Z"
        fill="#212B36"
      />
      <path
        d="M76.7801 23.9284C76.7801 23.9284 80.3951 25.7763 79.0482 30.2211C77.7013 34.6659 73.4397 44.6761 73.9031 47.6392C74.3664 50.6024 73.8707 50.6994 73.8707 50.6994C73.8707 50.6994 64.3616 54.1259 61.3984 50.2361C61.3984 50.2361 67.0554 30.2211 68.2569 27.7213C69.4583 25.2214 72.8902 22.4522 76.7801 23.9284Z"
        fill="url(#paint0_linear_4195_491382)"
      />
      <path
        d="M74.9056 33.8119C74.5543 34.9057 74.0617 35.949 73.4402 36.9152C73.1267 37.4932 72.7509 38.0352 72.3195 38.5315C71.0965 39.846 69.2648 40.3471 67.6269 41.069C67.3338 41.184 67.0624 41.348 66.8242 41.5539C66.5804 41.8306 66.3872 42.1479 66.2531 42.4913C65.3641 44.4363 64.1681 46.4081 62.2178 47.2863C63.0205 44.2208 63.8341 41.1552 64.7499 38.1274C64.9657 36.9911 65.6687 36.0065 66.6733 35.4336C68.7206 33.9304 70.7517 32.4004 72.8206 30.9242C73.5264 30.4177 74.2645 29.8466 74.8625 30.7625C75.3851 31.5653 75.148 32.9714 74.9056 33.8119Z"
        fill="url(#paint1_linear_4195_491382)"
      />
      <path
        d="M70.0556 16.1144C69.8509 16.3245 69.5869 16.5401 69.5869 16.831C69.6215 17.0499 69.7125 17.256 69.8509 17.429C70.4759 18.5065 69.9048 20.0151 70.659 20.9902C70.9401 21.3239 71.3159 21.5643 71.7366 21.6798C72.1643 21.7376 72.5783 21.8708 72.9596 22.0731C73.2485 22.3577 73.4132 22.7451 73.4175 23.1506C73.4606 23.6355 73.3744 24.1204 73.4175 24.6053C73.3955 24.9357 73.5464 25.2538 73.8162 25.4458C73.9501 25.5046 74.0956 25.5323 74.2418 25.5266C75.0817 25.5646 75.923 25.4994 76.747 25.3326C76.8655 25.3172 76.9784 25.2728 77.0757 25.2033C77.1712 25.0918 77.2329 24.9554 77.2535 24.81C77.6091 23.1937 77.9754 21.5775 78.331 19.9612C78.5398 19.38 78.8013 18.8192 79.1122 18.2856C79.3957 17.4792 79.4916 16.6187 79.3924 15.7696C79.3005 15.1339 79.0845 14.5225 78.7566 13.9702C78.4878 13.449 78.0957 13.0014 77.6144 12.6664C77.2595 12.4573 76.8768 12.2994 76.4777 12.1976C75.0984 11.772 72.9434 11.2009 71.7473 12.3862C70.7668 13.3775 71.0901 15.0854 70.0556 16.1144Z"
        fill="#FCBDBA"
      />
      <path
        d="M69.9108 17.9268C70.2071 17.7436 71.1661 18.1262 71.5271 18.1208C72.6639 18.0884 73.5906 18.6595 74.5011 17.9591C75.2446 17.4204 75.5409 16.456 75.7995 15.5724C75.8049 15.5241 75.8049 15.4753 75.7995 15.427C75.789 15.3651 75.7651 15.3062 75.7294 15.2546C75.3092 14.6404 74.4526 14.5757 73.7306 14.3979C72.443 14.0258 71.2856 13.2996 70.3903 12.3021C70.153 12.098 69.9932 11.8183 69.9378 11.5102C69.965 11.149 70.1659 10.8236 70.4765 10.6374C71.3974 10.0555 72.5111 9.86177 73.5744 10.0986C74.6224 10.3407 75.6565 10.6393 76.6723 10.993C77.6528 11.2677 78.6657 11.4347 79.6408 11.7472C79.849 11.807 80.047 11.8978 80.2281 12.0166C80.4663 12.2052 80.6518 12.452 80.7668 12.7332C81.117 13.4506 81.117 14.2893 80.7668 15.0067C80.6965 15.1105 80.6437 15.2252 80.6106 15.3461C80.6051 15.4684 80.6215 15.5907 80.6591 15.7071C80.9015 16.7846 80.3682 17.8622 79.851 18.7942L78.5041 21.3048C78.3674 21.6078 78.165 21.8765 77.9114 22.0914C77.6477 22.3059 77.2737 22.3191 76.9955 22.1238C76.7154 21.8813 76.6992 21.3964 76.3706 21.2348C76.1452 21.1609 75.8986 21.1905 75.6971 21.3156C75.1045 21.6012 74.6196 22.0537 74.0377 22.3931C73.0318 22.9536 71.8465 23.0986 70.7351 22.7972C70.4085 22.7165 70.1034 22.5657 69.8408 22.3554C69.2643 21.8598 69.302 21.1971 69.4206 20.5236C69.5391 19.8502 69.302 18.3039 69.9108 17.9268Z"
        fill="#005249"
      />
      <path
        d="M75.9388 30.9049C75.9388 30.9049 72.049 40.9097 56.6081 42.8223L56.0693 40.6672C56.0693 40.6672 66.3812 36.5888 68.6063 32.4511C69.2457 31.2884 69.7985 30.0801 70.2603 28.8361C70.9607 26.8965 73.2073 25.8513 75.0068 26.8588C76.0735 27.4568 76.7362 28.6421 75.9388 30.9049Z"
        fill="url(#paint2_linear_4195_491382)"
      />
      <path
        d="M53.4569 40.2166C53.4744 40.4899 53.5891 40.7478 53.7801 40.9439C52.7905 41.3132 51.7439 41.5065 50.6877 41.515C50.4952 41.4927 50.3017 41.5468 50.1489 41.6659C49.9442 41.8868 50.1058 42.2477 50.2944 42.4794C51.0968 43.3847 52.2063 43.9605 53.4084 44.0957C54.1916 44.2478 55.0033 44.116 55.6981 43.7239C56.1778 43.3642 56.5823 42.9139 56.8888 42.3986C56.9511 42.3198 56.9985 42.2303 57.0289 42.1346C57.052 41.9453 57.0061 41.754 56.8996 41.5958C56.7757 41.3642 56.6033 40.9062 56.3608 40.7931C56.1184 40.6799 55.7843 40.7931 55.5365 40.75C54.8253 40.5884 54.1896 40.1358 53.4569 40.2166Z"
        fill="#FCBDBA"
      />
      <path
        d="M83.3786 84.4197C83.5365 84.533 83.6302 84.7155 83.6302 84.9099C83.6302 85.1044 83.5365 85.2869 83.3786 85.4002C81.7064 87.2016 79.7036 88.6649 77.4791 89.7103C76.865 89.9958 76.0191 90.2006 75.5989 89.6672C75.1786 89.1338 75.6258 88.3257 75.9814 87.7115C76.4616 86.8871 76.7558 85.9677 76.8434 85.0177C76.844 84.6924 76.908 84.3703 77.032 84.0695C77.4684 83.2667 79.6126 82.4532 80.5016 82.4532C81.6815 82.4424 82.6566 83.6008 83.3786 84.4197Z"
        fill="#7A0C2E"
      />
      <path
        d="M57.4918 87.5639C57.5777 87.7392 57.5718 87.9456 57.476 88.1157C57.3802 88.2858 57.2067 88.3978 57.0123 88.4152C54.6676 89.148 52.2036 89.4223 49.7552 89.2233C49.0763 89.164 48.2412 88.9216 48.1389 88.2481C48.0365 87.5747 48.8285 87.0952 49.4427 86.745C50.2631 86.266 50.9721 85.6177 51.5223 84.8432C51.6818 84.5595 51.8982 84.3119 52.158 84.1159C52.9339 83.631 55.2074 83.9704 55.9832 84.4176C57.0069 85.0048 57.2763 86.4918 57.4918 87.5639Z"
        fill="#7A0C2E"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_4195_491382"
        x="22"
        y="9.20703"
        width="61.6216"
        height="50.6406"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="6" dy="6" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.321569 0 0 0 0 0.286275 0 0 0 0.48 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4195_491382"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4195_491382"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4195_491382"
        x1="63.5466"
        y1="31.1829"
        x2="64.7118"
        y2="52.6898"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#76DAA8" />
        <stop offset="1" stopColor="#26735B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4195_491382"
        x1="38.8335"
        y1="43.5418"
        x2="80.6758"
        y2="74.9095"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.01" />
        <stop offset="0.08" stopOpacity="0.69" />
        <stop offset="0.21" stopOpacity="0.32" />
        <stop offset="1" stopOpacity="0.01" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4195_491382"
        x1="58.4893"
        y1="30.8563"
        x2="58.8287"
        y2="43.1901"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#76DAA8" />
        <stop offset="1" stopColor="#26735B" />
      </linearGradient>
      <clipPath id="clip0_4195_491382">
        <path
          d="M0 0H106V84C106 92.8366 98.8366 100 90 100H0V0Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Revenue;
