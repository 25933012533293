import { Box, Button, Grid, Typography } from '@mui/material';
import MainLayout from '../../layouts/MainLayout';
import LeftArrow from '../../components/Icons/LeftArrow';
import ProductImages from './ProductImages';
import { useNavigate, useParams } from 'react-router-dom';
import Iconify from '../../minimal/Iconify';
import { useFormikContext } from 'formik';
import Product from './Product';
import productService from '../../services/product';
import useAppDispatch from '../../hooks/useAppDispatch';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import Confirmation from '../../components/Confirmation';
import { pageProductEditActions, selectPageProductEdit } from './slice';
import useAppSelector from '../../hooks/useAppSelector';
import { productActions } from '../../store/product';
import { HEADER } from '../../config';
import HeaderBreadcrumbs from '../../minimal/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../config/routeConfig';

const ProductEdit = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { confirming } = useAppSelector(selectPageProductEdit);
  const params = useParams<{ id?: string }>();
  const { editing: product } = useAppSelector(selectPageProductEdit);

  const handleBack = () => {
    navigate(-1);
  };

  const handleDelete = async () => {
    if (params.id) {
      try {
        await productService.deleteProduct(params.id);
        await dispatch(productActions.refresh()).unwrap();
        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Xóa sản phẩm thành công',
            dismissAfter: 3000,
          })
        );
        navigate(-1);
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    }
  };

  const formik = useFormikContext<PendingProduct>();

  return (
    <MainLayout height="unset">
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <HeaderBreadcrumbs
            links={[
              { name: 'Quản lý sản phẩm', href: PATH_DASHBOARD.product.list },
              {
                name: params.id ? 'Chi tiết sản phẩm' : 'Tạo',
                href: PATH_DASHBOARD.product.detail,
              },
            ]}
          >
            <Typography variant="h4" gutterBottom>
              {params.id ? product?.name : 'Tạo sản phẩm'}
            </Typography>
          </HeaderBreadcrumbs>
          <Product />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            position: 'sticky',
            right: 16,
            top: HEADER.DASHBOARD_DESKTOP_HEIGHT - 16,
            height: '87vh',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            height={'100%'}
          >
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ mr: 2, whiteSpace: 'nowrap' }}
                  onClick={() =>
                    formik.values.name
                      ? dispatch(pageProductEditActions.openConfirm('back'))
                      : handleBack()
                  }
                >
                  <LeftArrow />
                  Quay lại
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => formik.handleSubmit()}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Lưu sản phẩm
                </Button>
              </Box>
              <ProductImages />
            </Box>
            {!!params.id && (
              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  dispatch(pageProductEditActions.openConfirm('delete'))
                }
              >
                <Iconify
                  icon="eva:trash-2-fill"
                  width={24}
                  height={24}
                  sx={{ mr: 1 }}
                />
                Xoá sản phẩm
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>

      <Confirmation
        open={confirming === 'back'}
        title={params.id ? 'Xác nhận quay lại' : 'Huỷ sản phẩm đang tạo'}
        description={
          params.id
            ? 'Sản phẩm này sẽ không được cập nhật sau khi bấm xác nhận. Bạn có chắc muốn quay lại?'
            : 'Sản phẩm đang tạo sẽ không được lưu sau khi bấm xác nhận. Bạn có chắc muốn huỷ sản phẩm này?'
        }
        onClose={() => dispatch(pageProductEditActions.cancelConfirm())}
        onConfirm={handleBack}
      />

      <Confirmation
        open={confirming === 'delete'}
        title="Xoá sản phẩm"
        description="Sản phẩm này sẽ được xoá sau khi bấm xác nhận. Bạn có chắc muốn xoá sản phẩm này?"
        onClose={() => dispatch(pageProductEditActions.cancelConfirm())}
        onConfirm={handleDelete}
      />
    </MainLayout>
  );
};

export default ProductEdit;
