import { Box, Typography, Card, Avatar, Tooltip, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useAppSelector from '../../hooks/useAppSelector';
import { selectPageDashboard } from './slice';
import { numberFormat } from '../../utils/constants';
import Scrollbar from '../../minimal/Scrollbar';
import useResponsive from '../../hooks/useResponsive';
import EmptyProduct from '../../components/Icons/EmptyProduct';
import Iconify from '../../minimal/Iconify';
import { PATH_DASHBOARD } from '../../config/routeConfig';
import { useNavigate } from 'react-router-dom';
import { getOptimizeImage } from '../../utils/imageUtils';

const ProductWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: 48,
  height: 48,
  marginRight: theme.spacing(2),
  borderRadius: '12px',
}));

const OverflowText = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const EmptyStateWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: theme.spacing(2, 0),
}));

const TopSales = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const { topProducts } = useAppSelector(selectPageDashboard);

  return (
    <Card>
      <Box mt={3} mb={1} mx={2}>
        <Typography variant="h6" fontWeight={500}>
          Sản phẩm bán chạy 7 ngày gần nhất
        </Typography>
      </Box>
      <Scrollbar
        sx={{
          height: 'calc(463px - 32px - 32px - 36px - 22px)',
          overflowX: 'hidden',
        }}
      >
        {topProducts === null || topProducts.length === 0 ? (
          <EmptyStateWrapper mt={isDesktop ? theme.spacing(3) : 0}>
            <EmptyProduct height={isDesktop ? '150' : '120'} />
            <Typography
              variant={isDesktop ? 'h5' : 'h6'}
              fontWeight={600}
              color={theme.palette.grey[500]}
              sx={{ my: isDesktop ? 3 : 1.5 }}
            >
              Bạn chưa có đơn hàng nào!
            </Typography>
            <Button
              variant="contained"
              startIcon={
                <Iconify
                  icon="eva:plus-fill"
                  color={theme.palette.common.white}
                />
              }
              sx={{ minWidth: 180, textTransform: 'inherit' }}
              onClick={() => navigate(PATH_DASHBOARD.pos)}
            >
              Tạo đơn hàng
            </Button>
          </EmptyStateWrapper>
        ) : (
          <>
            {topProducts.map((sku) => (
              <ProductWrapper key={sku.sku_id}>
                <AvatarStyle
                  alt={'product name'}
                  src={getOptimizeImage(
                    `${sku.product_images?.[0] || '/assets/product-image.png'}`,
                    {
                      w: 128,
                      h: 128,
                      fit: 'crop',
                    }
                  )}
                />
                <Box width={isDesktop ? '80%' : '78%'}>
                  <Box display="flex" justifyContent="space-between">
                    <Tooltip title={sku.product_name} placement="top-start">
                      <OverflowText
                        noWrap
                        variant="body2"
                        fontWeight={600}
                        sx={{ width: isDesktop ? '56%' : '46%' }}
                      >
                        {sku.product_name}
                      </OverflowText>
                    </Tooltip>
                    <Box overflow="hidden" textAlign="right">
                      <Tooltip
                        title={numberFormat.format(sku.total_amount)}
                        placement="top-start"
                      >
                        <OverflowText
                          variant="body2"
                          fontWeight={600}
                          color={theme.palette.primary.main}
                          noWrap
                        >
                          {numberFormat.format(sku.total_amount)}
                        </OverflowText>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {sku.sku_name ? (
                      <Tooltip title={sku.sku_name} placement="top-start">
                        <OverflowText
                          variant="body2"
                          color={theme.palette.grey[600]}
                          noWrap
                          sx={{ width: isDesktop ? '68%' : '46%', mr: 2 }}
                        >
                          {sku.sku_name}
                        </OverflowText>
                      </Tooltip>
                    ) : (
                      <Box />
                    )}
                    <OverflowText
                      variant="body2"
                      color={theme.palette.grey[500]}
                    >
                      SL: {numberFormat.format(sku.total_quantity)}
                    </OverflowText>
                  </Box>
                </Box>
              </ProductWrapper>
            ))}
          </>
        )}
      </Scrollbar>
    </Card>
  );
};

export default TopSales;
