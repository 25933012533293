import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getFieldProps } from '../../utils/formik';
import { BoxStyle, DialogTitleStyle } from './ContactsComponent';
import useAddContactForm from './useAddContactForm';

type AddContactDialogProps = {
  open: boolean;
  defaultValue?: string;
  setOpen: (value: boolean) => void;
  saveAnotherContact?: (value: Contact) => void;
  onSuccess?: (contact: Contact) => void;
};

const AddContactDialog = ({
  open,
  defaultValue,
  setOpen,
  saveAnotherContact,
  onSuccess,
}: AddContactDialogProps) => {
  const theme = useTheme();
  const formik = useAddContactForm({
    defaultValue,
    setOpen,
    onSuccess,
    saveAnotherContact,
  });
  const { name } = formik.values;

  return (
    <Dialog
      open={open}
      onClose={() => {
        formik.resetForm();
        setOpen(false);
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitleStyle>Thêm vào danh bạ</DialogTitleStyle>
      <Box
        component={'form'}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <DialogContent
          sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
        >
          <BoxStyle>
            <TextField
              fullWidth
              placeholder="Nhập tên liên hệ"
              sx={{ mt: 1 }}
              label="Tên liên hệ"
              variant="outlined"
              {...getFieldProps(formik, 'name')}
              autoFocus
            />
          </BoxStyle>
          <BoxStyle>
            <TextField
              fullWidth
              placeholder="Nhập số điện thoại"
              label="Số điện thoại"
              variant="outlined"
              {...getFieldProps(formik, 'phone_number')}
            />
          </BoxStyle>
          <BoxStyle>
            <TextField
              fullWidth
              placeholder="Nhập địa chỉ"
              label="Địa chỉ"
              variant="outlined"
              {...getFieldProps(formik, 'address')}
            />
          </BoxStyle>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              formik.resetForm();
              setOpen(false);
            }}
          >
            Hủy
          </Button>
          <Button
            variant="contained"
            disabled={formik.isSubmitting || !name}
            type="submit"
          >
            Lưu
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddContactDialog;
