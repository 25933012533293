import localForage from 'localforage';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import loginSlice from './login';
import authSlice from './auth';
import contactSlice from './contact';
import pendingOrderSlice from './pos';
import toastSlice from './toast';
import posSlice from './pos';
import notificationSlice from './notification';
import appSlice from './app';
import categorySlice from './category';
import productSlice from './product';
import promotionSlice from './promotion';
import dashboardSlice from '../screens/Dashboard/slice';
import pageProductsSlice from '../screens/Products/slice';
import pageOrdersSlice from '../screens/Orders/slice';
import pageCashbooksSlice from '../screens/Cashbook/slice';
import pageContactsSlice from '../screens/Contacts/slice';
import pageProductEditSlice from '../screens/ProductEdit/slice';
import pagePosSlice from '../screens/POS/slice';
import pageOrderDetailSlice from '../screens/OrderDetail/slice';
import pageContactEditSlice from '../screens/ContactDetail/slice';

export const persistentStorage = localForage;

function persisAppReducer<State, TReducer>(
  slice: {
    name: string;
    reducer: TReducer;
  },
  whitelist: Array<keyof State>
) {
  return persistReducer(
    {
      key: slice.name,
      storage: persistentStorage,
      whitelist: whitelist as Array<string>,
      timeout: undefined,
    },
    slice.reducer as ExpectedAny
  ) as ExpectedAny as TReducer;
}

const rootReducer = combineReducers({
  [authSlice.name]: persisAppReducer(authSlice, [
    'user_info',
    'business',
    'permissions',
  ]),
  [contactSlice.name]: persisAppReducer(contactSlice, ['ids', 'byId']),
  [pendingOrderSlice.name]: persisAppReducer(pendingOrderSlice, ['orders']),
  [loginSlice.name]: persisAppReducer(loginSlice, [
    'device_id',
    'challenge',
    'cooldown',
  ]),
  [toastSlice.name]: toastSlice.reducer,
  [posSlice.name]: persisAppReducer(posSlice, ['pendingOrders']),
  [notificationSlice.name]: notificationSlice.reducer,
  [appSlice.name]: persisAppReducer(appSlice, ['sidebar']),
  [categorySlice.name]: persisAppReducer(categorySlice, [
    'ids',
    'byId',
    'categoryDrafts',
  ]),
  [productSlice.name]: persisAppReducer(productSlice, [
    'ids',
    'byId',
    'productDrafts',
  ]),
  [promotionSlice.name]: persisAppReducer(promotionSlice, [
    'ids',
    'byId',
    'promotionDrafts',
  ]),
  [dashboardSlice.name]: dashboardSlice.reducer,
  // pages
  [pageProductsSlice.name]: pageProductsSlice.reducer,
  [pageOrdersSlice.name]: pageOrdersSlice.reducer,
  [pageOrderDetailSlice.name]: pageOrderDetailSlice.reducer,
  [pageCashbooksSlice.name]: pageCashbooksSlice.reducer,
  [pageContactsSlice.name]: pageContactsSlice.reducer,
  [pageProductEditSlice.name]: pageProductEditSlice.reducer,
  [pagePosSlice.name]: pagePosSlice.reducer,
  [pageContactEditSlice.name]: pageContactEditSlice.reducer,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
