export function isLocalImage(
  image: string | PendingUploadImage
): image is PendingUploadImage {
  return typeof image === 'object' && image.type === 'local';
}

export function getProductStock(sku: SkuMixed) {
  if (sku.sku_type === 'non_stock') {
    if (sku.is_active) {
      return true;
    }
    return false;
  }
  return sku.can_pick_quantity;
}

export function isAdvanceStock(sku: Pick<SkuMixed, 'sku_type'>) {
  return sku.sku_type === 'stock';
}

export function getFinalPrice({
  normal_price,
  selling_price,
}: Pick<Sku, 'normal_price' | 'selling_price'>): number {
  return selling_price || normal_price;
}

export function getCanPickQuantity({
  quantity = 0,
  blocked_quantity = 0,
  delivering_quantity = 0,
}: InventoryDetail): number {
  return quantity - blocked_quantity - delivering_quantity;
}
