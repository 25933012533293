import { NavLink as RouterLink } from 'react-router-dom';
import { Box, Link, ListItemText } from '@mui/material';
import Iconify from '../../../minimal/Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { isExternalLink } from '../../../minimal/nav-section';
import { useState } from 'react';
import Label from '../../../minimal/Label';
import useAppSelector from '../../../hooks/useAppSelector';
import { selectAppAuth } from '../../../store/auth';
import ProUserDialog from '../../../components/ProUserDialog';

// ----------------------------------------------------------------------

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
}: NavItemProps) {
  const { isProUser } = useAppSelector(selectAppAuth);
  const { title, path, icon, isPro = false, children } = item;
  const [openCommingSoon, setOpenCommingSoon] = useState(false);

  const renderContent = (
    <>
      {icon && (
        <ListItemIconStyle active={active} isCollapse={isCollapse}>
          {icon}
        </ListItemIconStyle>
      )}
      {!isCollapse && (
        <>
          {' '}
          <ListItemTextStyle
            disableTypography
            primary={title}
            isCollapse={isCollapse}
          />
          {title === 'Bán hàng' && (
            <Label
              color="error"
              sx={{
                backgroundColor: (theme) => theme.palette.error.main,
                color: (theme) => theme.palette.common.white,
                p: 1.5,
                cursor: 'pointer',
                fontSize: '12px',
              }}
            >
              Phổ biến
            </Label>
          )}
        </>
      )}

      {!isCollapse && <>{children && <ArrowIcon open={open} />}</>}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <>
      <ListItemStyle
        onClick={() => (!isProUser && isPro ? setOpenCommingSoon(true) : {})}
        component={RouterLink}
        to={isProUser || !isPro ? path : ''}
        activeRoot={active}
        isCollapse={isCollapse}
      >
        {renderContent}
      </ListItemStyle>
      <ProUserDialog
        open={openCommingSoon}
        onClose={() => setOpenCommingSoon(false)}
      />
    </>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = NavItemProps;

export function NavItemSub({ item, active = false }: NavItemSubProps) {
  const { title, path } = item;

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText
        disableTypography
        primary={title}
        sx={{ whiteSpace: 'nowrap' }}
      />
    </>
  );

  return isExternalLink(path) ? (
    <ListItemStyle
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={'eva:arrow-ios-forward-fill'}
      sx={{
        width: 16,
        height: 16,
        transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
        transition: 'transform ease-in-out 250ms',
      }}
    />
  );
}
