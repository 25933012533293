import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import type { RootState } from './rootReducer';
import authService from '../services/auth';
import { userService } from '../services/user';
import { PURGE } from 'redux-persist';
import {
  ACCESS_TOKEN_KEY,
  BUSINESS_KEY,
  FIRST_TIME_REDIRECT,
  REFRESH_TOKEN_BEFORE,
  REFRESH_TOKEN_KEY,
} from '../utils/constants';
import logger from '../utils/logger';
import { shouldRefresh } from '../utils/authUtils';

type AuthState = {
  state:
    | 'loading'
    | 'ready'
    | 'switch_success'
    | 'changed_business'
    | 'refresh_account';
  user_info: UserInfo | null;
  isProUser: boolean;
  business: Business | null;
  listBusiness: Array<ShortenBusiness>;
  permissions: Array<string>;
};

const initialState: AuthState = {
  state: 'loading',
  business: null,
  listBusiness: [],
  user_info: null,
  isProUser: false,
  permissions: [],
};

// TODO: refresh access token REFRESH_TOKEN_BEFORE before expire
let timeId: ReturnType<typeof setTimeout> | null = null;

const initialize = createAsyncThunk(
  'auth/initialize',
  async (_: void, { dispatch }: ExpectedAny) => {
    let token = await authService.getAccessToken();

    if (!token || shouldRefresh(token)) {
      token = await authService.renewToken();
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }

    const jwt = jwtDecode<JWT>(token);

    if (timeId) {
      clearTimeout(timeId);
    }

    const sleepMs =
      jwt.exp * 1000 - new Date().valueOf() - REFRESH_TOKEN_BEFORE + 1000;

    logger.debug('Refresh token after:', sleepMs, 'ms');
    timeId = setTimeout(
      () => {
        dispatch(authActions.initialize());
      },
      // https://nodejs.org/api/timers.html#settimeoutcallback-delay-args
      Math.min(sleepMs, 2147483647)
    );

    return await userService.getUserProfile();
  }
);

const logout = createAsyncThunk<void, void>(
  'auth/logout',
  async (_: unknown) => {
    const { getPersistor } = await import('./index');
    localStorage.clear();
    getPersistor().purge();
  }
);

const clearData = createAsyncThunk<void, void>(
  'auth/clear-data',
  async (_: unknown) => {
    const { getPersistor } = await import('./index');
    getPersistor().purge();
  }
);

const switchBusiness = createAsyncThunk<SwitchBusiness, string>(
  'auth/switch-business',
  async (id) => {
    const business = await authService.switchBusiness(id);

    if (!business) {
      throw new Error('NO_BUSINESS');
    }
    return business;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStateAuth: (
      state,
      action: PayloadAction<
        | 'loading'
        | 'ready'
        | 'switch_success'
        | 'changed_business'
        | 'refresh_account'
      >
    ) => {
      state.state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(initialize.fulfilled, (state, action) => {
        state.state = 'ready';
        if (action.payload) {
          const { business_info } = action.payload;
          // switch to owner bussiness (remove after setup role)
          const existedBusiness = business_info.list_business.find(
            (value) => value.business_id === business_info.current_business.id
          );

          if (existedBusiness && !existedBusiness.is_owner) {
            const ownerBusiness =
              business_info.list_business.find(
                (business) =>
                  business.business_id === localStorage.getItem(BUSINESS_KEY) &&
                  business.is_owner
              ) ||
              business_info.list_business.find((business) => business.is_owner);
            Object.assign(ownerBusiness, {
              id: ownerBusiness?.business_id || '',
            });
            state.business = ownerBusiness;
          } else {
            state.business = business_info.current_business || null;
          }
          state.user_info = action.payload.user_info || null;
          state.isProUser = (action.payload.user_info.user_roles & 2) !== 0;
          if (localStorage.getItem(FIRST_TIME_REDIRECT) === null) {
            localStorage.setItem(FIRST_TIME_REDIRECT, 'true');
          }
          state.listBusiness = business_info.list_business.filter(
            (business) => business.is_owner
          );
          state.permissions = action.payload.permissions || [];
          if (
            business_info.current_business.id !==
            localStorage.getItem(BUSINESS_KEY)
          ) {
            state.state = 'changed_business';
          }
        }
      })
      .addCase(switchBusiness.fulfilled, (state, action) => {
        localStorage.setItem(REFRESH_TOKEN_KEY, action.payload.refresh_token);
        localStorage.setItem(ACCESS_TOKEN_KEY, action.payload.token);
        localStorage.setItem(
          BUSINESS_KEY,
          action.payload.current_business?.id || ''
        );
        state.business = action.payload.current_business;
        state.state = 'switch_success';
      })
      .addMatcher(isAnyOf(logout.fulfilled, initialize.rejected), (state) => {
        state.state = 'refresh_account';
        state.user_info = null;
        state.isProUser = false;
        state.permissions = [];
      });
  },
});

export const authActions = {
  initialize,
  logout,
  clearData,
  switchBusiness,
  ...authSlice.actions,
};
export const selectAppAuth = (state: RootState) => state.auth;
export default authSlice;
