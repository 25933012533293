import qs from 'qs';
import { API_URI } from '../config/env';
import { fetchAll, fetchData, post, put } from '../utils/fetch';
import authService from './auth';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getNewContacts(lastUpdate: Date) {
  return fetchData<Array<JSONRecord<Contact>>>(
    `${API_URI}/finan-business/api/v1/contact/get-list?${qs.stringify({
      business_id: await authService.getBusinessId(),
      page: 0,
      page_size: 10000,
      // update_after: lastUpdate.toISOString(),
      has_record_deleted: true,
      state: 'delivering',
    })}`,
    {
      authorization: true,
    }
  );
}

async function createContact(contact: PendingContact) {
  return await post<JSONRecord<Contact>>(
    `${API_URI}/finan-business/api/v2/contact/create`,
    { ...contact },
    { authorization: true }
  );
}

async function getContact({
  id,
  businessId,
}: {
  id: string;
  businessId: string;
}) {
  return await fetchData<JSONRecord<Contact>>(
    `${API_URI}/finan-business/api/v2/contact/get-detail/${id}?${qs.stringify({
      business_id: businessId,
    })}`,
    { authorization: true }
  );
}

async function getNotes({
  contact_id,
  page,
  page_size,
}: {
  contact_id: string;
  page: number;
  page_size: number;
}) {
  return await fetchData<JSONRecord<Note[]>>(
    `${API_URI}/finan-business/api/v1/contact-note/get-list?${qs.stringify({
      contact_id: contact_id,
      page: page,
      page_size: page_size,
      sort: 'updated_at desc',
    })}`,
    { authorization: true }
  );
}

async function getOneNote({ id }: { id: string }) {
  const res = await fetchData<JSONRecord<Note[]>>(
    `${API_URI}/finan-business/api/v1/contact-note/get-one/?${id}`,
    { authorization: true }
  );
  return res;
}

async function createNote({ body }: { body: PendingNote }) {
  return await post(
    `${API_URI}/finan-business/api/v1/contact-note/create`,
    { ...body },
    {
      authorization: true,
    }
  );
}

async function updateNote({ id, body }: { id: string; body: PendingNote }) {
  const res = await put(
    `${API_URI}/finan-business/api/v1/contact-note/update/${id}`,
    body,
    {
      authorization: true,
    }
  );
  return res;
}

async function getContactTransaction({
  business_id,
  contact_id,
  page,
  page_size,
}: {
  business_id: string;
  contact_id: string;
  page: number;
  page_size: number;
}) {
  return await fetchData<JSONRecord<ContactTransactions[]>>(
    `${API_URI}/finan-transaction/api/v1/contact-transaction/get-list?${qs.stringify(
      {
        business_id: business_id,
        contact_id: contact_id,
        page: page,
        page_size: page_size,
      }
    )}`,
    { authorization: true }
  );
}

async function getContactOrders({
  business_id,
  contact_id,
  page,
  page_size,
}: {
  business_id: string;
  contact_id: string;
  page: number;
  page_size: number;
}) {
  return await fetchData<JSONRecord<orderContact[]>>(
    `${API_URI}/finan-order/api/v2/get-list-order?${qs.stringify({
      business_id: business_id,
      contact_id: contact_id,
      page: page,
      page_size: page_size,
    })}`,
    { authorization: true }
  );
}

async function updateContact({
  id,
  contact,
}: {
  id: string;
  contact: updateContact;
}) {
  const res = await put(
    `${API_URI}/finan-business/api/v1/contact/update/${id}`,
    contact,
    {
      authorization: true,
    }
  );
  return res;
}

async function transactionContact({ body }: { body: PendingTransaction }) {
  return await post(
    `${API_URI}/finan-transaction/api/v1/contact-transaction/create`,
    { ...body },
    {
      authorization: true,
    }
  );
}

async function getTransactionContacts({
  transaction_type,
  page_size = 10,
  filter = 'today',
}: {
  transaction_type: string;
  page_size?: number;
  filter?: string;
}) {
  const business_id = await authService.getBusinessId();
  const { data, meta } = await fetchAll<{
    data: Array<DebtReminderContact>;
    meta: ResponseMeta;
  }>(
    `${API_URI}/finan-business/api/v1/business-has-contact/get-list-reminder?${qs.stringify(
      {
        business_id,
        page: 1,
        page_size,
        transaction_type,
        filter,
      }
    )}`,
    { authorization: true }
  );

  return { data, meta };
}

const contactService = {
  getNewContacts,
  createContact,
  getContact,
  getNotes,
  createNote,
  getOneNote,
  updateNote,
  getContactTransaction,
  getContactOrders,
  updateContact,
  transactionContact,
  getTransactionContacts,
};
export default contactService;
