import productService from '../../services/product';
import noAccents from '../../utils/encoding';
import configOffline from '../../utils/offline/configOffline';

export const productOffline = configOffline({
  name: 'cache_product',
  fetchChange: (lastUpdate) => productService.getProducts(lastUpdate),
  makeSearchData: (item: Product) => ({
    id: item.id,
    name: noAccents(item.name),
    product_code: item.product_code,
    sku_code: item.list_sku?.map((s) => s.sku_code),
    category: (item.category || []).map((c) => c.id),
    barcode: item.list_sku?.map((s) => s.barcode),
  }),
  searchFields: ['name', 'product_code', 'sku_code', 'category', 'barcode'],
});
