import { AppBar, Box, IconButton, Stack, styled, Toolbar } from '@mui/material';
import { memo, useState } from 'react';
import { HEADER, NAVBAR } from '../../config/styles';
import useAppSelector from '../../hooks/useAppSelector';
import useResponsive from '../../hooks/useResponsive';
import Avatar from '../../minimal/Avatar';
import { selectApp } from '../../store/app';
import { selectAppAuth } from '../../store/auth';
import AccountPopover from '../AccountPopover';
import CollapseButton from '../NavbarVertical/CollapseButton';
// import OfflineButton from '../OfflineButon';
// import NotificationsPopover from './NotificationsPopover';

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  backgroundColor: theme.palette.common.white,
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}));

type HeaderProps = {
  children?: React.ReactNode;
};

const Header = ({ children }: HeaderProps) => {
  const { sidebar } = useAppSelector(selectApp);
  const { user_info, business } = useAppSelector(selectAppAuth);
  const [openAccount, setOpenAccount] = useState<HTMLElement | null>(null);
  const isDesktop = useResponsive('up', 'lg');
  return (
    <RootStyle
      isCollapse={!sidebar.open}
      verticalLayout={false}
      isOffset={true}
    >
      <Toolbar style={{ paddingLeft: 0 }}>
        {children}
        {!isDesktop && (
          <Box ml={3}>
            <CollapseButton />
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          alignContent="space-around"
          spacing={2}
        >
          {/* <OfflineButton /> */}
          {/* <NotificationsPopover /> */}
          <IconButton onClick={(e) => setOpenAccount(e.currentTarget)}>
            <Avatar src={user_info?.avatar || business?.avatar} />
          </IconButton>
        </Stack>
      </Toolbar>
      <AccountPopover open={openAccount} setOpen={setOpenAccount} />
    </RootStyle>
  );
};

export default memo(Header);
