import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAppDispatch from '../../hooks/useAppDispatch';
import contactService from '../../services/contact';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import { initialTransaction } from './settings';
import moment from 'moment';
import { contactActions } from '../../store/contact';
import { pageContactEditActions, selectPageContactEdit } from './slice';
import authService from '../../services/auth';
import useAppSelector from '../../hooks/useAppSelector';

const validationSchema = Yup.object().shape({
  amount: Yup.string().required('Số tiền không được để trống'),
  start_time: Yup.string()
    .required('Ngày tháng không được để trống')
    .nullable(),
});

type useTransactionFormProps = {
  id: string;
  isPayTransaction?: boolean;
  setOpen?: (value: boolean) => void;
  onSuccess?: () => void;
};

const useTransactionForm = ({
  id,
  isPayTransaction,
  setOpen,
  onSuccess,
}: useTransactionFormProps) => {
  const dispatch = useAppDispatch();
  const { editing: contact } = useAppSelector(selectPageContactEdit);

  const checkPayment = contact
    ? contact?.total_amount_in - contact?.total_amount_out
    : 0;
  return useFormik({
    initialValues: initialTransaction(),
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const business_id = await authService.getBusinessId();
        if (business_id) {
          const nextContact: PendingTransaction = {
            amount: +values.amount,
            business_id,
            contact_id: id || values.contact_id,
            currency: 'VND',
            start_time: moment(values.start_time).toISOString(),
            description: values.description,
            transaction_type: values.transaction_type
              ? values.transaction_type
              : checkPayment > 0
              ? 'out'
              : checkPayment < 0
              ? 'in'
              : 'in',
            images: [],
            is_pay_transaction: isPayTransaction || false,
          };
          if (!nextContact.contact_id) {
            dispatch(
              toastActions.create({
                severity: 'error',
                message: 'Chưa nhập khách hàng',
                dismissAfter: 3000,
              })
            );
            return;
          }

          await contactService.transactionContact({ body: nextContact });
          resetForm();
          setOpen && setOpen(false);
          dispatch(pageContactEditActions.setOpenNewDept(''));
          dispatch(
            pageContactEditActions.openContact({
              id: id || values.contact_id,
              business_id,
            })
          );
          dispatch(
            pageContactEditActions.getContactTransaction({
              contact_id: id || values.contact_id,
              business_id,
              page: 1,
              page_size: 100,
            })
          );
          dispatch(
            toastActions.create({
              severity: 'success',
              message: 'Cập nhật thành công',
              dismissAfter: 3000,
            })
          );
          await dispatch(contactActions.refresh()).unwrap();
          onSuccess?.();
        }
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    },
  });
};

export default useTransactionForm;
