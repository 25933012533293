import localforage from 'localforage';
import { API_URI } from '../config/env';
import { post } from '../utils/fetch';

type UploadInput = {
  name: string;
  media_type: string;
};
async function createUploadLink({ name, media_type }: UploadInput) {
  return await post<string>(
    `${API_URI}/ms-media-management/api/media/pre_up`,
    { name, media_type },
    {
      authorization: true,
    }
  );
}

async function upload(
  uploadUrl: string,
  { mime_type, content }: { mime_type: string; content: ArrayBuffer }
) {
  return await fetch(uploadUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': mime_type,
      'x-amz-acl': 'public-read',
    },
    body: content,
  });
}

async function commitUploadLink({ name, media_type }: UploadInput) {
  return await post<{ upload_url: string }>(
    `${API_URI}/ms-media-management/api/media/pos_up`,
    { name, media_type },
    {
      authorization: true,
    }
  );
}

async function saveLocal(name: string, content: ArrayBuffer) {
  await await localforage.setItem(`media.${name}`, content);
}

async function readLocal(name: string): Promise<ArrayBuffer | null> {
  return await localforage.getItem(`media.${name}`);
}

async function getLocalThumbnail(name: string): Promise<string> {
  const buffer = await readLocal(name);

  if (buffer) {
    return URL.createObjectURL(new Blob([buffer]));
  }

  return '';
}

const mediaService = {
  createUploadLink,
  upload,
  commitUploadLink,
  saveLocal,
  readLocal,
  getLocalThumbnail,
};

export default mediaService;
