import { OfflineConfig } from './configOffline';
import { OfflineState } from './configOfflineCacheSlice';
import { getSearchWorker } from './utils';

async function searchOfflineIds<
  Name extends string,
  Data extends ISyncRecord,
  SearchData
>(
  store: { [name in Name]: OfflineState<Data> },
  query: FuseQuery,
  offlineConfig: OfflineConfig<Name, Data, SearchData>
) {
  try {
    if (!query) {
      return store[offlineConfig.name].ids;
    }
    const fuse = await getSearchWorker(offlineConfig.name, {
      keys: offlineConfig.searchFields,
    });
    const rs = await fuse.search(query);
    return rs;
  } catch (ex) {
    throw new Error(`Failed to search ${offlineConfig.name}: ${JSON.stringify(query)}.`, {
      ...(ex instanceof Error ? { cause: ex } : {}),
    });
  }
}

export default searchOfflineIds;
