import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAppDispatch from '../../hooks/useAppDispatch';
import contactService from '../../services/contact';
import { toastActions } from '../../store/toast';
import { getErrorMessage } from '../../utils/errorUtils';
import moment from 'moment';
import { contactActions } from '../../store/contact';
import { pageContactEditActions, selectPageContactEdit } from './slice';
import useAppSelector from '../../hooks/useAppSelector';

const validationSchema = Yup.object().shape({
  note: Yup.string().required('Ghi chú được để trống'),
});

type useNoteFormProps = {
  setOpen?: (value: boolean) => void;
  id: string;
};
//
const useNoteForm = ({ setOpen, id }: useNoteFormProps) => {
  const dispatch = useAppDispatch();
  const { noteUpdate, createNote } = useAppSelector(selectPageContactEdit);
  return useFormik({
    initialValues: {
      note: !createNote && noteUpdate?.note !== '' ? noteUpdate.note : '',
      day: !createNote && noteUpdate?.day !== '' ? noteUpdate.day : '',
      contact_id: id || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const nextNote: PendingNote = {
          note: values.note,
          day: values.day
            ? moment(values.day).toISOString()
            : moment(new Date().toString()).toISOString(),
          contact_id: id,
        };
        createNote
          ? await contactService.createNote({ body: nextNote })
          : await contactService.updateNote({
              id: (noteUpdate && noteUpdate.id) || '',
              body: nextNote,
            });
        dispatch(
          pageContactEditActions.fetchNotes({
            contact_id: id,
            page: 1,
            page_size: 100,
          })
        );
        setOpen && setOpen(false);
        await dispatch(contactActions.refresh()).unwrap();
        resetForm();
        dispatch(
          toastActions.create({
            severity: 'success',
            message: 'Cập nhật thành công',
            dismissAfter: 3000,
          })
        );
      } catch (ex) {
        dispatch(
          toastActions.create({
            severity: 'error',
            message: getErrorMessage(ex),
            dismissAfter: 3000,
          })
        );
      }
    },
  });
};

export default useNoteForm;
