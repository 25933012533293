const LogoText = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 20.7734L13.41 19.3634L7.83 13.7734L20 13.7734L20 11.7734L7.83 11.7734L13.42 6.19344L12 4.77344L4 12.7734L12 20.7734Z"
      fill="#00AB55"
    />
  </svg>
);

export default LogoText;
