import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
} from '@mui/material';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { TableHeader } from '../../components/TableHeader';
import TableSelectedActions from '../../components/TableSelectedActions';
import useAppSelector from '../../hooks/useAppSelector';
import usePrinter from '../../hooks/usePrinter';
import Iconify from '../../minimal/Iconify';
import { ORDER_STATUS } from '../../utils/constants';
import OrderPaymentDialog from './OrderPaymentDialog';
import OrderRow from './OrderRow';
import { TABLE_HEAD, TABLE_HEAD_HIDE_ACTION } from './settings';
import {
  selectLocalSearchResult,
  selectedLocalSelectedStatus,
  selectPageOrders,
  pageOrdersActions,
} from './slice';

const OrdersHeader = () => {
  const dispatch = useDispatch();
  const selectedStatus = useAppSelector(selectedLocalSelectedStatus);
  const { rows: orders } = useAppSelector(selectLocalSearchResult);

  const {
    main: { configInvoice, paymentsInfo, arraySelected },
  } = useAppSelector(selectPageOrders);

  const stringIds = arraySelected.toString();
  const ordersPrint = orders.filter((value) => stringIds.includes(value.id));

  const { handlePrint, showDataPrint } = usePrinter({
    ordersPrint,
    configs: configInvoice,
    paymentsInfo,
  });
  const checkBox = {
    indeterminate:
      arraySelected.length > 0 &&
      arraySelected.length !== orders.length &&
      [...arraySelected].some(Boolean),
    checked:
      arraySelected.length > 0 &&
      [...arraySelected].every(Boolean) &&
      arraySelected.length === orders.length,
    onChange: () =>
      dispatch(
        pageOrdersActions.setArraySelected([
          null,
          null,
          orders,
          arraySelected.length === orders.length,
        ])
      ),
  };

  return (
    <>
      {arraySelected.filter((value) => value).length > 0 && (
        <TableSelectedActions
          numSelected={arraySelected.filter((value) => value).length}
          rowCount={orders.length}
          onSelectAllRows={() =>
            dispatch(
              pageOrdersActions.setArraySelected([
                null,
                null,
                orders,
                arraySelected.length === orders.length,
              ])
            )
          }
          actions={
            <Stack spacing={1} direction="row">
              <Tooltip title="In đơn hàng" onClick={handlePrint}>
                <IconButton color="primary">
                  {showDataPrint()}

                  <Iconify icon={'eva:printer-fill'} />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        />
      )}
      <TableHeader
        headLabel={
          selectedStatus?.id == ORDER_STATUS.CANCEL
            ? TABLE_HEAD_HIDE_ACTION
            : TABLE_HEAD
        }
        checkBox={checkBox}
      />
    </>
  );
};

const OrdersTable = memo(() => {
  const { rows: orders } = useAppSelector(selectLocalSearchResult);
  const {
    main: { orderPaymentDialog },
  } = useAppSelector(selectPageOrders);

  return (
    <>
      <TableContainer
        sx={{
          mb: 1,
          position: 'relative',
        }}
      >
        <Table stickyHeader>
          <OrdersHeader />

          <TableBody>
            {orders.map((order, index) => (
              <OrderRow key={order.id} order={order} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {orderPaymentDialog && <OrderPaymentDialog />}
    </>
  );
});

OrdersTable.displayName = 'OrdersTable';

export default OrdersTable;
