import {
  TableRow,
  TableCell,
  TableHead,
  styled,
  Checkbox,
} from '@mui/material';
import useResponsive from '../../hooks/useResponsive';

type TableHeaderProps = {
  headLabel: Array<HeadCellConfig>;
  padding?: string;
  checkBox?: {
    indeterminate: boolean;
    checked: boolean;
    onChange: (event: React.MouseEvent<HTMLButtonElement>) => void;
  };
};

type TableCellStyleProps = {
  mypadding: string | undefined;
  isDesktop: boolean;
};

const TableCellStyle = styled(TableCell)<TableCellStyleProps>(
  ({ mypadding, isDesktop, theme }) => ({
    backgroundColor: '#E6E9EC',
    padding: mypadding || theme.spacing(2),
    '&.MuiTableCell-root:first-of-type': {
      boxShadow: 'none',
    },
    '&.MuiTableCell-root:last-of-type': {
      paddingRight: isDesktop ? theme.spacing(2) : mypadding,
    },
  })
);

export default function TableHeader({
  headLabel,
  padding,
  checkBox,
}: TableHeaderProps) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <TableHead>
      <TableRow>
        {checkBox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={checkBox.indeterminate}
              checked={checkBox.checked}
              onChange={checkBox.onChange}
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCellStyle
            key={headCell.id}
            align={headCell.align}
            mypadding={headCell.padding || padding}
            isDesktop={isDesktop || false}
          >
            {headCell.label}
          </TableCellStyle>
        ))}
      </TableRow>
    </TableHead>
  );
}
