import * as Yup from 'yup';


export const toPendingContact = (
  contact: Omit<Contact, 'updated_at' | 'deleted_at'>
) => ({
  ...contact,

});

export const productSchema = Yup.object().shape({
  
});
