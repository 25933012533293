import { DatePicker } from '@mui/lab';
import {
  TextField,
  Dialog,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { BoxStyle, DialogContentStyle } from './ContactDetailComponent';
import { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useNoteForm from './useNoteForm';
import { getFieldProps } from '../../utils/formik';

type NoteDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: () => void;
};

const NoteDialog = ({ open, setOpen, onSubmit }: NoteDialogProps) => {
  const theme = useTheme();
  const { id } = useParams<{ id?: string }>();
  const formik = useNoteForm({ id: id || '' });
  const { errors, touched, setFieldValue } = formik;
  const { day } = formik && formik.values;
  return (
    <Dialog
      open={open}
      onClose={() => {
        formik.resetForm();
        setOpen(false);
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogContentStyle>
        <BoxStyle>
          <DatePicker
            value={day !== '' ? day : new Date().toString()}
            onChange={(newValue) => setFieldValue('day', newValue)}
            inputFormat="dd/MM/yyyy"
            label="Chọn thời gian ghi chú"
            renderInput={(props) => <TextField {...props} fullWidth />}
          />

          {touched.day && errors.day && (
            <Typography
              variant="caption"
              color={theme.palette.error.main}
              sx={{ ml: '14px' }}
            >
              {errors.day}
            </Typography>
          )}
        </BoxStyle>
        <BoxStyle>
          <TextField
            fullWidth
            placeholder="Nhập ghi chú"
            multiline
            rows={4}
            autoFocus
            {...getFieldProps(formik, 'note')}
          />
        </BoxStyle>
      </DialogContentStyle>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            formik.resetForm();
            setOpen(false);
          }}
        >
          Huỷ
        </Button>
        <Button
          variant="contained"
          disabled={formik.isSubmitting || !formik.values.note}
          onClick={() => {
            formik.handleSubmit();
            setOpen(false);
            onSubmit();
          }}
        >
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(NoteDialog);
